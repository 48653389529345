import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, difference, each, every, intersection, keyBy, map } from 'lodash';

import { CallService, EnkoraMessageService, Helpers, Logger } from 'shared';

import { PermissionGroups, TaActiveDataHolderService, TaCompanyInfo, TaEmployment } from '../../../../../shared';

@Component({
	templateUrl : './permissions.modal.html',
	styleUrls   : ['./permissions.modal.scss']
})
export class PermissionsModal implements OnInit {

	@Input() public standalone = true;

	public latest: PermissionGroups;
	public initial: PermissionGroups;

	public is_valid = true;
	public no_changes = true;
	public save_message = '';
	public companies: TaCompanyInfo[] = [];
	public show_individual_end_date = true;
	public show_header_individual_end_date = true;

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            public dataHolder: TaActiveDataHolderService)
	{
	}

	ngOnInit(): void
	{
		this.companies = [];
		each(this.dataHolder.employments.employers, (employer: TaEmployment) => {
			this.companies.push(employer.company.company_info);
		});
	}

	back(): void
	{
		this.activeModal.dismiss('back');
	}

	close(): void
	{
		this.activeModal.dismiss(this.initial.groups);
	}

	confirm(): void
	{
		if (this.no_changes) {
			this.activeModal.close();
			return;
		}
		if (!this.is_valid) return;

		this.savePermissions();
	}

	savePermissions(): void
	{
		const multi_call = [];
		let i = 1;

		const to_delete = difference(map(this.initial.groups, 'id'), map(this.latest.groups, 'id'));
		const to_add = difference(map(this.latest.groups, 'id'), to_delete);

		each(to_delete, group_id => {
			multi_call.push({
				call : `cta2.updateAccessRightTemplateGroupEmployees.${i++}`,
				args : [{
					access_right_template_group_id : group_id,
					delete                         : true,
					account_ids                    : [this.dataHolder.employee.personal_info.account_id]
				}]
			});
		});

		const latest_group_map = keyBy(this.latest.groups, 'id');

		each(to_add, group_id => {
			multi_call.push({
				call : `cta2.updateAccessRightTemplateGroupEmployees.${i++}`,
				args : [{
					access_right_template_group_id : group_id,
					account_ids                    : [this.dataHolder.employee.personal_info.account_id],
					access_right_expiration        : latest_group_map[group_id].access_right_expiration ?
						latest_group_map[group_id].access_right_expiration.endOf('day').format('YYYY-MM-DD HH:mm:ss') : null
				}]
			});
		});

		this.call.make('common/multicall', [multi_call]).subscribe(() => {
			this.activeModal.close(this.latest.groups);
		}, (error) => {
			this.messageService.error(error);
		});
	}

	handlePermissionChange(params: PermissionGroups): void
	{
		this.latest = params;
		if (!this.initial) this.initial = cloneDeep(params);

		const group_map = keyBy(this.latest.groups, 'id');

		const len = this.initial.groups.length;
		this.no_changes = len == params.groups.length &&
			len == intersection(map(this.initial.groups, 'id'), map(this.latest.groups, 'id')).length;

		if (this.no_changes) {
			this.no_changes = every(this.initial.groups, group =>
				Helpers.areMomentDatesSame(
					group.access_right_expiration,
					group_map[group.id].access_right_expiration)
			);
		}

		const dates_valid: boolean = every(this.latest.groups, group => {
			return !group.access_right_expiration ||
				Helpers.areMomentDateInRange(group.access_right_expiration, group.valid_from_date, group.valid_until_date);
		});

		this.no_changes = this.no_changes || !dates_valid;

		this.save_message = this.no_changes ? '' : 'Save and ';

		Logger.log('Form: Modified permission data: ', params, this.initial, this.latest);
	}
}
