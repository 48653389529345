import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { ModalOpenerService } from 'shared';

import { LoginState, LoginStepsData, ShopRegistrationFieldsService } from '../services';

import { ShopLoginModal } from './login/login.modal';
import { ShopRegisterModal } from './register/register.modal';
import { ShopReminderModal } from './reminder/reminder.modal';

@Injectable()
export class ShopLoginStepsService {

	constructor(private modalHelper: ModalOpenerService,
	            private registerFields: ShopRegistrationFieldsService)
	{
	}

	public login(): Observable<boolean>
	{
		return new Observable((observer: Observer<boolean>) => {
			this.openLogin(observer);
		});
	}

	private openLogin(observer: Observer<boolean>)
	{
		const modalRef = this.modalHelper.openShopModal(ShopLoginModal);

		modalRef.result.then((result: LoginStepsData) => {
			switch (result.state) {
				case LoginState.Login:
					observer.next(true);
					observer.complete();
					break;
				case LoginState.Register:
					this.openRegister(observer);
					break;
				case LoginState.Reminder:
					this.openReminder(observer, result.value);
					break;
			}
		}, () => {
		});
	}

	private openRegister(observer: Observer<boolean>)
	{
		this.registerFields.get().subscribe(() => {
			const modalRef = this.modalHelper.openShopModal(ShopRegisterModal);

			modalRef.result.then((result: LoginStepsData) => {
				switch (result.state) {
					case LoginState.Close:
						observer.next(true);
						observer.complete();
						break;
					case LoginState.Reminder:
						this.openReminder(observer, result.value);
						break;
					default:
						this.openLogin(observer);
						break;
				}
			}, () => {
				observer.next(false);
				observer.complete();
			});
		});
	}

	private openReminder(observer: Observer<boolean>, email = '')
	{
		const modalRef = this.modalHelper.openShopModal(ShopReminderModal);
		if (email) {
			(modalRef.componentInstance as ShopReminderModal).email = email;
		}

		modalRef.result.then((result: LoginStepsData) => {
			if (result) {
			} else {
				this.openLogin(observer);
			}
		}, () => {
			observer.next(false);
			observer.complete();
		});
	}
}
