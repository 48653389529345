export class Constants {
	// NOTE: The file is generated, DO NOT edit manually!
	static USER_DEFAULT_DEVICE = 3;
	static USER_DEFAULT_GUEST = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static ET_LOG = 1;
	static ET_INSPECTION = 2;
	static ET_SALE = 3;
	static ET_PAYMENT = 4;
	static ET_ISSUING = 5;
	static ET_LOADING = 6;
	static ET_CHARGING = 7;
	static ET_VALIDATION = 8;
	static ET_ACCESS = 9;
	static ET_REVERSE_SALE = 10;
	static ET_RECEIPT = 11;
	static ET_SHIFT = 12;
	static ET_END_SHIFT = 13;
	static ET_TRANSFER_ACCESS_RIGHT = 14;
	static ET_LOCKER = 15;
	static ET_PRODUCT_EXTENSION = 16;
	static ET_LOCKER_OPEN_REQUEST = 17;
	static ET_EDIT = 18;
	static ET_INVENTORY = 19;
	static ET_MESSAGE = 20;
	static ET_TOKEN_ATTACH = 21;
	static ET_TOKEN_DETACH = 22;
	static ET_TRANSFER_RESERVATION = 23;
	static ET_IDENTITY_CHECK = 24;
	static ET_CASHBOX = 25;
	static ET_DEVICE_OFFLINE = 26;
	static ET_LOCKER_NEEDS_CLEANING = 27;
	static ET_LOCKER_NEEDS_MAINTENANCE = 28;
	static ET_DEVICE_PRINTER_OK = 29;
	static ET_DEVICE_PRINTER_PAPER_IS_LOW = 30;
	static ET_DEVICE_PRINTER_NO_PAPER = 31;
	static ET_DEVICE_PRINTER_OTHER_ERROR = 32;

	// NOTE: The file is generated, DO NOT edit manually!
	static EDIT_NEW = 1;
	static EDIT_EDIT = 2;
	static EDIT_DELETE = 3;
	static EDIT_ADD_RELATION = 4;
	static EDIT_DELETE_RELATION = 5;
	static EDIT_UPDATE_RELATION = 6;

	// NOTE: The file is generated, DO NOT edit manually!
	static CB_CASHBOX = 1;
	static CB_EXTERNAL = 2;

	// NOTE: The file is generated, DO NOT edit manually!
	static CE_SHIFT_START = 1;
	static CE_SHIFT_END = 2;
	static CE_CORRECTION = 3;
	static CE_PAYMENT = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static INVENTORY_ADD = 1;
	static INVENTORY_CORRECTION = 2;
	static INVENTORY_SALE = 3;
	static INVENTORY_OWN_USE = 4;
	static INVENTORY_MOVE = 5;
	static INVENTORY_LOST = 6;
	static INVENTORY_RETURN = 7;
	static INVENTORY_PICK_UP = 8;

	// NOTE: The file is generated, DO NOT edit manually!
	static IC_NEW = 1;
	static IC_GOOD = 2;
	static IC_SATISFACTORY = 3;
	static IC_BROKEN = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static AR_INVALID = 'invalid';
	static AR_CHARGEABLE = 'chargeable';
	static AR_VALIDABLE = 'validable';
	static AR_INSPECTABLE = 'inspectable';
	static AR_NOT_YET = 'not_yet';

	// NOTE: The file is generated, DO NOT edit manually!
	static TOKEN_MIFARE_CARD = 1;
	static TOKEN_MIFARE_KEYFOB = 2;
	static TOKEN_BARCODE = 3;
	static TOKEN_PIN = 4;
	static TOKEN_MOBILE = 5;
	static TOKEN_MICROLOG = 6;
	static TOKEN_EPC = 7;
	static TOKEN_HID = 8;
	static TOKEN_NT = 9;
	static TOKEN_EM = 10;
	static TOKEN_ICODE = 11;
	static TOKEN_INDALA = 12;

	// NOTE: The file is generated, DO NOT edit manually!
	static TOKEN_PRE_MIFARE_CARD = 'mf_';
	static TOKEN_PRE_MIFARE_KEYFOB = 'mf_';
	static TOKEN_PRE_BARCODE = 'bc_';
	static TOKEN_PRE_PIN = 'pin_';
	static TOKEN_PRE_MOBILE = 'mt_';
	static TOKEN_PRE_MICROLOG = 'ml_';
	static TOKEN_PRE_EPC = 'epc_';
	static TOKEN_PRE_HID = 'hid_';
	static TOKEN_PRE_NT = 'nt_';
	static TOKEN_PRE_EM = 'em_';
	static TOKEN_PRE_ICODE = 'ic_';
	static TOKEN_PRE_INDALA = 'ind_';

	// NOTE: The file is generated, DO NOT edit manually!
	static NM_MAIN = 1;
	static NM_EN_AGREEMENTS = 2;
	static NM_EN_CUSTOMERS = 3;
	static NM_EN_RESERVATIONS = 4;
	static NM_EN_PAYMENTS = 5;
	static NM_EN_ACCESS_CONTROL = 6;
	static NM_EN_LOCKERS = 7;
	static NM_EN_CRM = 8;
	static NM_EN_SALESPOINT = 9;
	static NM_EN_ANALYTICS = 10;
	static NM_EN_TA = 11;
	static NM_EN_OFFLINE = 12;
	static NM_EN_ACS_CONTROL_PANEL = 13;
	static NM_EN_PRODUCTS = 14;
	static NM_EN_NEXUS_DEPLOY = 15;
	static NM_EN_WEBSHOP = 16;
	static NM_EN_BALANCE = 17;
	static NM_EN_DMS = 18;
	static NM_EN_SUBSCRIPTIONS = 19;
	static NM_EN_SST = 20;
	static NM_EN_PIPEDRIVE_INTEGRATION = 21;
	static NM_UNUSED_22 = 22;
	static NM_UNUSED_23 = 23;
	static NM_UNUSED_100 = 100;
	static NM_UNUSED_101 = 101;
	static NM_EN_INVENTORY = 102;
	static NM_EN_SMS = 103;
	static NM_EN_CTA = 104;
	static NM_EN_STATION = 107;
	static NM_EN_ONTHEMOVE = 109;
	static NM_UNUSED_24 = 24;
	static NM_WINTIME_INTEGRATION = 25;
	static NM_TIKON_INTEGRATION = 26;
	static NM_ACTIVE_CAMPAIGN_INTEGRATION = 27;
	static NM_CTA_TAX_REPORTING = 28;
	static NM_UNUSED_29 = 29;
	static NM_FIDELIX_INTEGRATION = 30;
	static NM_NEXGOLF_INTEGRATION = 31;
	static NM_MICROFLEX_INTEGRATION = 32;
	static NM_EFINA_INTEGRATION = 33;
	static NM_GOOGLE_MAPS_INTEGRATION = 34;
	static NM_SAP_EXPORT = 35;
	static NM_ABLOY_OS_INTEGRATION = 36;
	static NM_NETVISOR_EXPORT = 37;
	static NM_PROE_ACCOUNTING_INTEGRATION = 38;
	static NM_PROE_INVOICE_INTEGRATION = 39;
	static NM_MOBILE_CUSTOMER_SERVICE = 40;
	static NM_TILAAJAVASTUU_INTEGRATION = 41;
	static NM_EXCEL_EXPORT = 42;
	static NM_AURINKOMATKAT_SAP = 43;
	static NM_GIFTCARD = 44;
	static NM_VESILIIKUNTAKORTTI = 45;
	static NM_UNUSED_46 = 46;
	static NM_GOLFLIITTO_INTEGRATION = 47;
	static NM_ESPOO_ACCOUNTING_INTEGRATION = 48;
	static NM_CLUB_MANAGEMENT_INTEGRATION = 49;
	static NM_HSL_INTEGRATION = 50;
	static NM_FK_VIITEMAKSUT_INTEGRATION = 51;
	static NM_COMMUNITY_INTEGRATION = 52;
	static NM_SMARTSHIP_INTEGRATION = 53;
	static NM_ID06_INTEGRATION = 54;
	static NM_HMS_INTEGRATION = 55;
	static NM_RAMISMART_INVENTORY_SERVICE = 56;
	static NM_UNUSED_57 = 57;
	static NM_LEMONSOFT_ACCOUNTING_INTEGRATION = 58;
	static NM_LEMONSOFT_INVOICE_INTEGRATION = 59;
	static NM_E1_INTEGRATION = 60;
	static NM_HELSINKI_ASIOINTI_INTEGRATION = 61;
	static NM_TALPA_SAP_INVOICE_INTEGRATION = 62;
	static NM_PAYMENT_HIGHWAY_INTEGRATION = 63;
	static NM_VTJ_INTEGRATION = 64;
	static NM_R8_INVOICE_INTEGRATION = 65;
	static NM_PROCOUNTOR_ACCOUNTING_INTEGRATION = 66;
	static NM_RAINDANCE_ACCOUNTING_INTEGRATION = 67;
	static NM_VEIKKAUS_SAP_ACCOUNTING_INTEGRATION = 68;
	static NM_MYWELLNESS_INTEGRATION = 69;
	static NM_EPASSI_INTEGRATION = 70;
	static NM_SURVEYPAL_INTEGRATION = 71;
	static NM_VR_SAP_ACCOUNTING_INTEGRATION = 72;

	// NOTE: The file is generated, DO NOT edit manually!
	static UT_ADMINISTRATOR = 1;
	static UT_SALES_PERSON = 2;
	static UT_DRIVER = 3;
	static UT_DEVICE_DAEMON = 4;
	static UT_GUEST = 5;
	static UT_CUSTOMER = 6;
	static UT_MANAGER = 7;
	static UT_EXTERNAL = 8;
	static UT_INSTRUCTOR = 9;
	static UT_ACCESS_MANAGER = 10;
	static UT_EMPLOYEE = 11;
	static UT_TA_MANAGER = 12;
	static UT_EMPLOYER = 13;
	static UT_SERVICE_PROVIDER = 14;
	static UT_RESERVATION_MANAGER = 15;
	static UT_EXTERNAL_SALES_PERSON = 16;
	static UT_ACCOUNTANT = 17;
	static UT_LOCKER_SERVICE_DESK = 18;
	static UT_RESERVATION_VIEWER = 19;
	static UT_DASHBOARD_VIEWER = 20;
	static UT_SALES_AND_RESERVATIONS = 21;
	static UT_SIMPLIFIED_MANAGER = 22;
	static UT_SIMPLIFIED_SALES_PERSON = 23;
	static UT_INSTRUCTOR_AND_RESERVATIONS = 24;
	static UT_SERVICE_DESK = 25;
	static UT_SERVICE_DESK_ADVANCED = 26;
	static UT_LOCKER_MANAGER = 27;
	static UT_MAIN_USER = 28;

	// NOTE: The file is generated, DO NOT edit manually!
	static DEVICE_SERVER = 1;
	static DEVICE_SALES_POINT = 2;
	static DEVICE_TURNSTILE = 3;
	static DEVICE_DOOR = 4;
	static DEVICE_VIRTUAL_ACS = 5;
	static DEVICE_LOCKER = 6;
	static DEVICE_EXTERNAL = 7;
	static DEVICE_SST = 8;
	static DEVICE_MOBILE = 9;
	static DEVICE_VIRTUAL_POS = 10;
	static DEVICE_MOBILE_POS = 11;
	static DEVICE_WEBSHOP_WEB = 12;
	static DEVICE_CUSTOMER_MOBILE_APP = 13;
	static DEVICE_LOCKER_CONTROLLER = 14;

	// NOTE: The file is generated, DO NOT edit manually!
	static APT_TURNSTILE = 1;
	static APT_DOOR_WITH_PINPAD = 2;
	static APT_DOOR_WITHOUT_PINPAD = 3;
	static APT_TURNSTILE_WITH_SST = 4;
	static APT_WITH_SST = 5;

	// NOTE: The file is generated, DO NOT edit manually!
	static DAY_WORKDAY = 8;
	static DAY_WEEKEND = 9;
	static DAY_HOLIDAY = 10;
	static DAY_ANY_DAY = 11;

	// NOTE: The file is generated, DO NOT edit manually!
	static FPT_NORMAL = 1;
	static FPT_CARD_PRODUCT = 2;
	static FPT_PRODUCT_WITH_VALIDATION = 3;
	static FPT_CARD_DEPOSIT = 4;
	static FPT_AGREEMENT_BALANCE = 5;
	static FPT_DEPOSIT = 6;
	static FPT_BUNDLE = 7;
	static FPT_LOAD_BALANCE = 8;
	static FPT_DISCOUNT = 9;
	static FPT_INVOICE_PAYMENT = 10;
	static FPT_CASH_WITHDRAWAL = 11;
	static FPT_SUBSCRIPTION = 12;

	// NOTE: The file is generated, DO NOT edit manually!
	static CG_ADULT = 1;
	static CG_STUDENT = 2;
	static CG_SENIOR = 3;
	static CG_CHILD = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static INVOICE_NEW = 1;
	static INVOICE_APPROVED = 2;
	static INVOICE_SENT = 3;
	static INVOICE_PAID = 4;
	static INVOICE_CANCELLED = 5;
	static INVOICE_IN_DEBT_COLLECTION = 6;
	static INVOICE_CREDIT_LOSS = 7;

	// NOTE: The file is generated, DO NOT edit manually!
	static INVOICE_PAYMENT_BANK = 1;
	static INVOICE_PAYMENT_POS = 2;
	static INVOICE_PAYMENT_NETVISOR = 3;
	static INVOICE_PAYMENT_NEXUS = 4;
	static INVOICE_PAYMENT_VISMA_PPG = 5;

	// NOTE: The file is generated, DO NOT edit manually!
	static PT_CASH = 1;
	static PT_CARD = 2;
	static PT_INVOICE = 3;
	static PT_COUPON = 4;
	static PT_OBLIGATION = 5;
	static PT_EXTERNAL = 6;
	static PT_ROUNDING = 7;
	static PT_ONLINE = 8;
	static PT_EXTERNAL_INVOICE = 9;
	static PT_PAY_LATER = 10;
	static PT_AGREEMENT_BALANCE = 11;
	static PT_UNUSED_12 = 12;
	static PT_BALANCE = 13;

	// NOTE: The file is generated, DO NOT edit manually!
	static PC_CASH = 1;
	static PC_CARD = 2;
	static PC_INVOICE = 3;
	static PC_COUPON = 4;
	static PC_OBLIGATION = 5;
	static PC_EXTERNAL = 6;
	static PC_ROUNDING = 7;
	static PC_ONLINE = 8;
	static PC_EXTERNAL_INVOICE = 9;
	static PC_PAY_LATER = 10;
	static PC_AGREEMENT_BALANCE = 11;
	static PC_UNUSED_12 = 12;
	static PC_BALANCE = 13;
	static PC_CASH_EXTRA = 14;
	static PC_CASH_MISSING = 15;
	static PC_DISCOUNT = 16;

	// NOTE: The file is generated, DO NOT edit manually!
	static PRICE_FIXED_PRICE = 1;
	static PRICE_PROMPT_ALWAYS = 2;
	static PRICE_PROMPT_OPTIONALLY = 3;
	static PRICE_TIME_SLOT_PRICE = 4;
	static PRICE_NEGATIVE_PRICE = 5;
	static PRICE_CUSTOMER_INPUT = 6;

	// NOTE: The file is generated, DO NOT edit manually!
	static APM_CLOSED = 1;
	static APM_ACCESS_RIGHT = 2;
	static APM_ACCESS_RIGHT_AND_PIN = 3;
	static APM_BUTTON = 4;
	static APM_ALWAYS_OPEN = 5;
	static APM_NO_BARRIER = 6;
	static APM_PIN = 7;
	static APM_UNCONTROLLED = 8;
	static APM_SELECT = 9;
	static APM_ALWAYS_OPEN_AND_INSPECT = 10;

	// NOTE: The file is generated, DO NOT edit manually!
	static TA_WORK = 1;
	static TA_OUT = 2;
	static TA_LUNCH = 3;
	static TA_SICK_LEAVE = 4;
	static TA_VACATION = 5;
	static TA_BUSINESS_TRIP = 6;
	static TA_HOLIDAY = 7;
	static TA_WEEKEND = 8;
	static TA_WORK_OUT = 9;
	static TA_SMOKING = 10;
	static TA_OVERTIME = 11;
	static TA_LUNCH_IN = 12;
	static TA_IN_EARLIER = 13;
	static TA_TOMORROW = 14;
	static TA_PAID_LEAVE = 15;

	// NOTE: The file is generated, DO NOT edit manually!
	static TATT_TOTAL = 1;
	static TATT_DAILY_EXTRA_WORK = 2;
	static TATT_DAILY_OVERTIME_1 = 3;
	static TATT_DAILY_OVERTIME_2 = 4;
	static TATT_WEEKLY_EXTRA_TIME = 5;
	static TATT_WEEKLY_OVERTIME_1 = 6;
	static TATT_WEEKLY_OVERTIME_2 = 7;
	static TATT_HOLIDAY_WORK = 8;
	static TATT_REGULAR = 9;
	static TATT_FLEXIBLE_TIME = 10;
	static TATT_DAILY_REST = 11;
	static TATT_DAILY_LUNCH = 12;
	static TATT_DAILY_SMOKE = 13;
	static TATT_WORKDAY_1 = 14;
	static TATT_WORKDAY_2 = 15;
	static TATT_WEEKEND_1 = 16;
	static TATT_WEEKEND_2 = 17;

	// NOTE: The file is generated, DO NOT edit manually!
	static GENDER_MALE = 1;
	static GENDER_FEMALE = 2;
	static GENDER_OTHER = 3;
	static GENDER_UNDISCLOSED = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static RES_QUEUE = 1;
	static RES_RESERVED = 2;
	static RES_CONFIRMED_QUEUE = 3;
	static RES_CONFIRMED_RESERVED = 4;
	static RES_ADMITTED = 5;
	static RES_CANCELLED = 6;
	static RES_UNCONFIRMED = 7;
	static RES_UNCONFIRMED_QUEUE = 8;
	static RES_LATE = 9;

	// NOTE: The file is generated, DO NOT edit manually!
	static REPORT_GRAPH_VERTICAL = 1;
	static REPORT_GRAPH_HORIZONTAL = 2;

	// NOTE: The file is generated, DO NOT edit manually!
	static MODEL_ACTION_MESSAGE_TYPE_MODAL = 'modal';
	static MODEL_ACTION_MESSAGE_TYPE_GET_REDIRECT = 'get_redirect';
	static MODEL_ACTION_MESSAGE_TYPE_ALERT = 'alert';
	static MODEL_ACTION_MESSAGE_TYPE_FORM = 'form';
	static MODEL_ACTION_MESSAGE_TYPE_CONFIRM = 'confirm';

	// NOTE: The file is generated, DO NOT edit manually!
	static MESSAGE_EMAIL = 1;
	static MESSAGE_SMS = 2;

	// NOTE: The file is generated, DO NOT edit manually!
	static MESSAGE_STATUS_DRAFT = 1;
	static MESSAGE_STATUS_NEW = 2;
	static MESSAGE_STATUS_SENT = 3;
	static MESSAGE_STATUS_DELIVERED = 4;
	static MESSAGE_STATUS_MESSAGE_READ = 5;
	static MESSAGE_STATUS_NOT_DELIVERED = 6;
	static MESSAGE_STATUS_CANCELLED = 7;

	// NOTE: The file is generated, DO NOT edit manually!
	static MESSAGE_CODE_RESERVATION_END_NOTIFICATION = 1;
	static MESSAGE_CODE_RESERVATION_START_NOTIFICATION = 2;

	// NOTE: The file is generated, DO NOT edit manually!
	static ORDER_TYPE_SALE = 1;
	static ORDER_TYPE_RETURN = 2;
	static ORDER_TYPE_REQUEST = 3;

	// NOTE: The file is generated, DO NOT edit manually!
	static ORDER_STATUS_NEW = 1;
	static ORDER_STATUS_CANCELLED = 2;
	static ORDER_STATUS_SENT = 3;
	static ORDER_STATUS_DELIVERED = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static DELIVERY_TYPE_EMAIL = 1;
	static DELIVERY_TYPE_MAIL_STANDARD = 2;
	static DELIVERY_TYPE_MAIL_EXPRESS = 3;
	static DELIVERY_TYPE_GIFTCARD = 4;
	static DELIVERY_TYPE_PRINT = 5;

	// NOTE: The file is generated, DO NOT edit manually!
	static SUBSCRIPTION_INVOICING_TYPE_CURRENT_PERIOD = 1;
	static SUBSCRIPTION_INVOICING_TYPE_NEXT_PERIOD = 2;
	static SUBSCRIPTION_INVOICING_TYPE_PREVIOUS_PERIOD = 3;
	static SUBSCRIPTION_INVOICING_TYPE_CALENDAR_MONTH = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static INVOICE_DELIVERY_TYPE_AUTOMATIC = 1;
	static INVOICE_DELIVERY_TYPE_DO_NOT_SEND = 2;
	static INVOICE_DELIVERY_TYPE_POST = 3;
	static INVOICE_DELIVERY_TYPE_EMAIL = 4;
	static INVOICE_DELIVERY_TYPE_ELECTRONIC_INVOICE = 5;

	// NOTE: The file is generated, DO NOT edit manually!
	static RAPPL_STATUS_DRAFT = 1;
	static RAPPL_STATUS_SUBMITTED = 2;
	static RAPPL_STATUS_IN_PROGRESS = 3;
	static RAPPL_STATUS_APPROVED = 4;
	static RAPPL_STATUS_DECLINED = 5;

	// NOTE: The file is generated, DO NOT edit manually!
	static ST_CUSTOMER_ACCESS = 1;
	static ST_EMPLOYEE_ACCESS = 2;
	static ST_ACCESS_MODE_ALWAYS_OPEN = 3;
	static ST_LOCKER_MASTER = 4;
	static ST_DOCUMENT_LOCKER = 5;
	static ST_KIOSK_MASTER = 7;
	static ST_GIVE_ONE_ACCESS = 8;
	static ST_LOCKER_ITEM_EXCHANGE = 9;
	static ST_LOCKER_NORMAL = 10;
	static ST_APPOINTMENT_RESERVATION = 11;
	static ST_SYSTEM_ACCESS = 12;
	static ST_HOTEL_RESERVATIONS = 13;
	static ST_LOCKER_CLEANING = 14;
	static ST_LOCKER_UPKEEP = 15;
	static ST_LOCKER_MAINTENANCE_SERVICE = 16;

	// NOTE: The file is generated, DO NOT edit manually!
	static LOCKER_LOG = 1;
	static LOCKER_RESERVE = 2;
	static LOCKER_END_RESERVATION = 3;
	static LOCKER_LOCK = 4;
	static LOCKER_UNLOCK = 5;
	static LOCKER_ENTER = 6;
	static LOCKER_EXIT = 7;

	// NOTE: The file is generated, DO NOT edit manually!
	static LOCKER_STATUS_IN_USE = 1;
	static LOCKER_STATUS_BROKEN = 2;
	static LOCKER_STATUS_NEEDS_CLEANING = 3;
	static LOCKER_STATUS_MAINTENANCE_TESTING = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static CONTRACT_RESERVATION = 1;

	// NOTE: The file is generated, DO NOT edit manually!
	static TAG_GROUP_TYPE_COURSE_DISCOUNT = 1;
	static TAG_GROUP_TYPE_CUSTOMER_VIDEO_LEVEL = 2;
	static TAG_GROUP_TYPE_PAYROLL_TAGS = 3;
	static TAG_GROUP_TYPE_ACCESSIBILITY = 8;
	static TAG_GROUP_TYPE_COURSE_FILTER = 9;

	// NOTE: The file is generated, DO NOT edit manually!
	static TAX_CODE_EMPLOYMENT = 1;
	static TAX_CODE_AGENCY_WORKER = 2;
	static TAX_CODE_PRACTITIONER = 3;
	static TAX_CODE_TRAINEE = 4;
	static TAX_CODE_VISITOR = 255;

	// NOTE: The file is generated, DO NOT edit manually!
	static BUSINESS_ID_TYPE_NONE = 0;
	static BUSINESS_ID_TYPE_VAT_NUMBER = 1;
	static BUSINESS_ID_TYPE_FOREIGN_TRADE_REGISTER_NO = 2;
	static BUSINESS_ID_TYPE_TIN = 3;
	static BUSINESS_ID_TYPE_FOREIGN_CIVIC_REG_NO = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static SUPPORT_EMAIL = 'tuki@enkora.fi';
	static SUPPORT_PHONE = '+358 9 425 51007';

	// NOTE: The file is generated, DO NOT edit manually!
	static ACCOUNT_BLOCK_RESERVATION_PENALTY = 1;

	// NOTE: The file is generated, DO NOT edit manually!
	static CAMPAIGN_TYPE_AUTOMATIC = 1;
	static CAMPAIGN_TYPE_INPUT_CODE = 2;
	static CAMPAIGN_TYPE_ACCOUNT = 3;

	// NOTE: The file is generated, DO NOT edit manually!
	static INSTRUCTOR_STATUS_NORMAL = 1;
	static INSTRUCTOR_STATUS_SICK_LEAVE = 2;
	static INSTRUCTOR_STATUS_EMERGENCY_BACKUP = 3;

	// NOTE: The file is generated, DO NOT edit manually!
	static HM_OB = 'EN_OB';
	static HM_BB = 'EN_BB';
	static HM_RB = 'EN_RB';
	static HM_WB = 'EN_WB';
	static HM_PC_WIN = 'EN_PC_WIN';

	// NOTE: The file is generated, DO NOT edit manually!
	static NDF_DATETIME = 'Y-m-d H:i:s';
	static NDF_DATE = 'Y-m-d';
	static NDF_LAST_DATE_OF_MONTH = 'Y-m-t';
	static NDF_TIME = 'H:i:s';
	static NDF_TIME_MINUTES = 'H:i';
	static NDF_MONTH = 'Y-m';
	static NDF_YEAR = 'Y';
	static NDF_MINUTE = 'Y-m-d H:i';
	static NDF_HOUR = 'Y-m-d H';
	static NDF_WEEK = 'Y-\WW';
	static NDF_DATETIME_XML = 'Y-m-d\TH:i:s';
	static NDF_FI_DATE = 'd/m/Y';
	static NDF_FI_DATETIME = 'd.m.Y H:i:s';
	static NDF_FI_DATETIME_MINUTES = 'd.m.Y H:i';

	// NOTE: The file is generated, DO NOT edit manually!
	static NDT_SECOND = 1;
	static NDT_MINUTE = 60;
	static NDT_HOUR = 3600;
	static NDT_DAY = 86400;
	static NDT_WEEK = 604800;
	static NDT_MONTH = 2592000;
	static NDT_YEAR = 31536000;

	// NOTE: The file is generated, DO NOT edit manually!
	static NDT_ZERO_DATE = '0000-00-00';
	static NDT_ZERO_TIME = '00:00:00';
	static NDT_ZERO_DATETIME = '0000-00-00 00:00:00';

	// NOTE: The file is generated, DO NOT edit manually!
	static NDT_DATE_PAST = '1900-01-01';
	static NDT_DATE_FUTURE = '3000-01-01';

	// NOTE: The file is generated, DO NOT edit manually!
	static NDT_END_TIME = '23:59:59';

	// NOTE: The file is generated, DO NOT edit manually!
	static WLG_NEXUS_HEADER = 1;

	// NOTE: The file is generated, DO NOT edit manually!
	static WEBSHOP_SHOP = -1;
	static WEBSHOP_PUBLIC_CALENDAR = -2;
	static WEBSHOP_RESERVATION_APPLICATION = -3;

	// NOTE: The file is generated, DO NOT edit manually!
	static F_HIDDEN = 'hidden';
	static F_TEXT = 'text';
	static F_LONG_TEXT = 'long_text';
	static F_WYSIWYG = 'wysiwyg';
	static F_PASSWORD = 'password';
	static F_DROPDOWN = 'dropdown';
	static F_RADIO = 'radio';
	static F_MULTISELECT = 'multiselect';
	static F_CHECKBOX = 'checkbox';
	static F_BOOLEAN_CHECKBOX = 'boolean_checkbox';
	static F_DURATION = 'duration';
	static F_READONLY = 'readonly';
	static F_FILE = 'file';

	// NOTE: The file is generated, DO NOT edit manually!
	static SUOMIFI_AUTHENTICATION_TIMESTAMP = 'suomi.fi authentication timestamp';
	static SUOMIFI_CUSTOMER_INFORMATION_CHECKED_BY_USER = 'suomi.fi customer information checked by user';

	// NOTE: The file is generated, DO NOT edit manually!
	static PRINTER_OK = 1;
	static PRINTER_PAPER_LOW = 2;
	static PRINTER_NO_PAPER = 3;
	static PRINTER_OTHER = 4;

	// NOTE: The file is generated, DO NOT edit manually!
	static CT_RAMIRENT_SALES_PERSON = 1;
	static CT_CUSTOMER_CENTER_CONTACT = 2;
	static CT_RAMISMART_HUB = 3;
	static CT_FOREIGN_WORKER_REPRESENTATIVE = 4;


	static USER_DEFAULTS = {
		[Constants.USER_DEFAULT_DEVICE]: 'Device',
		[Constants.USER_DEFAULT_GUEST]: 'Guest',
	};

	static EVENT_TYPES = {
		[Constants.ET_LOG]: 'Log',
		[Constants.ET_INSPECTION]: 'Inspection',
		[Constants.ET_SALE]: 'Sale',
		[Constants.ET_PAYMENT]: 'Payment',
		[Constants.ET_ISSUING]: 'Issuing',
		[Constants.ET_LOADING]: 'Loading',
		[Constants.ET_CHARGING]: 'Charging',
		[Constants.ET_VALIDATION]: 'Validation',
		[Constants.ET_ACCESS]: 'Access',
		[Constants.ET_REVERSE_SALE]: 'Reverse Sale',
		[Constants.ET_RECEIPT]: 'Receipt',
		[Constants.ET_SHIFT]: 'Shift',
		[Constants.ET_END_SHIFT]: 'End Shift',
		[Constants.ET_TRANSFER_ACCESS_RIGHT]: 'Transfer Access Right',
		[Constants.ET_LOCKER]: 'Locker',
		[Constants.ET_PRODUCT_EXTENSION]: 'Product Extension',
		[Constants.ET_LOCKER_OPEN_REQUEST]: 'Locker Open Request',
		[Constants.ET_EDIT]: 'Edit',
		[Constants.ET_INVENTORY]: 'Inventory',
		[Constants.ET_MESSAGE]: 'Message',
		[Constants.ET_TOKEN_ATTACH]: 'Token Attach',
		[Constants.ET_TOKEN_DETACH]: 'Token Detach',
		[Constants.ET_TRANSFER_RESERVATION]: 'Transfer Reservation',
		[Constants.ET_IDENTITY_CHECK]: 'Identity Check',
		[Constants.ET_CASHBOX]: 'Cashbox',
		[Constants.ET_DEVICE_OFFLINE]: 'Device Offline',
		[Constants.ET_LOCKER_NEEDS_CLEANING]: 'Locker Needs Cleaning',
		[Constants.ET_LOCKER_NEEDS_MAINTENANCE]: 'Locker Needs Maintenance',
		[Constants.ET_DEVICE_PRINTER_OK]: 'Device Printer Ok',
		[Constants.ET_DEVICE_PRINTER_PAPER_IS_LOW]: 'Device Printer Paper Is Low',
		[Constants.ET_DEVICE_PRINTER_NO_PAPER]: 'Device Printer No Paper',
		[Constants.ET_DEVICE_PRINTER_OTHER_ERROR]: 'Device Printer Other Error',
	};

	static EDIT_TYPES = {
		[Constants.EDIT_NEW]: 'New',
		[Constants.EDIT_EDIT]: 'Edit',
		[Constants.EDIT_DELETE]: 'Delete',
		[Constants.EDIT_ADD_RELATION]: 'Add Relation',
		[Constants.EDIT_DELETE_RELATION]: 'Delete Relation',
		[Constants.EDIT_UPDATE_RELATION]: 'Update Relation',
	};

	static CASHBOX_TYPES = {
		[Constants.CB_CASHBOX]: 'Cashbox',
		[Constants.CB_EXTERNAL]: 'External',
	};

	static CASHBOX_EVENT_TYPES = {
		[Constants.CE_SHIFT_START]: 'Shift Start',
		[Constants.CE_SHIFT_END]: 'Shift End',
		[Constants.CE_CORRECTION]: 'Correction',
		[Constants.CE_PAYMENT]: 'Payment',
	};

	static INVENTORY_EVENT_TYPES = {
		[Constants.INVENTORY_ADD]: 'Add',
		[Constants.INVENTORY_CORRECTION]: 'Correction',
		[Constants.INVENTORY_SALE]: 'Sale',
		[Constants.INVENTORY_OWN_USE]: 'Own Use',
		[Constants.INVENTORY_MOVE]: 'Move',
		[Constants.INVENTORY_LOST]: 'Lost',
		[Constants.INVENTORY_RETURN]: 'Return',
		[Constants.INVENTORY_PICK_UP]: 'Pick Up',
	};

	static INVENTORY_CONDITIONS = {
		[Constants.IC_NEW]: 'New',
		[Constants.IC_GOOD]: 'Good',
		[Constants.IC_SATISFACTORY]: 'Satisfactory',
		[Constants.IC_BROKEN]: 'Broken',
	};

	static ACCESS_RIGHT_STATUS = {
		[Constants.AR_INVALID]: 'Invalid',
		[Constants.AR_CHARGEABLE]: 'Chargeable',
		[Constants.AR_VALIDABLE]: 'Validable',
		[Constants.AR_INSPECTABLE]: 'Inspectable',
		[Constants.AR_NOT_YET]: 'Not Yet',
	};

	static TOKEN_TYPES = {
		[Constants.TOKEN_MIFARE_CARD]: 'Mifare Card',
		[Constants.TOKEN_MIFARE_KEYFOB]: 'Mifare Keyfob',
		[Constants.TOKEN_BARCODE]: 'Barcode',
		[Constants.TOKEN_PIN]: 'Pin',
		[Constants.TOKEN_MOBILE]: 'Mobile',
		[Constants.TOKEN_MICROLOG]: 'Microlog',
		[Constants.TOKEN_EPC]: 'Epc',
		[Constants.TOKEN_HID]: 'Hid',
		[Constants.TOKEN_NT]: 'Nt',
		[Constants.TOKEN_EM]: 'Em',
		[Constants.TOKEN_ICODE]: 'Icode',
		[Constants.TOKEN_INDALA]: 'Indala',
	};

	static TOKEN_PREFIXES = {
		[Constants.TOKEN_PRE_MIFARE_CARD]: 'Mifare Card',
		[Constants.TOKEN_PRE_BARCODE]: 'Barcode',
		[Constants.TOKEN_PRE_PIN]: 'Pin',
		[Constants.TOKEN_PRE_MOBILE]: 'Mobile',
		[Constants.TOKEN_PRE_MICROLOG]: 'Microlog',
		[Constants.TOKEN_PRE_EPC]: 'Epc',
		[Constants.TOKEN_PRE_HID]: 'Hid',
		[Constants.TOKEN_PRE_NT]: 'Nt',
		[Constants.TOKEN_PRE_EM]: 'Em',
		[Constants.TOKEN_PRE_ICODE]: 'Icode',
		[Constants.TOKEN_PRE_INDALA]: 'Indala',
	};

	static MODULES = {
		[Constants.NM_MAIN]: 'Main',
		[Constants.NM_EN_AGREEMENTS]: 'En Agreements',
		[Constants.NM_EN_CUSTOMERS]: 'En Customers',
		[Constants.NM_EN_RESERVATIONS]: 'En Reservations',
		[Constants.NM_EN_PAYMENTS]: 'En Payments',
		[Constants.NM_EN_ACCESS_CONTROL]: 'En Access Control',
		[Constants.NM_EN_LOCKERS]: 'En Lockers',
		[Constants.NM_EN_CRM]: 'En Crm',
		[Constants.NM_EN_SALESPOINT]: 'En Salespoint',
		[Constants.NM_EN_ANALYTICS]: 'En Analytics',
		[Constants.NM_EN_TA]: 'En Ta',
		[Constants.NM_EN_OFFLINE]: 'En Offline',
		[Constants.NM_EN_ACS_CONTROL_PANEL]: 'En Acs Control Panel',
		[Constants.NM_EN_PRODUCTS]: 'En Products',
		[Constants.NM_EN_NEXUS_DEPLOY]: 'En Nexus Deploy',
		[Constants.NM_EN_WEBSHOP]: 'En Webshop',
		[Constants.NM_EN_BALANCE]: 'En Balance',
		[Constants.NM_EN_DMS]: 'En Dms',
		[Constants.NM_EN_SUBSCRIPTIONS]: 'En Subscriptions',
		[Constants.NM_EN_SST]: 'En Sst',
		[Constants.NM_EN_PIPEDRIVE_INTEGRATION]: 'En Pipedrive Integration',
		[Constants.NM_UNUSED_22]: 'Unused 22',
		[Constants.NM_UNUSED_23]: 'Unused 23',
		[Constants.NM_UNUSED_100]: 'Unused 100',
		[Constants.NM_UNUSED_101]: 'Unused 101',
		[Constants.NM_EN_INVENTORY]: 'En Inventory',
		[Constants.NM_EN_SMS]: 'En Sms',
		[Constants.NM_EN_CTA]: 'En Cta',
		[Constants.NM_EN_STATION]: 'En Station',
		[Constants.NM_EN_ONTHEMOVE]: 'En Onthemove',
		[Constants.NM_UNUSED_24]: 'Unused 24',
		[Constants.NM_WINTIME_INTEGRATION]: 'Wintime Integration',
		[Constants.NM_TIKON_INTEGRATION]: 'Tikon Integration',
		[Constants.NM_ACTIVE_CAMPAIGN_INTEGRATION]: 'Active Campaign Integration',
		[Constants.NM_CTA_TAX_REPORTING]: 'Cta Tax Reporting',
		[Constants.NM_UNUSED_29]: 'Unused 29',
		[Constants.NM_FIDELIX_INTEGRATION]: 'Fidelix Integration',
		[Constants.NM_NEXGOLF_INTEGRATION]: 'Nexgolf Integration',
		[Constants.NM_MICROFLEX_INTEGRATION]: 'Microflex Integration',
		[Constants.NM_EFINA_INTEGRATION]: 'Efina Integration',
		[Constants.NM_GOOGLE_MAPS_INTEGRATION]: 'Google Maps Integration',
		[Constants.NM_SAP_EXPORT]: 'Sap Export',
		[Constants.NM_ABLOY_OS_INTEGRATION]: 'Abloy Os Integration',
		[Constants.NM_NETVISOR_EXPORT]: 'Netvisor Export',
		[Constants.NM_PROE_ACCOUNTING_INTEGRATION]: 'Proe Accounting Integration',
		[Constants.NM_PROE_INVOICE_INTEGRATION]: 'Proe Invoice Integration',
		[Constants.NM_MOBILE_CUSTOMER_SERVICE]: 'Mobile Customer Service',
		[Constants.NM_TILAAJAVASTUU_INTEGRATION]: 'Tilaajavastuu Integration',
		[Constants.NM_EXCEL_EXPORT]: 'Excel Export',
		[Constants.NM_AURINKOMATKAT_SAP]: 'Aurinkomatkat Sap',
		[Constants.NM_GIFTCARD]: 'Giftcard',
		[Constants.NM_VESILIIKUNTAKORTTI]: 'Vesiliikuntakortti',
		[Constants.NM_UNUSED_46]: 'Unused 46',
		[Constants.NM_GOLFLIITTO_INTEGRATION]: 'Golfliitto Integration',
		[Constants.NM_ESPOO_ACCOUNTING_INTEGRATION]: 'Espoo Accounting Integration',
		[Constants.NM_CLUB_MANAGEMENT_INTEGRATION]: 'Club Management Integration',
		[Constants.NM_HSL_INTEGRATION]: 'Hsl Integration',
		[Constants.NM_FK_VIITEMAKSUT_INTEGRATION]: 'Fk Viitemaksut Integration',
		[Constants.NM_COMMUNITY_INTEGRATION]: 'Community Integration',
		[Constants.NM_SMARTSHIP_INTEGRATION]: 'Smartship Integration',
		[Constants.NM_ID06_INTEGRATION]: 'Id06 Integration',
		[Constants.NM_HMS_INTEGRATION]: 'Hms Integration',
		[Constants.NM_RAMISMART_INVENTORY_SERVICE]: 'Ramismart Inventory Service',
		[Constants.NM_UNUSED_57]: 'Unused 57',
		[Constants.NM_LEMONSOFT_ACCOUNTING_INTEGRATION]: 'Lemonsoft Accounting Integration',
		[Constants.NM_LEMONSOFT_INVOICE_INTEGRATION]: 'Lemonsoft Invoice Integration',
		[Constants.NM_E1_INTEGRATION]: 'E1 Integration',
		[Constants.NM_HELSINKI_ASIOINTI_INTEGRATION]: 'Helsinki Asiointi Integration',
		[Constants.NM_TALPA_SAP_INVOICE_INTEGRATION]: 'Talpa Sap Invoice Integration',
		[Constants.NM_PAYMENT_HIGHWAY_INTEGRATION]: 'Payment Highway Integration',
		[Constants.NM_VTJ_INTEGRATION]: 'Vtj Integration',
		[Constants.NM_R8_INVOICE_INTEGRATION]: 'R8 Invoice Integration',
		[Constants.NM_PROCOUNTOR_ACCOUNTING_INTEGRATION]: 'Procountor Accounting Integration',
		[Constants.NM_RAINDANCE_ACCOUNTING_INTEGRATION]: 'Raindance Accounting Integration',
		[Constants.NM_VEIKKAUS_SAP_ACCOUNTING_INTEGRATION]: 'Veikkaus Sap Accounting Integration',
		[Constants.NM_MYWELLNESS_INTEGRATION]: 'Mywellness Integration',
		[Constants.NM_EPASSI_INTEGRATION]: 'Epassi Integration',
		[Constants.NM_SURVEYPAL_INTEGRATION]: 'Surveypal Integration',
		[Constants.NM_VR_SAP_ACCOUNTING_INTEGRATION]: 'Vr Sap Accounting Integration',
	};

	static USER_TYPES = {
		[Constants.UT_ADMINISTRATOR]: 'Administrator',
		[Constants.UT_SALES_PERSON]: 'Sales Person',
		[Constants.UT_DRIVER]: 'Driver',
		[Constants.UT_DEVICE_DAEMON]: 'Device Daemon',
		[Constants.UT_GUEST]: 'Guest',
		[Constants.UT_CUSTOMER]: 'Customer',
		[Constants.UT_MANAGER]: 'Manager',
		[Constants.UT_EXTERNAL]: 'External',
		[Constants.UT_INSTRUCTOR]: 'Instructor',
		[Constants.UT_ACCESS_MANAGER]: 'Access Manager',
		[Constants.UT_EMPLOYEE]: 'Employee',
		[Constants.UT_TA_MANAGER]: 'Ta Manager',
		[Constants.UT_EMPLOYER]: 'Employer',
		[Constants.UT_SERVICE_PROVIDER]: 'Service Provider',
		[Constants.UT_RESERVATION_MANAGER]: 'Reservation Manager',
		[Constants.UT_EXTERNAL_SALES_PERSON]: 'External Sales Person',
		[Constants.UT_ACCOUNTANT]: 'Accountant',
		[Constants.UT_LOCKER_SERVICE_DESK]: 'Locker Service Desk',
		[Constants.UT_RESERVATION_VIEWER]: 'Reservation Viewer',
		[Constants.UT_DASHBOARD_VIEWER]: 'Dashboard Viewer',
		[Constants.UT_SALES_AND_RESERVATIONS]: 'Sales And Reservations',
		[Constants.UT_SIMPLIFIED_MANAGER]: 'Simplified Manager',
		[Constants.UT_SIMPLIFIED_SALES_PERSON]: 'Simplified Sales Person',
		[Constants.UT_INSTRUCTOR_AND_RESERVATIONS]: 'Instructor And Reservations',
		[Constants.UT_SERVICE_DESK]: 'Service Desk',
		[Constants.UT_SERVICE_DESK_ADVANCED]: 'Service Desk Advanced',
		[Constants.UT_LOCKER_MANAGER]: 'Locker Manager',
		[Constants.UT_MAIN_USER]: 'Main User',
	};

	static DEVICE_TYPES = {
		[Constants.DEVICE_SERVER]: 'Server',
		[Constants.DEVICE_SALES_POINT]: 'Sales Point',
		[Constants.DEVICE_TURNSTILE]: 'Turnstile',
		[Constants.DEVICE_DOOR]: 'Door',
		[Constants.DEVICE_VIRTUAL_ACS]: 'Virtual Acs',
		[Constants.DEVICE_LOCKER]: 'Locker',
		[Constants.DEVICE_EXTERNAL]: 'External',
		[Constants.DEVICE_SST]: 'Sst',
		[Constants.DEVICE_MOBILE]: 'Mobile',
		[Constants.DEVICE_VIRTUAL_POS]: 'Virtual Pos',
		[Constants.DEVICE_MOBILE_POS]: 'Mobile Pos',
		[Constants.DEVICE_WEBSHOP_WEB]: 'Webshop Web',
		[Constants.DEVICE_CUSTOMER_MOBILE_APP]: 'Customer Mobile App',
		[Constants.DEVICE_LOCKER_CONTROLLER]: 'Locker Controller',
	};

	static ACCESS_POINT_TYPES = {
		[Constants.APT_TURNSTILE]: 'Turnstile',
		[Constants.APT_DOOR_WITH_PINPAD]: 'Door With Pinpad',
		[Constants.APT_DOOR_WITHOUT_PINPAD]: 'Door Without Pinpad',
		[Constants.APT_TURNSTILE_WITH_SST]: 'Turnstile With Sst',
		[Constants.APT_WITH_SST]: 'With Sst',
	};

	static DAY_TYPES = {
		[Constants.DAY_WORKDAY]: 'Workday',
		[Constants.DAY_WEEKEND]: 'Weekend',
		[Constants.DAY_HOLIDAY]: 'Holiday',
		[Constants.DAY_ANY_DAY]: 'Any Day',
	};

	static FARE_PRODUCT_TYPES = {
		[Constants.FPT_NORMAL]: 'Normal',
		[Constants.FPT_CARD_PRODUCT]: 'Card Product',
		[Constants.FPT_PRODUCT_WITH_VALIDATION]: 'Product With Validation',
		[Constants.FPT_CARD_DEPOSIT]: 'Card Deposit',
		[Constants.FPT_AGREEMENT_BALANCE]: 'Agreement Balance',
		[Constants.FPT_DEPOSIT]: 'Deposit',
		[Constants.FPT_BUNDLE]: 'Bundle',
		[Constants.FPT_LOAD_BALANCE]: 'Load Balance',
		[Constants.FPT_DISCOUNT]: 'Discount',
		[Constants.FPT_INVOICE_PAYMENT]: 'Invoice Payment',
		[Constants.FPT_CASH_WITHDRAWAL]: 'Cash Withdrawal',
		[Constants.FPT_SUBSCRIPTION]: 'Subscription',
	};

	static CUSTOMER_GROUPS = {
		[Constants.CG_ADULT]: 'Adult',
		[Constants.CG_STUDENT]: 'Student',
		[Constants.CG_SENIOR]: 'Senior',
		[Constants.CG_CHILD]: 'Child',
	};

	static INVOICE_STATUS = {
		[Constants.INVOICE_NEW]: 'New',
		[Constants.INVOICE_APPROVED]: 'Approved',
		[Constants.INVOICE_SENT]: 'Sent',
		[Constants.INVOICE_PAID]: 'Paid',
		[Constants.INVOICE_CANCELLED]: 'Cancelled',
		[Constants.INVOICE_IN_DEBT_COLLECTION]: 'In Debt Collection',
		[Constants.INVOICE_CREDIT_LOSS]: 'Credit Loss',
	};

	static INVOICE_PAYMENT_SOURCES = {
		[Constants.INVOICE_PAYMENT_BANK]: 'Bank',
		[Constants.INVOICE_PAYMENT_POS]: 'Pos',
		[Constants.INVOICE_PAYMENT_NETVISOR]: 'Netvisor',
		[Constants.INVOICE_PAYMENT_NEXUS]: 'Nexus',
		[Constants.INVOICE_PAYMENT_VISMA_PPG]: 'Visma Ppg',
	};

	static PAYMENT_TYPES = {
		[Constants.PT_CASH]: 'Cash',
		[Constants.PT_CARD]: 'Card',
		[Constants.PT_INVOICE]: 'Invoice',
		[Constants.PT_COUPON]: 'Coupon',
		[Constants.PT_OBLIGATION]: 'Obligation',
		[Constants.PT_EXTERNAL]: 'External',
		[Constants.PT_ROUNDING]: 'Rounding',
		[Constants.PT_ONLINE]: 'Online',
		[Constants.PT_EXTERNAL_INVOICE]: 'External Invoice',
		[Constants.PT_PAY_LATER]: 'Pay Later',
		[Constants.PT_AGREEMENT_BALANCE]: 'Agreement Balance',
		[Constants.PT_UNUSED_12]: 'Unused 12',
		[Constants.PT_BALANCE]: 'Balance',
	};

	static PAYMENT_CLASS = {
		[Constants.PC_CASH]: 'Cash',
		[Constants.PC_CARD]: 'Card',
		[Constants.PC_INVOICE]: 'Invoice',
		[Constants.PC_COUPON]: 'Coupon',
		[Constants.PC_OBLIGATION]: 'Obligation',
		[Constants.PC_EXTERNAL]: 'External',
		[Constants.PC_ROUNDING]: 'Rounding',
		[Constants.PC_ONLINE]: 'Online',
		[Constants.PC_EXTERNAL_INVOICE]: 'External Invoice',
		[Constants.PC_PAY_LATER]: 'Pay Later',
		[Constants.PC_AGREEMENT_BALANCE]: 'Agreement Balance',
		[Constants.PC_UNUSED_12]: 'Unused 12',
		[Constants.PC_BALANCE]: 'Balance',
		[Constants.PC_CASH_EXTRA]: 'Cash Extra',
		[Constants.PC_CASH_MISSING]: 'Cash Missing',
		[Constants.PC_DISCOUNT]: 'Discount',
	};

	static PRICE_TYPES = {
		[Constants.PRICE_FIXED_PRICE]: 'Fixed Price',
		[Constants.PRICE_PROMPT_ALWAYS]: 'Prompt Always',
		[Constants.PRICE_PROMPT_OPTIONALLY]: 'Prompt Optionally',
		[Constants.PRICE_TIME_SLOT_PRICE]: 'Time Slot Price',
		[Constants.PRICE_NEGATIVE_PRICE]: 'Negative Price',
		[Constants.PRICE_CUSTOMER_INPUT]: 'Customer Input',
	};

	static ACCESS_MODES = {
		[Constants.APM_CLOSED]: 'Closed',
		[Constants.APM_ACCESS_RIGHT]: 'Access Right',
		[Constants.APM_ACCESS_RIGHT_AND_PIN]: 'Access Right And Pin',
		[Constants.APM_BUTTON]: 'Button',
		[Constants.APM_ALWAYS_OPEN]: 'Always Open',
		[Constants.APM_NO_BARRIER]: 'No Barrier',
		[Constants.APM_PIN]: 'Pin',
		[Constants.APM_UNCONTROLLED]: 'Uncontrolled',
		[Constants.APM_SELECT]: 'Select',
		[Constants.APM_ALWAYS_OPEN_AND_INSPECT]: 'Always Open And Inspect',
	};

	static TA_STATUS = {
		[Constants.TA_WORK]: 'Work',
		[Constants.TA_OUT]: 'Out',
		[Constants.TA_LUNCH]: 'Lunch',
		[Constants.TA_SICK_LEAVE]: 'Sick Leave',
		[Constants.TA_VACATION]: 'Vacation',
		[Constants.TA_BUSINESS_TRIP]: 'Business Trip',
		[Constants.TA_HOLIDAY]: 'Holiday',
		[Constants.TA_WEEKEND]: 'Weekend',
		[Constants.TA_WORK_OUT]: 'Work Out',
		[Constants.TA_SMOKING]: 'Smoking',
		[Constants.TA_OVERTIME]: 'Overtime',
		[Constants.TA_LUNCH_IN]: 'Lunch In',
		[Constants.TA_IN_EARLIER]: 'In Earlier',
		[Constants.TA_TOMORROW]: 'Tomorrow',
		[Constants.TA_PAID_LEAVE]: 'Paid Leave',
	};

	static TA_TIME_TYPES = {
		[Constants.TATT_TOTAL]: 'Total',
		[Constants.TATT_DAILY_EXTRA_WORK]: 'Daily Extra Work',
		[Constants.TATT_DAILY_OVERTIME_1]: 'Daily Overtime 1',
		[Constants.TATT_DAILY_OVERTIME_2]: 'Daily Overtime 2',
		[Constants.TATT_WEEKLY_EXTRA_TIME]: 'Weekly Extra Time',
		[Constants.TATT_WEEKLY_OVERTIME_1]: 'Weekly Overtime 1',
		[Constants.TATT_WEEKLY_OVERTIME_2]: 'Weekly Overtime 2',
		[Constants.TATT_HOLIDAY_WORK]: 'Holiday Work',
		[Constants.TATT_REGULAR]: 'Regular',
		[Constants.TATT_FLEXIBLE_TIME]: 'Flexible Time',
		[Constants.TATT_DAILY_REST]: 'Daily Rest',
		[Constants.TATT_DAILY_LUNCH]: 'Daily Lunch',
		[Constants.TATT_DAILY_SMOKE]: 'Daily Smoke',
		[Constants.TATT_WORKDAY_1]: 'Workday 1',
		[Constants.TATT_WORKDAY_2]: 'Workday 2',
		[Constants.TATT_WEEKEND_1]: 'Weekend 1',
		[Constants.TATT_WEEKEND_2]: 'Weekend 2',
	};

	static GENDERS = {
		[Constants.GENDER_MALE]: 'Male',
		[Constants.GENDER_FEMALE]: 'Female',
		[Constants.GENDER_OTHER]: 'Other',
		[Constants.GENDER_UNDISCLOSED]: 'Undisclosed',
	};

	static RESERVATION_STATUS = {
		[Constants.RES_QUEUE]: 'Queue',
		[Constants.RES_RESERVED]: 'Reserved',
		[Constants.RES_CONFIRMED_QUEUE]: 'Confirmed Queue',
		[Constants.RES_CONFIRMED_RESERVED]: 'Confirmed Reserved',
		[Constants.RES_ADMITTED]: 'Admitted',
		[Constants.RES_CANCELLED]: 'Cancelled',
		[Constants.RES_UNCONFIRMED]: 'Unconfirmed',
		[Constants.RES_UNCONFIRMED_QUEUE]: 'Unconfirmed Queue',
		[Constants.RES_LATE]: 'Late',
	};

	static REPORT_GRAPHS = {
		[Constants.REPORT_GRAPH_VERTICAL]: 'Vertical',
		[Constants.REPORT_GRAPH_HORIZONTAL]: 'Horizontal',
	};

	static MODEL_ACTION_MESSAGE_TYPES = {
		[Constants.MODEL_ACTION_MESSAGE_TYPE_MODAL]: 'Modal',
		[Constants.MODEL_ACTION_MESSAGE_TYPE_GET_REDIRECT]: 'Get Redirect',
		[Constants.MODEL_ACTION_MESSAGE_TYPE_ALERT]: 'Alert',
		[Constants.MODEL_ACTION_MESSAGE_TYPE_FORM]: 'Form',
		[Constants.MODEL_ACTION_MESSAGE_TYPE_CONFIRM]: 'Confirm',
	};

	static MESSAGE_TYPES = {
		[Constants.MESSAGE_EMAIL]: 'Email',
		[Constants.MESSAGE_SMS]: 'Sms',
	};

	static MESSAGE_STATUS = {
		[Constants.MESSAGE_STATUS_DRAFT]: 'Draft',
		[Constants.MESSAGE_STATUS_NEW]: 'New',
		[Constants.MESSAGE_STATUS_SENT]: 'Sent',
		[Constants.MESSAGE_STATUS_DELIVERED]: 'Delivered',
		[Constants.MESSAGE_STATUS_MESSAGE_READ]: 'Message Read',
		[Constants.MESSAGE_STATUS_NOT_DELIVERED]: 'Not Delivered',
		[Constants.MESSAGE_STATUS_CANCELLED]: 'Cancelled',
	};

	static MESSAGE_CODES = {
		[Constants.MESSAGE_CODE_RESERVATION_END_NOTIFICATION]: 'Reservation End Notification',
		[Constants.MESSAGE_CODE_RESERVATION_START_NOTIFICATION]: 'Reservation Start Notification',
	};

	static ORDER_TYPES = {
		[Constants.ORDER_TYPE_SALE]: 'Sale',
		[Constants.ORDER_TYPE_RETURN]: 'Return',
		[Constants.ORDER_TYPE_REQUEST]: 'Request',
	};

	static ORDER_STATUS = {
		[Constants.ORDER_STATUS_NEW]: 'New',
		[Constants.ORDER_STATUS_CANCELLED]: 'Cancelled',
		[Constants.ORDER_STATUS_SENT]: 'Sent',
		[Constants.ORDER_STATUS_DELIVERED]: 'Delivered',
	};

	static DELIVERY_TYPES = {
		[Constants.DELIVERY_TYPE_EMAIL]: 'Email',
		[Constants.DELIVERY_TYPE_MAIL_STANDARD]: 'Mail Standard',
		[Constants.DELIVERY_TYPE_MAIL_EXPRESS]: 'Mail Express',
		[Constants.DELIVERY_TYPE_GIFTCARD]: 'Giftcard',
		[Constants.DELIVERY_TYPE_PRINT]: 'Print',
	};

	static SUBSCRIPTION_INVOICING_TYPES = {
		[Constants.SUBSCRIPTION_INVOICING_TYPE_CURRENT_PERIOD]: 'Current Period',
		[Constants.SUBSCRIPTION_INVOICING_TYPE_NEXT_PERIOD]: 'Next Period',
		[Constants.SUBSCRIPTION_INVOICING_TYPE_PREVIOUS_PERIOD]: 'Previous Period',
		[Constants.SUBSCRIPTION_INVOICING_TYPE_CALENDAR_MONTH]: 'Calendar Month',
	};

	static INVOICE_DELIVERY_TYPES = {
		[Constants.INVOICE_DELIVERY_TYPE_AUTOMATIC]: 'Automatic',
		[Constants.INVOICE_DELIVERY_TYPE_DO_NOT_SEND]: 'Do Not Send',
		[Constants.INVOICE_DELIVERY_TYPE_POST]: 'Post',
		[Constants.INVOICE_DELIVERY_TYPE_EMAIL]: 'Email',
		[Constants.INVOICE_DELIVERY_TYPE_ELECTRONIC_INVOICE]: 'Electronic Invoice',
	};

	static RESERVATION_APPLICATION_STATUS = {
		[Constants.RAPPL_STATUS_DRAFT]: 'Draft',
		[Constants.RAPPL_STATUS_SUBMITTED]: 'Submitted',
		[Constants.RAPPL_STATUS_IN_PROGRESS]: 'In Progress',
		[Constants.RAPPL_STATUS_APPROVED]: 'Approved',
		[Constants.RAPPL_STATUS_DECLINED]: 'Declined',
	};

	static SERVICE_TYPES = {
		[Constants.ST_CUSTOMER_ACCESS]: 'Customer Access',
		[Constants.ST_EMPLOYEE_ACCESS]: 'Employee Access',
		[Constants.ST_ACCESS_MODE_ALWAYS_OPEN]: 'Access Mode Always Open',
		[Constants.ST_LOCKER_MASTER]: 'Locker Master',
		[Constants.ST_DOCUMENT_LOCKER]: 'Document Locker',
		[Constants.ST_KIOSK_MASTER]: 'Kiosk Master',
		[Constants.ST_GIVE_ONE_ACCESS]: 'Give One Access',
		[Constants.ST_LOCKER_ITEM_EXCHANGE]: 'Locker Item Exchange',
		[Constants.ST_LOCKER_NORMAL]: 'Locker Normal',
		[Constants.ST_APPOINTMENT_RESERVATION]: 'Appointment Reservation',
		[Constants.ST_SYSTEM_ACCESS]: 'System Access',
		[Constants.ST_HOTEL_RESERVATIONS]: 'Hotel Reservations',
		[Constants.ST_LOCKER_CLEANING]: 'Locker Cleaning',
		[Constants.ST_LOCKER_UPKEEP]: 'Locker Upkeep',
		[Constants.ST_LOCKER_MAINTENANCE_SERVICE]: 'Locker Maintenance Service',
	};

	static LOCKER_EVENT_TYPE_CODES = {
		[Constants.LOCKER_LOG]: 'Log',
		[Constants.LOCKER_RESERVE]: 'Reserve',
		[Constants.LOCKER_END_RESERVATION]: 'End Reservation',
		[Constants.LOCKER_LOCK]: 'Lock',
		[Constants.LOCKER_UNLOCK]: 'Unlock',
		[Constants.LOCKER_ENTER]: 'Enter',
		[Constants.LOCKER_EXIT]: 'Exit',
	};

	static LOCKER_STATUS = {
		[Constants.LOCKER_STATUS_IN_USE]: 'In Use',
		[Constants.LOCKER_STATUS_BROKEN]: 'Broken',
		[Constants.LOCKER_STATUS_NEEDS_CLEANING]: 'Needs Cleaning',
		[Constants.LOCKER_STATUS_MAINTENANCE_TESTING]: 'Maintenance Testing',
	};

	static CONTRACT_TYPES = {
		[Constants.CONTRACT_RESERVATION]: 'Reservation',
	};

	static TAG_GROUP_TYPES = {
		[Constants.TAG_GROUP_TYPE_COURSE_DISCOUNT]: 'Course Discount',
		[Constants.TAG_GROUP_TYPE_CUSTOMER_VIDEO_LEVEL]: 'Customer Video Level',
		[Constants.TAG_GROUP_TYPE_PAYROLL_TAGS]: 'Payroll Tags',
		[Constants.TAG_GROUP_TYPE_ACCESSIBILITY]: 'Accessibility',
		[Constants.TAG_GROUP_TYPE_COURSE_FILTER]: 'Course Filter',
	};

	static CTA_TAX_CODES = {
		[Constants.TAX_CODE_EMPLOYMENT]: 'Employment',
		[Constants.TAX_CODE_AGENCY_WORKER]: 'Agency Worker',
		[Constants.TAX_CODE_PRACTITIONER]: 'Practitioner',
		[Constants.TAX_CODE_TRAINEE]: 'Trainee',
		[Constants.TAX_CODE_VISITOR]: 'Visitor',
	};

	static BUSINESS_ID_TYPES = {
		[Constants.BUSINESS_ID_TYPE_NONE]: 'None',
		[Constants.BUSINESS_ID_TYPE_VAT_NUMBER]: 'Vat Number',
		[Constants.BUSINESS_ID_TYPE_FOREIGN_TRADE_REGISTER_NO]: 'Foreign Trade Register No',
		[Constants.BUSINESS_ID_TYPE_TIN]: 'Tin',
		[Constants.BUSINESS_ID_TYPE_FOREIGN_CIVIC_REG_NO]: 'Foreign Civic Reg No',
	};

	static SUPPORTS = {
		[Constants.SUPPORT_EMAIL]: 'Email',
		[Constants.SUPPORT_PHONE]: 'Phone',
	};

	static ACCOUNT_BLOCK_TYPES = {
		[Constants.ACCOUNT_BLOCK_RESERVATION_PENALTY]: 'Reservation Penalty',
	};

	static CAMPAIGN_TYPES = {
		[Constants.CAMPAIGN_TYPE_AUTOMATIC]: 'Automatic',
		[Constants.CAMPAIGN_TYPE_INPUT_CODE]: 'Input Code',
		[Constants.CAMPAIGN_TYPE_ACCOUNT]: 'Account',
	};

	static INSTRUCTOR_STATUS = {
		[Constants.INSTRUCTOR_STATUS_NORMAL]: 'Normal',
		[Constants.INSTRUCTOR_STATUS_SICK_LEAVE]: 'Sick Leave',
		[Constants.INSTRUCTOR_STATUS_EMERGENCY_BACKUP]: 'Emergency Backup',
	};

	static HARDWARE_MODELS = {
		[Constants.HM_OB]: 'Ob',
		[Constants.HM_BB]: 'Bb',
		[Constants.HM_RB]: 'Rb',
		[Constants.HM_WB]: 'Wb',
		[Constants.HM_PC_WIN]: 'Pc Win',
	};

	static DATE_FORMATS = {
		[Constants.NDF_DATETIME]: 'Datetime',
		[Constants.NDF_DATE]: 'Date',
		[Constants.NDF_LAST_DATE_OF_MONTH]: 'Last Date Of Month',
		[Constants.NDF_TIME]: 'Time',
		[Constants.NDF_TIME_MINUTES]: 'Time Minutes',
		[Constants.NDF_MONTH]: 'Month',
		[Constants.NDF_YEAR]: 'Year',
		[Constants.NDF_MINUTE]: 'Minute',
		[Constants.NDF_HOUR]: 'Hour',
		[Constants.NDF_WEEK]: 'Week',
		[Constants.NDF_DATETIME_XML]: 'Datetime Xml',
		[Constants.NDF_FI_DATE]: 'Fi Date',
		[Constants.NDF_FI_DATETIME]: 'Fi Datetime',
		[Constants.NDF_FI_DATETIME_MINUTES]: 'Fi Datetime Minutes',
	};

	static DATETIME_PARTS_IN_SECONDS = {
		[Constants.NDT_SECOND]: 'Second',
		[Constants.NDT_MINUTE]: 'Minute',
		[Constants.NDT_HOUR]: 'Hour',
		[Constants.NDT_DAY]: 'Day',
		[Constants.NDT_WEEK]: 'Week',
		[Constants.NDT_MONTH]: 'Month',
		[Constants.NDT_YEAR]: 'Year',
	};

	static ZERO_DATES = {
		[Constants.NDT_ZERO_DATE]: 'Date',
		[Constants.NDT_ZERO_TIME]: 'Time',
		[Constants.NDT_ZERO_DATETIME]: 'Datetime',
	};

	static DATE_FUTURE_AND_PASTS = {
		[Constants.NDT_DATE_PAST]: 'Past',
		[Constants.NDT_DATE_FUTURE]: 'Future',
	};

	static DAY_END_TIMES = {
		[Constants.NDT_END_TIME]: 'Time',
	};

	static WEB_LINK_GROUPS = {
		[Constants.WLG_NEXUS_HEADER]: 'Nexus Header',
	};

	static WEBSHOP_SERVICE_IDS = {
		[Constants.WEBSHOP_SHOP]: 'Shop',
		[Constants.WEBSHOP_PUBLIC_CALENDAR]: 'Public Calendar',
		[Constants.WEBSHOP_RESERVATION_APPLICATION]: 'Reservation Application',
	};

	static FIELD_TYPES = {
		[Constants.F_HIDDEN]: 'Hidden',
		[Constants.F_TEXT]: 'Text',
		[Constants.F_LONG_TEXT]: 'Long Text',
		[Constants.F_WYSIWYG]: 'Wysiwyg',
		[Constants.F_PASSWORD]: 'Password',
		[Constants.F_DROPDOWN]: 'Dropdown',
		[Constants.F_RADIO]: 'Radio',
		[Constants.F_MULTISELECT]: 'Multiselect',
		[Constants.F_CHECKBOX]: 'Checkbox',
		[Constants.F_BOOLEAN_CHECKBOX]: 'Boolean Checkbox',
		[Constants.F_DURATION]: 'Duration',
		[Constants.F_READONLY]: 'Readonly',
		[Constants.F_FILE]: 'File',
	};

	static SUOMIFIS = {
		[Constants.SUOMIFI_AUTHENTICATION_TIMESTAMP]: 'Authentication Timestamp',
		[Constants.SUOMIFI_CUSTOMER_INFORMATION_CHECKED_BY_USER]: 'Customer Information Checked By User',
	};

	static PRINTER_STATUS = {
		[Constants.PRINTER_OK]: 'Ok',
		[Constants.PRINTER_PAPER_LOW]: 'Paper Low',
		[Constants.PRINTER_NO_PAPER]: 'No Paper',
		[Constants.PRINTER_OTHER]: 'Other',
	};

	static CONTACT_TYPES = {
		[Constants.CT_RAMIRENT_SALES_PERSON]: 'Ramirent Sales Person',
		[Constants.CT_CUSTOMER_CENTER_CONTACT]: 'Customer Center Contact',
		[Constants.CT_RAMISMART_HUB]: 'Ramismart Hub',
		[Constants.CT_FOREIGN_WORKER_REPRESENTATIVE]: 'Foreign Worker Representative',
	};
}
