<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">
		<div class="form-group row" *ngIf="!only_address">
			<label [for]="id + '_co'" class="col-md-3 col-form-label text-input-label" tt>C/O</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       [id]="id + '_co'"
				       name="co"
				       formControlName="co">
			</div>
		</div>
		<div class="form-group row" *ngIf="!only_address">
			<label [for]="id + '_po_box'" class="col-md-3 col-form-label text-input-label" tt>PO box</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       [id]="id + '_po_box'"
				       name="po_box"
				       formControlName="po_box">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + '_street'" class="col-md-3 col-form-label text-input-label" tt>Street address</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       [id]="id + '_street'"
				       name="street"
				       formControlName="street">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + '_postcode'" class="col-md-3 col-form-label text-input-label" tt>Postcode</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       [id]="id + '_postcode'"
				       name="postcode"
				       [placeholder]="post_code_placeholder"
				       formControlName="postcode">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + '_city'" class="col-md-3 col-form-label text-input-label" tt>City</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       [id]="id + '_city'"
				       name="city"
				       formControlName="city">
			</div>
		</div>
	</div>
</ng-container>