<ng-container *ngIf="inputForm">
	<form [formGroup]="inputForm">
		<ng-container *ngIf="companyForm">
			<div [formGroup]="companyForm">
				<h5 class="pt-5" tt>Company information</h5>

				<cta-ta-form-company-info [inputForm]="companyInfoForm"
				                          [modification_forbidden]="!!employee_id"
				                          (onOrganizationSelected)="organizationSelected($event)"
				                          (onCountryCodeSelected)="countryCodeSelected($event)">
				</cta-ta-form-company-info>

				<cta-ta-form-company-representative [inputForm]="contactForm"
				                                    [readonly]="employee_id"
				                                    [company_form]="company_form">
				</cta-ta-form-company-representative>

				<cta-ta-form-company-representative *ngIf="is_show_foreign_contact_form"
				                                    [inputForm]="foreignContactForm"
				                                    [readonly]="employee_id"
				                                    [company_form]="company_form"
				                                    [is_foreign_contact]="true">
				</cta-ta-form-company-representative>
			</div>
		</ng-container>

		<cta-ta-form-employee-contract *ngIf="show_contract"
		                               [inputForm]="contractForm">
		</cta-ta-form-employee-contract>
	</form>
</ng-container>
