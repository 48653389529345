<div class="modal-header">
	<span class="aria-focus" tabindex="0"></span>
	<h3 tabindex="0" class="modal-title" tt>Schedule</h3>
	<button type="button" class="close" tabindex="-1" aria-hidden="true" (click)="activeModal.dismiss()">
		<span>&times;</span>
	</button>
</div>

<div class="modal-body">
	<table class="table eventGroupSchedule">
		<thead>
			<tr>
				<th tabindex="0" colspan="2" tt>Date</th>
				<th tabindex="0" tt>Time</th>
			</tr>
		</thead>
		<tbody>
			<tr tabindex="0" *ngFor="let row of rows">
				<td>{{ row.weekday }}</td>
				<td>{{ row.date }}</td>
				<td class="ue-time">{{ row.time }}</td>
			</tr>
		</tbody>
	</table>
</div>

<div class="modal-footer">
	<button class="btn btn"
	        id="cancelButton"
	        (click)="activeModal.dismiss()" tt>
		Close
	</button>
</div>
