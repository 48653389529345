import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name : 'emptyor'
})
export class EmptyOrValuePipe implements PipeTransform {

	transform<T>(value: T, empty= ''): T | string
	{
		if (!value) return empty;

		return value;
	}
}
