import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReceiptVatConfigurationService {
	get is_vat_zero() : any
	{
		return this._is_vat_zero;
	}

	set is_vat_zero(value : any)
	{
		this._is_vat_zero = value;
		this.is_vat_zero$.next(value);
	}

	private _is_vat_zero = false;
	public is_vat_zero$ = new BehaviorSubject<any>(this._is_vat_zero);

	constructor()
	{
	}
}
