import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SelectReceiptDataService {
	get data() : any {
		return this._data;
	}

	set data(value : any) {
		this._data = value;
		this.data$.next(value);
	}

	private _data : {
		id           ? : any,
		reservations ? : any,
		receipt_id   ? : any
	} = {};

	public data$ = new BehaviorSubject<any>(this._data);

	constructor() {
	}
}
