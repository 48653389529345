<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">
		<div class="form-group row">
			<label for="site_name" class="col-md-3 col-form-label text-input-label" tt>Site name</label>
			<div class="col-md-9">
				<input [readonly]="readonly"
				       class="form-control"
				       type="text"
				       id="site_name"
				       name="name"
				       formControlName="name">
			</div>
		</div>

		<div class="form-group row">
			<label for="external_id" class="col-md-3 col-form-label text-input-label" tt>External ID</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       id="external_id"
				       name="external_id"
				       formControlName="external_id">
			</div>
		</div>

		<div class="form-group row" *ngIf="show_auth_site_id">
			<label for="auth_site_id" class="col-md-3 col-form-label text-input-label" tt>Auth Service Site ID</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       id="auth_site_id"
				       name="auth_site_id"
				       formControlName="auth_site_id">
			</div>
		</div>

		<cta-ta-form-address [readonly]="readonly"
		                     [only_address]="true"
		                     [inputForm]="addressForm">
		</cta-ta-form-address>

		<cta-ta-form-company-agreement [readonly]="readonly"
		                               [inputForm]="agreementForm">
		</cta-ta-form-company-agreement>

		<cta-ta-form-company-selector [readonly]="readonly"
		                              [inputForm]="companyInfoForm">
		</cta-ta-form-company-selector>

		<cta-ta-form-contact-selector [readonly]="readonly"
		                              [inputForm]="contactForm">
		</cta-ta-form-contact-selector>
	</div>
</ng-container>
