<div class="cart" *ngIf="cart && cart.items.length">
	<table>
		<tr>
			<td class="texts" id="cart_texts">
				<i tabindex="0" [attr.aria-label]="'Shopping cart' | tt" class="fa fa-shopping-cart"></i>
				<i tabindex="0" class="item-count">
					<span [attr.aria-label]="('products' | tt) + ' ' + cart.item_count"></span>
					<span aria-hidden="true" class="badge bg-danger">{{ cart.item_count }}</span>
				</i>
				<i tabindex="0" class="price-count">
					<span [attr.aria-label]="('Total price' | tt) + ' ' + (cart.total_price | fastcents)"></span>
					<span aria-hidden="true" class="badge bg-secondary">{{ cart.total_price | emptyor | fastcents }}</span>
				</i>
			</td>
			<td class="controls">
				<button class="btn btn-primary btn-success buy btn-small"
				        (click)="buyProducts()"
				        tt>
					Cart
				</button>
			</td>
		</tr>
	</table>
</div>

<div [@slideIn] *ngIf="error" class="general-error" aria-live="assertive" role="alert"
     [ngClass]="{
			'bg-danger': error.severity === 'error',
			'bg-success': error.severity === 'success',
			'bg-warning': error.severity === 'warn',
			'bg-info': error.severity === 'info',
			'pull-to-top': ((!cart || !cart.items.length) && !prereservation_seconds),
			'add-space-top2': ((cart && cart.items.length) && prereservation_seconds > 0)
	  }">
	<div class="title" tt>{{ error.summary }}</div>
	<div *ngIf="error?.detail" class="msg" tt>{{ error.detail }}</div>
	<span class="dismiss" (click)="dismissError()">✖</span>
</div>

<div *ngIf="prereservation_seconds > 0">
	<span class="prereservation-label label label-warning"
	      [ngClass]="{
				'pull-to-top': !(cart && cart.items.length),
				'add-space-top': (cart && cart.items.length)
			}">
		<span class="me-1" tt>Prereservation</span>
		<span tt>Expires in</span> {{ prereservation_seconds * 1000 | date:'mm:ss' }}
		<div class="names">{{ prereservation_label }}</div>
	</span>
	<span
		id="aria-live-label"
		aria-live="polite"
		role="alert"
		tabindex="0"
		[attr.aria-label]="('Prereservation' | tt) + ' ' + ('Expires in' | tt)
			+ ' ' + (initial_prereservation_seconds * 1000 | date:'mm') + ' ' + ('minutes' | tt)">
	</span>
</div>
