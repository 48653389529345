import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ReservationDetails, ShopReservationListService } from '../../../services';
import { AlertCode, AlertType } from '../../../alerts';

@Component({
	templateUrl : './details.modal.html',
	styleUrls   : ['./details.modal.scss']
})
export class UserReservationEventsDetailsModal {
	@Input() details: ReservationDetails;

	public cancelled = false;
	public alert: AlertType;

	constructor(public activeModal: NgbActiveModal,
	            public reservation_list: ShopReservationListService)
	{
	}

	public cancel(): void
	{
		this.reservation_list.removeReservation(this.details.reservation_id).subscribe(
			result => {
				this.cancelled = !!result?.reservation_status_id;
				this.alert = {
					code    : AlertCode.Success,
					message : 'Reservation has been cancelled'
				};
				this.activeModal.dismiss();
			},
			error => {
				this.alert = {
					code    : AlertCode.Error,
					message : error
				};
			}
		);
	}
}
