import { Injectable } from '@angular/core';
import { Constants } from '../../shared/misc/constants';

import * as _ from 'lodash';
import * as moment from 'moment/moment';

declare let Enkora: { tt: (a: string) => string };

@Injectable()
export class CourseAttendanceGeneratorService {

	constructor() {
	}

	private _makeHtml(content : string) : string {
		return `
			<html>
				<head>
					<title>Attendance list</title>
					<style>
						.break-page {
							page-break-after: always;
						}
						.rotate {
						   font-size: 1.5em;
							/* Safari */
							-webkit-transform: rotate(-90deg);
							/* Firefox */
							-moz-transform: rotate(-90deg);
							/* IE */
							-ms-transform: rotate(-90deg);
							/* Opera */
							-o-transform: rotate(-90deg);
							/* Internet Explorer */
							filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
						}
						.title {
							display: block;
							font-size: 2em;
							font-weight: bold;
						}
						.subtitle {
							display: block;
							font-size: 1.5em;
							font-weight: bold;
						}
						.small-text {
							font-size: 0.8em;
						}
						.name-cell {
						   font-size: 1.5em;
							width: 230px;
							min-width: 230px;
							max-width: 230px;
						}
						.date-cell {
							width: 20px;
							min-width: 20px;
							max-width: 20px;
						}
						.name-header-cell {
							height: 100px;
						}
						.extra-date-cell {
							width: 35px;
							min-width: 35px;
							max-width: 35px;
						}
					</style>
				</head>
				<body onload="window.print();window.close()">${content}</body>
			</html>`;
	}

	private _makeCourse(course : any) : string {
		let content = '';

		content += `<div class="title">${course.reservation_event_group_id}. ${course.name}</div>`;
		content += `<div class="subtitle">${course.location}</div>`;
		content += `<div class="text">${Enkora.tt('Dates')}:
			${moment(course.start).format('DD.MM.YYYY')}—${moment(course.end).format('DD.MM.YYYY')}</div>`;
		let weekdays = [];
		_.forEach(course.weekdays, (weekday : any) => {
			weekdays.push(`<b>${weekday.days}</b>&nbsp;<em>${weekday.times}</em>`);
		});
		content += weekdays.join(', ');

		let rows = '';

		let row = '';
		let event = null;
		row += `
			<td class="name-cell name-header-cell">
				<h2>
					${Enkora.tt('Name')}
				</h2>
			</td>
		`;

		for (let i = 0; i < 20; i++) {
			if (course.reservation_events) {
				event = course.reservation_events[i];
			}
			row += `
				<td class="date-cell">
					<div class="rotate">
						${event ? moment(event.time_start).format('DD/MM') : ''}
					</div>
				</td>
			`;
		}
		row += `
			<td class="extra-date-cell">
				<div class="rotate">
				   pv/kk
				</div>
			</td>
		`;

		rows += `<tr>${row}</tr>`;

		let reservations : any[] = _.sortBy(course.reservations, 'last_name');
		let resCount = 0;
		let dateCellPattern = '';
		for (let i = 0; i < 20; i++) {
			dateCellPattern += `<td class="date-cell"></td>`;
		}
		_.forEach(reservations, reservation => {
			if (reservation.reservation_status_id != Constants.RES_RESERVED
					&& reservation.reservation_status_id != Constants.RES_ADMITTED) return;
			resCount++;
			rows += `<tr><td class="name-cell">${resCount}. ${reservation.name}</td>${dateCellPattern}<td class="extra-date-cell"></td></tr>`;
		});
		for (let i = 0; i < 5; i++) {
			resCount++;
			rows += `<tr><td class="name-cell"><i>${resCount}.</i></td>${dateCellPattern}<td class="extra-date-cell"></td></tr>`;
		}

		content += `
			<table class="small-text" style="width: 670px; border-collapse: collapse;" border="1">
			<tbody>
				${rows}
			</tbody>
			</table>
		`;

		content += `<div class="break-page"></div>`;
		return content;
	}

	private _printHtml(html : string) {
		let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		popupWin.document.open();
		popupWin.document.write(html);
		popupWin.document.close();
	}

	public printCourses(courses : any[]) {
		let content = '';

		_.forEach(courses, course => {
			content += this._makeCourse(course);
		});

		let html = this._makeHtml(content);

		this._printHtml(html);
	}
}
