import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from '../../shared';

export interface ReservationType {
	reservation_type_id : string;
	name : string;
	priority : number;
	color : string;

	is_active : boolean;
	is_default : boolean;
}

export interface ReservationTypes {
	map : { [type_id : string] : ReservationType }
	values : ReservationType[];
}

@Injectable()
export class ReservationTypesService extends EnkoraFetcher<ReservationTypes> {

	params : CallParameter[] = [{name : 'reservation/getReservationTypes'}];

	constructor(call : CallService)
	{
		super(call);
	}

	protected postProcess(reply : any[])
	{
		const result : ReservationTypes = {
			map   : {},
			values : []
		};
		each(reply, t => {
			const type : ReservationType = {
				reservation_type_id : t.reservation_type_id,
				name                : t.name,
				priority            : t.priority,
				color               : t.color,

				is_active  : t.is_active,
				is_default : t.is_default
			};

			result.values.push(type);
			result.map[type.reservation_type_id] = type;
		});

		return result;
	}
}
