import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
	AuthenticationService,
	Constants,
	Helpers,
	ParameterService,
	ErrorPipeService,
	ErrorType
} from 'shared';

@Injectable()
export class SessionMonitorService {

	private subError: Subscription;

	constructor(private param: ParameterService,
	            private router: Router,
	            private errorPipe: ErrorPipeService,
	            private auth: AuthenticationService) { }

	public subscribeToAuthError(): void
	{
		if (this.subError) return;
		this.subError = this.errorPipe.error$.subscribe(error => {
			if (!this.auth.value || error.type !== ErrorType.Auth) return;
			const user_type_id = this.auth.value.user_type_id;
			this.param.getValue('ramismart auth redirect users types', Constants.NM_EN_CTA).subscribe(value => {
				if (value) {
					const redirect_user_types = Helpers.split(value).map(type => +type);
					const is_available = redirect_user_types.includes(user_type_id);
					if (is_available) {
						this.router.navigateByUrl('/login/logout');
						return;
					}
				}
				Helpers.reload();
			});
		});
	}

	public unsubscribeAuthError(): void
	{
		if (this.subError) {
			this.subError.unsubscribe();
			this.subError = null;
		}
	}
}
