<div class="w-85 ms-auto me-auto">
	<ng-container *ngIf="user; else nouser">
		<shop-alert-message [alert]="alert">
		</shop-alert-message>

		<div class="row">
			<div class="col-lg-12">
				<h1 tabindex="0">{{ 'My account' | tt }}</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4">
				<h3 tabindex="0" [attr.aria-label]="('Username' | tt) + ' ' + user.name">{{user.name}}</h3>

				<!-- User info -->
				<table class="table userInfo">
					<tr>
						<th aria-hidden="true" tt>Account ID</th>
						<td tabindex="0" [attr.aria-label]="('Account ID' | tt) + ' ' + user.account_id">
							{{ user.account_id }}
							<p *ngIf="is_child_account">
								<span class="label" tt>Child account</span>
							</p>
						</td>
					</tr>

					<tr class="phone_number" *ngIf="user.phone_number">
						<th aria-hidden="true" tt>Phone Number</th>
						<td tabindex="0" [attr.aria-label]="('Phone Number' | tt) + ' ' + user.phone_number">{{ user.phone_number }}</td>
					</tr>

					<tr *ngIf="user.address || user.address_street || user.address_postcode || user.address_city">
						<th aria-hidden="true" tt>Address</th>
						<td tabindex="0"
						    [attr.aria-label]="('Address' | tt) + ' ' + user.address_street
						      + ' ' + user.address_postcode + ' ' + user.address_city">
							{{ user.address_street }}<br>
							{{ user.address_postcode }} {{ user.address_city }}
						</td>
					</tr>

					<tr class="email" *ngIf="user.email">
						<th aria-hidden="true" tt>Email</th>
						<td tabindex="0" [attr.aria-label]="('Email' | tt) + ' ' + user.email">{{ user.email }}</td>
					</tr>

					<tr class="date_of_birth" *ngIf="user.date_of_birth && !ssn_check">
						<th aria-hidden="true" tt>Date Of Birth</th>
						<td tabindex="0" [attr.aria-label]="('Date Of Birth' | tt) + ' ' + user.date_of_birth">
							{{ user.date_of_birth | amDateFormat:'LL' }}
						</td>
					</tr>

					<tr class="access_pin" *ngIf="user.access_pin">
						<th aria-hidden="true" tt>Access Pin</th>
						<td style="font-size: 14px; font-weight: bold; color: green;"
						    tabindex="0" [attr.aria-label]="('Access Pin' | tt) + ' ' + user.access_pin">
							{{ user.access_pin }}
						</td>
					</tr>

					<tr class="balance" *ngIf="balance !== null">
						<th aria-hidden="true" tt>Balance</th>
						<td style="font-size: 14px; font-weight: bold; color: green;"
						    tabindex="0" [attr.aria-label]="('Balance' | tt) + ' ' + balance">
							{{ balance | fastcents }}
						</td>
					</tr>
				</table>

				<button id="button_modify_user_info" class="btn btn-info me-1" (click)="modifyAccount()" tt>Edit info
				</button>

				<ng-container *ngIf="is_child_account; else parent_account">
					<button class="btn btn-primary account_link" (click)="selectors.goUserProfile()" tt>Parent account
					</button>
				</ng-container>

				<ng-template #parent_account>
					<ng-container *ngIf="can_create_child">
						<button class="btn btn-default addChildAccount" (click)="createAccount()" tt>Add new child account
						</button>
					</ng-container>
				</ng-template>

				<!-- Linked accounts -->
				<div class="linked-accounts" *ngIf="user.linked_accounts && user.linked_accounts.length">
					<h3 tabindex="0" tt>Linked account(s)</h3>
					<table class="user-child-accounts table table-striped table-condensed" style="width: 100%;">
						<thead>
						<tr>
							<th tabindex="0" tt>Account ID</th>
							<th tabindex="0" tt>Name</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let la of user.linked_accounts; let i=index">
							<td tabindex="0">{{ la.account_id }}</td>
							<td class="uca-name">
								<a href="javascript:void(0)" class="account_link" (click)="selectors.goUserProfile(la.user_id)"
								   tabindex="0">
									{{ la.name | emptyor:'undefined' | tt }}
								</a>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

			</div>

			<div class="col-lg-8" *ngIf="auth.value">

				<shop-user-reservation-events *ngIf="!hide_reservations">
				</shop-user-reservation-events>

				<shop-user-reservation-event-groups *ngIf="!hide_courses">
				</shop-user-reservation-event-groups>

				<shop-user-access-rights *ngIf="access_rights?.length && !hide_access_rights_products"
				                         [access_rights]="access_rights">
				</shop-user-access-rights>

				<!-- Events -->
				<ng-container *ngIf="events && events.length && !hide_events">
					<h3 tabindex="0" tt>Events</h3>
					<table class="user-events-log table table-striped table-condensed" style="width: 100%;">
						<thead>
						<tr>
							<th tabindex="0" tt>Date</th>
							<th tabindex="0" class="ue-time" tt>Time</th>
							<th tabindex="0" tt>Event</th>
							<th tabindex="0" class="ue-device" tt>Device</th>
							<th tabindex="0" tt>Access Right</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let event of events">
							<td tabindex="0">{{ event.parsed_date }}</td>
							<td tabindex="0" class="ue-time">{{ event.parsed_time }}</td>
							<td tabindex="0">{{ event.event_type_name }}</td>
							<td tabindex="0" class="ue-device">{{ event.device }}</td>
							<td tabindex="0">{{ event.access_right_template }}</td>
						</tr>
						</tbody>
					</table>
				</ng-container>

				<ng-container *ngIf="machine_events && machine_events.length && !hide_cta_events">

					<!-- Events CTA-->
					<h3 tabindex="0" tt>Events</h3>
					<table class="user-events-log table table-striped table-condensed" style="width: 100%;">
						<thead>
						<tr>
							<th tabindex="0" tt>Date</th>
							<th tabindex="0" class="ue-time" tt>Time</th>
							<th tabindex="0" tt>Event</th>
							<th tabindex="0" class="ue-device" tt>Device</th>
							<th tabindex="0" class="ue-device" tt>User</th>
							<th tabindex="0" tt>Access Right</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let event of machine_events">
							<td tabindex="0">{{ event.parsed_date }}</td>
							<td tabindex="0" class="ue-time">{{ event.parsed_time }}</td>
							<td tabindex="0">{{ event.event_type_name }}</td>
							<td tabindex="0" class="ue-device">{{ event.device_name }}</td>
							<td tabindex="0" class="ue-device">{{ event.user_name }}</td>
							<td tabindex="0">{{ event.access_right_template }}</td>
						</tr>
						</tbody>
					</table>
				</ng-container>

				<ng-container *ngIf="subscriptions && subscriptions.length && !hide_subscriptions">

					<h3 tabindex="0" tt>Subscriptions</h3>
					<table class="user-events-log table table-striped table-condensed" style="width: 100%;">
						<thead>
						<tr>
							<th tabindex="0" tt>Subscription ID</th>
							<th tabindex="0" tt>Name</th>
							<th tabindex="0" tt>Start date</th>
							<th tabindex="0" tt>End date</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let subscription of subscriptions">
							<td tabindex="0">{{ subscription.subscription_id }}</td>
							<td tabindex="0">{{ subscription.name }}</td>
							<td tabindex="0">{{ subscription.start_date.isValid()
								? (subscription.start_date | amDateFormat:'DD.MM.YYYY')
								: ''
								}}
							</td>
							<td tabindex="0">{{ subscription.end_date.isValid()
								? (subscription.start_date | amDateFormat:'DD.MM.YYYY')
								: 'Valid until further notice' | tt }}
							</td>
						</tr>
						</tbody>
					</table>
				</ng-container>

				<!-- No content div -->
				<div class="page-header well" id="noUserContent" *ngIf="isAccountEmpty() && isAccountNotificationVisible()">
					<h4 tabindex="0" tt>No data available</h4>
					<p tabindex="0" tt>
						Account does not have any activity yet. We'll display information here when there's more to show.
					</p>
				</div>

			</div>
		</div>
	</ng-container>

	<ng-template #nouser>
		<h1 tabindex="0" tt>Login required!</h1>

		<p class="lead">
			<span tabindex="0" tt>Please log in to view this page.</span><br>
			<a class="btn btn-success loginButton" (click)="doLogin()" tt>Login</a>
		</p>

	</ng-template>
</div>
