import { Component, Input, OnInit } from '@angular/core';

export enum AlertCode {
	Success = 'success',
	Warning = 'warning',
	Error = 'danger',
	Info = 'info'
}

export interface AlertType {
	code: AlertCode;
	title?: string;
	message?: string;
}

@Component({
	selector    : 'shop-alert-message',
	templateUrl : './alerts.component.html',
	styleUrls   : ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
	public visible = false;
	public AlertCode = AlertCode;

	constructor()
	{
	}

	private _alert: AlertType = null;

	get alert(): AlertType
	{
		return this._alert;
	}

	@Input()
	set alert(value: AlertType)
	{
		this._alert = value;
		this.visible = true;
	}

	ngOnInit(): void
	{
	}
}
