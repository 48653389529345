import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { forEach, keys, values } from 'lodash';

import { ReservationResource } from './resource-list.service';
import { Logger } from '../../shared';

export interface HierarchyItem {
	resource : ReservationResource;
	path_id : string;
	path_name : string;
	children : number[]
}

@Injectable()
export class ResourcesManagerService {
	public resources$ : Subject<ReservationResource[]> = new Subject<ReservationResource[]>();
	private _resource_map : { [resource_id : string] : ReservationResource } = {};

	private hierarchy_map : { [resource_id : string] : HierarchyItem[] } = {};
	private flat_hierarchy : { [path_id : string] : HierarchyItem } = {};
	private reverse_hierarchy : { [resource_id : number] : string[] } = {};
	private parent_prefix = [];

	constructor()
	{
	}

	private _resources : ReservationResource[] = [];

	get resources() : ReservationResource[]
	{
		return this._resources;
	}

	set resources(value : ReservationResource[])
	{
		this._resources = value;
		this._resource_map = {};
		forEach(value, (resource : ReservationResource) => {
			if (!resource.resource_id) return;
			resource.name = '' + resource.name;
			this._resource_map[resource.resource_id.toString()] = resource;
		});

		this.resetHierarchicalResources();
		this.makePrefixArray();

		this._resources = value;
		this.resources$.next(value);

		Logger.log('loaded resources', this._resource_map);
	}

	public getAllHierarchyResources()
	{
		let resources = [];
		forEach(this.parent_prefix, node => {
			resources.push({label : node.label, value : node.id});
		});

		return resources;
	}

	public updateSelectedHierarchy(new_resource_ids, old_resource_ids = []) : any
	{
		let old_hierarchy_selected = {};
		let new_hierarchy_selected = {};

		forEach(old_resource_ids, id => old_hierarchy_selected[id] = true);
		forEach(new_resource_ids, id => new_hierarchy_selected[id] = true);

		let resolved = false;
		forEach(new_hierarchy_selected, (value, key) => {
			if (!old_hierarchy_selected[key]) {
				let resource = this._resource_map[key];
				if (!resource) return;

				forEach(resource.sub_resource_ids, id => {
					if (this._resource_map[id])
						new_hierarchy_selected[id] = true;
				});

				resolved = true;
			}
		});

		if (resolved) return keys(new_hierarchy_selected);

		forEach(old_hierarchy_selected, (value, key) => {
			if (!new_hierarchy_selected[key]) {
				let resource = this._resource_map[key];
				if (!resource) return;

				forEach(resource.sub_resource_ids, id => {
					if (new_hierarchy_selected[id]) delete new_hierarchy_selected[id];
				});
			}
		});

		return keys(new_hierarchy_selected);
	}

	public getAllParents(resource_id: number)
	{
		let resource_map : { [resource_id : number] : any } = {};

		let parents = this._resource_map[resource_id].parent_resource_ids;
		if (parents) {
			forEach(parents, parent_id => {
				resource_map[parent_id] = true;

				forEach(this.getAllParents(parent_id), new_map => {
					resource_map[new_map] = true;
				});
			});
		}

		return resource_map;
	}

	private resetHierarchicalResources()
	{
		this.hierarchy_map = {};
		this.flat_hierarchy = {};
		this.getHierarchicalResources();

		this.reverse_hierarchy = {};
		forEach(this.flat_hierarchy, (item, path_id) => {
			if (!this.reverse_hierarchy[item.resource.resource_id])
				this.reverse_hierarchy[item.resource.resource_id] = [];

			this.reverse_hierarchy[item.resource.resource_id].push(path_id);
		});
	}

	private getHierarchicalResources() : HierarchyItem[]
	{
		forEach(this.resources, resource => {
			this.constructSubHierarchy(resource.resource_id);
		});

		let hierarchy = values(this.flat_hierarchy);

		return hierarchy.sort((a : HierarchyItem, b : HierarchyItem) => a.path_id.localeCompare(b.path_id));
	}

	private constructSubHierarchy(resource_id) : HierarchyItem[]
	{
		if (!this._resource_map[resource_id]) return [];

		let current_resource = this._resource_map[resource_id];
		if (this.hierarchy_map[resource_id]) return this.hierarchy_map[resource_id];

		let item : HierarchyItem = {
			resource  : current_resource,
			path_id   : '' + resource_id,
			path_name : current_resource.name,
			children  : []
		};
		this.flat_hierarchy[item.path_id] = item;
		let result = [item];
		this.hierarchy_map[resource_id] = [];

		forEach(current_resource.sub_resource_ids, sub_resource_id => {
			let subTree = this.constructSubHierarchy(sub_resource_id);
			forEach(subTree, (node) => {
				item = {
					resource  : node.resource,
					path_id   : resource_id + '->' + node.path_id,
					path_name : current_resource.name + ' ' + node.path_name,
					children  : [node.resource.resource_id, ...node.children]
				};
				result.push(item);
				this.flat_hierarchy[item.path_id] = item;
			});
		});

		this.hierarchy_map[resource_id] = result;
		return result;
	}

	private makePrefixArray()
	{
		this.parent_prefix = [];

		forEach(this.resources, (resource : ReservationResource) => {
			let label = resource.name;
			let prefix = resource.name;

			if (resource.parent_resource_ids) {
				const parents = resource.parent_resource_ids.sort((id1, id2) => {
					const r1 = this._resource_map[id1] && this._resource_map[id1].parent_resource_ids || [];
					const r2 = this._resource_map[id2] && this._resource_map[id2].parent_resource_ids || [];

					return r1.length - r2.length;
				}).map(id => this._resource_map[id] ? this._resource_map[id].name : id);

				parents.push(prefix);
				prefix = parents.join('~');
				label = Array(parents.length).join('   ') + label;
			}
			this.parent_prefix.push({prefix : prefix, id : resource.resource_id, label : label});
		});

		this.parent_prefix.sort((a, b) => a.prefix.localeCompare(b.prefix));
	}
}
