import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { each, isEmpty, isEqual, some } from 'lodash';

import { AuthenticationService, CallService, Constants, Helpers, ParameterService } from 'shared';

import {
	FieldFormsGeneratorService,
	LoginState,
	LoginStepsData,
	ShopFieldInput,
	ShopFields,
	ShopRegistrationFieldsService,
	ShopTagGroupsService
} from '../../services';
import { AlertCode, AlertType } from '../../alerts';
import { EmailFieldMessage, isEmailFieldMessage } from '../../fields/field-message.interface';

declare let Enkora: { tt: (a: string, b?: string | number) => string };

export enum RegisterState {
	None = 'None',
	Input = 'Input',
	Processing = 'Processing',
	Message = 'Message'
}

@Component({
	selector    : 'shop-register-modal',
	templateUrl : './register.modal.html',
	styleUrls   : ['./register.modal.scss']
})
export class ShopRegisterModal implements OnInit {

	public fields: ShopFields = null;

	default_form_fields: ShopFieldInput[] = [];
	default_simple_registration_form_fields: ShopFieldInput[] = [];

	public alert: AlertType = null;

	public simple_registration = false;
	public simple_skip_email_check = false;
	public no_public_login = false;
	public_tag_group_ids: string[] = [];
	simple_registration_fields: ShopFieldInput[] = [];
	registration_fields: ShopFieldInput[] = [];
	terms_link;
	no_iframe = true;
	public require_email_confirmation_for_domains: string[] = [];

	public registerState = RegisterState.None;
	public RegisterState = RegisterState;
	protected readonly isEmailFieldMessage = isEmailFieldMessage;

	constructor(public activeModal: NgbActiveModal,
	            public auth: AuthenticationService,
	            public call: CallService,
	            private tags: ShopTagGroupsService,
	            private registerFields: ShopRegistrationFieldsService,
	            private formGenerator: FieldFormsGeneratorService,
	            private parameterService: ParameterService)
	{
		this.default_form_fields =
			[
				'email',
				'first_name',
				'last_name',
				'password',
				'passwordConfirm',
				'phone_number',
				'address_street',
				'address_postcode',
				'address_city',
				'date_of_birth',
				'gender_id',
				'is_email_allowed',
				'usageterms'
			]
			.map(name => ({ name, id : name }));

		this.default_simple_registration_form_fields =
			['email', 'emailConfirm', 'first_name', 'last_name', 'phone_number', 'is_email_allowed', 'usageterms']
			.map(name => ({ name, id : name }));

		combineLatest([
			this.parameterService.getValuePairs([
				['simple registration allowed', Constants.NM_EN_WEBSHOP],
				['simple registration form fields', Constants.NM_EN_WEBSHOP],
				['simple registration skip user email check', Constants.NM_EN_WEBSHOP],
				['no public login', Constants.NM_EN_WEBSHOP],
				['publicly visible tag group ids', Constants.NM_EN_RESERVATIONS],
				['terms and conditions link', Constants.NM_MAIN],
				['webshop terms and conditions do not open iframe', Constants.NM_EN_WEBSHOP],
				['require email confirmation for domains', Constants.NM_EN_WEBSHOP]
			]),
			this.registerFields.get()
		]).pipe(first()).subscribe(params => {
			const [
				[
					simple_registration,
					simple_registration_fields,
					simple_skip_email_check,
					no_public_login,
					public_tag_group_ids,
					terms_link,
					no_iframe,
					require_email_confirmation_for_domains
				],
				registration_fields
			] = params;

			this.simple_registration = !!simple_registration;
			this.simple_skip_email_check = !!simple_skip_email_check;
			this.no_public_login = !!no_public_login;
			this.no_iframe = !!no_iframe;
			this.terms_link = Enkora.tt(terms_link);

			this.public_tag_group_ids = Helpers.split(public_tag_group_ids);
			this.registration_fields = registration_fields;

			this.simple_registration_fields = Helpers.split(simple_registration_fields).map(name => ({ name, id : name }));
			this.require_email_confirmation_for_domains = Helpers.split(require_email_confirmation_for_domains);

			this.initForm();
		});
	}

	ngOnInit(): void
	{
	}

	initForm(): void
	{
		let fields: ShopFieldInput[];
		const tags: ShopFieldInput[] = this.tags.getTagFields(this.public_tag_group_ids);

		if (this.simple_registration) {
			fields = !isEmpty(this.simple_registration_fields)
				? this.simple_registration_fields
				: this.default_simple_registration_form_fields;
		} else {
			fields = !isEmpty(this.registration_fields)
				? this.registration_fields
				: this.default_form_fields;
		}

		each(fields, field => {
			if (field.id == 'email') field.id = 'emailNew';
		});

		this.fields = this.formGenerator.generateForm(fields.concat(tags));
		this.registerState = RegisterState.Input;
	}

	public back(): void
	{
		this.close({ state : LoginState.Login });
	}

	public register(): void
	{
		const form_values: Record<string, any> = this.fields.rootForm.getRawValue();
		const params: Record<string, any> = {};
		const email_val = form_values['email'] || form_values['emailNew'];
		const require_email_confirmation = this.isEmailInList(email_val);
		console.warn('require_email_confirmation', require_email_confirmation, email_val);

		each(this.fields.fields, field => {
			if (field.type == 'checkboxes') {
				const values = [];
				each(form_values[field.id], (checkbox, index) => {
					if (checkbox) values.push(field.possible_values[index]);
				});

				params[field.id] = values;
			} else if (field.id === 'emailNew') {
				params.email = form_values[field.id];
			} else params[field.id] = form_values[field.id];
		});
		params.current_state = window.location.pathname;

		this.registerState = RegisterState.Processing;
		this.call.make('reservation2/postR2Users', [params]).subscribe(() => {
			if (this.simple_registration && !require_email_confirmation) {
				this.auth.reload().subscribe(() => {
					this.close({ state : LoginState.Close });
				});
			} else {
				this.registerState = RegisterState.Message;
				this.alert = {
					code    : AlertCode.Warning,
					title   : 'Please check your email to complete registration',
					message :
						`${Enkora.tt('A confirmation email has been sent to %s.', params.email)}
						${Enkora.tt('Please follow the link in the email to complete the registration process.')}`
				};
				this.fields = null;
			}
		}, (error) => {
			this.registerState = RegisterState.Message;
			this.alert = {
				code    : AlertCode.Error,
				title   : 'Error',
				message : error
			};
		});
	}

	public close(data: LoginStepsData): void
	{
		this.activeModal.close(data);
	}

	onEmailReminder(message: EmailFieldMessage): void
	{
		this.close({ state : LoginState.Reminder, value : message.email });
	}

	public isEmailInList(email: string): boolean
	{
		const split_email = email.split('@');
		const domain = split_email?.[1]?.toLowerCase();
		return some(
			this.require_email_confirmation_for_domains, field => isEqual(domain.toLowerCase(), field.toLowerCase())
		);
	}
}
