import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { CallService, EnkoraMessageService, Helpers, LocationService } from '../../../../../shared';
import { CtaStyleCode, TaDataHelper } from '../../services';
import { TaEmployment } from '../../interfaces';
import { isNull } from 'lodash';

@Component({
	selector    : 'cta-ta-form-employment-info',
	templateUrl : './employment-info.form.html',
	styleUrls   : ['./employment-info.form.scss']
})
export class EmploymentInfoForm implements OnInit {

	@Input() show_contract = true;
	@Input() company_form = false;

	public companyForm: FormGroup;
	public companyInfoForm: FormGroup;
	public contactForm: FormGroup;
	public foreignContactForm: FormGroup;
	public contractForm: FormGroup;
	public agreementForm: FormGroup;
	public employee_id;
	public is_show_foreign_contact_form = false;

	constructor(private call: CallService,
	            private messageService: EnkoraMessageService,
	            private location: LocationService)
	{
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this.companyForm = this._inputForm.get('company') as FormGroup;
		this.contractForm = this._inputForm.get('contract') as FormGroup;

		this.companyInfoForm = this.companyForm.get('company_info') as FormGroup;
		this.contactForm = this.companyForm.get('contact') as FormGroup;
		this.foreignContactForm = this.companyForm.get('foreign_contact') as FormGroup;
		this.agreementForm = this.companyForm.get('agreement') as FormGroup;
		const data = value.getRawValue() as TaEmployment;
		this.employee_id = data.employee_id;
		this.countryCodeSelected(data.company.company_info.country.country_code);
	}

	ngOnInit(): void
	{
	}

	countryCodeSelected(country_code : string)
	{
		this.is_show_foreign_contact_form = !isNull(country_code) && country_code != CtaStyleCode.FI;
	}

	organizationSelected(organization_id: string)
	{
		if (!organization_id) {
			const employment: TaEmployment = TaDataHelper.emptyEmployment;
			this._inputForm.patchValue({ employment, user_id : this._inputForm.getRawValue().user_id });
			this.contactForm.patchValue(employment.company.contact);
			this.foreignContactForm.patchValue(employment.company.foreign_contact);
			this.contractForm.patchValue({ default_employee_group_id : null });
			return;
		}

		this.location.getLocationId().subscribe(location_id => {
			const params = {
				organization_id,
				user_id         : this.inputForm.value.user_id,
				location_id
			};
			this.call.make('cta2/getUserOrganizations', [params]).subscribe(
				(reply: any[]) => {
					if (reply && reply.length) {
						const company_data: any = reply[0];
						const employment = TaDataHelper.emptyEmployment;

						employment.user_id = this.inputForm.value.user_id;
						employment.employee_id = company_data.employee_id;
						employment.contract = TaDataHelper.emptyContract;

						employment.company.company_info = {
							business_id              : company_data.business_id,
							foreign_business_id      : company_data.foreign_business_id,
							foreign_business_id_type : Helpers.safeToString(company_data.foreign_business_id_type),
							name                     : company_data.name,
							organization_id          : company_data.organization_id,
							country                  : TaDataHelper.extractCountryAddress(
								company_data.address, company_data.country_code, company_data.country_name),
							employer_role_id         : company_data.employer_role_id
						};

						employment.company.contact = TaDataHelper.extractContact(company_data.contact);
						employment.company.foreign_contact = TaDataHelper.extractContact(company_data.foreign_contact);
						this._inputForm.patchValue(employment);
						const default_employee_group_id = company_data.agreement.default_employee_group_id;
						this.contractForm.patchValue({ default_employee_group_id });
						this.contactForm.patchValue(employment.company.contact);
						this.foreignContactForm.patchValue(employment.company.foreign_contact);
					}
				},
				error => {
					this.messageService.error(error);
				}
			);
		});
	}
}
