<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.89 123.99" class="cta-logo">
	<defs>
		<style>.cls-1 {
			fill: #003882;
		}</style>
	</defs>
	<title>Ramirent_logo</title>
	<path class="cls-1"
	      d="M206.6,100.38h10.23v23.25H184.28L179,100.38H146.62L141,123.63H72.38L52.08,84.11c-1.55.16-3.26.16-4.81.16h-9v39.36H0V100.38H11.16V22.89H0V-.36H47.27c34.56,0,51.3,14,51.3,41.07,0,17-8.21,29-20.46,36.12l13.18,23.55h28.36l19.84-77.49h-11V-.36h54.87ZM70.21,42.26c0-11.77-7.29-19.37-23.71-19.37H38.28V61.64H46.5C62.92,61.64,70.21,54,70.21,42.26Zm104,36.43-9.77-43.86h-2.17L151.73,78.69Zm141.5-79H350V22.89H338.8v77.49H350v23.25H303.15V100.38h10.39V45.52L288.28,83.18h-2.17L261.46,45.52v54.86h10.39v23.25H227.06V100.38h9.61V22.89h-13V-.36h35.64L287.5,44Zm44.79,0h62.77V22.89H405.6v77.49h17.67v23.25H360.5V100.38h17.67V22.89H360.5ZM525.25,100.38h13v23.25H506.19L485.88,84.11a48,48,0,0,1-4.8.16h-9v39.36H433.81V100.38H445V22.89H433.81V-.36h47.27c34.56,0,51.3,14,51.3,41.07,0,17-8.21,29-20.46,36ZM504,42.26c0-11.77-7.29-19.37-23.72-19.37h-8.21V61.64h8.21C496.73,61.64,504,54,504,42.26ZM547.26-.36h82.45V22.89H587.25V49.55h32.23V73.73H587.25v26.65h42.46v23.25h-80.9V100.38h10.85V22.89h-12.4ZM841.89,39.47H822.67V22.89H803.3v77.49h16.89v23.25H759.13V100.38H776V22.89H740.53V123.63H720.07L677.76,50v50.37h10.38v23.25H640.25V100.38h13V22.89h-13V-.36H675.9L716,69.23V22.89h-9.92V-.36H841.89Z"
	      transform="translate(0 0.36)"/>
</svg>
<p class="sub-logo">RamiSmart<span>™</span></p>
