import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

import { ShopParametersService } from './shop-parameters.service';


export interface ShopServiceListInput {
	description: string;
	events: number;
	image_id: string | number;
	is_resource_reservation: number;
	name: string;
	service_id: number;
	ui_type: string;
}

export interface ShopServiceListItem {
	description: string;
	long_description?: string;
	events: number;
	image_id: string | number;
	image_url: string;
	is_resource_reservation: number;
	name: string;
	service_id: string;
	ui_type: string;
	service_type_id?: string | number;
	service_type_name?: string;
	name_translated?: string;
	description_translated?: string;
	long_description_translated?: string;
}

@Injectable()
export class ShopServiceListService extends EnkoraFetcher<ShopServiceListItem[]> {

	public service_map: { [key: string]: ShopServiceListItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationServices' }];

	constructor(call: CallService,
	            private shopParametersService: ShopParametersService)
	{
		super(call);
	}

	postProcess(reply: ShopServiceListInput[]): Observable<ShopServiceListItem[]>
	{
		return this.shopParametersService.get().pipe(
			switchMap(params => {
				const list: ShopServiceListItem[] = [];
				const hide_service_map = Helpers.arrayToSimpleMap(params.hidden_services);
				const ignore_hide_service_map = Helpers.arrayToSimpleMap(params.ng_shop_service_ids);

				this.service_map = {};
				each(reply, item => {
					if (!item?.service_id) return;
					if (hide_service_map(item.service_id) && !ignore_hide_service_map(item.service_id)) return;

					const row: ShopServiceListItem = {
						...item,
						image_url  : '',
						service_id : `${item.service_id}`
					};

					row.name_translated = Helpers.translateKey(
						`service ${row.service_id} name`, row.name, true);
					row.description_translated = Helpers.translateKey(
						`service ${row.service_id} description`, row.description, true);
					row.long_description_translated = Helpers.translateKey(
						`service ${row.service_id} long_description`, row.long_description, true);

					const image_id = row.image_id ? `${row.image_id}` : '1';
					if (image_id.match(/^http[s]*:\/\//)) row.image_url = image_id;
					else row.image_url = 'resource/reservations2/icons/' + image_id + '.png';

					row.image_url = row.image_url.replace(/&amp;/g, '&');

					this.service_map[row.service_id] = row;
					list.push(row);
				});

				return of(list);
			})
		);
	}

}
