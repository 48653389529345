import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { cloneDeep, each } from 'lodash';

import { CustomQueryEncoderHelper } from '../helpers';
import { Logger } from '../misc';

import { ConfigService } from './config.service';
import { ErrorPipeService } from './error-pipe/error-pipe.service';

interface ServerReply<ReturnType> {
	result: ReturnType,
	errors: string[],
	user_errors?: string[],
}

@Injectable()
export class CallService {

	static nexus_release = '';

	constructor(private config: ConfigService,
	            private http: HttpClient,
	            private errorPipe: ErrorPipeService)
	{
	}

	static safeStringify<T>(json: T): string
	{
		let res: string | T = json;
		try {
			res = JSON.stringify(json);
		} catch (e) {
		}

		return res as string;
	}

	static unwrapResponse<ReturnType = unknown>(
		res: HttpResponse<ServerReply<ReturnType>>,
		return_user_errors = false
	): ReturnType
	{
		if (Logger.deepDebugging) {
			Logger.log('Call Response: ' + CallService.safeStringify(res));
		}

		CallService.nexus_release = res.headers.get('X-Enkora-Nexus-Release');

		return CallService.handleData(res.body, return_user_errors);
	}

	private static handleData<ReturnType = unknown>(data: ServerReply<ReturnType>, return_user_errors = false): ReturnType
	{
		if (return_user_errors && data.user_errors && data.user_errors.length > 0) {
			if (typeof data.user_errors === 'object' && data.user_errors[0]) {
				throw data.user_errors[0];
			}

			throw data.user_errors.toString();
		}

		if (data.errors && data.errors.length > 0) {
			if (typeof data.errors === 'object' && data.errors[0]) {
				throw data.errors[0];
			}

			throw data.errors.toString();
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (typeof data.result !== 'undefined' && data.result === false) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (data.result.message) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				throw data.result.message;
			}
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (data.result && typeof data.result.result !== 'undefined' && data.result.result === false) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (data.result.message) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				throw data.result.message;
			}
			throw new Error('');
		}

		return data.result;
	}

	private static handleError<ReturnType>(errorMessage: { error?: { text: string } }): Observable<ReturnType>
	{
		// In a real world app, we might use a remote logging infrastructure
		// We'd also dig deeper into the error to get a better message
		if (errorMessage.error?.text) {
			let text = cloneDeep(errorMessage.error.text);
			const index = text.indexOf('{"result"');
			if (index > 0) {
				text = text.substr(index);
				try {
					return of(CallService.handleData(JSON.parse(text)));
				} catch (e) {
					return throwError(()=> new Error(e));
				}
			}
		}
		Logger.log(errorMessage, true); // log to console instead
		return throwError(errorMessage);
	}

	//make(call_name: string, data: object = [], extra_options?: object, extra_post_data: object = {}): Observable<any>
	make<ReturnType = any, ParameterType = unknown>(
		call_name: string,
		data?: ParameterType,
		extra_options?: Record<string, unknown>,
		extra_post_data: Record<string, unknown> = {}
	): Observable<ReturnType>
	{
		const postdata: Record<string, any> = {
			input_format  : 'json',
			output_format : 'json',
			content       : CallService.safeStringify(data ?? [])
		};

		each(extra_post_data, (value, key) => {
			postdata[key] = value;
		});

		if (this.config.normalizeApiCalls) {
			postdata['_normalize'] = true;
		}

		if (extra_options) {
			if (extra_options['normalize'] === false) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				delete postdata._normalize;
			} else if (extra_options['normalize']) {
				postdata['_normalize'] = true;
			}
		}

		if (extra_options?.['fast_normalize']) {
			postdata['_fast_normalize'] = true;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			delete postdata._normalize;
		}

		const base_relative_path_override = typeof extra_options?.['base_relative_path_override'] === 'string'
			? extra_options['base_relative_path_override']
			: 'call/';

		const uri = this.config.baseUrl + base_relative_path_override + call_name.toLowerCase();

		const body = new HttpParams({
			encoder    : new CustomQueryEncoderHelper(),
			fromObject : postdata
		}).toString();

		const headers = new HttpHeaders({ 'Content-Type' : 'application/x-www-form-urlencoded' });

		if (Logger.deepDebugging) {
			Logger.log(`CallService: Making a call to ${uri}`);
			Logger.log(`CallService: body: ${body}${headers}`);
			Logger.log(`CallService: header: ${CallService.safeStringify(headers)}`);
		}

		const return_user_errors = extra_options && extra_options['return_user_error']
			? !!extra_options['return_user_error']
			: false;

		return this.http.post(uri, body, { headers, observe : 'response' }).pipe(
			map(response => CallService.unwrapResponse<ReturnType>(
				response as HttpResponse<ServerReply<ReturnType>>,
				return_user_errors
			)),
			catchError((error) => {
				if (extra_options && extra_options['return_user_error']) {
					this.errorPipe.addUserError(error);
				} else {
					this.errorPipe.addError(error);
				}
				return CallService.handleError<ReturnType>(error);
			}));
	}
}
