import { Injectable, OnDestroy } from '@angular/core';
import { interval, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Logger } from '../../misc';
import { CallService } from '../call.service';
import { AuthenticationService } from '../authentication';
import { EnkoraMessageService } from '../enkora-message';

declare const Enkora: { tt: (a: string) => string };

interface TickValue {
	username: string;
	account_id: number;
	device_id: number;
	timestamp: string;
}

@Injectable()
export class TickService implements OnDestroy {
	private DEFAULT_TICK_INTERVAL = 60000;
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private tickIn_transit_boolean = false;
	private tick_is_set = false;
	public value$ = new Subject<TickValue | boolean>();

	constructor(private call: CallService,
	            private auth: AuthenticationService,
	            private messageService: EnkoraMessageService)
	{
	}

	setTick(interval_time: number = this.DEFAULT_TICK_INTERVAL): void
	{
		if (this.tick_is_set) { return; }
		if (!this.auth.value) {
			this.auth.get().subscribe(user => {
				if (!user) { return; }
				this.tick_is_set = true;

				interval(interval_time)
				.pipe(takeUntil(this.destroyed$))
				.subscribe(() => {
					this.checkTick();
				});
			});
		}
	}

	checkTick(): void
	{
		if (this.auth.value && !this.tickIn_transit_boolean) {
			this.tickIn_transit_boolean = true;
			this.call.make('common/tick').subscribe(
				value => {
					if (!value) {
						this.value$.next(false);
						this.handleExpiredTick();
					} else {
						this.value$.next(value);
						this.tickIn_transit_boolean = false;
						Logger.log('🕗', 'TickService, common/tick: success');
					}
				},
				error => {
					// Network error or other errors
					console.error('🕗', 'TickService common/tick, Error: ', error);
					this.value$.next(false);
					this.handleExpiredTick();
				}
			);
		}
	}

	checkTickObservable(): Observable<any>
	{
		const subject = new Subject();
		if (this.auth.value && !this.tickIn_transit_boolean) {
			this.tickIn_transit_boolean = true;
			this.call.make('common/tick').subscribe(
				value => {
					if (!value) {
						this.handleExpiredTick();
					} else {
						this.tickIn_transit_boolean = false;
						Logger.log('🕗', 'TickService, common/tick: success');
					}
					subject.next(value);
					subject.complete();
				},

				error => {
					// Network error or other errors
					console.error('🕗', 'TickService common/tick, Error: ', error);
					this.handleExpiredTick();
					subject.next(null);
					subject.complete();
				}
			);
		}
		return subject;
	}

	handleExpiredTick(): void
	{
		this.tickIn_transit_boolean = false;
		if (this.auth.value) {
			this.messageService.display(
				'error',
				Enkora.tt('Session expired.'),
				''
			);
			Logger.log('🕗', 'TickService, common/tick: Session expired');
			this.auth.get(true);
		}
	}

	ngOnDestroy(): void
	{
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
