// based on https://stackoverflow.com/q/39546645/5851804
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Helpers } from '../../misc';

@Injectable()
export class StringNgbFormatter extends NgbDateParserFormatter {
	constructor()
	{
		super();
	}

	format(date: NgbDateStruct): string
	{
		return Helpers.ngbDateToString(date);
	}

	parse(value: string): NgbDateStruct
	{
		return Helpers.stringToNgbDate(value);
	}
}
