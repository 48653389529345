import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Constants, Logger, ParameterService } from '../../../../../shared';

@Component({
	selector    : 'cta-ta-form-site-info',
	templateUrl : './site-info.form.html',
	styleUrls   : ['./site-info.form.scss']
})
export class SiteInfoForm implements OnInit {
	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this.addressForm = <FormGroup> this._inputForm.get('address');
		this.agreementForm = <FormGroup> this._inputForm.get('agreement');
		this.companyInfoForm = <FormGroup> this._inputForm.get('company_info');
		this.contactForm = <FormGroup> this._inputForm.get('contact');

		this._inputForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			Logger.log('Site reactive form update', data);
			this.onSiteDataChange.emit({ status : this._inputForm.status, data });
		});

		this.onSiteDataChange.emit({ status : this._inputForm.status, data : this._inputForm.getRawValue() });
	}

	@Input() public readonly = false;
	public show_auth_site_id = false;

	private _inputForm: FormGroup;
	public addressForm: FormGroup;

	public companyInfoForm: FormGroup;
	public contactForm: FormGroup;

	public agreementForm: FormGroup;

	@Output() onSiteDataChange = new EventEmitter();

	constructor(private parameter: ParameterService)
	{
		this.parameter.getBooleanValue('show auth_site_id field on site create wizard form', Constants.NM_EN_CTA).subscribe(
			value => this.show_auth_site_id = value
		);
	}

	ngOnInit(): void
	{
	}
}
