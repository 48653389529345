import { AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import * as moment from 'moment';

import { CallService } from '../services';

export class ExtraValidators {
	public static emailPattern = /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$/;

	static match(control_name: string, message = ''): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			const sibling = control.parent && control.parent.get(control_name);

			if (sibling && control.value !== sibling.value) {
				return { message };
			}

			return null;
		};
	}

	static matchChildren(first_child_name: string, second_child_name: string, message = ''): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			const first_child = control && control.get(first_child_name);
			const second_child = control && control.get(second_child_name);

			if (first_child && second_child && !isEqual(first_child.value, second_child.value)) {
				return { message };
			}

			return null;
		};
	}

	static checkArrivalAndDepartureDates(arrival_child_id: string, departure_child_id: string, message = ''): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			const arrival_child = control && control.get(arrival_child_id);
			const departure_child = control && control.get(departure_child_id);

			const arrival_date = new Date(arrival_child.value);
			const departure_date = new Date(departure_child.value);
			if (arrival_date >= departure_date) {
				return { message };
			}

			return null;
		};
	}

	static checkChild<T>(child_name: string, value: T, message = ''): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			const child = control && control.get(child_name);

			if (child && !isEqual(child.value, value)) {
				return { message };
			}

			return null;
		};
	}

	static notEqual(value: string | number, message = ''): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			if (control.value === value) {
				return { message };
			}
		};
	}

	static uniqueEmail(call: CallService): AsyncValidatorFn
	{
		return (control) => {
			if (!this.emailPattern.test(control.value)) return of(null);

			return call.make('user/checkIfUserExists', [
				{
					email : control.value
				}
			]).pipe(
				map(user_id => user_id ? { user_id, message : 'Email already exists' } : null),
				catchError(error => of({ message : error }))
			);
		};
	}

	static prohibitFutureDate(message = 'Future date is not allowed'): ValidatorFn
	{
		return (control): ValidationErrors | null => {
			const date = moment(control?.value);
			if (date.isAfter(moment(), 'day')) {
				return { message };
			}

			return null;
		};
	}

	static checkImageType(message = 'Wrong image type') : ValidatorFn
	{
		return (control) : ValidationErrors | null => {
			const value = control?.value;
			if (value && !(value.toLowerCase().endsWith('.jpg') || value.toLowerCase().endsWith('.jpeg'))) {
				return {message};
			}

			return null;
		};
	}
}
