import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { clone, find, each, isNull } from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { finalize, first, startWith } from 'rxjs/operators';

import {
	AuthenticationService,
	AutoSubs,
	AutoUnsubscribe,
	CallService, Helpers,
	ModalOpenerService,
	User,
	WebshopValidators
} from 'shared';

import { CourseItem } from '../../main/courses/course-item/course-item.component';
import {
	CourseFareProduct,
	ShopCartListService,
	ShopParametersService,
	ShopSelectorsStateService
} from '../../services';
import { ShopLoginStepsService } from '../login-steps.service';
import { ShopCartModal } from '../../cart';
import { AlertCode, AlertType } from '../../alerts';
import { SocialSecurityNumberParserService } from '../../asiointi/services/ssn-parser.service';
import {
	CourseCartItem,
	CourseError, CourseParticipant,
	CourseParticipantForm,
	CourseParticipantsPhase, CourseParticipantsReservationType,
	Gender,
	PreReservation,
	SaveParticipantsData,
	SaveParticipantsResponse
} from '../../main/courses/interfaces/general.interface';
import { Constants } from '../../../shared/misc/constants';
import { PrereservationService } from '../../services/prereservation.service';

declare let Enkora: { tt: (a: string) => string };

interface AgeCheckParticipant {
	name: string;
	ssn: string;
	alert: AlertType;
}

interface AgeCheck {
	result: boolean;
	failed_participants: AgeCheckParticipant[];
}

@AutoUnsubscribe()
@Component({
	templateUrl   : './course-participants.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./course-participants.modal.scss']
})
export class CourseParticipantsModal implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('modalContent') modalContent!: ElementRef;
	@AutoSubs() public subs;
	public course: CourseItem;
	public form: FormGroup;
	public form_errors: { [key: string]: boolean } = {};
	public user: User;
	public course_participants_phase = CourseParticipantsPhase;
	public phase: CourseParticipantsPhase = CourseParticipantsPhase.ParticipantAmount;
	public previous_phase: CourseParticipantsPhase;
	public hide_participants_amount_dialog = false;
	public show_payer_and_participants = false;
	public pre_reservation_response: PreReservation;
	public loading_pre_reservation = false;
	public alert: AlertType = null;
	public pre_reservation_seconds = 0;
	public pre_reservation_sub: Subscription;
	public genders: Gender[] = [];
	public save_participants_response: SaveParticipantsResponse;
	public pre_reserved_accounts: { account_id: string, accessibility: number }[] = [];
	public constants = Constants;
	public reservation_type: CourseParticipantsReservationType;
	public course_participants_reservation_type = CourseParticipantsReservationType;
	public queue_places_confirmed = false;
	public queue_confirm_error = '';
	public loading_save_participants = false;
	public loading_confirm_queue = false;
	public cancelling_pre_reservation = false;
	public loading_add_to_cart = false;
	public clear_prereservation = true;
	public alertCode = AlertCode;
	public unConfirmedQueueSpots = 0;
	public age_check: AgeCheck;
	public save_participants_failed = false;
	public add_product_error: string = null;
	public payer_name_warning_label = '';

	constructor(public activeModal: NgbActiveModal,
	            private fb: FormBuilder,
	            private selectors: ShopSelectorsStateService,
	            private call: CallService,
	            public auth: AuthenticationService,
	            private cart: ShopCartListService,
	            private loginStepsService: ShopLoginStepsService,
	            public params: ShopParametersService,
	            private modalHelper: ModalOpenerService,
	            private ssnService: SocialSecurityNumberParserService,
	            public prereservation: PrereservationService,
	) {
		this.form = this.fb.group({
			participants              : [1, [Validators.min(1), Validators.max(3)]],
			for_child                 : [false, null],
			child                     : [null, null],
			first_name                : ['', null],
			last_name                 : ['', null],
			payer_ssn                 : ['', Validators.required, this.validateSsn.bind(this)],
			payer_first_name          : ['', Validators.required],
			payer_last_name           : ['', Validators.required],
			payer_street              : ['', Validators.required],
			payer_postal_code         : ['', [Validators.required, Validators.pattern(WebshopValidators.POSTAL_CODE)]],
			payer_city                : ['', Validators.required],
			payer_phone               : ['', [Validators.required, Validators.pattern(WebshopValidators.PHONE)]],
			payer_email               : ['', [Validators.required, Validators.pattern(WebshopValidators.EMAIL)]],
			payer_gender              : [null, Validators.required],
			participant1_ssn          : ['', null],
			participant1_first_name   : ['', null],
			participant1_last_name    : ['', null],
			participant1_street       : ['', null],
			participant1_postal_code  : ['', null],
			participant1_city         : ['', null],
			participant1_phone        : ['', null],
			participant1_email        : ['', null],
			participant1_gender       : [null, null],
			participant1_fare_product : [null, null],
			participant2_ssn          : ['', null],
			participant2_first_name   : ['', null],
			participant2_last_name    : ['', null],
			participant2_street       : ['', null],
			participant2_postal_code  : ['', null],
			participant2_city         : ['', null],
			participant2_phone        : ['', null],
			participant2_email        : ['', null],
			participant2_gender       : [null, null],
			participant2_fare_product : [null, null],
			participant3_ssn          : ['', null],
			participant3_first_name   : ['', null],
			participant3_last_name    : ['', null],
			participant3_street       : ['', null],
			participant3_postal_code  : ['', null],
			participant3_city         : ['', null],
			participant3_phone        : ['', null],
			participant3_email        : ['', null],
			participant3_gender       : [null, null],
			participant3_fare_product : [null, null],
		});

		each(Object.keys(this.form.controls), key => {
			this.form_errors[key] = false;
		});

		this.form.get('payer_ssn').disable();
		this.subs = this.form.get('for_child').valueChanges.subscribe(is_for_child => {
			this.alert = null;
			this.setValidators(is_for_child);
		});

		this.subs = this.form.get('participants').valueChanges.subscribe(participants => {
			if (participants <= 1) {
				this.form.get('participants').setValue(1, { emitEvent : false });
			} else if (participants >= 3) {
				this.form.get('participants').setValue(3, { emitEvent : false });
			}
		});

		this.subs = combineLatest([
			this.form.get('participant1_ssn').valueChanges.pipe(startWith('')),
			this.form.get('participant2_ssn').valueChanges.pipe(startWith('')),
			this.form.get('participant3_ssn').valueChanges.pipe(startWith('')),
		]).subscribe(() => {
			this.alert = null;
		});
	}

	@Input() set input({ course }: {
		course: CourseItem
	})
	{
		this.course = course;
		this.showParticipantAmountDialog();
	}

	ngOnInit(): void
	{
		this.getUser();
		this.call.make('asiointi/getGenders', [{}]).subscribe(genders => {
			this.genders = Object.values(genders);
		}, (err) => {
			const error = this.parseError(err);
			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Could not fetch genders'),
				message : Enkora.tt(error.error)
			};
		});
	}

	private setValidators(is_for_child: boolean): void
	{
		if (is_for_child) {
			const number_of_participants = +this.form.get('participants').value;

			for (let i = 1; i<number_of_participants + 1; i++) {
				const field = 'participant' + i.toString();
				this.form.get(field + '_ssn').setValidators(
					[Validators.required, this.validateSsn.bind(this)]
				);
				this.form.get(field + '_first_name').setValidators(Validators.required);
				this.form.get(field + '_last_name').setValidators(Validators.required);
				this.form.get(field + '_street').setValidators(Validators.required);
				this.form.get(field + '_postal_code').setValidators(
					[Validators.required, Validators.pattern(WebshopValidators.POSTAL_CODE)]
				);
				this.form.get(field + '_city').setValidators(Validators.required);
				this.form.get(field + '_phone').setValidators(
					[Validators.required, Validators.pattern(WebshopValidators.PHONE)]
				);
				this.form.get(field + '_gender').setValidators(Validators.required);
				this.form.get(field + '_email').setValidators([Validators.required,
					Validators.pattern(WebshopValidators.EMAIL)]);
				this.updateValidators(i);
			}
		} else {
			for (let i = 1; i<4; i++) {
				const field = 'participant' + i.toString();
				this.form.get(field + '_ssn').clearValidators();
				this.form.get(field + '_first_name').clearValidators();
				this.form.get(field + '_last_name').clearValidators();
				this.form.get(field + '_street').clearValidators();
				this.form.get(field + '_postal_code').clearValidators();
				this.form.get(field + '_city').clearValidators();
				this.form.get(field + '_phone').clearValidators();
				this.form.get(field + '_gender').clearValidators();
				this.form.get(field + '_email').clearValidators();
				this.updateValidators(i);
			}
		}
	}

	private setFareProductValidators(number_of_participants: number): void
	{
		for (let i = 1; i<number_of_participants + 1; i++) {
			const field = 'participant' + i.toString();
			this.form.get(field + '_fare_product').setValidators(Validators.required);
			this.form.get(field + '_fare_product').updateValueAndValidity();
		}
	}

	private setFareProductsSelected(
		participants: CourseParticipant[],
		fare_products: CourseFareProduct[]
	): void {
		each(participants, (participant, i) => {
			each(fare_products, fp => {
				if (participant.age >= fp.min_age && participant.age <= fp.max_age) {
					const controlName = `participant${i + 1}_fare_product`;
					this.form.get(controlName).setValue(fp.fare_product_id);
					return false;
				}
			});
		});
	}

	private updateValidators(i: number): void
	{
		const field = 'participant' + i.toString();
		this.form.get(field + '_ssn').updateValueAndValidity();
		this.form.get(field + '_first_name').updateValueAndValidity();
		this.form.get(field + '_last_name').updateValueAndValidity();
		this.form.get(field + '_street').updateValueAndValidity();
		this.form.get(field + '_postal_code').updateValueAndValidity();
		this.form.get(field + '_city').updateValueAndValidity();
		this.form.get(field + '_phone').updateValueAndValidity();
		this.form.get(field + '_gender').updateValueAndValidity();
		this.form.get(field + '_email').updateValueAndValidity();
	}

	private showParticipantAmountDialog(): void
	{
		this.params.get(true).subscribe(() => {
			if (this.course.max_age && this.course.max_age <= this.params.data.children_max_age_group_value) {
				this.phase = CourseParticipantsPhase.ParticipantAmount;
				this.form.get('for_child').setValue(true);
			} else {
				this.hide_participants_amount_dialog = true;
				this.phase = CourseParticipantsPhase.ParticipantInfo;
			}

			this.show_payer_and_participants = this.params.data.course_reservation_show_payer_and_participant_fields;
			this.payer_name_warning_label = Helpers.translateKey(
				this.params.data.payer_name_warning_label,
				this.params.data.payer_name_warning_label,
				true
			);
		});
	}

	private getUser(selected_user_id?: number): void
	{
		this.auth.get(true)
		.pipe(first())
		.subscribe(user => {
			if (user) {
				this.user = user;
				const name = this.parseName(this.user.name);
				this.user.first_name = this.user.first_name || name.first_name;
				this.user.last_name = this.user.last_name || name.last_name;

				const address = this.parseAddress(this.user.address);
				this.user.address_street = this.user.address_street || address.street;
				this.user.address_postcode = this.user.address_postcode || address.postal_code;
				this.user.address_city = this.user.address_city || address.city;

				this.form.patchValue({
					payer_ssn         : user.social_security_number,
					payer_first_name  : user.first_name,
					payer_last_name   : user.last_name,
					payer_street      : user.address_street,
					payer_postal_code : user.address_postcode,
					payer_city        : user.address_city,
					payer_phone       : user.phone_number,
					payer_email       : user.email,
					payer_gender      : user.gender_id,
				});

				if (this.hide_participants_amount_dialog) {
					const already_prereserved = this.prereservation.pre_reserved_accounts.find(
						item => item.reservation_event_group_id == this.course.related_reservation_event_group_id
					);

					if (!already_prereserved) {
						this.makePreReservation();
					} else {
						this.handlePrereservationResponse(already_prereserved.prereservation_response);
					}
				}
			}

			if (selected_user_id) {
				const new_account = user.linked_accounts.find(account => +account.user_id == selected_user_id);
				this.form.get('child').setValue(new_account.account_id);
			}
		});
	}

	private parseName(full_name: string): {
		first_name: string;
		last_name: string;
	} {
		const split = full_name.split(' ');
		const last_name = split.pop();
		const names = split.join(' ');
		return {
			first_name : names,
			last_name  : last_name
		}
	}

	private parseAddress(address: string): {
		street: string;
		postal_code: string;
		city: string;
	} {
		const split = address?.split(/\r?\n/);

		return {
			street      : split?.length > 0 ? split[0] : '',
			postal_code : split?.length > 1 ? split[1] : '',
			city        : split?.length > 2 ? split[2] : ''
		}
	}

	public addNewChild(): void
	{
		const form_value: CourseParticipantForm = this.form.value;
		const content = [this.user.account_id, {
			first_name  : form_value.first_name,
			last_name   : form_value.last_name,
		}, { return_user_id : true }];

		this.call.make('account/linknewaccount', content).subscribe((user_id: string) => {
			this.getUser(+user_id);
			this.form.get('first_name').setValue('');
			this.form.get('last_name').setValue('');
		});
	}

	public tryAddProduct(): void
	{
		this.loading_add_to_cart = true;
		this.clear_prereservation = false;
		if (!this.auth.isAuthenticated() && !this.params.data.allow_cart_before_login) {
			this.loginStepsService.login().subscribe(value => {
				if (value) this.addProduct();
			});
			return;
		}

		this.addProduct();
	}

	private getAccountId(): number
	{
		return this.form.get('for_child')
			? +this.form.get('child').value
			: +this.user.account_id;
	}

	public addProduct(open_cart = true, empty_cart = false, update_prereservations = true): void
	{
		const cart_items = this.buildCartItems();
		this.cart.addCourses(cart_items)
		.pipe(finalize(() => this.loading_add_to_cart = false))
		.subscribe((cart) => {
			if (open_cart) {
				this.activeModal.dismiss();
				this.modalHelper.openShopModal(ShopCartModal);
			}

			if (update_prereservations) {
				each(cart.items, item => {
					this.prereservation.update(
						String(item.reservation_params?.reservation_account_id),
						item.reservation_params?.reservation_event_group_id,
						item.fare_product_id
					);
				});
			}

			if (empty_cart) {
				this.cart.emptyCart().subscribe(() => {
					each(this.save_participants_response.participants, participant => {
						this.prereservation.removePreReservationByAccountId(participant.account_id);
					});

					if (!this.prereservation.pre_reservations.length) {
						this.cancelling_pre_reservation = false;
						this.prereservation.removeTimer();
					}
				});
			}
		}, (err) => {
			this.clear_prereservation = true;
			this.add_product_error = err;
		});
	}

	public openShoppingCart(): void
	{
		this.modalHelper.openShopModal(ShopCartModal);
	}

	public confirmQueue(add_item_to_cart = false): void
	{
		this.loading_confirm_queue = true;
		const participants = this.buildQueueItems(this.save_participants_response.participants);
		const overview_data = clone(this.save_participants_response);
		overview_data.participants = participants;

		this.confirmCourseQueueReservation(overview_data, this.course)
		.pipe(finalize(() => this.loading_confirm_queue = false))
		.subscribe(res => {
			if (res) {
				this.queue_places_confirmed = true;
				this.prereservation.removeUnconfirmedQueueItems();

				if (add_item_to_cart) {
					this.addProduct(false);
				}
			} else {
				this.queue_confirm_error = 'Could not confirm queue reservation';
			}

			this.changePhase(this.course_participants_phase.Confirmation);
		}, () => {
			this.queue_confirm_error = 'Could not confirm queue reservation';
		});
	}

	private confirmCourseQueueReservation(overview_data: SaveParticipantsResponse, course: CourseItem): Observable<boolean>
	{
		const payload = {
			reservation_event_group_id : course.related_reservation_event_group_id,
			social_security_number     : overview_data.user.social_security_number,
			participants               : JSON.stringify(overview_data)
		};

		return this.call.make('asiointi/confirmCourseQueueReservation', [payload]);
	}

	private buildCartItems(): CourseCartItem[]
	{
		if (!this.show_payer_and_participants) {
			const course: CourseCartItem = {
				account                    : this.getAccountId(),
				quantity                   : 1,
				fare_product_id            : this.course.fare_product_id,
				price                      : this.course.price,
				title                      : this.course.title,
				reservation_event_group_id : this.course.related_reservation_event_group_id,
			};

			return [course];
		}

		const result: CourseCartItem[] = [];
		const number_of_participants = +this.form.get('participants').value;

		for (let i=0; i < number_of_participants; i++) {
			const [participant_field, field] = this.getParticipantField(i, number_of_participants);
			const selected_fare_product_id = +this.form.get(participant_field + 'fare_product').value;

			const participant = find(
				this.save_participants_response.participants,
				participant => participant.social_security_number == this.form.get(field + 'ssn').value
			);

			if (participant.reservation_status_id === this.constants.RES_UNCONFIRMED_QUEUE
				|| participant.reservation_status_id === this.constants.RES_QUEUE
			) {
				continue;
			}

			const selected_fare_product = find(
				participant.personal_fare_products,
				fp => fp.fare_product_id == selected_fare_product_id
			) ?? find(
				this.save_participants_response.fare_products,
				fp => fp.fare_product_id == selected_fare_product_id
			);

			const course_item: CourseCartItem = {
				account                    : +participant.account_id,
				quantity                   : 1,
				fare_product_id            : selected_fare_product_id,
				price                      : selected_fare_product?.price,
				title                      : this.course.title,
				reservation_event_group_id : this.course.related_reservation_event_group_id,
			};

			result.push(course_item);
		}

		return result;
	}

	private buildQueueItems(participants: CourseParticipant[]): CourseParticipant[]
	{
		const result: CourseParticipant[] = [];
		const number_of_participants = +this.form.get('participants').value;

		for (let i=0; i < number_of_participants; i++) {
			const [participant_field, field] = this.getParticipantField(i, number_of_participants);
			const selected_fare_product_id = +this.form.get(participant_field + 'fare_product').value;

			const participant = clone(
				find(
					participants,
					participant => participant.social_security_number == this.form.get(field + 'ssn').value
				)
			);

			if (participant.reservation_status_id !== this.constants.RES_UNCONFIRMED_QUEUE
				&& participant.reservation_status_id !== this.constants.RES_QUEUE
			) {
				continue;
			}

			participant.fare_product_id = selected_fare_product_id;
			result.push(participant);
		}

		return result;
	}

	private getParticipantField(index: number, number_of_participants: number): string[]
	{
		const participant_field = 'participant' + String((index + 1)) + '_';
		const field = number_of_participants > 1 || !this.hide_participants_amount_dialog
		|| (number_of_participants === 1 && this.form.get('participant1_ssn').value)
			? participant_field
			: 'payer_';

		return [participant_field, field];
	}

	public changePhase(new_phase: CourseParticipantsPhase): void
	{
		this.previous_phase = this.phase;
		this.phase = new_phase;
	}

	public returnToPreviousPhase(): void
	{
		this.changePhase(this.previous_phase);
	}

	public addParticipant(): void
	{
		let count = +this.form.get('participants').value + 1;
		if (count >= 3) { count = 3; }
		this.form.get('participants').setValue(count);
	}

	public removeParticipant(): void
	{
		let count = +this.form.get('participants').value - 1;
		if (count <= 1) { count = 1; }
		this.form.get('participants').setValue(count);
	}

	public makePreReservation(): void
	{
		this.loading_pre_reservation = true;

		this.prereservation.makePreReservation(
			this.user.social_security_number,
			this.course.related_reservation_event_group_id,
			this.form.get('participants').value
		).pipe(
			finalize(() => this.loading_pre_reservation = false)
		).subscribe(res => {
			this.handlePrereservationResponse(res);
		}, (err) => {
			const error = this.parseError(err);
			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Prereservation failed'),
				message : Enkora.tt(error.error)
			};
		});
	}

	private handlePrereservationResponse(res: PreReservation)
	{
		this.alert = null;
		this.pre_reservation_response = res;
		this.changePhase(CourseParticipantsPhase.ParticipantInfo);
		this.prereservation.setupTimer(res);
		this.pre_reserved_accounts = this.prereservation.pre_reserved_accounts;

		this.pre_reservation_response.filtered_reservations = this.pre_reserved_accounts.filter(
			account => !!account.accessibility
		);

		this.form.get('participants').setValue(this.pre_reservation_response.filtered_reservations.length);

		if (!this.hide_participants_amount_dialog) {
			this.setValidators(true);
		}

		if (!this.pre_reservation_response.filtered_reservations.length) {
			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Prereservation failed'),
				message : Enkora.tt('Course is full')
			};

			this.cancelling_pre_reservation = true;
			this.cancelPreReservation();
			return;
		}

		if (this.pre_reservation_response.filtered_reservations.length < this.pre_reserved_accounts.length) {
			this.alert = {
				code    : AlertCode.Info,
				title   : Enkora.tt('Notice'),
				message : Enkora.tt('Some participants could not enter the course because it is full')
			};
			return;
		}
	}

	public cancelPreReservation(): void
	{
		if (!this.user?.social_security_number) { return; }

		this.prereservation.cancelPreReservation(this.user.social_security_number)
		.pipe(finalize(() => {
			if (this.save_participants_response?.participants && !this.add_product_error) {
				this.addProduct(false, true, false);
				return;
			}

			if (!this.prereservation.pre_reservations.length) {
				this.cancelling_pre_reservation = false;
				this.prereservation.removeTimer();
			} else {
				if (this.save_participants_response) {
					each(this.save_participants_response.participants, participant => {
						this.prereservation.removePreReservationByAccountId(participant.account_id);
					});

					this.cancelling_pre_reservation = false;
					this.prereservation.removeTimer();
				}
			}
		}))
		.subscribe();

		this.selectors.setState(this.selectors.state); // this will reload course list data
	}

	public openCancelConfirmation(): void
	{
		if (this.prereservation.pre_reservation_active) {
			this.changePhase(this.course_participants_phase.Cancel);
		} else {
			this.activeModal.dismiss();
		}
	}

	public usePayersInfo(): void
	{
		this.form.patchValue({
			participant1_ssn         : this.form.get('payer_ssn').value,
			participant1_first_name  : this.form.get('payer_first_name').value,
			participant1_last_name   : this.form.get('payer_last_name').value,
			participant1_street      : this.form.get('payer_street').value,
			participant1_postal_code : this.form.get('payer_postal_code').value,
			participant1_city        : this.form.get('payer_city').value,
			participant1_phone       : this.form.get('payer_phone').value,
			participant1_email       : this.form.get('payer_email').value,
			participant1_gender      : this.form.get('payer_gender').value,
		});
	}

	public validateSsn(c: FormControl): { validateSsn?: { valid: boolean }, validateAge?: { valid: boolean } }
	{
		const validated = this.ssnService.parseSocialSecurityNumber(c.value);
		if (!validated || !validated.valid) {
			return {
				validateSsn : {
					valid : false
				}
			};
		}
		return null;
	}

	public returnToParticipantsDialog(): void
	{
		this.cancelling_pre_reservation = true;
		this.cancelPreReservation();
		this.changePhase(CourseParticipantsPhase.ParticipantAmount);
	}

	private checkAge(): AgeCheck
	{
		let age_check_passed = null;
		const number_of_participants = +this.form.get('participants').value + 1;
		const has_participants = this.form.get('for_child').value;
		const failed_participants: AgeCheckParticipant[] = [];

		for (let i = 1; i < number_of_participants; i++) {
			const field = has_participants ? 'participant' + i.toString() : 'payer';
			const ssn = this.form.get(field + '_ssn').value as string;
			const age = this.getAgeFromSSN(ssn);

			if (this.hasValidFareProducts(age, this.course.fare_products)) {
				if (isNull(age_check_passed)) age_check_passed = true;
				if (!has_participants) { break; }
			} else {
				age_check_passed = false;
				const participant_name = `
					${this.form.get(field + '_first_name').value} ${this.form.get(field + '_last_name').value}
				`;

				failed_participants.push({
					name  : participant_name,
					ssn   : ssn,
					alert : {
						code    : AlertCode.Warning,
						title   : Enkora.tt('Age not allowed'),
						message : participant_name
					}
				});
			}
		}

		return {
			result              : age_check_passed,
			failed_participants : failed_participants
		};
	}

	private hasValidFareProducts(age: number, fare_products: CourseFareProduct[]): boolean
	{
		let is_valid = false;
		each(fare_products, fp => {
			if (fp.min_age && fp.max_age && age >= fp.min_age && age <= fp.max_age) {
				is_valid = true;
				return false;
			}
		});
		return is_valid;
	}

	private getAgeFromSSN(ssn: string): number
	{
		const is_2k = ssn.substr(6, 1) === 'A';
		const year = is_2k ? '20' + ssn.substr(4, 2) : '19' + ssn.substr(4, 2);
		const dob = ssn.substr(0, 4) + year;
		const moment_dob = moment(dob, 'DDMMYYYY');
		return moment().diff(moment_dob, 'years');
	}

	public goToParticipantList(): void
	{
		if (!this.form.valid) {
			each(Object.keys(this.form.controls), key => {
				this.form_errors[key] = true;
			});

			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Invalid form data'),
				message : ''
			};

			each(this.form.controls, control => {
				control.markAsDirty();
			});

			return;
		}

		this.loading_save_participants = true;
		this.age_check = this.checkAge();

		if (this.age_check.result) {
			this.alert = null;
			this.updateParticipantsData();
		} else {
			this.loading_save_participants = false;
			this.alert = {
				code    : AlertCode.Warning,
				title   : Enkora.tt('Age not allowed'),
				message : Enkora.tt('Participant age does not meet the course age criteria')
			};
		}
	}

	private updateParticipantsData(): void
	{
		const payload: SaveParticipantsData = {
			reservation_event_group_id : this.course.related_reservation_event_group_id,
			user                       : {
				user_id      : this.user.user_id,
				first_name   : this.form.get('payer_first_name').value || this.user.first_name,
				middle_names : this.user.middle_names,
				last_name    : this.form.get('payer_last_name').value || this.user.last_name,
				phone_number : this.form.get('payer_phone').value || this.user.phone_number,
				address      : {
					street    : this.form.get('payer_street').value || this.user.address_street,
					city      : this.form.get('payer_city').value || this.user.address_city,
					postcode  : this.form.get('payer_postal_code').value || this.user.address_postcode
				},
				email                  : this.form.get('payer_email').value || this.user.email,
				social_security_number : this.user.social_security_number,
				account_id             : this.user.account_id,
				gender_id              : this.form.get('payer_gender').value || this.user.gender_id
			},
			participants              : []
		};

		const number_of_participants = +this.form.get('participants').value;
		const account_ids = this.pre_reservation_response.filtered_reservations.map(res => res.account_id);

		for (let i = 0; i < number_of_participants; i++) {
			let field = 'participant' + (i + 1).toString();
			if (!this.form.get('for_child').value) {
				field = 'payer';
			}

			payload.participants.push(
				{
					address : {
						city     : this.form.get(field + '_city').value,
						postcode : this.form.get(field + '_postal_code').value,
						street   : this.form.get(field + '_street').value
					},
					email                  : this.form.get(field + '_email').value,
					first_name             : this.form.get(field + '_first_name').value,
					last_name              : this.form.get(field + '_last_name').value,
					phone_number           : this.form.get(field + '_phone').value,
					social_security_number : this.form.get(field + '_ssn').value,
					account_id             : field === 'payer' ? this.user.account_id : account_ids[i],
					gender_id              : this.form.get(field + '_gender').value
				}
			);
		}

		this.call.make('asiointi/saveCourseRegistrationData', [{
			social_security_number : this.user.social_security_number,
			data                   : JSON.stringify(payload)
		}], { return_user_error : false })
		.pipe(finalize(() => {
			timer(2000).subscribe(() => {
				this.loading_save_participants = false;
			})
		}))
		.subscribe((res: SaveParticipantsResponse) => {
			this.save_participants_response = this.calculateAgesForParticipants(res);
			this.unConfirmedQueueSpots = this.getNumberOfUnConfirmedQueueSpots(this.save_participants_response.participants);

			this.save_participants_response.campaign_fare_products = this.getCampaignFareProducts(
				res.fare_products, res.participants
			);

			this.save_participants_response.fare_products = this.save_participants_response.fare_products.sort(
				(a, b) => b.price - a.price
			);

			this.setFareProductValidators(number_of_participants);
			this.setFareProductsSelected(
				this.save_participants_response.participants,
				this.save_participants_response.fare_products
			);

			each(this.save_participants_response.participants, participant => {
				this.prereservation.addPreReservationAccount(
					participant.account_id,
					participant.reservation_status_id,
				);
			});

			this.prereservation.resetPreReservedAccounts();

			this.reservation_type = this.getReservationType(this.save_participants_response.participants);
			this.changePhase(CourseParticipantsPhase.ParticipantList);
		}, (err) => {
			const error = this.parseError(err);

			if (error.code === 3) {
				this.save_participants_failed = true;
			}

			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Saving course data failed'),
				message : Enkora.tt(error.error)
			};
		});
	}

	private getNumberOfUnConfirmedQueueSpots(participants: CourseParticipant[]): number
	{
		return participants.filter(
			participant => participant.reservation_status_id === Constants.RES_UNCONFIRMED_QUEUE
		).length;
	}

	private getReservationType(participants: CourseParticipant[]): CourseParticipantsReservationType
	{
		let has_queue = false;
		let has_unconfirmed = false;

		for (const participant of participants) {
			if (participant.reservation_status_id === this.constants.RES_QUEUE
				|| participant.reservation_status_id === this.constants.RES_UNCONFIRMED_QUEUE
			) {
				has_queue = true;
			}

			if (participant.reservation_status_id === this.constants.RES_UNCONFIRMED
			) {
				has_unconfirmed = true;
			}
		}

		if (has_queue && has_unconfirmed) {
			return this.course_participants_reservation_type.MixedReservation;
		} else if (has_queue && !has_unconfirmed) {
			return this.course_participants_reservation_type.QueueReservation;
		}  else if (!has_queue && has_unconfirmed) {
			return this.course_participants_reservation_type.NormalReservation;
		} else {
			return this.course_participants_reservation_type.NormalReservation;
		}
	}

	private calculateAgesForParticipants(save_participants_response: SaveParticipantsResponse): SaveParticipantsResponse
	{
		for (const participant of save_participants_response.participants) {
			participant.age = this.getAgeFromSSN(participant.social_security_number);
		}

		return save_participants_response;
	}

	private getCampaignFareProducts(
		original_fare_products: CourseFareProduct[],
		participants: CourseParticipant[]): { [key: string]: CourseFareProduct[] }
	{
		const result: { [key: string]: CourseFareProduct[] } = {};

		for (const ofp of original_fare_products) {
			ofp.name = Helpers.translateKey(
			`fare_product ${ofp.fare_product_id} name`, ofp.name, true);
		}

		for (const participant of participants) {
			if (!participant.personal_fare_products) {
				result[participant.account_id] = original_fare_products;
				continue;
			}

			for (const ofp of original_fare_products) {
				const new_fare_product = clone(ofp);
				const campaign_fare_product = find(
					participant.personal_fare_products,
					fp => fp.fare_product_id == ofp.fare_product_id
				);

				if (campaign_fare_product) {
					new_fare_product.campaign_id = campaign_fare_product.campaign_id;
					new_fare_product.campaign_name = campaign_fare_product.campaign_name;
					new_fare_product.price = campaign_fare_product.price;
					new_fare_product.name = Helpers.translateKey(
						`fare_product ${campaign_fare_product.fare_product_id} name`, campaign_fare_product.name, true);
				}

				if (!result[participant.account_id]) { result[participant.account_id] = []; }
				result[participant.account_id].push(new_fare_product);
			}
		}

		return result;
	}

	private parseError(error: CourseError): { code: number, error: string }
	{
		if (typeof error === 'string') {
			const err = String(error);

			if (err.includes('Event already reserved!')) {
				return { code : 1, error : 'Event already reserved!' };
			} else if (err.includes('You have exceeded your reservation limit for this service!')
						|| err.includes('Varauskiintiösi tähän palveluun on ylittynyt!')
			) {
				return { code : 2, error : 'You have exceeded your reservation limit for this service!' };
			} else if (err.includes('Unconfirmed reservation for participant found')) {
				return {
					code  : 3,
					error : 'Unconfirmed reservation for participant found. Reservation is released once prereservation expires'
				}
			}

			return { code : 0, error : 'Reservation failed, please try again' };
		} else {
			return { code : -1, error : error?.user_errors ? error?.user_errors[0] : error?.errors[0] };
		}
	}

	setErrorVisible(key: string): void
	{
		this.form_errors[key] = true;
	}

	ngAfterViewInit(): void
	{
		Helpers.setElementFocus(this.modalContent?.nativeElement, '.aria-focus');
	}

	ngOnDestroy(): void
	{
		if (this.queue_places_confirmed
			&& !this.hide_participants_amount_dialog
			&& this.reservation_type === CourseParticipantsReservationType.MixedReservation
		) {
			this.openShoppingCart();
		} else {
			if (this.clear_prereservation) {
				this.cancelPreReservation();
			}
		}
	}
}
