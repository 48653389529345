import { TreeNode } from 'primeng/api';
import { each, filter } from 'lodash';

export interface NodesRelation {
	agreement_id: string;
	service_organization_id: string;
	customer_organization_id: string;
}

export class TaCompanyTreeGenerator {

	public static setupTree(nodes: TreeNode[])
	{
		this.forEach(nodes, node => {
			node.label = node.data.customer_organization_id + '. ' + node.data.name;
			node.data.organization_id = node.data.customer_organization_id;

			if (!node.children) node.children = [];

			if (node.children.length) {
				node.expanded = true;
			}
		});
	}

	public static forEach(nodes: TreeNode[],
	                      callback: (node?: TreeNode, parent?: TreeNode, path?: TreeNode[]) => any,
	                      parent = null,
	                      path: TreeNode[] = [])
	{
		each(nodes, (node: TreeNode) => {
			path.push(node);
			callback(node, parent, path);

			if (node.children && node.children.length) {
				this.forEach(node.children, callback, node, path);
			}
			path.pop();
		});
	}

	public static filterNodes(node: TreeNode, nodes: TreeNode[])
	{
		const filtered_nodes = [];
		each(nodes, (n) => {
			filtered_nodes.push(...this.filterNodes(node, n.children));
		});

		filtered_nodes.push(...filter(nodes, (n) => {
			return n.data.organization_id === node.data.organization_id;
		}));

		return filtered_nodes;
	}

	public static getNodesRelations(nodes: TreeNode[]): NodesRelation[]
	{
		const relations: NodesRelation[] = [];
		relations.push(...this.nodesRelationsLoop(nodes));
		return relations;
	}

	public static nodesRelationsLoop(nodes: TreeNode[], parent = null): NodesRelation[]
	{
		const relations: NodesRelation[] = [];
		each(nodes, (node: TreeNode) => {
			if (parent) {
				const relation = {
					agreement_id             : node.data.agreement_id,
					service_organization_id  : parent.data.organization_id,
					customer_organization_id : node.data.organization_id
				};
				relations.push(relation);
			}

			if (node.children && node.children.length) {
				relations.push(...this.nodesRelationsLoop(node.children, node));
			}
		});

		return relations;
	}

	public static limitSubcontractorsMaxLength(nodes: TreeNode[], max_length: number)
	{
		if (max_length == -1 || !nodes.length) return;

		this.forEach(nodes, (node, parent, path) => {
			if (path.length >= max_length) {
				each(path, (p, index) => {
					p.data.adding_limited = !!index;
				});
			}
		});

		const root_node = nodes[0];
		if (root_node) {
			const child_limit_count = filter(root_node.children, it => it.data.adding_limited).length ?? 0;
			root_node.data.adding_limited = (root_node.children?.length >= max_length && child_limit_count === root_node.children?.length);
		}
	}

	public static getSubcontractorsLength(nodes: TreeNode[]): number
	{
		let length = 0;
		this.forEach(nodes, (node, parent, path) => {
			if (path.length > length) {
				length = path.length;
			}
		});
		return length;
	}
}
