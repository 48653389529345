import { Injectable } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment/moment';
import { each } from 'lodash';
import { Moment } from 'moment';

import {
	AuthenticationService,
	CallService, Constants
} from 'shared';

import { PreReservation } from '../main/courses/interfaces/general.interface';

import { ShopServerTimeService } from './server-time.service';

export interface PrereservationAccount {
	account_id: string;
	accessibility: number;
	prereservation_response?: PreReservation;
	reservation_event_group_id?: number;
	fare_product_id?: number;
}

export interface PrereservationField {
	reserved_until: string;
	reservation_event_group_id: number;
	reservation_event_group_name: string;
	reservations: { [key: string]: number };
	fare_product_id?: number;
}

@Injectable()
export class PrereservationService {
	public pre_reservation_active = false;
	public pre_reservation_seconds = 0;
	public pre_reservation_sub: Subscription;
	public pre_reserved_accounts: PrereservationAccount[] = [];
	public pre_reservations: PrereservationAccount[] = [];
	public value$: Subject<number> = new Subject<number>();

	constructor(
		private call: CallService,
		private auth: AuthenticationService,
		private serverTimeService: ShopServerTimeService,
	) {
	}

	public init(prereservations: PrereservationField[]): void
	{
		this.auth.get().subscribe(user => {
			let earliest_timetamp: Moment = null;
			let earliest_prepreservation: PrereservationField = null;

			each(prereservations, prereservation => {
				const expires_moment = moment(prereservation.reserved_until);
				if (!earliest_timetamp || expires_moment.isBefore(earliest_timetamp)) {
					earliest_timetamp = expires_moment;
					earliest_prepreservation = prereservation;
				}
			});

			if (earliest_timetamp.isBefore(moment().subtract(10, 'seconds'))) { return; }

			const tmp_time = earliest_timetamp.format('YYYY-MM-DD HH:mm:ss');
			this.updateExpirationSeconds(tmp_time);
			this.value$.next(this.pre_reservation_seconds);

			const prereservation: PreReservation = {
				user           : {
					account_id  : +user.account_id,
					address     : {
						street   : user.address_street,
						postcode : user.address_postcode,
						city     : user.address_city,
					},
					email                  : user.email,
					first_name             : user.first_name,
					gender_id              : +user.gender_id,
					last_name              : user.last_name,
					middle_names           : user.middle_names,
					phone_number           : user.phone_number,
					social_security_number : user.social_security_number,
					user_id                : +user.user_id,
				},
				reservation_event_group_id : earliest_prepreservation.reservation_event_group_id,
				reserved_until             : earliest_prepreservation.reserved_until,
				reservations               : earliest_prepreservation.reservations,
			}

			this.setupTimer(prereservation);
		});
	}

	public makePreReservation(
		social_security_number: string,
		reservation_event_group_id: number,
		quantity: string
	): Observable<PreReservation>
	{
		const content = [{
			social_security_number     : social_security_number,
			reservation_event_group_id : reservation_event_group_id,
			quantity                   : quantity
		}];

		return this.call.make('asiointi/preReserveCoursePlaces', content);
	}

	public cancelPreReservation(social_security_number: string): Observable<void>
	{
		this.pre_reservation_active = false;

		const content = [{
			social_security_number : social_security_number,
		}];

		return this.call.make('asiointi/cancelPreReservedCoursePlaces', content);
	}

	public setupTimer(res: PreReservation): void
	{
		this.pre_reserved_accounts = [];
		this.pre_reservation_active = true;
		this.updateExpirationSeconds(res.reserved_until);

		if (this.pre_reservation_sub) {
			this.pre_reservation_sub.unsubscribe();
		}

		this.pre_reservation_sub = interval(1000).subscribe(() => {
			this.updateExpirationSeconds(res.reserved_until);
			this.value$.next(this.pre_reservation_seconds);
			if (this.pre_reservation_seconds <= 0) {
				this.removeTimer();
				this.pre_reservation_active = false;
			}
		});

		for (const [account_id, accessibility_id] of Object.entries(res.reservations)) {
			this.pre_reserved_accounts.push({
				account_id                 : account_id,
				accessibility              : accessibility_id,
				prereservation_response    : res,
			});
		}
	}

	private updateExpirationSeconds(reserved_until: string)
	{
		const now = this.serverTimeService.getServerTime();
		const expiration = moment(reserved_until);
		this.pre_reservation_seconds = expiration.diff(now, 'seconds');
	}

	public removeTimer(): void
	{
		if (!this.pre_reservation_sub) { return; }
		this.pre_reservation_sub.unsubscribe();
		this.pre_reservation_seconds = 0;
		this.pre_reserved_accounts = [];
		this.pre_reservations = [];
		this.value$.next(0);
	}

	public addPreReservationAccount(
		account_id: string,
		accessibility: number,
	): void {
		this.pre_reservations.push({
			account_id              : account_id,
			accessibility           : accessibility,
		});
	}

	public resetPreReservedAccounts(): void
	{
		this.pre_reserved_accounts = [];
	}

	public removePreReservationByAccountId(account_id: string): void
	{
		this.pre_reservations = this.pre_reservations.filter(
			preres => !(preres.account_id == account_id)
		);

		if (!this.pre_reservations.length) {
			this.pre_reservation_active = false;
			this.removeTimer();
		}
	}

	public removeUnconfirmedQueueItems(): void
	{
		this.pre_reservations = this.pre_reservations.filter(item => item.accessibility !== Constants.RES_UNCONFIRMED_QUEUE);

		if (!this.pre_reservations.length) {
			this.pre_reservation_active = false;
			this.removeTimer();
		}
	}

	public removePreReservation(account_id: string, reservation_event_group_id: number, fare_product_id: number): void
	{
		this.pre_reservations = this.pre_reservations.filter(
			preres => !(preres.account_id == account_id
				&& preres.reservation_event_group_id == reservation_event_group_id
				&& preres.fare_product_id == fare_product_id)
		);

		if (!this.pre_reservations.length) {
			this.pre_reservation_active = false;
			this.removeTimer();
		}
	}

	public removeAll(): void
	{
		this.pre_reservation_active = false;
		this.pre_reserved_accounts = [];
		this.pre_reservations = [];
		this.removeTimer();
	}

	public update(account_id: string, reservation_event_group_id: number, fare_product_id: number): void
	{
		const prereservation = this.pre_reservations.find(
			preres => preres.account_id == account_id
		);

		if (prereservation) {
			prereservation.reservation_event_group_id = reservation_event_group_id;
			prereservation.fare_product_id = fare_product_id;
		}
	}
}
