import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { TranslationWidth } from '@angular/common';
import { localeData } from 'moment';
import { upperFirst } from 'lodash';

@Injectable()
export class NgbDatepickerTranslationService extends NgbDatepickerI18n {
	getWeekdayShortName(weekday: number): string
	{
		return this.short(localeData().weekdaysShort()[weekday % 7]);
	}

	getMonthShortName(month: number): string
	{
		return this.short(localeData().monthsShort()[month - 1]);
	}

	getMonthFullName(month: number): string
	{
		return localeData().months()[month - 1];
	}

	getDayAriaLabel(date: NgbDateStruct): string
	{
		return `${date.day}.${date.month}.${date.year}`;
	}

	getWeekdayLabel(weekday: number, width?: TranslationWidth): string
	{
		return localeData().weekdaysShort()[weekday];
	}

	private short(str: string): string
	{
		return upperFirst(str).slice(0, 3);
	}
}
