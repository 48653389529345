import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { each } from 'lodash';

import {
	ConfigService,
	AuthenticationService,
	CallService,
	Constants,
	ModalOpenerService,
	ParameterService,
	Helpers,
	User
} from 'shared';

import { LoginState, LoginStepsData, ShopCustomField } from '../../services';
import { AlertCode, AlertType } from '../../alerts';
import { ShopUserModifyModal } from '../../user';

@Component({
	selector    : 'shop-login-modal',
	templateUrl : './login.modal.html',
	styleUrls   : ['./login.modal.scss']
})
export class ShopLoginModal implements OnInit, AfterViewInit {
	@ViewChild('modalContent') modalContent!: ElementRef;
	only_login = true;
	hide_login = false;
	hide_suomi_login = true;
	public email = '';
	public password = '';
	public alert: AlertType = null;
	public is_logging_in = false;
	public check_missing_info = false;
	public simple_registration = false;
	public simple_registration_fields = '';

	constructor(public activeModal: NgbActiveModal,
	            public auth: AuthenticationService,
	            public call: CallService,
	            public config: ConfigService,
	            private modalService: ModalOpenerService,
	            private params: ParameterService,
	            private cRef: ChangeDetectorRef,
	) {
		this.params.getBooleanValue(
			'only login',
			Constants.NM_EN_WEBSHOP
		).subscribe(only_login => this.only_login = only_login);

		this.params.getBooleanValue(
			'hide login with email',
			Constants.NM_EN_WEBSHOP
		).subscribe(hide_login => this.hide_login = hide_login);


		this.params.getValue(
			'SAML config name',
			Constants.NM_MAIN
		).subscribe(saml_config => {
			if (saml_config !== 'suomifi') return false;

			this.params.getBooleanValue(
				'hide suomifi login',
				Constants.NM_EN_WEBSHOP
			).subscribe(hide_suomi_login => {
				this.hide_suomi_login = hide_suomi_login;
				// If we have only Suomi login, we can redirect user instantly
				//if (this.hide_login && !this.hide_suomi_login) window.location.href = this.getSuomiLogin();
			});
		});

		this.params.getBooleanValue(
			'check if user info is complete on login',
			Constants.NM_EN_RESERVATIONS
		).subscribe(check_missing_info => this.check_missing_info = check_missing_info);

		this.params.getBooleanValue(
			'simple registration allowed',
			Constants.NM_EN_WEBSHOP
		).subscribe(simple_registration => this.simple_registration = simple_registration);

		this.params.getValue(
			'simple registration form fields',
			Constants.NM_EN_WEBSHOP
		).subscribe(simple_registration_fields => this.simple_registration_fields = simple_registration_fields);
	}

	ngOnInit(): void
	{
	}

	public close(data: LoginStepsData): void
	{
		this.activeModal.close(data);
	}

	public needRegister(): void
	{
		this.close({ state : LoginState.Register });
	}

	public needReminder(): void
	{
		this.close({ state : LoginState.Reminder, value : this.email });
	}

	public doLogin(): void
	{
		this.is_logging_in = true;
		this.auth.customerLogin(this.email, this.password, {
			return_getcurrentuser : true,
			return_account_tags   : true
		}).subscribe((user) => {
			this.is_logging_in = false;
			this.close({ state : LoginState.Login });

			// if user has missing info, open edit modal
			if (this.simple_registration && this.simple_registration_fields) {
				const shop_fields: { [key: string]: ShopCustomField } = {};
				const fields = this.simple_registration_fields.split(',');

				each(fields, field => {
					shop_fields[field] = {
						data_type            : 'text',
						description          : '',
						field_name           : field,
						is_customer_editable : 1,
						is_required          : 1,
						ui_order             : null,
						validation_regex     : null,
					};
				});

				this.checkMissingInfo(user, shop_fields);
			} else {
				this.call.make('common/getfields', ['reservations 2', { name_as_key : true }])
				.subscribe(fields => {
					const shop_fields = fields as { [key: string]: ShopCustomField };
					this.checkMissingInfo(user, shop_fields);
				});
			}
			this.cRef.detectChanges();
		}, (error) => {
			this.is_logging_in = false;
			this.alert = {
				code    : AlertCode.Error,
				title   : 'Login failed!',
				message : error
			};
		});
	}

	private checkMissingInfo(user: User, shop_fields: { [key: string]: ShopCustomField }): void
	{
		if (this.check_missing_info && Helpers.userHasMissingInfo(user, shop_fields)) {
			const modalRef = this.modalService.openStaticShopModal(ShopUserModifyModal);
			const componentInstance: ShopUserModifyModal = modalRef.componentInstance;
			componentInstance.user = user;
			componentInstance.customTitle = 'Complete the user info';
			componentInstance.hideCancelButton = true;

			modalRef.result.then(() => {
				this.auth.reload();
			}, () => {
			});
		}
	}

	public getSuomiLogin(): string
	{
		return this.config.baseUrl + 'suomifi';
	}

	ngAfterViewInit(): void
	{
		Helpers.setElementFocus(this.modalContent?.nativeElement, '.aria-focus');
	}
}
