import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { GuidService } from '../../../../../shared';

@Component({
	selector    : 'cta-order-form-extra-services',
	templateUrl : './order-extra-services.form.html',
	styleUrls   : ['./order-extra-services.form.scss']
})
export class OrderExtraServicesForm implements OnInit {
	public extra_services: FormArray;
	public id;

	constructor(guid: GuidService)
	{
		this.id = guid.Next;
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;
		this.extra_services = <FormArray>value.get('extra_services');
	}

	ngOnInit(): void
	{
	}

	resetForm(): void
	{
	}
}
