import { Injectable } from '@angular/core';
import { combineLatest, Observable, Observer } from 'rxjs';
import * as moment from 'moment';
import { clone, each, isEmpty, isEqual, isString } from 'lodash';

import { CallService, Constants, EnkoraFetcher, Helpers, ParameterService } from '../../shared';
import { ServicesManagerService } from './services-manager.service';
import { ReservationTypesService } from './reservation-types.service';
import { ApplicationTagsService } from './application-tags.service';
import { first } from 'rxjs/operators';

declare let Enkora: { tt: (a: string) => string };

export interface ReservationApplicationResource {
	resource_id : number;
	name : string;
	location_id : number;
	location : string;
	reservation_application_id : number;
}

export interface ReservationApplicationRaw {
	reservation_application_id : number;
	reservation_application_status_id : number;
	creation_timestamp : string;
	status_timestamp : string;
	is_active : number;
	service_id : number;
	user_id : number;
	organization_id : number;
	date_start : string;
	date_end : string;
	time_slots : string;
	data : string;
	organization : string;
	organization_external_id : number;
	account_id : number;
	service : string;
	reservation_type : string;
	tag_id : number;
	resources : ReservationApplicationResource[];
	location : string;
	resource_id : number;
	attachments : any;

	is_admission_fee : boolean;
	is_telecast : boolean;
	is_business_activity : boolean;
}

export interface ReservationApplicationData {
	reservation_type_id : number;
	age_groups : { [group_name : string] : { male : number, female : number } };
	agreement : { agreement_id : string, account_id : string }
	reservation_recurrence : string;
	applicant : { contact : any, invoicing_address : any, is_private : number, organization : any };
	application_age_group : string;
	additional_info : string;
	additional_notes : string;
	is_business_activity : boolean;
	purpose_of_usage? : string;
	tag_id : string;
	tag_ids : number[]
}

export interface ReservationApplicationWeekday {
	weekDay : string;
	time_start : string;
	time_end : string;
}

export interface ReservationApplication {
	reservation_application_id : number;
	reservation_application_status_id : number;
	creation_timestamp : string;
	status_timestamp : string;
	is_active : number;
	service_id : number;
	user_id : number;
	organization_id : number;
	date_start : string;
	date_end : string;
	time_slots : ReservationApplicationWeekday[];
	applied_weekdays : ReservationApplicationWeekday[];

	data : ReservationApplicationData;
	organization : string;
	organization_external_id : number;
	account_id : number;
	service : string;
	reservation_type : string;
	tag_id : number;
	resources : ReservationApplicationResource[];
	location : string;
	resource_id : number;
	attachments : any;
	services : any[];

	application_tags : string[];

	is_admission_fee : boolean;
	is_telecast : boolean;
	is_business_activity : boolean;
	is_single_reservation : boolean;

	applied_weekdays_string : string;
	resource_names : string;
	location_names : string;

	agreements_contact_email : string[];
}

@Injectable()
export class ApplicationReservationService extends EnkoraFetcher<ReservationApplication[]> {

	public timestamp : number = 0;
	public do_load_handled = false;

	constructor(call : CallService,
	            private parameterService : ParameterService,
	            private serviceManager : ServicesManagerService,
	            private reservationTypeManager : ReservationTypesService,
	            public applicationTags : ApplicationTagsService)
	{
		super(call);

		parameterService.getValue('load handled applications in application management', Constants.NM_EN_RESERVATIONS)
		.subscribe((res) => {
			this.do_load_handled = !!res;
		});
	}

	protected preProcess()
	{
		this.params = [{
			name    : 'reservation/getReservationApplications',
			content : [{
				load_unhandled        : !this.do_load_handled,
				last_status_timestamp : this.timestamp ? moment.unix(this.timestamp).format('YYYY-MM-DD HH:mm:ss') : null
			}]
		}];
	}

	protected postProcess(reply : ReservationApplicationRaw[], params : { last_status_timestamp : string })
	{
		return new Observable<ReservationApplication[]>((observer : Observer<ReservationApplication[]>) => {
			combineLatest([
				this.reservationTypeManager.get(),
				this.applicationTags.get()
			]).pipe(first()).subscribe(() => {
				const result : ReservationApplication[] = [];

				const use_existing = this.timestamp != 0;

				each(reply, row => {
					const application = this.prepareApplication(row);
					this.timestamp = Math.max(moment(application.status_timestamp, Helpers.serverFullDateFormat).unix(), this.timestamp);

					result.push(application);
				});

				if (!use_existing) {
					observer.next(result);
					observer.complete();
					return;
				}

				const existing = clone(this.value);
				const existing_index : { [id : string] : number } = {};
				each(existing, (row, i) => existing_index[row.reservation_application_id] = i);

				const remaining_items : ReservationApplication[] = [];
				each(result, row => {
					if (existing_index[row.reservation_application_id]) {
						existing[existing_index[row.reservation_application_id]] = row;
					} else remaining_items.push(row);
				});

				observer.next(existing.concat(remaining_items));
				observer.complete();
			});
		});
	}

	private prepareApplication(row : ReservationApplicationRaw) : ReservationApplication
	{
		let time_slots = [];
		if (isString(row.time_slots)) {
			try {
				time_slots = JSON.parse(row.time_slots);
			} catch (e) {
				console.log(e);
				time_slots = [];
			}
		}

		let data : any = {};
		if (isString(row.data)) {
			try {
				if (row.data.trim()) data = JSON.parse(row.data);
			} catch (e) {
				console.log(e);
				data = {};
			}
		}

		const application : ReservationApplication = {
			...row,
			time_slots,
			data,

			applied_weekdays         : [],
			services                 : this.serviceManager.getServicesForIds(row.service_id),
			is_single_reservation    : isEqual(data.reservation_recurrence, 'single'),
			application_tags         : [],
			applied_weekdays_string  : '',
			resource_names           : '',
			location_names           : '',
			agreements_contact_email : []
		};

		if (application.time_slots.length > 0) {
			const applied_weekdays : ReservationApplicationWeekday[] = [];
			const unique_checking = [];

			const weekdays : string[] = [];

			each(application.time_slots, ts => {
				if (ts.time_start && ts.time_end) {
					let time_start = moment(ts.time_start, Helpers.serverFullDateFormat);
					let time_end = moment(ts.time_end, Helpers.serverFullDateFormat);

					if (time_start.isValid() && time_end.isValid()) {
						const day : ReservationApplicationWeekday = {
							weekDay    : Enkora.tt(time_start.format('ddd')),
							time_start : time_start.format('HH:mm'),
							time_end   : time_end.format('HH:mm')
						};

						if (unique_checking.indexOf(JSON.stringify(day)) == -1) {
							applied_weekdays.push(day);
							unique_checking.push(JSON.stringify(day));

							weekdays.push([
								day.weekDay,
								day.time_start,
								'-',
								day.time_end].join(' '));
						}
					}
				}
			});
			application.applied_weekdays = applied_weekdays;
			application.applied_weekdays_string = weekdays.join(' ');
		}

		if (data.reservation_type_id)
			application.reservation_type = this.reservationTypeManager.value.map[data.reservation_type_id].name;

		if (isEmpty(data.tag_ids)) {
			data.tag_ids = [];
		} else {
			each(data.tag_ids, tag_id => {
				const tag = this.applicationTags.value.map[tag_id];
				if (tag) {
					application.application_tags.push(tag.name);
				}
			});
		}

		const resource_names = [];
		const location_names = [];

		const resource_map : {[resource_id:string]: string} = {};
		const location_map : {[location_id:string]: string} = {};
		if (row.resources) {
			each(row.resources, resource => {
				if (!resource_map[resource.resource_id]) {
					resource_map[resource.resource_id] = resource.name;
					resource_names.push(resource.name);
				}

				if (!location_map[resource.location_id]) {
					location_map[resource.location_id] = resource.location;
					location_names.push(resource.location);
				}
			});
		}

		application.resource_names = resource_names.join(', ');
		application.location_names = location_names.join(', ');

		return application;
	}
}
