import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, finalize, first } from 'rxjs/operators';
import { cloneDeep, each, find, isEqual, map, reverse, uniqBy } from 'lodash';
import { TreeNode } from 'primeng/api';

import {
	AutoSubs,
	AutoUnsubscribe,
	CallService,
	ConfirmModal,
	Constants,
	EnkoraMessageService,
	LocationService,
	Logger,
	ModalOpenerService,
	ParameterService
} from 'shared';

import {
	CtaStyle,
	NodesRelation,
	TaActiveDataHolderService,
	TaCompanyTreeGenerator,
	TaCtaStyleService,
	TaDataHelper,
	TaEmployment,
	TaFormGroupGeneratorService,
	TaRole
} from '../../../../../shared';
import { TreeOperation } from '../../../company';

import { RawOrganizationData, TaCompanyListService, TaCompanyTreeService } from '../../../services';
import { TaOrganizationRoleListService } from '../../../services/ta-organization-role-list-service';

declare let Enkora: { tt: (a: string, b?: string | number, c?: string | number) => string };

export enum ActionType {
	Add,
	Delete
}

export interface OperationSummary {
	action: ActionType;
	organization: string;
	prev_organization?: string;
	next_organization?: string;
}

export interface HistoryState {
	summary: OperationSummary;
	changes: TreeOperation[];
}

@AutoUnsubscribe()
@Component({
	templateUrl : './company-tree.modal.html',
	styleUrls   : ['./company-tree.modal.scss'],
	providers   : [TaCompanyListService]
})
export class CompanyTreeModal implements OnInit, OnDestroy {

	@AutoSubs() subs;
	@Input() public standalone = true;

	public employmentForm: FormGroup = null;
	public changes: TreeOperation[] = [];

	public latest_value: TaEmployment = null;
	public initial_value: TaEmployment = null;
	public save_message = '';

	public is_valid = false;
	public no_changes = true;

	public tree: TreeNode[] = [];
	public organization_id = '';
	public organizations: RawOrganizationData[] = [];

	public history: HistoryState[] = [];
	public tree_updating = false;
	public subcontractors_max_length = -1;
	private ctaStyle: CtaStyle = TaCtaStyleService.emptyStyle;
	private location_id: string;
	private treeHelper = TaCompanyTreeGenerator;
	private subcontractors_length = Number.MAX_SAFE_INTEGER;

	public roles: TaRole[] = [];

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            private location: LocationService,
	            private formGenerator: TaFormGroupGeneratorService,
	            private ctaStyleService: TaCtaStyleService,
	            private companyListService: TaCompanyListService,
	            public dataHolder: TaActiveDataHolderService,
	            private organizationRoles: TaOrganizationRoleListService,
	            private modalHelper: ModalOpenerService,
	            public treeService: TaCompanyTreeService,
	            private param: ParameterService)
	{
	}

	ngOnInit(): void
	{
		this.subs = combineLatest([
			this.location.getLocationId().pipe(first()),
			this.dataHolder.employment$,
			this.ctaStyleService.getFirst(),
			this.param.getNumberValue('subcontractor chain max length', Constants.NM_EN_CTA),
			this.organizationRoles.get()
		]).subscribe(reply => {
			const [location_id, employment, ctaStyle, max_length, employer_roles] = reply;
			this.location_id = location_id;
			this.organization_id = employment.company.company_info.organization_id;
			this.ctaStyle = ctaStyle;
			this.subcontractors_max_length = max_length;
			this.roles = employer_roles;
		});

		this.subs = this.companyListService.get().subscribe(organizations => {
			this.organizations = organizations;
		});

		this.subs = this.treeService.get().subscribe(tree => {
			this.tree = tree;

			if (this.dataHolder.is_add_new_employee && this.organization_id) {
				this.tree = this.filterTreeByOrganization(this.tree, this.organization_id);
			}

			this.updateHistoryOperations(this.treeHelper.getNodesRelations(this.tree));

			const cur_sub_len = this.treeHelper.getSubcontractorsLength(this.tree);
			if (cur_sub_len < this.subcontractors_length) {
				this.subcontractors_length = cur_sub_len;
			}

			if (this.subcontractors_length < cur_sub_len && cur_sub_len > this.subcontractors_max_length && this.history.length) {
				this.undo();
				this.subcontractors_length = cur_sub_len;
				this.messageService.error(Enkora.tt('Violation of the max length of the subcontractor chain, max is %s', this.subcontractors_max_length));
				return;
			}

			this.treeHelper.limitSubcontractorsMaxLength(this.tree, this.subcontractors_max_length);
		});
	}

	ngOnDestroy(): void
	{
		if(this.treeService.reload) {
			this.treeService.reload();
		}
	}

	public back(): void
	{
		this.activeModal.dismiss('back');
	}

	public confirm(): void
	{
		if (!this.changes || !this.changes.length) {
			this.activeModal.close(false);
			return;
		}

		const nonEmptyOrganizationsToDelete = this.getNonEmptyOrganizationsToDelete();
		if (nonEmptyOrganizationsToDelete) {
			const modalRef = this.modalHelper.openStaticLgModal(ConfirmModal);
			modalRef.componentInstance.params = {
				...ConfirmModal.default,
				ok_button     : Enkora.tt('Confirm'),
				cancel_button : Enkora.tt('Cancel'),
				inner_message : [
					Enkora.tt('Do you want to delete all the agreements from the following companies with employees?'),
					nonEmptyOrganizationsToDelete
				].join()
			};

			modalRef.result.then(
				() => this.updateAgreementTree(),
				() => this.history.pop());
		} else {
			this.updateAgreementTree();
		}
	}

	undoAll(): void
	{
		const undo_operations: Observable<void>[] = map(this.history, state => {
			return this.reverseChanges(state.changes);
		});
		reverse(undo_operations);

		this.tree_updating = true;
		combineLatest(undo_operations).pipe(
			finalize(() => this.tree_updating = false)
		).subscribe(() => {
				this.treeService.reload();
				this.history.length = 0;
			},
			error => {
				this.messageService.error(error);
			});
	}

	undo(): void
	{
		const last = this.history.pop();
		this.tree_updating = true;
		this.reverseChanges(last.changes).pipe(
			finalize(() => this.tree_updating = false)
		).subscribe(() => {
				this.treeService.reload();
			},
			error => {
				this.messageService.error(error);
			});
	}

	public saveEmployment(): void
	{
		const employment: TaEmployment = cloneDeep(this.latest_value);

		const params = {
			user_id     : employment.user_id,
			employee_id : employment.employee_id,
			location_id : this.location_id,

			contract : TaDataHelper.contractToEmployee(employment.contract),
			...TaDataHelper.employmentToOrganization(employment)
		};

		this.subs = this.call.make<{
			organization_id: string;
			commissioner_organization_id: string;
			employee_id: string;
		}>('cta2/saveEmploymentInformation', [params]).subscribe(reply => {
			employment.employee_id = reply.employee_id;
			employment.company.company_info.organization_id = reply.organization_id;

			this.companyListService.reload();
		}, error => {
			this.messageService.error(error);
		});
	}

	public createNewCompany(): void
	{
		this.initEmploymentForm(TaDataHelper.emptyEmployment);
	}

	public treeChange(state: HistoryState): void
	{
		this.history.push(state);
		this.changes = state.changes;
		this.confirm();
	}

	private updateAgreementTree()
	{
		const params = {
			location_id : this.location_id,
			agreements  : []
		};

		each(this.changes, change => {
			if (change.is_delete) {
				params.agreements.push({
					is_delete    : true,
					agreement_id : change.agreement_id
				});
			} else {
				params.agreements.push({
					customer_organization_id : change.to.organization_id,
					service_organization_id  : change.from.organization_id
				});
			}
		});

		this.tree_updating = true;
		this.call.make('cta2/updateAgreementTree', [params]).pipe(
			finalize(() => this.tree_updating = false)
		).subscribe(() => {
				this.treeService.reload();
			},
			error => {
				this.messageService.error(error);
			}
		);

		this.changes = [];
	}

	private reverseChanges(changes: TreeOperation[]): Observable<void>
	{
		const params = {
			location_id : this.location_id,
			agreements  : []
		};

		each(changes, change => {
			if (change.is_delete) {
				params.agreements.push({
					customer_organization_id : change.to.organization_id,
					service_organization_id  : change.from.organization_id
				});
			} else {
				params.agreements.push({
					is_delete    : true,
					agreement_id : change.agreement_id
				});
			}
		});

		return this.call.make('cta2/updateAgreementTree', [params]);
	}

	private handleEmploymentDataChange(params: { status: string, data: TaEmployment })
	{
		this.is_valid = params.status != 'INVALID';
		this.latest_value = params.data;
		if (!this.initial_value) this.initial_value = cloneDeep(params.data);

		this.no_changes = isEqual(this.initial_value, this.latest_value);

		Logger.log('Form: Modified employment data: ', params);
	}

	private initEmploymentForm(employment: TaEmployment)
	{
		this.employmentForm = this.formGenerator.initEmployment(employment, true, {
			company : {
				company_info : {
					name        : { validators : [Validators.required] },
					business_id : { validators : [Validators.required, Validators.pattern(this.ctaStyle.business_id.pattern)] },
					country     : {
						country_code : { validators : [Validators.required] },
						country_name : { validators : [Validators.required] },
						address      : {
							street   : { validators : [Validators.required] },
							postcode : { validators : [Validators.required, Validators.pattern(this.ctaStyle.postcode.pattern)] },
							city     : { validators : [Validators.required] }
						}
					}
				},
				agreement    : {
					start : { validators : [Validators.required] },
					end   : { validators : [Validators.required] }
				},
				contact      : {
					first_name : { validators : [Validators.required] },
					last_name  : { validators : [Validators.required] }
				}
			}
		});

		this.employmentForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			Logger.log('Employment reactive form update', data);
			this.handleEmploymentDataChange({ status : this.employmentForm.status, data });
		});

		this.handleEmploymentDataChange({
			status : this.employmentForm.status,
			data   : this.employmentForm.getRawValue()
		});
	}

	private getNonEmptyOrganizationsToDelete(): string
	{
		let organizations = '';
		each(uniqBy(this.changes, 'agreement_id'), change => {
			if (change.is_delete) {
				const org = find(this.organizations, org => {
					return org.organization_id === change.to.organization_id && +org.total_employees > 0 && !change.no_confirmation;
				});
				if (org) {
					organizations += '<br>';
					organizations += Enkora.tt('%s - %s employees', `${org.name}`, `<span style="color: red;">${org.total_employees}</span>`);
				}
			}
		});
		return organizations;
	}

	private updateHistoryOperations(relations: NodesRelation[])
	{
		each(this.history, state => {
			each(state.changes, operation => {
				operation.agreement_id = this.findAgreementId(relations, operation)?.agreement_id ?? operation.agreement_id;
			});
		});
	}

	private findAgreementId(relations: NodesRelation[], operation: TreeOperation): NodesRelation
	{
		return find(relations, it => it.service_organization_id === operation.from.organization_id
			&& it.customer_organization_id === operation.to.organization_id);
	}

	private filterTreeByOrganization(tree: TreeNode[], organization_id: string): TreeNode[]
	{
		const organization_data = {
			data : {
				organization_id : organization_id
			}
		};
		if (this.treeHelper.filterNodes(organization_data, tree[0].children).length) {
			const main_children = cloneDeep(tree[0].children);
			let count_deleted_item = 0;
			each(main_children, (item, index) => {
				if (item.data.organization_id != organization_data.data.organization_id
					&& !this.treeHelper.filterNodes(organization_data, item.children).length) {
					tree[0].children.splice(index - count_deleted_item, 1);
					count_deleted_item++;
				}
			});
		}
		return tree;
	}
}
