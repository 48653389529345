import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';
import { ShopFieldInput } from './shop.interface';

export interface ShopFieldServer {
	field_name: string;
	is_required?: boolean;
	ui_order?: number;
}

@Injectable()
export class ShopRegistrationFieldsService extends EnkoraFetcher<ShopFieldInput[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/GetRegistrationFormFields' }];

	constructor(call: CallService)
	{
		super(call);
	}

	postProcess(reply: ShopFieldServer[]): ShopFieldInput[]
	{
		const fields: ShopFieldInput[] = [];

		each(reply, (field, id) => {
			fields.push({
				id          : `${id}`,
				name        : field.field_name,
				is_required : field.is_required,
				ui_order    : field.ui_order
			});
		});

		return fields;
	}
}
