import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { each } from 'lodash';

import {
	AuthenticationService,
	CallService,
	Constants,
	EnkoraMessageService,
	Helpers, ModalOpenerService,
	ParameterService,
	User
} from 'shared';

import {
	Cart,
	CartItem,
	PaymentModalsService,
	PaymentType,
	PaymentTypesService,
	ShopCartListService, ShopCustomField,
	ShopParametersService,
	WebshopParameters
} from '../../services';
import { ShopLoginStepsService } from '../../modals';
import { AlertCode, AlertType } from '../../alerts';
import { ShopUserModifyModal } from '../../user';
import { PrereservationService } from '../../services/prereservation.service';
import { CourseParticipantsPhase } from '../../main/courses/interfaces/general.interface';

declare let $: any;
declare let Enkora: { tt: (a: string) => string };

@Component({
	encapsulation : ViewEncapsulation.None,
	templateUrl   : './cart.modal.html',
	styleUrls     : ['./cart.modal.scss']
})
export class ShopCartModal implements OnInit {

	public cart: Cart = null;

	public allow_balance = false;
	public balance = 0;
	public loading = false;
	public loading_empty_cart = false;

	public pay_by_balance = 0;
	public pay_by_balance_fraction = 0;
	public pay_by_cash = 0;
	public usage_terms = false;

	public is_payment_started = false;

	public payment_types: PaymentType[] = [];
	public payment_form = '';
	public shop_params: WebshopParameters = null;
	public check_missing_info = false;
	public allow_only_one_course = false;
	public user: User;
	public show_cancel_confirm = false;
	public selected_item_to_cancel: CartItem;
	public show_empty_cart_confirm = false;
	public is_cancelling = false;

	public alert: AlertType = null;

	constructor(public activeModal: NgbActiveModal,
	            public auth: AuthenticationService,
	            public call: CallService,
	            private loginService: ShopLoginStepsService,
	            private messageService: EnkoraMessageService,
	            public shopParametersService: ShopParametersService,
	            public cartService: ShopCartListService,
	            private paymentTypesService: PaymentTypesService,
	            private params: ParameterService,
	            private modalService: ModalOpenerService,
	            private paymentModalService: PaymentModalsService,
	            private prereservation: PrereservationService,
	) {
		this.shopParametersService.get().subscribe(params => {
			this.shop_params = params;
		});

		if (this.auth.isAuthenticated()) {
			this.initAfterAuthentication();
		}

		this.auth.get().subscribe(user => {
			this.user = user;
		});

		this.params.getBooleanValue(
			'check if user info is complete',
			Constants.NM_EN_RESERVATIONS
		).subscribe(check_missing_info => this.check_missing_info = check_missing_info);

		this.params.getBooleanValue(
			'allow only one course reservation in cart',
			Constants.NM_EN_WEBSHOP
		).subscribe(allow_only_one_course => this.allow_only_one_course = allow_only_one_course);

		this.getOnlinePaymentTypes();
	}

	ngOnInit(): void
	{
		this.updateCart();
	}

	emptyCart(): void
	{
		this.show_empty_cart_confirm = false;
		this.loading_empty_cart = true;

		this.cartService.emptyCart()
		.pipe(finalize(() => this.loading_empty_cart = false))
		.subscribe(() => {
			this.prereservation.removeAll();
		}, error => {
			this.messageService.error(error);
		});
	}

	buyProducts(payment_type_id: unknown): void
	{
		if (!this.usage_terms) {
			this.alert = {
				code  : AlertCode.Error,
				title : 'Please accept usage terms'
			};
			return;
		}

		if (this.user && this.check_missing_info) {
			if (this.shop_params.simple_registration_allowed && this.shop_params.simple_registration_form_fields) {
				const shop_fields: { [key: string]: ShopCustomField } = {};
				const fields = this.shop_params.simple_registration_form_fields.split(',');

				each(fields, field => {
					shop_fields[field] = {
						data_type            : 'text',
						description          : '',
						field_name           : field,
						is_customer_editable : 1,
						is_required          : 1,
						ui_order             : null,
						validation_regex     : null,
					};
				});

				this.checkMissingInfo(this.user, shop_fields, payment_type_id);
			} else {
				this.call.make('common/getfields', ['reservations 2', { name_as_key : true }])
				.subscribe(fields => {
					const shop_fields = fields as { [key: string]: ShopCustomField };
					this.checkMissingInfo(this.user, shop_fields, payment_type_id);
				});
			}
		} else {
			this.handleBuyProduct(payment_type_id);
		}
	}

	private checkMissingInfo(user: User, shop_fields: { [key: string]: ShopCustomField }, payment_type_id: unknown): void
	{
		if (Helpers.userHasMissingInfo(user, shop_fields)) {
			const modalRef = this.modalService.openStaticShopModal(ShopUserModifyModal);
			const componentInstance: ShopUserModifyModal = modalRef.componentInstance;
			componentInstance.user = user;
			componentInstance.customTitle = 'Complete the user info';
			componentInstance.hideCancelButton = true;

			modalRef.result.then(() => {
				this.auth.reload();
			}, () => {
			});
			return;
		} else {
			this.handleBuyProduct(payment_type_id);
		}
	}

	private handleBuyProduct(payment_type_id: unknown): void
	{
		if (!this.usage_terms) {
			this.alert = {
				code  : AlertCode.Error,
				title : 'Please accept usage terms'
			};
			return;
		}

		this.is_payment_started = true;
		this.cartService.paymentStart({
			return_url     : this.cartService.generatePaymentFullPath({ doPayment : 'success' }),
			cancel_url     : this.cartService.generatePaymentFullPath({ doPayment : 'cancel' }),
			balance_amount : this.pay_by_balance,
			payment_type_id
		}).subscribe((data: Record<string, any>) => {
			if (data.payment_succeeded) {
				this.is_payment_started = false;
				this.updateCart();
				this.paymentModalService.handlePaymentResult('success');
			} else {
				this.payment_form = data.form;
				setTimeout(() => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					$('#paymentFormContainer').find('form').submit();
				}, 0);
			}
		}, () => {
			this.is_payment_started = false;
			this.alert = {
				code  : AlertCode.Error,
				title : 'Could not initialize payment'
			};
		});
	}

	updateBalancePayment(): void
	{
		this.pay_by_balance = this.pay_by_balance_fraction * 100;
		this.pay_by_cash = this.cart.total_price - this.pay_by_balance;
	}

	deleteItem(item: CartItem): void
	{
		this.is_cancelling = true;
		this.cartService.removeItem(item)
		.pipe(finalize(() => {
			this.show_cancel_confirm = false;
			this.is_cancelling = false;
			this.getOnlinePaymentTypes();
		})).subscribe((success) => {
			if (success && item.reservation_params) {
				this.prereservation.removePreReservation(
					String(item.reservation_params.reservation_account_id),
					item.reservation_params.reservation_event_group_id,
					item.fare_product_id
				);
			}
		}, error => {
			this.messageService.error(error);
		});
	}

	deleteItemConfirm(item: CartItem): void
	{
		this.selected_item_to_cancel = item;
		this.toggleCancelConfirm();
	}

	openLogin(): void
	{
		this.loginService.login().subscribe(() => {
			this.initAfterAuthentication();
			this.updateCart();
		});
	}

	public updateUserBalance(): void
	{
		this.call.make('cart/checkcurrentuserbalance', []).subscribe((reply: { balance: number }) => {
			this.balance = reply.balance;
			this.allow_balance = true;
		});
	}

	private updateCart()
	{
		this.loading = true;
		this.cartService.get(true).subscribe(cart => {
			this.cart = cart;

			each(this.cart.items, item => {
				item.title = Helpers.translateKey(
					`reservation_event_group ${item.reservation_event_group_id} name`,
					item.title,
					true
				);
			});

			this.alert = null;
			this.loading = false;
		}, () => {
			this.loading = false;
		});
	}

	private initAfterAuthentication()
	{
		if (this.shop_params?.balance_payments_enabled) {
			this.updateUserBalance();
		}
	}

	public toggleCancelConfirm(): void
	{
		this.show_cancel_confirm = !this.show_cancel_confirm;
	}

	public toggleEmptyCartConfirm(): void
	{
		this.alert = null;
		this.show_empty_cart_confirm = !this.show_empty_cart_confirm;
	}

	protected readonly CourseParticipantsPhase = CourseParticipantsPhase;

	hideContinueShoppingButton(): boolean {
		return this.allow_only_one_course && !!this.cart?.items?.length;
	}

	private getOnlinePaymentTypes(): void {
		this.loading = true;
		this.paymentTypesService.get(true).subscribe(values => {
			this.payment_types = values
			this.loading = false;
		}, () => {
			this.loading = false;
		});
	}
}
