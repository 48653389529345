import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { CallService } from '../../shared';

@Injectable({
	providedIn : 'root'
})
export class UserPermissionsService {
	get permissions() : any {
		return this._permissions;
	}

	set permissions(value : any) {
		this._permissions = value;
		this.permissions$.next(value);
	}

	private _requested : boolean = false;
	private _resolved : boolean = false;
	private _permissions : any = {reservation_editing : true};

	public permissions$ : Subject<any[]> = new Subject();

	constructor(private call : CallService) {
	}

	getPermissions() {
		if (!this._requested) {
			this._requested = true;

			this.call.make("user/getCurrentUser",[true, true]).subscribe((reply : any) => {
				this._resolved = true;
				if (reply && reply.permissions) {
					this.permissions = reply.permissions;
				} else {
					this.permissions = {reservation_editing : true};
				}
			}, (error) => {
				this.permissions = {reservation_editing : true};
			});

			return this.permissions$.pipe(first());
		}

		if (!this._resolved) return this.permissions$;
		return of(this.permissions);
	}
}
