import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { CallService } from '../../shared/services';
import { forEach } from 'lodash';

@Injectable()
export class LanguageService {
	language_map = {};
	defaultLanguageId = null;
	public languages$ : Subject<any[]> = new Subject();
	private _requested : boolean = false;
	private _resolved : boolean = false;

	constructor(private call : CallService)
	{
	}

	private _languages : any[] = [];

	get languages() : any[]
	{
		return this._languages;
	}

	set languages(value : any[])
	{
		this._languages = value;
		this.language_map = {};
		forEach(value, language => {
			this.language_map[language.language_id] = language.name;
		});

		this.languages$.next(value);
	}

	getLanguages()
	{
		if (!this._requested) {
			this._requested = true;

			this.call.make('common/getLanguages').subscribe((languages : any[]) => {
				this._resolved = true;
				let activeLanguages = [];
				forEach(languages, (language : any) => {
					if (language.is_active) {
						activeLanguages.push(language);
						if (language.default) this.defaultLanguageId = language.language_id;
					}
				});
				this.languages = activeLanguages;
			}, () => {
				this.languages = [];
			});

			return this.languages$.pipe(first());
		}

		if (!this._resolved) return this.languages$;
		return of(this.languages);
	}

}
