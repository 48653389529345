import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from '../../shared/';

export interface ReservationApplicationStatusRaw {
	reservation_application_status_id : number;
	name : string;
	is_resolved : number;
	is_active : number;
}

export interface ReservationApplicationStatus {
	reservation_application_status_id : number;
	name : string;
	is_resolved : boolean;
	is_active : boolean;
}

export interface ReservationApplicationStatuses {
	values : ReservationApplicationStatus[];
	resolved : ReservationApplicationStatus[];
	map : { [status_id : number] : ReservationApplicationStatus };
}

@Injectable()
export class ApplicationReservationStatusService extends EnkoraFetcher<ReservationApplicationStatuses> {

	params : CallParameter[] = [{name : 'reservation/getReservationApplicationStatuses'}];

	constructor(call : CallService)
	{
		super(call);
	}

	protected postProcess(reply : ReservationApplicationStatusRaw[])
	{
		const result : ReservationApplicationStatuses = {
			values   : [],
			resolved : [],
			map      : {}
		};

		each(reply, s => {
			const status : ReservationApplicationStatus = {
				reservation_application_status_id : s.reservation_application_status_id,
				name                              : s.name,
				is_resolved                       : !!s.is_resolved,
				is_active                         : !!s.is_active
			};
			result.map[status.reservation_application_status_id] = status;
			if (status.is_resolved) result.resolved.push(status);
			result.values.push(status);
		});

		return result;
	}
}
