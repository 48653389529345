import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import Player, { Options } from '@vimeo/player';

export enum PlayerInputStatus {
	Play = 'Play',
	Pause = 'Pause',
}

export enum PlayerEvent {
	Play = 'Play',
	Pause = 'Pause',
	Ended = 'Ended',
	TimeUpdate = 'TimeUpdate',
	Seeked = 'Seeked'
}

@Component({
	selector    : 'app-vimeo-player',
	templateUrl : './vimeo-player.component.html',
	styleUrls   : ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements OnInit, OnDestroy {
	@ViewChild('target', {static : true}) target: ElementRef<HTMLDivElement>;

	@Input() public is_watched = false;
	@Output() public onEventFired = new EventEmitter<PlayerEvent>();

	private player: Player;

	constructor()
	{
	}

	private _status: PlayerInputStatus = PlayerInputStatus.Pause;

	get changeStatus(): PlayerInputStatus
	{
		return this._status;
	}

	@Input() set changeStatus(value: PlayerInputStatus)
	{
		this._status = value;
		if (this.player) {
			switch (value) {
				case PlayerInputStatus.Pause:
					this.player.pause();
					break;
				case PlayerInputStatus.Play:
					this.player.play();
					break;
			}
		}
	}

	private _options: Options;

	@Input() set options(value: Options)
	{
		this._options = value;
		this.player = new Player(this.target.nativeElement, {
			controls    : false,
			autoplay    : false,
			playsinline : true,
			responsive  : true,
			...this._options
		});

		this.player.on('loaded', () => {
			const frame = this.target.nativeElement.getElementsByTagName('iframe').item(0);
			if (frame !== null) {
				frame.style.width = '100%';
			}
			if (this.changeStatus === PlayerInputStatus.Play) {
				this.player.play();
			}
		});


		this.player.on('play', () => {
			this.onEventFired.emit(PlayerEvent.Play);
		});

		this.player.on('pause', () => {
			this.onEventFired.emit(PlayerEvent.Pause);
		});

		this.player.on('ended', () => {
			this.onEventFired.emit(PlayerEvent.Ended);
		});

		this.player.on('timeupdate', () => {
			this.onEventFired.emit(PlayerEvent.TimeUpdate);
		});

		this.player.on('seeked', () => {
			this.onEventFired.emit(PlayerEvent.Seeked);
		});
	}

	ngOnInit(): void
	{
	}

	ngOnDestroy(): void
	{
		if (this.player) {
			this.player.destroy();
		}
	}
}
