<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">
		<div class="form-group row">
			<label [for]="id + 'first_name'" class="col-md-3 col-form-label text-input-label" tt>First name</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + 'first_name'"
				       name="first_name"
				       [readonly]="readonly"
				       formControlName="first_name">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + 'last_name'" class="col-md-3 col-form-label text-input-label" tt>Last name</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + 'last_name'"
				       name="last_name"
				       [readonly]="readonly"
				       formControlName="last_name">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + 'email'" class="col-md-3 col-form-label text-input-label" tt>Email</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="email"
				       [id]="id + 'email'"
				       name="email"
				       [readonly]="readonly"
				       formControlName="email">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + 'username'" class="col-md-3 col-form-label text-input-label" tt>Username</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + 'username'"
				       name="username"
				       [readonly]="readonly"
				       formControlName="username">
			</div>
		</div>
		<div class="form-group row">
			<label [for]="id + 'password'" class="col-md-3 col-form-label text-input-label" tt>Password</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="password"
				       [id]="id + 'password'"
				       name="password"
				       [readonly]="readonly"
				       formControlName="password">
			</div>
		</div>
		<div class="row">
			<div class="col-md-9 offset-md-3">
				<div class="checkbox">
					<input type="checkbox"
					       [id]="id + 'send_password'"
					       name="send_password"
					       [readonly]="readonly"
					       formControlName="send_password">

					<label [for]="id + 'send_password'" tt>
						Email password link
					</label>
				</div>
			</div>
		</div>
	</div>
</ng-container>