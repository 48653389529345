import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class TimetableRefreshService {

	refresh$ = new Subject();

	private _innerDebounceRefresher = new Subject();

	doRefresh()
	{
		this._innerDebounceRefresher.next({});
	}


	constructor()
	{
		this._innerDebounceRefresher.pipe(debounceTime(200)).subscribe(_ => this.refresh$.next({}));
	}
}
