import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { EnkoraMessageService } from 'shared';

import { TaCtaStyleService, TaDataHelper, TaFindCompanyService, TaFormGroupGeneratorService } from '../../services';
import { TaCompanyInfo } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-company-selector',
	templateUrl : './company-selector.form.html',
	providers   : [TaFindCompanyService],
	styleUrls   : ['./company-selector.form.scss']
})
export class CompanySelectorForm implements OnInit {
	@Input() public readonly = false;

	public companyList: TaCompanyInfo[] = [];
	public newCompanyInfoForm: FormGroup;
	public show_company = false;
	public new_company = null;

	public companyInput$ = new Subject<string>();

	private companyInfo$ = new Subject<TaCompanyInfo>();
	private latestValue: TaCompanyInfo;

	constructor(private formGenerator: TaFormGroupGeneratorService,
	            private messageService: EnkoraMessageService,
	            public findService: TaFindCompanyService)
	{
	}

	public _inputForm: FormGroup;

	public get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input()
	public set inputForm(fg: FormGroup)
	{
		this._inputForm = fg;

		if (!fg) return;

		this.companyList = fg && fg.value.organization_id ? [fg.value] : [];

		this.companyInput$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(name =>
			this.findService.getByName(name).subscribe(list => this.companyList = list)
		);

		if (fg) {
			this.companyInfo$.pipe(debounceTime(200)).subscribe(value => {
				if (value) this.inputForm.patchValue(value);
				this.companyList = [value];
			});
		}
	}

	ngOnInit(): void
	{
	}

	getValidators()
	{
		return {
			name        : { validators : Validators.required },
			business_id : { validators : Validators.required },
			country     : {
				country_code : { validators : Validators.required },
				address      : {
					street   : { validators : Validators.required },
					postcode : { validators : Validators.required },
					city     : { validators : Validators.required }
				}
			},
			employer_role_id : { validators : [] }
		};
	}

	showAddNewCompany(): void
	{
		const validators = this.getValidators();

		this.newCompanyInfoForm = this.formGenerator.initCompanyInfo(TaDataHelper.emptyCompanyInfo, validators);

		this.newCompanyInfoForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			if (this.latestValue == null || this.latestValue.country.country_code != data.country.country_code) {
				const new_validators = this.getValidators();
				this.updatePostcodeValidationForCountry(new_validators, data);
				this.formGenerator.updateFormGroupValidators(this.newCompanyInfoForm, new_validators);
			}
			this.latestValue = data;
		});

		this.show_company = true;
	}

	updatePostcodeValidationForCountry(validators: any, data: TaCompanyInfo): void
	{
		const country_code = data.country.country_code;
		const postcode_pattern = TaCtaStyleService.getStyle(country_code).postcode.pattern;
		validators.country.address.postcode.validators = [
			Validators.required,
			Validators.pattern(postcode_pattern)
		];
	}

	hideNewCompanyForm(): void
	{
		this.show_company = false;
	}

	addNewCompany(): void
	{
		const company_info: TaCompanyInfo = this.newCompanyInfoForm.value;
		if (company_info.organization_id) {
			this.changeCompany(company_info);
			this.show_company = false;
			return;
		}

		this.findService.saveOrganization(company_info).subscribe((reply: TaCompanyInfo) => {
			this.changeCompany(<any>reply);
			this.show_company = false;
		}, error => this.messageService.error(error));

	}

	changeCompany(company: TaCompanyInfo): void
	{
		this.companyInfo$.next(company);
	}
}
