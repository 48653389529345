import { Injectable } from '@angular/core';
import { each, find } from 'lodash';

import { CallService, Constants, EnkoraFetcher, ParameterService } from 'shared';

import { TaEmployeeContact } from '../../../shared';

interface Content {
	location_id: string;
	timestamp: string;
}

@Injectable()
export class TaEmployeeContactService extends EnkoraFetcher<TaEmployeeContact[]> {
	private countries: { id: string, name: string }[] = [];

	constructor(call: CallService, params: ParameterService)
	{
		super(call);
		params.getValue('iso_country_codes', Constants.NM_EN_CTA).subscribe(countries => {
			this.countries = countries as unknown as { id: string, name: string }[];
		});
	}

	preProcess(options?: Content): void
	{
		this.params = [{
			name    : 'cta2/getContactInformation',
			content : [
				{
					location_id : options.location_id ? options.location_id : null,
					timestamp   : options.timestamp ? options.timestamp : null
				}
			]
		}];
	}

	postProcess(reply: TaEmployeeContact[]): TaEmployeeContact[]
	{
		each(reply, item => {
			const country = find(this.countries, { id : item.home_country_code });
			item.home_country_name = country ? country.name : item.home_country_code;
		});
		return reply;
	}
}
