<div class="modal-header">
	<h3 class="modal-title">{{ details.owner_name }}</h3>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body hotel-reservations-modal">
	<shop-alert-message [alert]="alert"></shop-alert-message>

	<p class="reservation-data">
		<i class="fa fa-clock-o"></i>
		{{ details.time_start | amDateFormat:'dddd DD.MM.YYYY HH:mm'}} - {{ details.time_end | amDateFormat:'HH:mm'}}
		<br>
		<i class="fa fa-home"></i>
		{{ details.location }} / {{ details.resource_names }}
		<br>
		<i class="fa fa-user"></i>
		{{ details.owner_name }}
		<br>
	</p>
</div>

<div class="modal-footer">
	<button class="btn btn-danger pull-left"
	        *ngIf="!cancelled && !details.is_past"
	        (click)="cancel()" tt>
		Cancel Reservation
	</button>

	<button class="btn"
	        id="cancelButton"
	        (click)="activeModal.dismiss()" tt>
		Close
	</button>
</div>
