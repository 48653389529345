import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { each, keyBy } from 'lodash';

import { PermissionGroupModel, RawPermissionGroupRow, TaPermissionHelper } from '../../../shared';

import { TaTimetableListService } from './ta-timetable-list.service';

export interface PermissionGroupMap {
	[key: string]: PermissionGroupModel;
}

@Injectable({
	providedIn : 'root'
})
export class TaPermissionGroupListService extends EnkoraFetcher<PermissionGroupModel[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getAccessRightTemplateAccessAreas' }];
	private last_location_id = null;

	constructor(call: CallService,
	            private location: LocationService,
	            private timetable: TaTimetableListService)
	{
		super(call);

		this.location.getLocationId().pipe(
			switchMap(location_id => this.timetable.get(true, { location_id }).pipe(
				switchMap(() => this.get(true, { location_id }))
			))
		).subscribe();
	}

	preProcess(options: { location_id?: string } = {}): PermissionGroupModel[]
	{
		const location_id = options.location_id || this.last_location_id;
		if (!location_id) return null;

		Logger.log(`Permission group list update: location_id= ${location_id}`);

		this.params = [{
			name    : 'cta2/getAccessRightTemplateAccessAreas',
			content : [{ location_id }]
		}];
	}

	postProcess(reply: RawPermissionGroupRow[]): PermissionGroupModel[]
	{
		return TaPermissionHelper.extractRawPermissionGroups(reply, this.timetable.timetables_map);
	}

	public extractRawPermissionGroups(raw_input: RawPermissionGroupRow[]): PermissionGroupModel[]
	{
		return TaPermissionHelper.extractRawPermissionGroups(raw_input, this.timetable.timetables_map);
	}

	public saveGroup(params: Record<string, unknown>): Observable<{ skipped_accounts: string[] }>
	{
		return this.call.make('cta2/updateAccessRightTemplateGroup', [params]).pipe(tap(() => this.reload()));
	}

	public deleteGroup(group_id: string): Observable<unknown>
	{
		return this.call.make('cta2/deactivateAccessRightTemplateGroup', [group_id]).pipe(tap(() => this.reload()));
	}

	public convertToActiveGroupMap(groups: PermissionGroupModel[],
	                               initial_groups: PermissionGroupModel[] = []
	): PermissionGroupMap
	{
		const active_groups: PermissionGroupMap = {};
		const map = keyBy(initial_groups, g => g.id);

		each(groups, (group) => {
			const user_group = map[group.id];
			group.access_right_expiration = user_group ? user_group.access_right_expiration : null;
			if (map[group.id]) active_groups[group.id] = group;
		});
		return active_groups;
	}
}
