import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { TaCompanyTreeGenerator } from '../../../shared';

@Injectable()
export class TaCompanyTreeService extends EnkoraFetcher<any> {

	protected params: CallParameter[] = [{ name : 'cta/gettree' }];
	private last_location_id = null;

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { location_id?: string } = {})
	{
		options = options || {};
		const location_id = options.location_id || this.last_location_id;
		if (!location_id) return null;

		Logger.log(`Company tree update: location_id= ${location_id}`);

		this.params = [{
			name    : 'cta/gettree',
			content : [
				{
					location_id,
					include_main_contractor : true
				}
			]
		}];
	}

	protected postProcess(reply)
	{
		const root: TreeNode[] = [reply];
		TaCompanyTreeGenerator.setupTree(root);

		Logger.log('Company tree constructed: ', root);
		return root;
	}
}
