import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { AccessArea } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaAccessPointListService extends EnkoraFetcher<AccessArea[]> {

	protected params: CallParameter[] = [{ name : 'acs/getAccessPointByLocationId' }];
	private last_location_id = null;

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { location_id?: string }): null
	{
		const location_id = options.location_id || this.last_location_id;
		if (!location_id) return null;

		Logger.log(`Site status update: location_id= ${location_id}`);

		this.params = [{
			name    : 'acs/getAccessPointByLocationId',
			content : [location_id]
		}];
	}
}
