<ng-container *ngIf="inputForm">
	<div>
		<div class="h5 mt-4 mb-3" tt>
			Ramismart access managers for this site
		</div>

		<ngx-datatable class="site-contacts-table"
		               [columnMode]="'force'"
		               [headerHeight]="50"
		               [rowHeight]="'auto'"
		               [messages]="{ emptyMessage: 'No data to display' | tt }"
		               [rows]="inputForm.value">

			<ngx-datatable-column [width]="150"
			                      [name]="'Name' | tt"
			                      [sortable]="false"
			                      prop="name">
			</ngx-datatable-column>
			<ngx-datatable-column [width]="100"
			                      [name]="'Email' | tt"
			                      [sortable]="false"
			                      prop="email">
			</ngx-datatable-column>
			<ngx-datatable-column [width]="100"
			                      [name]="'Phone number' | tt"
			                      [sortable]="false"
			                      prop="phone_number">
			</ngx-datatable-column>

			<ngx-datatable-column [width]="45"
			                      [name]="''"
			                      [sortable]="false"
			                      [draggable]="false"
			                      prop="account_id">

				<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
					<span class="remove-user-icon"
					      (click)="removeContact(row)">
						<cta-icon-remove></cta-icon-remove>
					</span>
				</ng-template>
			</ngx-datatable-column>

		</ngx-datatable>
		<div class="ramismart-contact-form-wrap pt-4" *ngIf="newContactForm">
			<div class="row">
				<div class="col-10 offset-1">
					<cta-ta-form-contact-selector [inputForm]="newContactForm"
					                              [title]="'Ramismart access manager'">
					</cta-ta-form-contact-selector>
				</div>
			</div>
			<div class="row mb-3">
				<div class="offset-7 col-md-3">
					<button class="btn btn-primary-custom btn-sm"
					        (click)="addNewContact()"
					        [disabled]="newContactForm.invalid" tt>
						Add to the list
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-container>
