import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';

import { AuthenticationService, Logger } from 'shared';

declare let window;

@Injectable()
export class LoginHandlerService {

	constructor(private route: ActivatedRoute,
					private router: Router,
					private auth: AuthenticationService)
	{
	}

	doRedirectOnLogin(): void
	{
		const url: string = this.route.snapshot.queryParams['next'] || '/ng/dashboard';

		this.auth.get().pipe(filter(user => !!user), first()).subscribe(() => {
			Logger.log('⏩ Authenticated user should not be able to go to login page. —>', url);
			const parts = url.split('/');

			if (!parts[0]) parts.shift();
			if (parts[0] == 'ng') {
				parts.shift();
				const newUrl = parts.join('/');
				this.router.navigateByUrl(newUrl);
			} else if (parts[0].startsWith('?')) {
				this.router.navigateByUrl('/dashboard' + parts.join('/'));
			} else {
				window.location.href = '..' + url;
			}
		});
	}
}
