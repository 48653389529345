import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';

import { AuthenticationService, CallService, Logger } from 'shared';

declare let window: Window;

@Injectable()
export class UserAuthenticationGuard implements CanActivate, CanLoad {

	constructor(private router: Router,
	            private call: CallService,
	            private auth: AuthenticationService)
	{
	}

	private prepareRoute(url: string, observer: Observer<boolean>): void
	{
		this.auth.get().subscribe(value => {
			if (!value) {
				let next = '/ng/' + url;
				const split = window.location.pathname.split(url);
				if (split.length > 1) next += split[1];

				const queryParams = url && url != 'login' ? { next } : null;
				this.router.navigate(['/login'], { queryParams });

				observer.next(false);
			} else observer.next(true);

			observer.complete();
		}, (error) => {
			Logger.log('strange error from UserAuthenticationGuard::prepareRoute', error);
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean
	{
		Logger.log('🔒', 'UserAuthenticationGuard : canActivate()');

		return new Observable((observer: Observer<boolean>) => {
			this.prepareRoute(state.url, observer);
		});
	}

	canLoad(route: Route): Observable<boolean> | boolean
	{
		Logger.log('🔒', 'UserAuthenticationGuard : canLoad()');

		return new Observable((observer: Observer<boolean>) => {
			this.prepareRoute(route.path, observer);
		});
	}
}
