import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, timer } from 'rxjs';
import { trigger, animate, state, style, transition } from '@angular/animations';

import {
	AuthenticationService,
	AutoSubs,
	AutoUnsubscribe,
	EnkoraMessageService,
	ErrorMessageBody,
	ModalOpenerService, TickService
} from 'shared';

import { ShopCartModal } from '../modal/cart.modal';
import { Cart, ShopCartListService } from '../../services';
import { PrereservationService } from '../../services/prereservation.service';

@AutoUnsubscribe()
@Component({
	selector    : 'shop-cart-status',
	templateUrl : './status.component.html',
	styleUrls   : ['./status.component.scss'],
	animations  : [
		trigger('slideIn', [
			state('void', style({ transform : 'translateX(100%)' })),
			transition(':enter', [
				animate('300ms ease-out', style({ transform : 'translateX(0)' })),
			]),
		]),
	],
})
export class ShopCartStatusComponent implements OnInit, OnDestroy {
	@AutoSubs() subs;
	public cart: Cart;
	public error: ErrorMessageBody;
	public initial_prereservation_seconds = 0;
	public prereservation_seconds = 0;
	public prereservation_expired = false;
	public prereservation_label = '';

	constructor(
		private modalHelper: ModalOpenerService,
      public cartService: ShopCartListService,
		public msgService: EnkoraMessageService,
		private prereservation: PrereservationService,
		private tick: TickService,
		private auth: AuthenticationService,
	) {
		this.cartService.get().subscribe(cart => this.cart = cart);
		this.subs = this.msgService.value$.subscribe(msg => {
			this.error = msg;
			timer(msg.life).subscribe(() => {
				this.error = null;
			});
		});

		this.subs = this.prereservation.value$.subscribe(seconds => {
			if (this.prereservation_seconds === seconds) { return; }

			if (!this.initial_prereservation_seconds) {
				this.initial_prereservation_seconds = seconds;
			}

			this.prereservation_seconds = seconds;
			this.prereservation_expired = false;

			if (!this.prereservation_seconds) {
				this.prereservation_label = '';
			}

			if (this.prereservation_seconds <= 0 && this.prereservation.pre_reservation_active) {
				this.prereservation_expired = true;
				this.error = {
					summary  : 'Prereservation',
					detail   : 'Prereservation has expired',
					severity : 'error',
					life     : 999999999,
				};
				this.modalHelper.dismissAll();
				this.cartService.emptyCart().subscribe();
			}
		});

		this.subs = combineLatest([
			this.tick.value$,
			this.auth.value$
		]).subscribe(([tick, auth]) => {
			if (!tick || !auth) {
				this.cartService.resetCartUI();
			}
		});
	}

	ngOnInit(): void
	{
	}

	buyProducts(): void
	{
		this.modalHelper.openShopModal(ShopCartModal);
	}

	dismissError(): void
	{
		this.error = null;
	}

	ngOnDestroy(): void
	{
	}
}
