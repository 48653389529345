import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationParams } from '../../interfaces';

@Component({
	selector      : 'confirmation-modal',
	templateUrl   : './confirmation.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./confirmation.modal.scss']
})
export class ConfirmationModal implements OnInit {

	public in_process = false;
	@Input() params: ConfirmationParams = ConfirmationModal.default;

	constructor(public modal: NgbActiveModal)
	{
	}

	public static get default(): ConfirmationParams
	{
		return {
			title         : 'Are you sure?',
			message       : '',
			inner_message : '',
			ok_button     : 'Confirm',
			cancel_button : 'Cancel'
		};
	}

	ngOnInit(): void
	{
		this.in_process = false;
	}

	close(): void
	{
		if (!this.params.before_return) {
			this.modal.close();
			return;
		}

		this.in_process = true;
		this.params.before_return.subscribe(() => {
			this.modal.close();
		}, () => {
			this.in_process = false;
		});
	}
}
