import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { clone, each } from 'lodash';
import { map } from 'rxjs/operators';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from '../../../../shared';
import { TaCompanyInfo } from '../interfaces';

@Injectable()
export class TaFindCompanyService extends EnkoraFetcher<TaCompanyInfo[]> {

	protected params: CallParameter[] = [{ name : 'cta2/findOrganizations' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public convertReplyToCompanyInfo = (data: any): TaCompanyInfo => {
		return {
			organization_id          : data.organization_id,
			business_id              : data.business_id,
			foreign_business_id      : data.foreign_business_id,
			foreign_business_id_type : Helpers.safeToString(data.foreign_business_id_type),
			name                     : data.name,
			info                     : data.info,
			country                  : {
				country_code : data.country_code,
				country_name : data.country_name,
				address      : data.address
			},
			employer_role_id         : data.employer_role_id || []
		};
	};

	public convertCompanyInfoToMessage = (): any => {
	};

	preProcess(options?: any)
	{
		options = options || {};
		if (!options.name && !options.business_id) return [];
		if (options.name && options.name.length <= 3) return [];

		this.params = [{
			name    : 'cta2/findOrganizations',
			content : [options]
	}];

	}

	postProcess(reply: any[])
	{
		const result: TaCompanyInfo[] = [];
		each(reply, row => {
			result.push(this.convertReplyToCompanyInfo(row));
		});

		return result;
	}

	public getByName(name: string): Observable<TaCompanyInfo[]>
	{
		if (!name || name.length <= 3) return of([] as TaCompanyInfo[]);
		return this.call.make('cta2/findOrganizationsByName', [name]).pipe(
			map(reply => {
				return this.postProcess(reply);
			})
		);
	}

	public saveOrganization(company_info: TaCompanyInfo): Observable<TaCompanyInfo>
	{
		const params = {
			name                : company_info.name,
			organization_id     : company_info.organization_id,
			business_id         : company_info.business_id,
			foreign_business_id : company_info.foreign_business_id,
			info                : company_info.info,
			country_code        : company_info.country.country_code,
			address             : company_info.country.address,
			employer_role_id    : company_info.employer_role_id
		};

		return this.call.make('cta2/saveOrganizationInformation', [params]).pipe(
			map(reply => {
				const new_company_info = this.convertReplyToCompanyInfo(reply);

				const new_value = clone(this.value) || [];
				new_value.push(new_company_info);
				this.value = new_value;

				return new_company_info;
			})
		);
	}
}
