import { Component, Input, OnInit } from '@angular/core';

import { AccessRight } from '../../../shared/misc/access_right.interface';

@Component({
	selector    : 'shop-user-access-rights',
	templateUrl : './access-rights.component.html',
	styleUrls   : ['./access-rights.component.scss']
})
export class UserAccessRightsComponent implements OnInit {

	@Input() past_allowed = true;
	show_past = false;
	past_count = 0;

	constructor()
	{
	}

	private _access_rights: AccessRight[] = [];

	get access_rights(): AccessRight[]
	{
		return this._access_rights;
	}

	@Input()
	set access_rights(value: AccessRight[])
	{
		this._access_rights = value;
	}

	ngOnInit(): void
	{
	}

	togglePast(): void
	{
		this.show_past = !this.show_past;
	}

}
