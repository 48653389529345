import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { EnkoraMessageService } from 'shared';

import { TaDataHelper, TaFindUsersAndContactsService, TaFormGroupGeneratorService } from '../../services';
import { TaContact } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-contact-selector',
	templateUrl : './contact-selector.form.html',
	providers   : [TaFindUsersAndContactsService],
	styleUrls   : ['./contact-selector.form.scss']
})
export class ContactSelectorForm implements OnInit {

	@Input() public readonly = false;
	@Input() title = 'Primary contact';

	public contactList: TaContact[] = [];
	public contactInput$ = new Subject<string>();
	contact$ = new Subject<TaContact>();
	public show_contact = false;
	public new_contact = null;
	// public selectedContact: TaContact = null;
	public can_add_new_contact = false;
	public newContactForm: FormGroup;

	constructor(private formGenerator: TaFormGroupGeneratorService,
	            private messageService: EnkoraMessageService,
	            public findService: TaFindUsersAndContactsService)
	{
	}

	public _inputForm: FormGroup;

	public get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() public set inputForm(fg: FormGroup)
	{
		this._inputForm = fg;

		if (!fg) return;

		this.contactList = fg && fg.value.user_id ? [fg.value] : [];

		this.contactInput$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(name =>
			this.findService.get(true, { name })
		);

		this.findService.getLazy().subscribe(list => this.contactList = list);

		if (fg) {
			this.contact$.pipe(debounceTime(200)).subscribe(value => {
				if (value) this.inputForm.patchValue(value);
				this.contactList = [value];
			});
		}
	}

	ngOnInit(): void
	{
	}

	showAddNewContact(): void
	{
		this.can_add_new_contact = false;
		this.newContactForm = this.formGenerator.initContact(TaDataHelper.emptyPerson, {
			first_name : { validators : Validators.required },
			last_name  : { validators : Validators.required },
			email      : { validators : Validators.required },
			username   : { validators : Validators.required },
			password   : { validators : Validators.required }
		});
		this.newContactForm.valueChanges.pipe(debounceTime(200)).subscribe(() => {
			this.can_add_new_contact = this.newContactForm.valid;
		});
		this.show_contact = true;
	}

	hideNewContactForm(): void
	{
		this.show_contact = false;
	}

	addNewContact(): void
	{
		const contact: TaContact = this.newContactForm.value;
		if (contact.user_id) {
			this.changeContact(contact);
			this.show_contact = false;
			return;
		}

		this.findService.saveUser(contact).subscribe((reply: TaContact) => {
			this.changeContact(reply);
			this.show_contact = false;
		}, error => {
			this.messageService.error(error);
		});

	}

	changeContact(contact: TaContact): void
	{
		this.contact$.next(contact);
	}
}
