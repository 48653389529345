import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { each, values } from 'lodash';

export interface RawFilterOptions {
	organization_id?: string;
	organization_name?: string;
	account_id?: string;
	employee_name?: string;
	employee_group_id?: string;
	employee_group_name?: string;
}

export interface IdNameString {
	id: string,
	name: string
}

export interface TsReportSelectors {
	companies: IdNameString[];
	employees: IdNameString[];
	employee_groups: IdNameString[];
}

@Injectable({
  providedIn : 'root'
})
export class TaFilterOptionsService extends EnkoraFetcher<TsReportSelectors> {

	protected params: CallParameter[] = [{ name : 'cta2/getDefaultFilterOptions' }];
	private location_id: string = null;

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.location_id = location_id;
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { location_id?: string } = {}): void
	{
		const location_id = options.location_id || this.location_id;
		if (!location_id) return null;

		this.params = [{
			name    : 'cta2/getDefaultFilterOptions',
			content : [{location_id}]
		}];
	}

	postProcess(reply: RawFilterOptions[])
	{
		const organization_map: { [organization_id: string]: IdNameString } = {};
		const employee_map: { [account_id: string]: IdNameString } = {};
		const employee_group_map: { [employee_group_id: string]: IdNameString } = {};

		each(reply, row => {
			const organization_id = row.organization_id ?? null;
			const account_id = row.account_id ?? null;
			const employee_group_id = row.employee_group_id ?? null;

			if (organization_id && !organization_map[organization_id]) {
				organization_map[organization_id] = {
					id   : '' + organization_id,
					name : row.organization_name || 'Visitors'
				};
			}

			if (account_id && !employee_map[account_id]) {
				employee_map[account_id] = {
					id   : '' + account_id,
					name : row.employee_name || ''
				};
			}

			if (employee_group_id && !employee_group_map[employee_group_id]) {
				employee_group_map[employee_group_id] = {
					id   : '' + employee_group_id,
					name : row.employee_group_name || ''
				};
			}
		});

		const companies = values(organization_map);
		const employees = values(employee_map);
		const employee_groups = values(employee_group_map);

		return {
			companies,
			employees,
			employee_groups
		};
	}
}
