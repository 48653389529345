import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { clone, each } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import {
	CallParameter,
	CallService,
	ConfigService, Constants,
	EnkoraFetcher,
	LocationService,
	Logger,
	ParameterService,
	TickService
} from 'shared';

import { ServiceModule, ServiceModuleLink, ServiceModuleLinkMap, ServiceModulesState } from '../../interfaces';
import { combineLatest } from 'rxjs';

@Injectable({
	providedIn : 'root'
})
export class ServiceModuleService extends EnkoraFetcher<ServiceModule[], { location_id?: string }> {

	protected params: CallParameter[] = [{ name : 'cta2/getModulesForLocations'  }];
	private last_location_id = null;
	private is_legacy_ui = false;

	private internal_links: ServiceModuleLinkMap = {
		'CTA TA'                 : {
			active   : {
				cross_domain : false,
				url          : 'app/cta/status/',
				ng_link      : '/ta'
			},
			inactive : {
				cross_domain : true,
				url          : 'http://www.ramismart.fi/'
			}
		},
		'CTA Order'              : {
			active   : {
				cross_domain : false,
				ng_link      : '/inventory',
				url          : 'app/order'
			},
			inactive : {
				cross_domain : true,
				url          : 'http://www.ramismart.fi/'
			}
		},
		'CTA Budget'             : {
			active   : {
				cross_domain : false,
				ng_link      : '/budget',
				url          : 'app/budget/'
			},
			inactive : {
				cross_domain : false,
				ng_link      : '/activate-service/budget/',
				url          : ' '
			}
		},
		'CTA Cameras'            : {
			active   : {
				cross_domain : false,
				url          : 'app/cta/status/yard_cameras'
			},
			inactive : {
				cross_domain : false,
				ng_link      : '/activate-service/camera/',
				url          : 'http://www.ramismart.fi/'
			}
		},
		'CTA Reservations'       : {
			active   : {
				cross_domain : true,
				url          : 'http://www.ramismart.fi'
			},
			inactive : {
				cross_domain : true,
				url          : 'http://www.ramismart.fi'
			}
		},
		'CTA Machine management' : {
			active   : {
				cross_domain : false,
				url          : 'app/cta/machines',
				ng_link      : '/mc'
			},
			inactive : {
				cross_domain : true,
				url          : 'http://www.ramismart.fi/'
			}
		}
	};

	constructor(private router: Router,
	            public call: CallService,
	            private tick: TickService,
	            private config: ConfigService,
	            private parameter : ParameterService,
	            private location: LocationService)
	{
		super(call);

		this.doInit();

		combineLatest([
			this.location.getLocationId(),
			this.parameter.getBooleanValue('enable legacy ui', Constants.NM_EN_CTA)
		]).pipe(debounceTime(300)).subscribe(reply => {
				const [location_id, is_legacy_ui] = reply;
				this.is_legacy_ui = is_legacy_ui;
				if (!location_id) this.doInit();
				else if (location_id != this.last_location_id)
					this.get(true, {location_id});
			}
		);
	}

	private _modules_state: ServiceModulesState;

	get modules_state(): ServiceModulesState
	{
		return this._modules_state;
	}

	protected postProcess(reply: ServiceModule[], content: { location_id: string }): ServiceModule[]
	{
		Logger.log('🚪', 'cta2/getModulesForLocations', reply);

		if (JSON.stringify(this.value) === JSON.stringify(reply)) return;

		this.last_location_id = content.location_id;
		this._modules_state = this.getState(reply as ServiceModule[]);

		return reply;
	}

	protected preProcess(options: { location_id?: string }): void | []
	{
		if (!options || !options.location_id && !this.last_location_id) {
			this.doInit();
			return [];
		}

		this.params = [{
			name    : 'cta2/getModulesForLocations',
			content : [{
				location_ids : [options.location_id || this.last_location_id]
			}]
		}];
	}

	private doInit()
	{
		this.value = [];
		this.last_location_id = null;
		this._modules_state = {
			active   : [],
			inactive : []
		};
	}

	private getState(input_array: ServiceModule[]): ServiceModulesState
	{
		const result: ServiceModulesState = { inactive : [], active : [] };

		each(input_array, module => {
			if (!this.internal_links[module.module_name]) return;

			const activity = module.is_active ? 'active' : 'inactive';

			const internal_link: ServiceModuleLink = clone(
				this.internal_links[module.module_name][activity]
			);

			if (internal_link.ng_link && !this.is_legacy_ui) {
				internal_link.url = internal_link.ng_link;
				internal_link.is_ng = true;
			} else if (!internal_link.cross_domain) {
				internal_link.url = this.config.baseUrl + internal_link.url;
			}

			const current_module = clone(module);
			current_module.link = internal_link;
			result[activity].push(current_module);
		});
		return result;
	}
}
