import * as moment from 'moment';
import { cloneDeep, each, isEmpty, map } from 'lodash';

import { Constants, Helpers } from 'shared';

import {
	DeliveryDetails,
	OrderDetails,
	OrderInfo,
	PermissionGroupModel,
	ProductDetails,
	ProductExtraServices,
	TaAddress,
	TaAgreement,
	TaCompany,
	TaCompanyInfo,
	TaContact,
	TaContactSupport,
	TaContactType,
	TaContract,
	TaCountryAddress,
	TaEmployee,
	TaEmployeeContact,
	TaEmployment,
	TaEmployments,
	TaLocation,
	TaLocationTag,
	TaPerson,
	TaPersonalInfo,
	TaQualification,
	TaRamismart,
	TaRole,
	TaSite,
	TaWorkType,
	Token,
	MachineStatistic,
	RawTaRole,
	TaUserIdentifier,
	TaOrganization
} from '../../interfaces';

import { TaCompanyTreeGenerator } from './ta-company-tree-generator.class';
import { TaPermissionHelper } from './ta-permission-helper.class';

export class TaDataHelper {
	public static get emptyAddress(): TaAddress
	{
		return {
			co       : '',
			po_box   : '',
			street   : '',
			postcode : '',
			city     : ''
		};
	}

	public static get emptyCountryAddress(): TaCountryAddress
	{
		return {
			country_code : '',
			country_name : '',
			address      : this.emptyAddress
		};
	}

	public static get emptyPerson(): TaPerson
	{
		return {
			user_id          : null,
			account_id       : null,
			picture_filename : null,
			first_name       : '',
			last_name        : '',
			name             : '',
			title            : '',
			email            : '',
			phone_number     : '',
			date_of_birth    : null,
			username         : '',
			password         : '',
			send_password    : false
		};
	}

	public static get emptyPersonalInfo(): TaPersonalInfo
	{
		return {
			...this.emptyPerson,
			tax_number   : null,
			country      : this.emptyCountryAddress,
			home_country : this.emptyCountryAddress
		};
	}

	public static get emptyContact(): TaContact
	{
		return {
			...this.emptyPerson,
			contact_id           : null,
			contact_type_id      : null,
			is_location_specific : true
		};
	}

	public static get emptyContactType(): TaContactType
	{
		return {
			contact_type_id   : '',
			contact_type_name : ''
		};
	}

	public static get emptyCompanyInfo(): TaCompanyInfo
	{
		return {
			name                     : '',
			business_id              : '',
			foreign_business_id      : '',
			foreign_business_id_type : '',
			info                     : '',
			organization_id          : null,
			country                  : this.emptyCountryAddress,
			employer_role_id         : ''
		};
	}

	public static get emptyLocation(): TaLocation
	{
		return {
			name         : '',
			code         : '',
			location_id  : '',
			external_id  : '',
			auth_site_id : ''
		};
	}

	public static get emptyAgreement(): TaAgreement
	{
		return {
			start                     : null,
			end                       : null,
			default_employee_group_id : null,
			personal_id_length        : 12,
			has_employees             : false,
		};
	}

	public static get emptyWorkType(): TaWorkType
	{
		return {
			is_visitor : false,
			code       : null,
			name       : null
		};
	}

	public static get emptyContract(): TaContract
	{
		return {
			...this.emptyAgreement,
			employee_group_id : null,
			work_type         : this.emptyWorkType,
			title_id          : null,
			commissioner      : this.emptyCompanyInfo
		};
	}

	public static get emptyCompany(): TaCompany
	{
		return {
			company_info    : this.emptyCompanyInfo,
			location        : this.emptyLocation,
			contact         : this.emptyContact,
			foreign_contact : this.emptyContact
		};
	}

	public static get emptyEmployment(): TaEmployment
	{
		return {
			company     : this.emptyCompany,
			contract    : this.emptyContract,
			employee_id : null,
			user_id     : null
		};
	}

	public static get emptyUserIdentifierLength(): TaUserIdentifier
	{
		return {
			label: 'Tax Number',
			length: 12
		};
	}

	public static get emptyEmployeeContact(): TaEmployeeContact
	{
		return {
			name                          : '',
			tax_number                    : '',
			phone_number                  : null,
			email                         : null,
			home_country_code             : null,
			home_country_name             : null,
			organization_name             : null,
			business_id                   : '',
			employee_representative_name  : null,
			employee_representative_email : null,
		};
	}

	public static get emptyEmployments(): TaEmployments
	{
		return {
			employers : []
		};
	}

	public static get emptyQualification(): TaQualification
	{
		return {
			name           : null,
			value          : null,
			contact_person : null,
			start_date     : null,
			end_date       : null,
			tag_id         : null,
			employee_id    : null,
			employee_name  : null,
			account_tag_id : null,
			external_id    : null,
			is_expired     : false
		};
	}

	public static get emptyToken(): Token
	{
		return {
			is_new            : true,
			name              : null,
			type              : null,
			existing          : null,
			external_id       : null,
			is_blacklisted    : null,
			is_verified       : null,
			notes             : null,
			organization_name : null,
			organization_id   : null
		};
	}

	public static get emptyEmployee(): TaEmployee
	{
		return {
			personal_info  : this.emptyPersonalInfo,
			is_visitor     : false,
			employments    : this.emptyEmployments,
			groups         : [],
			tokens         : [],
			qualifications : []
		};
	}

	public static get emptyRamismart(): TaRamismart
	{
		return {
			contact_types : [],
			modules       : []
		};
	}

	public static get emptySite(): TaSite
	{
		return {
			name          : '',
			code          : '',
			location_id   : '',
			external_id   : '',
			auth_site_id  : '',
			ramismart     : this.emptyRamismart,
			address       : this.emptyAddress,
			agreement     : this.emptyAgreement,
			company_info  : this.emptyCompanyInfo,
			location_tags : [],
			contact       : this.emptyContact,
			users         : []
		};
	}

	public static get emptyMachineStatistic(): MachineStatistic
	{
		return {
			online_machines            : 0,
			total_machines             : 0,
			devices_reserved_last_week : 0,
			reservations_last_week     : 0,
			used_by_day                : 0,
		};
	}

	public static get emptyPermissionGroup(): PermissionGroupModel
	{
		return {
			id                      : '',
			name                    : '',
			valid_from_date         : null,
			valid_until_date        : null,
			access_right_expiration : null,
			employee_count          : 0,
			is_active               : false,
			access_area_table       : {
				access_areas : [],
				timetables   : [],
				is_permitted : [[]]
			},
			machine_table           : {
				machines     : [],
				is_permitted : []
			}
		};
	}

	public static get emptyLocationTag(): TaLocationTag
	{
		return {
			name            : '',
			description     : '',
			possible_values : null,
			ui_order        : 0,
			tag_group_id    : '',
			tag_id          : '',
			type            : 'text',
			value           : null
		};
	}

	public static get emptyProductExtraService(): ProductExtraServices
	{
		return {
			name        : '',
			service_id  : '',
			is_per_day  : false,
			is_selected : false,
			price       : 0
		};
	}

	public static get emptyProductDetails(): ProductDetails
	{
		return {
			product_id     : '',
			quantity       : 0,
			cost_center_id : '',
			comment        : '',
			extra_services : []
		};
	}

	public static get emptyOrderInfo(): OrderInfo
	{
		return {
			order_date            : null,
			cost_center_id        : '',
			buyer_name            : '',
			buyer_user_id         : '',
			email                 : '',
			delivery_confirmation : false,
			phone_number          : ''
		};
	}

	public static get emptyDeliveryDetails(): DeliveryDetails
	{
		return {
			site_name            : '',
			site_address         : '',
			delivery_address     : '',
			requested_date       : null,
			pickup_time          : '',
			expected_return_date : null,

			recipient_name : '',
			phone_number   : '',
			info           : ''
		};
	}

	public static get emptyContactSupportInfo(): TaContactSupport
	{
		return {
			name         : null,
			email        : null,
			phone_number : null,
			text         : null
		};
	}

	public static get emptyOrderDetails(): OrderDetails
	{
		return {
			order_id         : '',
			order_info       : this.emptyOrderInfo,
			company          : this.emptyCompany,
			delivery_details : this.emptyDeliveryDetails
		};
	}

	public static extractAddress(address_object: any): TaAddress
	{
		if (!address_object) return this.emptyAddress;

		return {
			co       : address_object['co'],
			po_box   : address_object['pl'],
			street   : address_object['street'],
			postcode : address_object['postcode'],
			city     : address_object['city']
		};
	}

	public static extractCountryAddress(address_object, country_code = '', country_name = ''): TaCountryAddress
	{
		if (!address_object) return this.emptyCountryAddress;

		return {
			country_code,
			country_name,
			address : this.extractAddress(address_object)
		};
	}

	public static extractPerson(person_object: any): TaPerson
	{
		if (!person_object) return this.emptyPerson;

		const date_of_birth = person_object.date_of_birth && moment(person_object.date_of_birth) || null;

		return {
			user_id          : person_object.user_id,
			account_id       : person_object.account_id,
			first_name       : person_object.first_name,
			last_name        : person_object.last_name,
			name             : person_object.first_name + ' ' + person_object.last_name,
			email            : person_object.email,
			phone_number     : person_object.phone_number,
			date_of_birth,
			picture_filename : person_object.picture_filename
		};
	}

	public static extractContact(contact_object: any): TaContact
	{
		if (!contact_object) return this.emptyContact;

		return {
			...this.extractPerson(contact_object),
			contact_id           : contact_object.contact_id,
			is_location_specific : !!contact_object.is_location_specific
		};
	}

	public static extractContract(contract_object: any): TaContract
	{
		const default_employee_group_id = contract_object.agreement
			? contract_object.agreement.default_employee_group_id
			: null;

		return {
			title_id          : contract_object.title_id,
			default_employee_group_id,
			employee_group_id : contract_object.employee_group_id,
			work_type         : this.extractWorkType(contract_object.work_type_id),
			start             : contract_object.start_date && moment(contract_object.start_date),
			end               : contract_object.end_date && moment(contract_object.end_date)
		};
	}

	public static extractPersonalInfo(user_object: any, tags_object: any): TaPersonalInfo
	{
		let emergency_info: TaPerson = null;
		each(tags_object, tag => {
			if (tag.name == 'emergency_info') {
				const value = JSON.parse(tag.value);
				emergency_info = {
					name         : value.emergency_name,
					phone_number : value.emergency_phone_number
				};
			}
		});

		return {
			...this.extractPerson(user_object),
			tax_number             : user_object.tax_number,
			emergency_name         : emergency_info ? emergency_info.name : null,
			emergency_phone_number : emergency_info ? emergency_info.phone_number : null,
			country                :
				this.extractCountryAddress(user_object.address, user_object.country_code, user_object.country_name),
			home_country           :
				this.extractCountryAddress(user_object.home_address, user_object.home_country_code,
					user_object.home_country_name)
		};
	}

	public static extractEmployment(employer_object: any): TaEmployment
	{
		const employment = TaDataHelper.emptyEmployment;
		employment.employee_id = employer_object.employee_id;
		employment.location_id = employer_object.location_id;
		employment.is_active = employer_object.is_active;

		employment.company = {
			company_info    : this.extractCompanyInfo(employer_object),
			location        : this.extractLocation(employer_object),
			contact         : this.extractContact(employer_object.contact),
			foreign_contact : this.extractContact(employer_object.foreign_contact),
			tree            : null
		};
		employment.contract = this.extractContract(employer_object);

		return employment;
	}

	public static extractEmployeeContact(employee_contact_object: any): TaEmployeeContact
	{
		return {
			name                          : employee_contact_object.name,
			tax_number                    : employee_contact_object.tax_number,
			phone_number                  : employee_contact_object.phone_number,
			email                         : employee_contact_object.email,
			home_country_code             : employee_contact_object.home_country_code,
			home_country_name             : employee_contact_object.home_country_name,
			organization_name             : employee_contact_object.organization_name,
			business_id                   : employee_contact_object.business_id,
			employee_representative_name  : employee_contact_object.employee_representative_name,
			employee_representative_email : employee_contact_object.employee_representative_email,
		};
	}

	public static extractEmployee(employee_object: any): TaEmployee
	{
		if (!employee_object.user) {
			const employee = this.emptyEmployee;
			employee.personal_info.tax_number = employee_object.user ? employee_object.user.tax_number : '';
			if (employee_object.card_error) employee.card_error = employee_object.card_error;

			return employee;
		}

		if (employee_object.employee_tags) employee_object.user.account_tags = employee_object.employee_tags;

		const employee_map = {};
		const employments = this.emptyEmployments;

		let is_visitor = true;

		each(employee_object.employee, employer => {
			if (!employer) return;

			const employment = this.extractEmployment(employer);
			employment.user_id = employee_object.user.user_id;

			if (employer.commissioner) {
				employment.contract.commissioner = this.extractCommissioner(employer.commissioner);
				employment.contract.commissioner.country = this.extractCountryAddress(this.emptyAddress,
					employer.commissioner.country.country_code,
					employer.commissioner.country.country_name);
			}

			if (!employment.contract.work_type.is_visitor) {
				is_visitor = false;
			}

			employments.employers.push(employment);

			employee_map[employer.employee_id] = employment.company.company_info.name;
		});

		if (!employee_object.employee || !employee_object.employee.length) is_visitor = false;

		let all_employers: TaEmployment[] = [];
		each(employee_object.suggested_employees, employer => {
			if (!employer) return;

			const employment = this.extractEmployment(employer);
			employment.user_id = employee_object.user.user_id;
			all_employers.push(employment);
		});

		if (isEmpty(all_employers)) {
			all_employers = cloneDeep(employments.employers);
		}

		const qualifications: TaQualification[] = [];
		each(employee_object.tags, tag => {
			if (tag.name != 'emergency_info') {
				qualifications.push({
					tag_id         : tag.tag_id,
					name           : tag.name,
					contact_person : tag.contact_person,
					value          : tag.value,
					employee_id    : tag.employee_id,
					employee_name  : tag.employee_name ?? employee_map[tag.employee_id],
					start_date     : moment(tag.start_date),
					end_date       : moment(tag.end_date),
					external_id    : tag.external_id,
					account_tag_id : tag.account_tag_id,
					is_expired     : moment(tag.end_date).isBefore(Helpers.now)
				});
			}
		});

		return {
			personal_info : this.extractPersonalInfo(employee_object.user, employee_object.tags),
			employments,
			groups        : TaPermissionHelper.extractRawPermissionGroups(employee_object['groups']) || [],
			tokens        : employee_object.tokens || [],
			all_employers,
			is_visitor,
			qualifications
		};
	}

	public static extractToken(token_object: any): Token
	{
		return {
			is_new : false,

			name : token_object.token_name,
			type : token_object.token_type,

			existing    : token_object.token_existing,
			external_id : token_object.token_external_id,

			is_blacklisted : token_object.token_is_blacklisted,
			is_verified    : token_object.token_is_verified,

			notes             : token_object.token_notes,
			organization_name : token_object.token_organization,
			organization_id   : token_object.organization_id
		};

	}

	public static extractLocation(info_object: any): TaLocation
	{
		return {
			name         : info_object.name,
			code         : info_object.code,
			location_id  : info_object.location_id,
			external_id  : info_object.location_external_id,
			auth_site_id : info_object.auth_site_id
		};
	}

	public static extractCompanyInfo(info_object: any): TaCompanyInfo
	{
		return {
			name                     : info_object.organization_name || info_object.name,
			organization_id          : info_object.organization_id,
			business_id              : info_object.business_id,
			foreign_business_id      : info_object.foreign_business_id,
			foreign_business_id_type : '' + info_object.foreign_business_id_type,
			info                     : info_object.info,
			country                  : this.extractCountryAddress(info_object.address, info_object.country_code,
				info_object.country_name),
			employer_role_id         : info_object.employer_role_id || ''
		};
	}

	public static extractCommissioner(info_object: any): any
	{
		return {
			name                     : info_object.name,
			organization_id          : info_object.organization_id,
			business_id              : info_object.business_id,
			foreign_business_id      : info_object.foreign_business_id,
			foreign_business_id_type : '' + info_object.foreign_business_id_type,
			info                     : info_object.info,
			country_code             : info_object.country.country_code,
			country_name             : info_object.country.country_name
		};
	}

	public static extractAgreement(agreement_object: any = {}): TaAgreement
	{
		if (!agreement_object) agreement_object = {};

		const start = agreement_object.valid_from && moment(agreement_object.valid_from) || null;
		const end = agreement_object.valid_until && moment(agreement_object.valid_until) || null;
		return {
			start,
			end,
			agreement_id : agreement_object.agreement_id
		};
	}

	public static extractCompany(company: {
		agreement: any,
		tree: any,
		organization: any,
		representative: any,
		foreign_representative: TaContact,
		representatives_suggest: any
	}): TaCompany
	{

		let representative = company.representative;
		if (!representative && company.representatives_suggest && company.representatives_suggest.length) {
			representative = company.representatives_suggest[0];
		}

		const nodes = [company.tree];
		TaCompanyTreeGenerator.setupTree(nodes);

		return {
			company_info    : this.extractCompanyInfo(company.organization),
			contact         : representative ? this.extractContact(representative) : null,
			foreign_contact : company.foreign_representative ? this.extractContact(company.foreign_representative) : null,
			location        : this.extractLocation(company.organization),
			tree            : nodes,
			agreement       : this.extractAgreement(company.agreement)
		};
	}

	public static employmentToOrganization(employment: TaEmployment = this.emptyEmployment): TaOrganization
	{
		const company: TaCompany = cloneDeep(employment.company);
		const company_info: TaCompanyInfo = company.company_info;
		const contact: TaContact = company.contact;
		const foreign_contact: TaContact = company.foreign_contact;

		return {
			organization: {
				...company_info,

				country_code: company_info.country.country_code,
				country_name: company_info.country.country_name,
				address     : company_info.country.address
			},
			contact,
			foreign_contact
		};
	}

	public static contractToEmployee(contract: TaContract = this.emptyContract)
	{
		return {
			start_date         : contract.start && contract.start.format(Helpers.serverDateFormat),
			end_date           : contract.end && contract.end.format(Helpers.serverDateFormat),
			work_type_tax_code : contract.work_type && contract.work_type.code,
			employee_group_id  : contract.employee_group_id,
			title_id           : contract.title_id,
			commissioner       : this.extractCommissioner(contract.commissioner)
		};
	}

	public static extractWorkType(work_type_code: number): TaWorkType
	{
		return {
			is_visitor : work_type_code == Constants.TAX_CODE_VISITOR,
			code       : work_type_code,
			name       : work_type_code && Constants.CTA_TAX_CODES[work_type_code]
		};
	}


	public static extractOrganizationRoles(roles: RawTaRole[]): TaRole[]
	{
		return map(roles, role => {
			return {
				id   : role.organization_role_id,
				name : role.name
			};
		});
	}

}
