import { Injectable } from '@angular/core';
import { each } from 'lodash';

declare let Enkora: { tt: (a: string) => string };

export interface EventTypes {
	[event_type_id: string]: string
}

@Injectable()
export class ShopEventTypeListService {

	public map: EventTypes = {};

	public set(input: EventTypes): void
	{
		this.map = input;
		each(this.map, (value, key) => {
			this.map[key] = Enkora.tt(value);
		});
	}
}
