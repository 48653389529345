import { Injectable } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { clone, each, findIndex, includes, keyBy } from 'lodash';

import {
	CallParameter,
	CallService,
	Constants,
	EnkoraFetcher,
	EnkoraMessageService,
	Helpers,
	LocationService,
	Logger,
	ParameterService
} from '../../../../../shared';
import { Schedule, ScheduleTime } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaTimetableListService extends EnkoraFetcher<Schedule[]> {

	private static default_start_time: NgbTimeStruct = {
		hour   : 6,
		minute : 0,
		second : 0
	};
	private static default_end_time: NgbTimeStruct = {
		hour   : 18,
		minute : 0,
		second : 0
	};
	timetables_map: { [id: string]: Schedule } = {};
	protected params: CallParameter[] = [{ name : 'cta2/getTimetables' }];
	private location_id: string = null;
	private default_timetable_ids: string[] = null;

	constructor(call: CallService,
	            private messageService: EnkoraMessageService,
	            private location: LocationService,
	            private param: ParameterService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.location_id = location_id;
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);

		combineLatest([
			this.param.getValue('default access_right_template_id', Constants.NM_EN_CTA),
			this.param.getValue('default schedule start time', Constants.NM_EN_TA),
			this.param.getValue('default schedule end time', Constants.NM_EN_TA)
		]).subscribe(res => {
			const [timetable_ids, start_time, end_time] = res;

			this.default_timetable_ids = timetable_ids.split(',');

			if (start_time) {
				TaTimetableListService.default_start_time = Helpers.timeToTimePicker(start_time);
			}
			if (end_time) {
				TaTimetableListService.default_end_time = Helpers.timeToTimePicker(end_time);
			}
		});
	}

	public static get emptyTimeslot(): ScheduleTime
	{
		return {
			timetable_id     : null,
			location_id      : null,
			is_exception_day : false,
			date             : null,
			day_type_id      : null,
			is_empty         : true,
			time_span        : '',
			is_whole_day     : false,
			include_this_day : false,
			name             : '',
			time_start       : '00:00:00',
			time_end         : '00:00:00',
			is_valid         : true
		};
	}

	public static get defaultStartTime(): NgbTimeStruct
	{
		return TaTimetableListService.default_start_time;
	}

	public static get defaultEndTime(): NgbTimeStruct
	{
		return TaTimetableListService.default_end_time;
	}

	public static get emptyWeek(): ScheduleTime[]
	{
		const result: ScheduleTime[] = [];
		for (let i = 0; i < 7; ++i) {
			result.push(this.getEmptyDay(i));
		}

		return result;
	}

	public static get emptySchedule(): Schedule
	{
		return {
			timetable_id : null,
			name         : '',
			timeslots    : this.emptyWeek,
			exceptions   : []
		};
	}

	public static getEmptyDay(weekday = 0): ScheduleTime
	{
		const day = this.emptyTimeslot;
		day.name = moment().weekday(weekday).format('dddd');
		day.day_type_id = `${weekday + 1}`;

		return day;
	}

	public isDefaultTimetable(timetable: Schedule): boolean
	{
		return includes(this.default_timetable_ids, `${timetable.timetable_id}`);
	}

	preProcess(options: { location_id?: string } = {}): void
	{
		const location_id = options.location_id;

		Logger.log('Timetable list update');

		this.params = [{
			name    : 'cta2/getTimetables',
			content : [{ location_id }]
		}];
	}

	postProcess(reply: Schedule[]): Schedule[]
	{
		const result: Schedule[] = [];
		each(reply, timetable => {
			const item: Schedule = {
				name         : timetable.name,
				timetable_id : timetable.timetable_id,
				exceptions   : [],
				timeslots    : []
			};

			const day_map = {};
			const exceptions = [];

			each(timetable.timeslots, (timeslot: ScheduleTime) => {

				if (timeslot.is_exception_day) {
					exceptions.push(timeslot);
				} else {
					if (!day_map[timeslot.day_type_id]) {
						day_map[timeslot.day_type_id] = timeslot;
					}
				}

			});

			item.exceptions = exceptions;
			each(item.exceptions, exception => {
				exception.date = moment(exception.date).format('D.M.Y');
				exception.time_span = Helpers.timeStringsToSpan(exception.time_start, exception.time_end);
			});

			item.timeslots = TaTimetableListService.emptyWeek;

			for (let i = 0; i < 7; ++i) {
				if (day_map[i + 1]) {
					const source: ScheduleTime = day_map[i + 1];
					const day = TaTimetableListService.getEmptyDay(i);

					if (source.time_end == '24:00:00') source.time_end = '23:59:59';
					if (source.time_end == '23:59:00') source.time_end = '23:59:59';

					if (source.time_start == '00:00:00' && source.time_end == '23:59:59') {
						day.is_whole_day = true;
					}

					day.time_start = source.time_start;
					day.time_end = source.time_end;
					day.time_span = Helpers.timeStringsToSpan(day.time_start, day.time_end);

					day.day_type_id = source.day_type_id;
					day.is_empty = false;

					item.timeslots[i] = day;
				}
			}
			result.push(item);
		});

		this.timetables_map = keyBy(result, 'timetable_id');

		return result;
	}

	createTimetable(timetable: Schedule): Observable<string | number>
	{
		const params = {
			timetable_id : timetable.timetable_id,
			location_id  : this.location_id,
			name         : timetable.name,
			timeslots    : [],
			exceptions   : []
		};

		each(timetable.timeslots, timeslot => {
			if (!timeslot.is_empty) {
				params.timeslots.push({
					day_type_id : timeslot.day_type_id,
					time_start  : timeslot.time_start,
					time_end    : timeslot.time_end
				});
			}
		});

		each(timetable.exceptions, exception => {
			params.exceptions.push({
				is_exception_day : true,
				date             : exception.date,
				name             : exception.name,
				time_start       : exception.time_start,
				time_end         : exception.time_end,
				is_whole_day     : exception.is_whole_day
			});
		});
		return this.call.make<string | number>('cta2/createTimetable', [params]).pipe(tap(
			timetable_id => {
				if (this.isDefaultTimetable(timetable)) {
					this.reload();
					return;
				}
				if (!this.timetables_map[timetable.timetable_id]) {
					timetable.timetable_id = timetable_id.toString();
					this.value.push(timetable);
				} else {
					const value_copy = clone(this.value);
					timetable.timetable_id = timetable_id.toString();
					const foundIndex = findIndex(value_copy, timetable => timetable.timetable_id == timetable_id);
					value_copy[foundIndex] = timetable;
					this.value = value_copy;
				}

				this.timetables_map[timetable.timetable_id] = timetable;
			},
			error => {
				this.messageService.error(error);
			}
		));
	}
}
