import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';

@Component({
	templateUrl   : './info.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./info.modal.scss']
})

export class InfoModal implements OnInit {

	public type_class: string;
	public title: string;
	public text: string;

	constructor(public activeModal: NgbActiveModal,
	            private fb: FormBuilder,
	            private cdRef: ChangeDetectorRef)
	{
	}

	@Input() set input({ type_class, title, text }: {
		type_class: string,
		title: string,
		text: string
	})
	{
		this.type_class = type_class;
		this.title = title;
		this.text = text;
		this.cdRef.detectChanges();
	}

	ngOnInit(): void
	{
	}
}
