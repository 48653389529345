import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface FareProductField {
	field_name: string;
	is_customer_editable: number;
	is_required: number;
	ui_order: number;
	field_group: string;
	validation_regex?: string;
	description?: string;
}

@Injectable()
export class ShopFareProductFieldsService extends EnkoraFetcher<FareProductField[]> {

	protected params: CallParameter[] = [{ name : 'common/get_fields' }];

	constructor(call: CallService)
	{
		super(call);
	}

}
