import { Injectable } from '@angular/core';
import { CallParameter, CallService, EnkoraFetcher } from '../../shared';

import { forEach } from 'lodash';
import { SelectItem } from 'primeng/api';

@Injectable()
export class CourseCustomerGroupTagService extends EnkoraFetcher<any> {

	protected params : CallParameter[] = [{name : 'reservation/getCourseCustomersGroupTags'}];

	constructor(call : CallService)
	{
		super(call);
		this.get(true);
	}

	public getSelectItems()
   {
     let selectItems : SelectItem[] = [];
     forEach(this.value, (tag) => {
       selectItems.push({value: tag.tag_id, label: tag.name});
     });

     return selectItems;
   }
}
