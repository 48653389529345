import { Injectable } from '@angular/core';
import { CallService, LoaderModalService } from '../../shared/services';

declare let Enkora: { tt: (a: string) => string };

@Injectable()
export class PrintReservationReceiptService {

  constructor(private call : CallService,
              private loader: LoaderModalService)
  {
  }

  printReservationReceipt(reservation_event_id, reservation_account_id)
  {
    this.loader.start();

    var content = [reservation_account_id, reservation_event_id];

    this.call.make('reservation/getreservationreceipt/', content).subscribe((receipt) => {
      var new_window = window.open('', '', 'width=800,height=600,scrollbars=yes,resizable=yes');
      new_window.document.write(`
					<html>
						<head>
							<title>${Enkora.tt('Reservation Receipt')}</title>
						</head>
						<body>
							<h1 style="font-size: medium">${receipt.title}</h1>
							<p style="white-space: pre-wrap; font-size: small;">${receipt.body}</p>
						</body>
					</html>`);

      new_window.document.close();
      new_window.print();

      this.loader.stop();
    }, (error) => {
      alert('error!');
      this.loader.stop();
    });
  };
}
