import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationAgreementService {

	private _agreement: any = null;

	constructor() {
	}

	public set agreement(value) {
		this._agreement = value;
	}

	public get agreement() {
		return this._agreement;
	}

	public flushAgreement() {
		this._agreement = null;
	}
}
