import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ProductItemInput {
	name: string;
	customer_group_id: string;
	customer_group_name: string;
	price: number;
	original_price: number;
	agreement_name: string;
	amount: number;
	description: string;
	service_group_id: string;
	bundle_price: number;
	sale_group_ids: string;
}

export interface ProductItem {
	name: string;
	customer_group_id: string;
	customer_group_name: string;
	price: number;
	original_price: number;
	agreement_name: string;
	amount: number;
	description: string;
	service_group_id: string;
	bundle_price: number;
	sale_group_ids: string[];
	related_reservation_event_group_id?: string;
	fare_product_id?: string;
}

@Injectable()
export class ShopProductListService extends EnkoraFetcher<ProductItem[]> {

	protected params: CallParameter[] = [{
		name    : 'reservation2/getR2FareProducts',
		content : [true, false, { customer_names : true }]
	}];

	constructor(call: CallService)
	{
		super(call);
	}

	postProcess(reply: ProductItemInput[]): ProductItem[]
	{
		const result: ProductItem[] = [];
		each(reply, item => {
			result.push({
				...item,
				sale_group_ids : Helpers.split(item.sale_group_ids)
			});
		});

		return result;
	}

	public getFiltered(sale_group_id = '', service_group_id = ''): Observable<ProductItem[]>
	{
		if (!sale_group_id || !service_group_id) return of([] as ProductItem[]);

		return this.get().pipe(
			map(list => {
				const filtered: ProductItem[] = [];

				each(list, item => {
					if (item.service_group_id != service_group_id) return;
					if (!find(item.sale_group_ids, sg_id => sg_id == sale_group_id)) return;

					if (item.bundle_price) item.price = item.bundle_price;

					filtered.push(item);
				});

				return filtered;
			})
		);
	}
}
