import { Injectable } from '@angular/core';
import { each, keyBy } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

import { CourseItem } from '../main/courses/course-item/course-item.component';

export interface EventGroupReservation {
	end: string;
	event_number: number;
	instructors: null
	is_course: boolean;
	location: string;
	name: string;
	queue: number;
	reservation_account_id: number;
	reservation_account_name: string;
	reservation_event_group_id: number;
	reservation_id: number;
	reservation_status_id: number;
	start: string;
}

@Injectable()
export class ShopCourseEventGroupReservationsService extends EnkoraFetcher<EventGroupReservation[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2EventGroupReservations' }];

	constructor(
		call: CallService)
	{
		super(call);
	}

	public addReservationsToCourses(courses: CourseItem[], reservations: EventGroupReservation[]): CourseItem[] {
		const result: CourseItem[] = [];
		const reservation_map: { [key: string]: EventGroupReservation[] } = {};
		each(reservations, reservation => {
			const id = String(reservation.reservation_event_group_id);
			if (!reservation_map[id]) {
				reservation_map[id] = [];
			}

			reservation_map[id].push(reservation);
		});

		each(courses, course => {
			result.push({
				...course,
				reservations :
					reservation_map[course.related_reservation_event_group_id]
						? reservation_map[course.related_reservation_event_group_id]
						: null
			});
		});
		return result;
	}
}
