import { Injectable } from '@angular/core';
import { Observable, Observer, throwError } from 'rxjs';
import { cloneDeep, each, isNil } from 'lodash';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { CallParameter, CallService, EnkoraFetcher, EnkoraMessageService, Logger } from 'shared';

import { ProductItem } from './product-list.service';
import { CourseCartItem } from '../main/courses/interfaces/general.interface';

declare let Enkora: { tt: (a: string) => string };

export interface CourseParams {
	fare_product_id: number,
	quantity: number,
	price: number,
	title: string,
	account: { account_id: number },
	reservation_event_group_id: number
}

export interface CartItem {
	item_id: string;

	name: string;
	title: string;
	quantity: number;
	bundle_price: number;
	original_price: number;
	price: number;
	total_price: number;

	amount_for_second_vat: number;
	second_vat_percentage: number;
	organization_id: number;

	bundle_vat_amount: number;
	vat_amount: number;
	vat_percentage: number;
	no_vat_amount: number;
	related_reservation_event_group_id?: number;
	fare_product_id?: number;
	reservation_params?: {
		reservation_event_group_id: number;
		reservation_account_id: number;
		tags: string[];
	};
	reservation_event_group_id?: number;
}

export interface Cart {
	items: CartItem[];
	total_price: number;
	total_vat: number;
	total_no_vat: number;
	item_count: number;
}

@Injectable()
export class ShopCartListService extends EnkoraFetcher<Cart> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2Cart' }];

	constructor(
		call: CallService,
		private msgService: EnkoraMessageService,
	)
	{
		super(call);
	}

	static get empty(): Cart
	{
		return { items : [], total_price : 0, total_vat : 0, total_no_vat : 0, item_count : 0 };
	}

	postProcess(reply: CartItem[]): Cart
	{
		if (!reply) return ShopCartListService.empty;

		return this.addItems(ShopCartListService.empty, reply);
	}

	addItems(cart: Cart, items: CartItem[]): Cart
	{
		const value: Cart = cloneDeep(cart);

		each(items, (item: CartItem) => {
			if (item.bundle_price) item.price = item.bundle_price;
			item.total_price = item.price * item.quantity;

			if (item.bundle_vat_amount) {
				item.vat_amount = item.bundle_vat_amount;
			} else if (item.amount_for_second_vat && item.second_vat_percentage) {
				item.vat_amount = item.price - item.amount_for_second_vat / (1 + item.vat_percentage / 100);
			} else {
				item.vat_amount = item.price - item.price / (1 + item.vat_percentage / 100);
			}

			item.no_vat_amount = item.price - item.vat_amount;

			value.total_price += item.total_price;
			value.total_vat += item.vat_amount;
			value.total_no_vat += item.no_vat_amount;
			value.item_count += item.quantity;

			value.items.push(item);
		});

		return value;
	}

	public addProducts(
		products: ProductItem[],
		options: { account?: any } = {}
	): Observable<Cart>
	{
		const params = [];
		each(products, product => {
			let quantity = product.amount || 1;
			while (quantity--) {
				params.push({
					fare_product_id : product.fare_product_id,
					quantity        : 1,

					price : product.price,
					title : product.name,

					account                            : options.account,
					related_reservation_event_group_id : product.related_reservation_event_group_id
				});
			}
		});

		return this.call.make('cart/addmultiplecartitems', [params]).pipe(
			tap((items: CartItem[]) => {
				this.value = this.addItems(this.value, items);
			}),
			catchError(error => {
				Logger.log('addmultiplecartitems error', error);
				this.msgService.error(error);
				throw error;
			}),
			map(() => this.value),
			first()
		);
	}

	public addCourses(
		items: CourseCartItem[]
	): Observable<Cart>
	{
		const params: CourseParams[] = [];
		each(items, item => {
			let quantity = item.quantity || 1;
			while (quantity--) {
				params.push({
					fare_product_id            : item.fare_product_id,
					quantity                   : 1,
					price                      : item.price,
					title                      : item.title,
					account                    : { account_id : item.account },
					reservation_event_group_id : item.reservation_event_group_id,
				});
			}
		});

		return this.call.make<CartItem[]>('cart/addmultiplecartitems', [params]).pipe(
			tap((items) => {
				this.value = this.addItems(this.value, items);
			}),
			catchError(error => {
				Logger.log('addmultiplecartitems error', error);
				this.msgService.error(error);
				throw error;
			}),
			map(() => this.value),
			first()
		);
	}

	public addResource(fare_product_id: string | number,
	                   reservation_event_id: string | number,
	                   price: number,
	                   quantity = 1
	): Observable<boolean>
	{
		const params = {
			fare_product_id,
			reservation_event_id,
			price,
			quantity
		};

		return this.call.make('reservation2/postR2Cart', [params]).pipe(
			switchMap(() => this.reload()),
			map(() => true)
		);
	}

	paymentStart(params = {}): Observable<any>
	{
		return this.call.make('cart/externalpaymentstart', [params]);
	}

	checkGiftcardBalance(params = {}): Observable<{ result: true, balance: number } | { result: false, message: string }>
	{
		return this.call.make('cart/checkgiftcardbalance', [params]);
	}

	loadBalanceFromGiftcard(gift_card_number: string, amount: number): Observable<boolean>
	{
		return this.call.make('cart/loadbalancefromgiftcard', [gift_card_number, amount]);
	}

	getReceiptInfo(): Observable<{
		total_amount: number,
		is_pay_later: boolean,
		is_closed: boolean,
		receipt_id: string | number,
		has_tickets: boolean
	}>
	{
		return this.call.make('cart/getreceiptinfo', []);
	}

	emptyCart(): Observable<boolean>
	{
		return this.removeCartOrItem(null, true);
	}

	resetCartUI(): void
	{
		if (this.value) {
			this.value.items = [];
			this.value.item_count = 0;
			this.value.total_no_vat = 0;
			this.value.total_price = 0;
			this.value.total_vat = 0;
		}
	}

	removeItem(item: CartItem): Observable<boolean>
	{
		return this.removeCartOrItem(item, false);
	}

	generatePaymentFullPath(params: Record<string, any>): string
	{
		let qParams = '';
		if (params) {
			const encoded_params = [];
			each(params, (value, key) => {
				encoded_params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
			});
			qParams = '?' + encoded_params.join('&');
		}
		return window.location.pathname + qParams + window.location.hash;
	}

	private removeCartOrItem(item: CartItem, is_empty_cart: boolean): Observable<boolean>
	{
		if (!is_empty_cart && (!item || isNil(item.item_id))) {
			return throwError(() => new Error('Incorrect cart item'));
		}

		return new Observable((observer: Observer<boolean>) => {
			this.call.make('cart/islocked').subscribe((reply: { isLocked: boolean }) => {
				if (reply.isLocked) {
					if (confirm(Enkora.tt(
						'Payment is not completed. Do you want to restart the buying process? All items will be cleared.'))
					) {
						this.call.make('cart/resetcart').subscribe(() => {
							this.value = ShopCartListService.empty;
							observer.next(false);
							observer.complete();
						}, error => observer.error(error));
					} else {
						observer.next(false);
						observer.complete();
					}
				} else {
					if (is_empty_cart) {
						this.call.make('cart/emptyCart', []).subscribe(() => {
							this.reload();
							observer.next(true);
							observer.complete();
						}, error => observer.error(error));
					} else {
						this.call.make('cart/deleteCartItem', [{ item_id : item.item_id }]).subscribe(() => {
							this.reload();
							observer.next(true);
							observer.complete();
						}, error => observer.error(error));
					}
				}
			});
		});
	}
}

