<div class="modal-header">
	<h3 tabindex="0" tt>
		<span *ngIf="!custom_title">Edit</span>
		<span *ngIf="custom_title">{{ custom_title }}</span>
	</h3>
	<button tabindex="0" *ngIf="!hide_cancel_button" type="button" class="close" data-dismiss="modal" aria-hidden="true"
	        (click)="activeModal.dismiss()">&times;
	</button>
</div>

<div class="modal-body">
	<shop-alert-message [alert]="alert"></shop-alert-message>

	<div *ngIf="modifyForm?.rootForm">
		<!-- Placeholder for the form -->
		<shop-fields [shop_fields]="modifyForm">
		</shop-fields>
	</div>
</div>

<div class="modal-footer">
	<button *ngIf="!hide_cancel_button" class="btn closeButton" (click)="back()" tt>Cancel</button>

	<button type="submit"
	        class="btn btn-primary submit"
	        (click)="modify()"
	        [disabled]="!modifyForm?.rootForm?.valid || is_modifying" tt>
		Save
	</button>
</div>
