<div class="centered card" *ngIf="loader.is_active">
	<div class="card-body internal">
		<div>
			<i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
		</div>
		<div>
			<span class="large-text" tt>Loading...</span>
		</div>
	</div>
</div>
