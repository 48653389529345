import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from '../../shared';

import { cloneDeep, each, isArray, isEmpty } from 'lodash';

declare let Enkora: { tt: (a: string) => string };

export interface ReservationResourceServicesAtArea {
	service_at_area_id : number;
	service_id : number;
	name : string;
	time_offset_duration : number;
	time_slot_duration : number;
	minimum_time_slots_number : number;
	resource_id : number;
}

export interface ReservationResourceFareProduct {
	fare_product_id : number;
	name : string;
	customer_group_id : number;
	price_type_id : number;
	vat_group_id : number;
	price : number;
	resource_id : number;
}

export interface ReservationResource {
	resource_id : number;
	location_id : string;
	name : string;

	parent_resource_ids : number[];
	sub_resource_ids : number[];
	linked_resources : number[];
	fare_products : ReservationResourceFareProduct[];
	all_services_at_areas : ReservationResourceServicesAtArea[];
}

export interface ReservationResources {
	values : ReservationResource[];
	map : { [resource_id : number] : ReservationResource };
	child_parent_map : { [child_id : string] : { [parent_id : string] : boolean } };
}

@Injectable()
export class ResourceListService extends EnkoraFetcher<ReservationResources> {

	params : CallParameter[] = [{name : 'reservation/getResources', content : [{}, {short : true}]}];

	constructor(call : CallService)
	{
		super(call);
	}

	public getResourceById(resource_id : number) : any {
		let result = null;
		each(this.value.values, resource => {
			if ( resource.resource_id === resource_id ) {
				result = resource;
				return false;
			}
		});
		return result;
	}

	public buildResourcesForLocationIds(location_ids : string[]) : ReservationResource[]
	{
		const resources : ReservationResource[] = [];
		const location_map = Helpers.arrayToSimpleMap(location_ids);

		if (!isEmpty(location_ids)) {
			const root : ReservationResource = {
				name        : Enkora.tt('Other resources'),
				resource_id : 0,
				location_id : null,

				sub_resource_ids      : [],
				parent_resource_ids   : [],
				all_services_at_areas : [],
				fare_products         : [],
				linked_resources      : []
			};

			each(cloneDeep(this.value.values), resource => {
				if (!location_map(resource.location_id)) {
					if (!resource.parent_resource_ids) {
						resource.parent_resource_ids = [];
					}
					resource.parent_resource_ids.push(0);
					root.sub_resource_ids.push(resource.resource_id);
				}
				resources.push(resource);
			});

			resources.push(root);
		}
		return resources;
	}

	public getServiceAtAreaId(resource_id, service_id)
	{
		let res = null;
		if (!isEmpty(this.value.map[resource_id])
			&& !isEmpty(this.value.map[resource_id].all_services_at_areas)
		) {
			each(this.value.map[resource_id].all_services_at_areas, (sas : ReservationResourceServicesAtArea) => {
				if (sas.service_id == service_id) {
					res = sas.service_at_area_id;
					return false;
				}
			});
		}

		return res;
	}

	public getServicesForIds(ids : any) : { label : string, value : string, object : ReservationResourceServicesAtArea }[]
	{
		ids = ids.map(id => (+id));
		let res : { label : string, value : string, object : ReservationResourceServicesAtArea }[] = [];

		let services_at_areas : { [service_id : string] : ReservationResourceServicesAtArea } = {};
		let first = true;
		let resources : ReservationResource[] = this.value.values.filter(resource => (ids.indexOf(resource.resource_id) != -1));
		each(resources, resource => {
			let old_services_at_areas = services_at_areas;
			services_at_areas = {};

			each(resource.all_services_at_areas, (sas : ReservationResourceServicesAtArea) => {
				if (first || old_services_at_areas[sas.service_id]) {
					services_at_areas[sas.service_id] = sas;
				}
			});
			first = false;
		});

		each(services_at_areas, (sas : ReservationResourceServicesAtArea) => {
			res.push({label : sas.name, value : '' + sas.service_id, object : sas});
		});

		return res;
	}

	public flattenResourceIds(resource_ids : string) : string[]
	{
		let res : string[] = [];

		try {
			res = ('' + resource_ids).split(',');
		} catch (e) {
			console.log(e);
		}
		return res;
	}

	public getNamesForIds(ids : any) : string[]
	{
		let res : string[] = [];

		if (!isArray(ids)) ids = [ids];
		each(ids, (id) => {
			if (!id) return;
			let resource = this.value.map[+id];
			if (resource) res.push(resource.name || ('' + resource.resource_id));
		});
		return res;
	}

	public getListForIds(ids : any) : string
	{
		return this.getNamesForIds(ids).join(', ');
	}

	protected postProcess(reply : any[])
	{
		const result : ReservationResources = {
			values           : [],
			map              : {},
			child_parent_map : {}
		};

		each(reply, r => {
			const resource : ReservationResource = {
				...r,

				resource_id         : r.resource_id ? +r.resource_id : null,
				name                : '' + r.name,
				parent_resource_ids : isArray(r.parent_resource_ids) ? r.parent_resource_ids : []
			};

			result.map[resource.resource_id] = resource;
			result.child_parent_map[resource.resource_id] = {};
			result.values.push(resource);

			each(resource.parent_resource_ids, resource_id => {
				result.child_parent_map[resource.resource_id][resource_id] = true;
			});
		});

		return result;
	}
}
