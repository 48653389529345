<div class="modal-header">
	<h2 class="no-margin-bottom">
		{{(dataHolder.is_add_new_employee? 'Add a new employer': 'Edit employment') | tt}}
	</h2>
	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.dismiss();">
		<cta-icon-close></cta-icon-close>
	</button>
</div>
<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="2">
	</cta-ta-add-employee-progressbar>

	<p tt>
		Time and attendance employment info modal description
	</p>

	<p class="mb-5 alert alert-warning" *ngIf="dataHolder.is_add_new_employee && is_empty_company_info">
		<span tt>Note Vastuu response missing company data</span>
	</p>

	<h4>
		{{(dataHolder.is_add_new_employee? 'Add employment information' : 'Edit employment information') | tt}}
	</h4>

	<ng-container *ngIf="dataHolder.is_add_new_employee && suggested_employees?.length > 0">
		<label for="suggested_employees" tt>Select suggested the employee</label>
		<ng-select id="suggested_employees"
		           [placeholder]="'Select...' | tt"
		           [(ngModel)]="suggested_employee"
		           (ngModelChange)="changeSuggestEmployee()">
			<ng-option *ngFor="let employee of suggested_employees"
			           [value]="employee.company.company_info.business_id || employee.company.company_info.foreign_business_id">
				{{employee.company.company_info.name}}
			</ng-option>
		</ng-select>
	</ng-container>

	<cta-ta-form-employment-info [inputForm]="employmentForm"
	                             [company_form]="true">
	</cta-ta-form-employment-info>
	<div class="alert alert-danger" *ngIf="duplicate_business_id">
		<span tt>The Company and the Temporary commissioner cannot be same. Please change one to proceed.</span>
	</div>
	<div class="alert alert-danger" *ngIf="is_employee_already_exists">
		<span tt>An employment for this company and employee already exists.</span>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" *ngIf="!standalone" (click)="back()" tt>Previous</button>

	<div class="spacer"></div>

	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" tt>Close</button>

	<button type="button"
	        class="btn btn-primary-custom"
	        *ngIf="save_message || !standalone"
	        [disabled]="!is_valid || is_employee_already_exists"
	        (click)="confirm()">
		{{save_message + (standalone ? 'close' : 'continue') | titlecase | tt}}
	</button>
</div>
