import { Inject, Injectable, Optional } from '@angular/core';
import { each, orderBy } from 'lodash';

import { CallParameter, CallService, Constants, EnkoraFetcher, LocationService, Logger } from 'shared';

export interface ContentType {
	location_id: string
	timestamp: string;
	page: string;
	limit: string;
	companies: string;
	employees: string;
	access_points: string;
}

@Injectable({
	providedIn : 'root'
})
export class TaAccessEventsService extends EnkoraFetcher<unknown[], Partial<ContentType>> {

	protected params: CallParameter[] = [{ name : 'cta/getAccessEvents' }];

	constructor(call: CallService,
	            private location: LocationService,
	            @Inject('access_events_limit') @Optional() private access_events_limit?: string)
	{
		super(call);

		const limit = access_events_limit
			? { limit : access_events_limit }
			: {};
		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id, ...limit });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(content: Partial<ContentType> = {}): void | []
	{
		const location_id = content.location_id || this.latest_content?.location_id;
		if (!location_id) return [];

		Logger.log('Access events update: location_id= ' + location_id);

		this.params = [{
			name    : 'cta/getAccessEvents',
			content : [
				{
					location_id,
					timestamp       : content.timestamp ? content.timestamp : null,
					page            : content.page,
					page_length     : content.limit,
					organization_id : content.companies,
					user_id         : content.employees,
					access_points   : content.access_points
				}
			]
		}];
	}

	postProcess(reply: { access: any, status: string }[]): any
	{
		reply = reply || [];
		each(reply, event => {
			switch (event.access) {
				case Constants.ET_ACCESS:
					event.status = 'accepted';
					break;
				case Constants.ET_INSPECTION:
					event.status = 'denied';
					break;
				default:
					event.status = '';
			}
		});

		return orderBy(reply, 'timestamp', 'desc');
	}
}
