import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { each } from 'lodash';
import moment from 'moment';

import { AuthenticationService, Helpers, User } from 'shared';

import { CourseItem } from '../../main/courses/course-item/course-item.component';
import { ShopParametersService } from '../../services';
import { ReservationEvent } from '../../../reservation/services/interfaces';

interface CourseTimetableRow {
	weekday: string;
	date: string;
	time: string;
}

@Component({
	templateUrl   : './course-timetable.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./course-timetable.modal.scss']
})

export class CourseTimetableModal implements OnInit, AfterViewInit {
	@ViewChild('modalContent') modalContent!: ElementRef;
	
	public course: CourseItem;
	public user: User;
	public rows: CourseTimetableRow[] = [];

	constructor(public activeModal: NgbActiveModal,
	            public auth: AuthenticationService,
	            public params: ShopParametersService)
	{
	}

	@Input() set input({ course }: { course: CourseItem })
	{
		this.course = course;
		this.rows = this.processReservationEvents(course.reservation_events);
	}

	private processReservationEvents(reservation_events: ReservationEvent[]): CourseTimetableRow[]
	{
		const results: CourseTimetableRow[] = [];
		each(reservation_events, event => {
			results.push({
				weekday : moment(event.time_start).format('dddd'),
				date    : `${Helpers.removeLeadingZero(moment(event.time_start).format('DD.MM'))}.`,
				time    : moment(event.time_start).format('HH:mm') + ' - ' + moment(event.time_end).format('HH:mm'),
			});
		});
		return results;
	}

	ngOnInit(): void
	{
	}

	ngAfterViewInit(): void
	{
		Helpers.setElementFocus(this.modalContent?.nativeElement, '.aria-focus');
	}
}
