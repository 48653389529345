import { Injectable } from '@angular/core';

import { EnkoraDataInjector } from 'shared';

import { ShopServiceListService } from './service-list.service';
import { ShopLocationListService } from './location-list.service';
import { ShopSaleGroupListService } from './sale-group-list.service';
import { ShopServiceGroupListService } from './service-group-list.service';
import { EventTypes, ShopEventTypeListService } from './event-type-list.service';
import { ShopResourceListService } from './resource-list.service';
import { ShopResourceTagListService } from './resource-tag-list.service';
import { ShopReservationGroupListService } from './reservation-group-list.service';
import { ShopInstructorListService } from './instructor-list.service';
import { ShopCourseTagListService } from './course-tag-list.service';
import { ShopSeasonListService } from './season-list.service';

@Injectable()
export class ShopDataInjectionService {

	constructor(private serviceList: ShopServiceListService,
	            private locationList: ShopLocationListService,
	            private resourceList: ShopResourceListService,
	            private resourceTagList: ShopResourceTagListService,
	            private saleGroupList: ShopSaleGroupListService,
	            private serviceGroupList: ShopServiceGroupListService,
	            private reservationGroupList: ShopReservationGroupListService,
	            private instructorsList: ShopInstructorListService,
	            private courseTagList: ShopCourseTagListService,
	            private seasonList: ShopSeasonListService,
	            private event_types: ShopEventTypeListService)
	{
	}

	presetAll(): void
	{
		this.serviceList.preset(EnkoraDataInjector.get('services') || []);
		this.reservationGroupList.preset(EnkoraDataInjector.get('groups') || []);
		this.instructorsList.preset(EnkoraDataInjector.get('instructors') || []);
		this.seasonList.preset(EnkoraDataInjector.get('seasons') || []);
		this.courseTagList.preset(EnkoraDataInjector.get('course_tags') || []);
		this.locationList.preset(EnkoraDataInjector.get('locations') || []);
		this.resourceList.preset(EnkoraDataInjector.get('resources') || []);
		this.resourceTagList.preset(EnkoraDataInjector.get('resource_tags') || []);
		this.saleGroupList.preset(EnkoraDataInjector.get('sale_groups') || []);
		this.serviceGroupList.preset(EnkoraDataInjector.get('service_groups') || []);
		this.event_types.set(EnkoraDataInjector.get<EventTypes>('event_types') || {});
	}
}
