import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map as mapRxjs, tap } from 'rxjs/operators';
import { cloneDeep, each } from 'lodash';
import * as moment from 'moment';

import { CallParameter, CallService, Constants, EnkoraFetcher } from 'shared';

import { ReservationEvent } from '../../reservation/services';

declare let Enkora: { tt: (a: string) => string };

export interface Reservation {
	reservation_id: string;
	service_id: string;
	owner_account_name: string;
	reservation_account_name: string;

	resource_names: string;
	reservation_event_group: string;
	reservation_status_id: string;

	time_start: moment.Moment;
	time_end: moment.Moment;

	reservation_account_id: string;
	resources: string;
	location: string;
	instructor: string;

	name: string;
	is_paid: boolean;
	is_self: boolean;
	is_active: boolean;
	is_course: boolean;
	is_queued: boolean;
	is_unconfirmed: boolean;
	is_past: boolean;
	is_cancelable: boolean;
	is_resource_reservation: boolean;

	[key: string]: any;
}

export interface MakeReservation {
	additional_fare_products: any[];
	additional_reservations: any[];
	reservation_agreement_id: number | string;
	service_id: number | string;
	resource_id: number | string;
	start_date: string;
	end_date: string;
	capacity: number;
	name: string;
	owner_account_id: number | string;
	notes?: string;
	extra_data_for_event?: { [field_group_id: string]: any };
}

export interface ReservationDetailsRaw {
	reservation_event_id: string;
	owner_name: string;
	location: string;
	resource_names: string;
	time_start: string;
	time_end: string;

	free_queue: number;
	free: number;
	queue: number;
	reserved: number;
	number_of_instructors: number;
}

export interface ReservationDetails {
	reservation_id: string;
	reservation_event_id: string;
	owner_name: string;
	location: string;
	resource_names: string;
	time_start: moment.Moment;
	time_end: moment.Moment;
	is_past: boolean;

	free_queue: number;
	free: number;
	queue: number;
	reserved: number;
	number_of_instructors: number;
}

@Injectable()
export class ShopReservationListService extends EnkoraFetcher<Reservation[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2Reservations' }];

	constructor(call: CallService)
	{
		super(call);
	}

	is_cancelable = (reservation_status_id: string | number): boolean =>
		[Constants.RES_QUEUE, Constants.RES_RESERVED, Constants.RES_UNCONFIRMED].indexOf(+reservation_status_id) >= 0;

	is_unconfirmed = (reservation_status_id: string | number): boolean =>
		[Constants.RES_UNCONFIRMED, Constants.RES_UNCONFIRMED_QUEUE].indexOf(+reservation_status_id) >= 0;

	is_active = (reservation_status_id: string | number): boolean =>
		[Constants.RES_UNCONFIRMED, Constants.RES_RESERVED, Constants.RES_CONFIRMED_RESERVED, Constants.RES_ADMITTED].indexOf(+reservation_status_id) >= 0;

	is_queued = (reservation_status_id: string | number): boolean =>
		[Constants.RES_QUEUE, Constants.RES_CONFIRMED_QUEUE].indexOf(+reservation_status_id) >= 0;

	public filterByAccount(account_id = '', all_accounts = false): Reservation[]
	{
		const result: Reservation[] = [];

		each(this.value, reservation => {
			if (reservation.is_course) return;

			if (all_accounts || reservation.reservation_account_id == account_id || reservation.owner_account_id == account_id) {

				const new_reservation = cloneDeep(reservation);
				new_reservation.is_self = new_reservation.reservation_account_id == account_id;
				result.push(new_reservation);
			}
		});

		return result;
	}

	public makeHotelReservation(payload: MakeReservation): Observable<ReservationEvent>
	{
		return this.call.make<ReservationEvent>('reservation2/reserveHotel',
			[{
				time_start               : payload.start_date,
				time_end                 : payload.end_date,
				service_id               : payload.service_id,
				resource_id              : payload.resource_id,
				reservation_agreement_id : payload.reservation_agreement_id,
				capacity                 : payload.capacity,
				additional_fare_products : payload.additional_fare_products,
				additional_reservations  : payload.additional_reservations,
				name                     : payload.name,
				owner_account_id         : payload.owner_account_id,
				notes                    : payload.notes,
				extra_data_for_event     : payload.extra_data_for_event
			}]
		).pipe(tap(() => this.invalidate()));
	}

	postProcess(reply: Reservation[]): Reservation[]
	{
		const result: Reservation[] = [];
		const reservation_ids: { [key: string]: boolean } = {};

		each(reply, item => {
			const row: Reservation = item;

			row.reservation_id = `${row.reservation_id}`;
			row.service_id = `${row.service_id}`;

			// deduplication, somehow we receive twice all the data
			if (reservation_ids[row.reservation_id]) return;

			row.is_resource_reservation = item.resources && item.resources != '' && item.resources != '0';

			row.is_cancelable = this.is_cancelable(item.reservation_status_id);
			row.is_unconfirmed = this.is_unconfirmed(item.reservation_status_id);
			row.is_active = this.is_active(item.reservation_status_id);
			row.is_queued = this.is_queued(item.reservation_status_id);

			row.name = row.is_resource_reservation
				? item.resource_names || Enkora.tt('Resource')
				: item.reservation_event_group;

			row.time_start = moment(item.time_start);
			row.time_end = moment(item.time_end);

			row.is_past = row.time_start.isBefore() && row.time_end.isBefore();

			row.instructor = item.owner_account_name || Enkora.tt('undefined');

			reservation_ids[row.reservation_id] = true;
			result.push(row);
		});

		return result;
	}

	public getReservation(reservation_id: string, reservation_event_id: string): Observable<ReservationDetails>
	{
		return this.call.make<ReservationDetailsRaw>('reservation2/getEventsForCustomer', [
			{ reservation_event_id }
		]).pipe(
			mapRxjs(value => {
				const time_start = moment(value.time_start);
				const time_end = moment(value.time_end);
				const is_past = time_start.isBefore() && time_end.isBefore();

				return ({
					...value,
					reservation_id,
					time_start,
					time_end,
					is_past
				});
			})
		);
	}

	public removeReservation(reservation_id: string): Observable<{ reservation_status_id: number }>
	{
		return this.call.make<{ reservation_status_id: number }>('reservation2/deleteR2Reservations', [{
			reservation_id
		}]).pipe(tap(() => this.reload()));
	}
}
