import { EventEmitter, Injectable, Output } from '@angular/core';
import { ReservationResource } from './resource-list.service';

interface ResourceSelectorState {
	selectedResources : any[];
	nonBlockingResources : any[];
	resources : ReservationResource[];
	resourceIdsToMove : number[];
}

@Injectable()
export class ResourceSelectorStateService {

	@Output() getCurrentState = new EventEmitter<boolean>();
	@Output() currentStateUpdate = new EventEmitter<boolean>();

	public state : ResourceSelectorState;

	constructor()
	{
	}
}
