import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CourseRefreshService {

	public show_loader = true;
	public refresher$ = new BehaviorSubject<any>({});

	constructor()
	{
	}

	public doRefresh(show_loader? : boolean)
	{
		if (show_loader !== undefined) this.show_loader = show_loader;
		this.refresher$.next({});
	}

}
