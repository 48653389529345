<nav class="navbar navbar-expand-md navbar-main-custom">

	<div class="container">

		<a class="navbar-brand logo-no-hover" routerLink="{{cta_logo_link}}">
			<cta-logo></cta-logo>
		</a>

		<button class="navbar-toggler navbar-toggler-right"
		        type="button"
		        (click)="mobileMenuOpen = !mobileMenuOpen">

			<span class="sr-only" tt>Toggle navigation</span>
			<cta-icon-hamburger></cta-icon-hamburger>
		</button>

		<div class="collapse navbar-collapse" id="navbarMenu" [ngbCollapse]="!mobileMenuOpen">
			<ul class="navbar-nav ms-auto">

				<ng-container *ngIf="systemLocation">
					<li class="nav-item dropdown"
					    *ngIf="auth.value && systemLocation.all"
					    ngbDropdown
					    autoClose="outside"
					    #myDrop="ngbDropdown">

						<a class="nav-link dropdown-toggle" role="button" ngbDropdownToggle>
							<ng-container
								*ngIf="systemLocation && systemLocation.current && systemLocation.current.name; else no_name">
								{{ systemLocation.current.name }}
							</ng-container>
							<ng-template #no_name>
								{{ 'Site' | tt }}
							</ng-template>
						</a>
						<div class="dropdown-menu dropdown-menu-right dropdown-main-custom dropdown-main-custom--locations"
						     ngbDropdownMenu>
							<input type="text"
							       class="dropdown-item"
							       [(ngModel)]="searchLocation"
							       (ngModelChange)="updateLocations()"
							       [placeholder]="'Search for...' | tt"
							       (click)="$event.stopImmediatePropagation()"/>

							<a class="dropdown-item"
							   *ngFor="let item of active_locations"
							   (click)="$event.preventDefault(); myDrop.close(); setLocation(item)"
							   href>

								{{ item.name }}
							</a>
						</div>
					</li>
				</ng-container>

				<!-- dropdown multilevel -->
				<ng-container *ngIf="systemMenu">
					<li class="nav-item dropdown" *ngIf="auth.value && systemMenu.length" placement="bottom-right"
					    ngbDropdown>

						<a class="nav-link dropdown-toggle no-dropdown-arrow" role="button" ngbDropdownToggle>
							<cta-icon-cog></cta-icon-cog>
						</a>

						<div class="dropdown-menu dropdown-menu-right dropdown-main-custom" ngbDropdownMenu>

							<ng-container *ngFor="let group of systemMenu">

								<ng-container *ngIf="group.length === 1 && !group[0]?.disabled">
									<!-- Legacy linking -->
									<a class="dropdown-item"
									   *ngIf="group[0]?.title"
									   [href]="group[0]?.path | apiurl"
									   target="_blank">

										{{ group[0].title | tt}}
									</a>
								</ng-container>

								<ng-container *ngIf="group.length > 1 && !group[0]?.disabled">
									<div class="sub-menu-wrap">
										<!-- Legacy linking -->
										<a class="dropdown-item-text dropdown-submenu-toggle"
										   [href]="group[0]?.path | apiurl"
										   target="_blank"
											(click)="$event.preventDefault();"
											(mousedown)="$event.preventDefault();">

											{{group[0]?.title | tt }}
										</a>

										<div class="dropdown-submenu">
											<ng-container *ngFor="let item of group | slice : 1">
												<!-- Legacy linking -->
												<a class="dropdown-item"
												   *ngIf="!item[0]?.disabled"
												   [href]="item[0]?.path | apiurl"
												   target="_blank">

													{{ item[0]?.title | tt }}
												</a>
											</ng-container>
										</div>

									</div>

								</ng-container>
							</ng-container>
						</div>
					</li>
				</ng-container>

				<ng-container *ngIf="systemLanguage">
					<!-- dropdown -->
					<li class="nav-item dropdown" *ngIf="systemLanguage.length" ngbDropdown>

						<a class="nav-link dropdown-toggle no-dropdown-arrow" role="button" ngbDropdownToggle>
							<!-- IconGlobe start-->
							<cta-icon-globe></cta-icon-globe>
							<!-- IconGlobe end-->
						</a>
						<div class="dropdown-menu dropdown-menu-right dropdown-main-custom" ngbDropdownMenu>
							<a class="dropdown-item"
							   [ngClass]="{'active': auth.value?.language_id == item.language_id}"
							   *ngFor="let item of systemLanguage"
							   (click)="setLanguage($event, item.language_id )" href>{{ item.name }}</a>
						</div>

					</li>
				</ng-container>

				<li class="nav-item" *ngIf="auth.value">
					<a class="nav-link" [href]="'app2/password2' | apiurl">
						<cta-icon-user></cta-icon-user>
					</a>
				</li>

				<!-- dropdown -->
				<li class="nav-item dropdown" *ngIf="auth.value" ngbDropdown>
					<a class="nav-link dropdown-toggle no-dropdown-arrow" role="button" ngbDropdownToggle>
						<i class="fa fa-question" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-right dropdown-main-custom"
					     ngbDropdownMenu>
						<ng-container *ngIf="this.instructions_links">
							<a class="dropdown-item"
							   *ngFor="let link of this.instructions_links"
							   href="{{ link.target }}" target="_blank">{{ link.name | tt }}</a>
						</ng-container>

						<a class="dropdown-item" role="button" href="javascript;">
							<button class="btn_link" (click)="openContactSupport()">{{'Contact Support' | tt}}</button>
						</a>
					</div>
				</li>

				<!--Logout link begin -->
				<li class="nav-item menu-log-out" *ngIf="auth.value">

					<a class="nav-link"
					   (click)="$event.preventDefault(); logout( $event )"
					   routerLink="/login">

		            <span class="log-action-label" tt>
		              Log out
		            </span>
						<span class="log-action-icon">
		               <cta-icon-logout></cta-icon-logout>
		            </span>
					</a>
				</li>
				<!--Logout link end -->

				<!--Login link begin -->
				<li class="nav-item dropdown" *ngIf="!auth.value" placement="bottom-right" ngbDropdown>

					<a *ngIf="ctaStyle && ctaStyle.is_external_login" class="nav-link dropdown-toggle"
					   role="button"
					   ngbDropdownToggle>

						<span class="log-action-icon">
							<cta-icon-login-admin></cta-icon-login-admin>
		            </span>
					</a>

					<a *ngIf="ctaStyle && !ctaStyle.is_external_login" class="nav-link dropdown-toggle"
					   role="button"
					   ngbDropdownToggle>

						<!-- text and icon should change depends on user login status -->
						<span class="log-action-label" tt>
		              Log in
		            </span>
						<span class="log-action-icon">
							<cta-icon-login></cta-icon-login>
		            </span>
					</a>

					<div class="dropdown-menu dropdown-menu-right dropdown-login"
					     (click)="$event.stopPropagation()"
					     ngbDropdownMenu>
						<form class="login-form form">
							<div class="form-group">
								<input type="text"
								       class="form-control"
								       id="username"
								       name="username"
								       [(ngModel)]="username"
								       [placeholder]="'Username' | tt">
							</div>

							<div class="form-group">
								<input type="password"
								       class="form-control"
								       id="password"
								       name="password"
								       [(ngModel)]="password"
								       placeholder="{{ 'Password' | tt }}">
							</div>

							<div class="form-group">
								<button class="btn btn-primary-custom"
								        (click)="login()"
								        [disabled]="!username || !password || loginLoading"
								        tt>
									Login
								</button>
								<br>
								<p *ngIf="loginLoading === null" class="text-gray">
									<span tt>Please login in with your username and password.</span>
									<br>
								</p>
								<p *ngIf="loginSuccess === false && !loginLoading" class="login-error">
									<span tt>Incorrect username or password.</span>
									<br>
								</p>
								<a class="btn-link blue" routerLink="/login/new-password">
										<span class="link-label" tt>
											Forgot your password?
										</span>
									<cta-icon-link></cta-icon-link>
								</a>
								<p *ngIf="loginError" class="login-error">
									<br>
									<span>{{ 'Something went wrong:' | tt }} {{ loginError }}</span>
								</p>
							</div>
						</form>
					</div>
				</li>
				<!--Login link end -->

			</ul>

		</div> <!-- / .navbar-collapse -->

	</div> <!-- / .container -->

</nav>
