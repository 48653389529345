import { Pipe, PipeTransform } from '@angular/core';

import { TranslationsService } from '../services';

const TranslationPrefix = 'translation ';

interface TtPipeOptions {
	prefix: boolean;
	col: string;
	vars: (string | number)[];
}

@Pipe({
	name : 'tt'
})
export class TtPipe implements PipeTransform {

	constructor(private translate: TranslationsService)
	{
	}

	public transform(input?: string, opts: Partial<TtPipeOptions> = {}): string
	{
		if (!input) return null;


		// handle string
		return this.internalTranslate(opts, input);
	}

	private internalTranslate(opts: Partial<TtPipeOptions>, input: string): string
	{
		const key = (opts.prefix) ? TranslationPrefix + input : input;
		const translation = this.translate.tt(key, ...(opts.vars || []));

		return translation === key ? input : translation;
	}
}
