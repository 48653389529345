import { Injectable } from '@angular/core';

@Injectable()
export class GuidService {

	private _guid_value = 1;

	constructor()
	{
	}

	public get Next(): number
	{
		return this._guid_value++;
	}
}
