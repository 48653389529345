import { BehaviorSubject, combineLatest, concat, Observable, of, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { cloneDeep, isEmpty } from 'lodash';

import { CallService } from '../services';

import { Logger } from './logger.class';

/*
 * In this file
 *    name       refers to the endpoint name call/some_name,
 *    content    refers to the content that we want to send inside POST request to the endpoint
 *    parameter  refers to the name + content pair
 *    options    refers to the sent parameters and subject which waits for reply
 *
 */
export interface CallParameter<ContentType = unknown> {
	name: string; // name of the call, e.g.: 'common/tick'
	content?: ContentType; // parameters for the call, e.g. [true, true]
}

interface FetcherOptions<ReturnType, ContentType> {
	subject?: Subject<ReturnType>;
	content: ContentType;
}

export class EnkoraFetcher<ReturnType, ContentType = unknown, RawReturnType = unknown> {
	count: number;
	public value$: Subject<ReturnType> = new Subject<ReturnType>();
	public error$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	protected show_debug = true;

	protected params: CallParameter<ContentType>[] = [];
	private _has_error = false;

	private content: ContentType = null;
	private content_is_provided = false;
	// technical variables
	private fetching_sub: Subscription = null;

	constructor(protected call: CallService)
	{
	}

	public get is_loading(): boolean
	{
		return !this._is_loaded || this.fetching;
	}

	get fetching(): boolean
	{
		return !!this.fetching_sub && !this.fetching_sub.closed;
	}

	public get latest_content(): ContentType
	{
		return this.content;
	}

	public set latest_content(value: ContentType)
	{
		this.content = value;
		this.content_is_provided = true;
	}

	// controllable by subclasses variables
	protected _value: ReturnType;

	get value(): ReturnType
	{
		return this._value;
	}

	set value(value: ReturnType)
	{
		if (value === undefined) return;

		this._value = value;
		this.value$.next(value);
	}

	private _is_loaded = false;

	protected get is_loaded(): boolean
	{
		return this._is_loaded;
	}

	protected set is_loaded(value: boolean)
	{
		this._is_loaded = value;
	}

	// Preprocess is a function that is called just before making extranal call,
	//  - if it returns a value (not undefined) then we use this value instead of making a call
	//  - if it returns undefined, then we make a call
	//
	//  as arguments this function takes options, a set of parameters that get was called with, these

	private static isObservable<T>(obj: any | Observable<any>): obj is Observable<T>
	{
		return !!obj && (obj instanceof Observable ||
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			(typeof obj.lift === 'function' && typeof obj.subscribe === 'function'));
	}

	// Postprocess is a function that is used when we made a call and returned successful result with data,
	// this result is withing reply and can be processed and changed, it is called before making a modification to
	// internal value
	// - if this function returns undefined then we do not make any changes to value
	// - if this function returns a value then we use it to assign to internal value state
	//

	public preset(data: RawReturnType, content?: ContentType): void
	{
		this.latest_content = cloneDeep(content);
		if (!this.checkContent(content)) return;

		this.ensureReply(this.postProcess(data, content), { content : content });
	}

	// params then are passed to preProcess() and postProcess()
	public get(forceLoad = false, params?: ContentType): Observable<ReturnType>
	{
		if (!forceLoad && (this._is_loaded || this._has_error)) {
			return concat(of(this._has_error ? null : this.value), this.value$);
		}

		if (forceLoad) {
			if (this.fetching_sub) {
				this.fetching_sub.unsubscribe();
			}
			this._is_loaded = false;
		}

		if (!this.fetching) {
			this.fetch({ content : params });
		}

		return this.value$;
	}

	public getOnce(content?: ContentType): Observable<ReturnType>
	{
		const subject = new Subject<ReturnType>();
		this.fetch({ subject, content : content }, false);

		return subject.pipe(first());
	}

	// Main function to get data, it takes forceLoad as first parameter and options as second,

	// Many times we need get data that was or will be retrieved but we do not want to provide any parameters
	public getLazy(): Observable<ReturnType>
	{
		// if data already loaded or waiting for data => provide place
		if (this.is_loaded || this.fetching) return concat(of(this.value), this.value$);

		// not loaded and not fetching, but has params without errors (probably due to invalidation) => let's reload
		if (this.content_is_provided && !this._has_error) return this.reload();

		// finally continue to wait (may be somebody want to trigger load latter)
		// Note: this final reason for lazyloading is that we load data only if somebody once tries to get value
		// and another place do not want to trigger the value load, but only be passive recipient
		return this.value$;
	}

	// Sometimes we need to reload from server
	public reload(): Observable<ReturnType>
	{
		this.get(true, this.latest_content).subscribe();

		return this.value$;
	}

	public invalidate(): void
	{
		if (!isEmpty(this.value$.observers)) this.reload();
		else {
			this._is_loaded = false;
			this._value = undefined;
		}
	}

	//  can be used to make call's parameters here
	protected preProcess(content?: ContentType): ReturnType | void
	{
		if (content) {
			this.params.forEach(param => param.content = content);
		}

		return;
	}

	// Params is a copy of parameters that get function was called with
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	protected postProcess(reply: RawReturnType, content?: ContentType): Observable<ReturnType> | ReturnType
	{
		return reply as unknown as ReturnType;
	}

	// internal worker for fetching data
	private fetch(options: FetcherOptions<ReturnType, ContentType>, save_content = true)
	{
		if (!this.checkContent(options.content)) return;

		const extra_options = options.content?.['extra_options'] ? options.content['extra_options'] : {};

		let obs$: Observable<RawReturnType | RawReturnType[]> = null;
		const calls$ = this.params.map((call) =>
			this.call.make<RawReturnType, ContentType>(call.name, call.content, extra_options));

		if (calls$.length == 1) {
			obs$ = calls$.pop();
		} else if (calls$.length > 1) {
			obs$ = combineLatest(calls$);
		} else return;

		if (save_content) {
			this.latest_content = cloneDeep(options.content);
		}

		this.fetching_sub = obs$.subscribe(
			(data: RawReturnType) => {
				if (this.show_debug) {
					Logger.log((this.params.length == 1 ? this.params[0].name : 'multi call'), this.params, data);
				}

				this.ensureReply(this.postProcess(data, options.content), options);
				this._has_error = false;
			},
			error => {
				// Tick not needed as we check it in onInit
				if (this.show_debug) {
					Logger.log('Error, ' + (this.params.length == 1
						? this.params[0].name
						: 'multi call'), this.params, error);
				}

				if (options.subject) options.subject.error(error);

				this.error$.next(error);

				this._has_error = true;
			}
		);
	}

	private checkContent(content?: ContentType): boolean
	{
		const value = this.preProcess(content);
		if (!this.params || !this.params.length) return;
		if (value !== undefined) {
			Logger.log('default params in ', this.params);
			this.value = value as ReturnType;
			return false;
		}

		return true;
	}

	private ensureReply(reply: ReturnType | Observable<ReturnType>, options: FetcherOptions<ReturnType, ContentType>)
	{
		if (EnkoraFetcher.isObservable(reply)) {
			this.fetching_sub = reply.subscribe((value: ReturnType) => {
				this.saveReply(value, options);
			}, (error) => {
				Logger.log('strange error from EnkoraFetcher::ensureReply', error);
			});
		} else {
			this.saveReply(reply, options);
		}
	}

	private saveReply(reply: ReturnType, options: FetcherOptions<ReturnType, ContentType>)
	{
		this._is_loaded = true;

		if (options.subject && options.subject != this.value$) {
			options.subject.next(reply);
			options.subject.complete();
		} else {
			this.value = reply;
		}
	}
}
