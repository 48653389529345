import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { cloneDeep, filter, find, isEmpty, isEqual, some } from 'lodash';

import {
	AutoSubs,
	AutoUnsubscribe,
	CallService,
	Constants,
	EnkoraMessageService,
	ExtraValidators,
	LocationService,
} from 'shared';

import {
	CtaStyle,
	TaActiveDataHolderService,
	TaCompanyInfo,
	TaCtaStyleService,
	TaDataHelper,
	TaEmployment,
	TaFormGroupGeneratorService,
	TaRole,
	TaOverviewDataHolderService,
	TaEmployee,
	CtaStyleCode
} from '../../../../../shared';
import { TaCompanyDetailsService } from '../../../services';
import { TaOrganizationRoleListService } from '../../../services/ta-organization-role-list-service';

@AutoUnsubscribe()
@Component({
	templateUrl : './employment-info.modal.html',
	styleUrls   : ['./employment-info.modal.scss']
})
export class EmploymentInfoModal implements OnInit, OnDestroy {

	@AutoSubs() subs;
	@Input() public standalone = true;
	@Input() public by_card_number = false;
	@Input() public by_tax_number = false;

	public employmentForm: FormGroup;

	public latest_value: TaEmployment = null;
	public initial_value: TaEmployment = null;

	public is_valid = false;
	public no_changes = true;
	public save_message = '';
	public edit_mode = false;
	public employer_roles: TaRole[] = [];
	public duplicate_business_id = false;
	public is_empty_company_info = false;
	public is_employee_already_exists = false;

	public suggested_employees: TaEmployment[] = null;
	public suggested_employee: string = null;

	private ctaStyle: CtaStyle = TaCtaStyleService.emptyStyle;
	private current_country_code: string = null;
	private user_id: string = null;
	private current_validator: any = null;

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            private location: LocationService,
	            private formGenerator: TaFormGroupGeneratorService,
	            private companyDetails: TaCompanyDetailsService,
	            private ctaStyleService: TaCtaStyleService,
	            public dataHolder: TaActiveDataHolderService,
	            public dataOverview: TaOverviewDataHolderService,
	            public organizationRoles: TaOrganizationRoleListService)
	{
	}

	ngOnInit(): void
	{
		this.subs = combineLatest([
			this.dataHolder.employment$,
			this.dataHolder.employee$,
			this.ctaStyleService.getFirst(),
			this.organizationRoles.get()
		]).subscribe((reply: [employment: TaEmployment, employee: TaEmployee, ctaStyle: CtaStyle, roles: TaRole[]]) => {
			const [employment, employee, ctaStyle, roles] = reply;

			this.ctaStyle = ctaStyle;
			if (employee.all_employers) {
				this.suggested_employees = filter(employee.all_employers, employer => !!employer.company.company_info.name);
			}
			this.is_empty_company_info = isEqual(employment.company.company_info, TaDataHelper.emptyCompanyInfo)
				&& !this.suggested_employees?.length && this.by_tax_number;
			this.user_id = employment.user_id;
			this.employer_roles = roles;

			if ((this.by_card_number || this.by_tax_number) && this.suggested_employees?.length == 1) {
				this.suggested_employee = this.suggested_employees[0].company.company_info.business_id
					? this.suggested_employees[0].company.company_info.business_id
					: this.suggested_employees[0].company.company_info.foreign_business_id;
				this.changeSuggestEmployee();
			} else {
				if (this.by_tax_number) {
					const our_employees = filter(this.suggested_employees, e => e.is_active);
					if (our_employees.length == 1) {
						this.suggested_employee = our_employees[0].company.company_info.business_id
							? our_employees[0].company.company_info.business_id
							: our_employees[0].company.company_info.foreign_business_id;
						this.changeSuggestEmployee();
						return;
					}
				}
				this.initEmploymentForm(employment);
			}
		});
	}

	ngOnDestroy(): void
	{
	}

	back(): void
	{
		this.activeModal.dismiss('back');
	}

	confirm(): void
	{
		if (!this.is_valid) return;

		if (this.no_changes && this.latest_value.employee_id) {
			this.activeModal.close();
			return;
		}

		this.saveEmployment();
	}

	saveEmployment(): void
	{
		this.subs = this.location.getLocationId().subscribe(location_id => {
			const employment: TaEmployment = cloneDeep(this.latest_value);
			const user_id = employment.user_id || this.dataHolder.personal_info.user_id;

			const params = {
				user_id,
				employee_id : employment.employee_id,
				location_id,

				contract : TaDataHelper.contractToEmployee(employment.contract),
				...TaDataHelper.employmentToOrganization(employment)
			};

			this.subs = this.call.make<{
				employee_id: string;
				organization_id: string
			}>('cta2/saveEmploymentInformation', [params]).subscribe(reply => {
				if (employment.contract.work_type.code != Constants.TAX_CODE_VISITOR) {
					this.dataOverview.employee.is_visitor = false;
				}

				employment.employee_id = `${reply.employee_id}`;

				combineLatest([
					this.location.get(),
					this.companyDetails.getOnce({
						organization_id : +reply.organization_id,
						location_id
					})]).pipe(first()).subscribe(reply => {
					const [location, company] = reply;

					if (!company.location.location_id) {
						company.location = { auth_site_id : '', ...location.current };
					}
					employment.company = company;
					this.activeModal.close(employment);
				});

			}, error => {
				this.messageService.error(error);
			});
		});
	}

	handleEmploymentDataChange(params: { status: string, data: TaEmployment }): void
	{
		this.is_valid = params.status != 'INVALID';

		this.updateValidatorIfNecessary(params.data);

		this.latest_value = params.data;
		if (!this.initial_value) this.initial_value = cloneDeep(params.data);

		this.current_country_code = this.latest_value.company.company_info.country.country_code;
		this.no_changes = isEqual(this.initial_value, this.latest_value);
		this.save_message = (this.no_changes && this.latest_value.employee_id) ? '' : 'Save and ';
	}

	changeSuggestEmployee(): void
	{
		if (!this.suggested_employee) {
			const empty_employment = TaDataHelper.emptyEmployment;
			empty_employment.user_id = this.user_id;
			this.initEmploymentForm(empty_employment);
		} else {
			const employment = find(this.suggested_employees, e_id => e_id.company.company_info.business_id
				== this.suggested_employee || e_id.company.company_info.foreign_business_id == this.suggested_employee);
			this.initEmploymentForm(employment);
		}
	}

	private updateValidatorIfNecessary(data: TaEmployment)
	{
		const latest_company = (this.latest_value != null)
			? this.latest_value.company.company_info
			: TaDataHelper.emptyCompanyInfo;
		const new_company = data.company.company_info;

		const latest_commissioner = (this.latest_value != null)
			? this.latest_value.contract.commissioner
			: TaDataHelper.emptyCompanyInfo;
		const new_commissioner = data.contract.commissioner;
		let new_validator = null;

		if (this.isChangedCompanyValidators(latest_company, new_company)
			|| this.isChangedCompanyValidators(latest_commissioner, new_commissioner)
		) {
			new_validator = this.getValidators();
			this.updateCompanyValidators(new_validator.company.company_info, new_company);
			this.updateCompanyValidators(new_validator.contract.commissioner, new_commissioner);
		}

		if (this.isChangedBusinessId(this.latest_value, data)
			|| this.isChangedWorkTypeToAgencyWorker(this.latest_value, data)
			|| new_validator) {
			new_validator = new_validator
				? new_validator : this.current_validator
					? this.current_validator : this.getValidators();

			const business_id_validators = new_validator.contract.commissioner.business_id.validators;
			business_id_validators.push(ExtraValidators.notEqual(new_company.business_id));
			this.duplicate_business_id = new_company.business_id
				&& new_company.business_id === new_commissioner.business_id;
		}

		if (new_validator
			&& data.company
			&& parseInt(data.company.company_info.foreign_business_id_type) == Constants.BUSINESS_ID_TYPE_VAT_NUMBER) {
			this.updateVatNumberValidators(new_validator, data.company.company_info.country.country_code);
		}

		if (data.contract.work_type.code != Constants.TAX_CODE_AGENCY_WORKER && new_validator) {
			this.resetCommissionerValidators(new_validator.contract.commissioner);
		}

		if (new_validator
			&& data.contract.work_type.code == Constants.TAX_CODE_AGENCY_WORKER
			&& !isEmpty(data.contract.commissioner.country.country_code)
			&& data.contract.commissioner.country.country_code != CtaStyleCode.FI) {
			new_validator.contract.commissioner.business_id.validators = [];
		}

		if (new_validator) {
			this.current_validator = new_validator;
			this.formGenerator.updateFormGroupValidators(this.employmentForm, new_validator);
		}

		if (data.company && this.isChangedBusinessId(this.latest_value, data)) {
			this.checkEmployeeAlreadyExists(data);
		}
	}

	private isChangedCompanyValidators(old_company: TaCompanyInfo, new_company: TaCompanyInfo)
	{
		if (old_company.business_id != new_company.business_id) return true;
		if (old_company.foreign_business_id != new_company.foreign_business_id) return true;
		if (old_company.foreign_business_id_type != new_company.foreign_business_id_type) return true;
		return old_company.country.country_code != new_company.country.country_code;
	}

	private isChangedBusinessId(old_employment: TaEmployment, new_employment: TaEmployment) : boolean
	{
		if ((old_employment == null && new_employment != null)
			|| old_employment.company.company_info.business_id != new_employment.company.company_info.business_id
			|| old_employment.contract.commissioner.business_id != new_employment.contract.commissioner.business_id) {
			return true;
		}
	}

	private isChangedWorkTypeToAgencyWorker(old_employment: TaEmployment, new_employment: TaEmployment) : boolean
	{
		if (old_employment.contract.work_type.code != new_employment.contract.work_type.code
			&& new_employment.contract.work_type.code == Constants.TAX_CODE_AGENCY_WORKER) {
			return true;
		}
	}

	private initEmploymentForm(employment: TaEmployment)
	{
		if (this.dataHolder.is_add_new_employee) {
			const default_employee_group_id = employment.contract.default_employee_group_id;
			const work_type = employment.contract.work_type || TaDataHelper.emptyWorkType;
			employment.contract = TaDataHelper.emptyContract;
			employment.contract.default_employee_group_id = default_employee_group_id;
			employment.contract.work_type = work_type;
			employment.employee_id = null;
		}

		const validators = this.getValidators();
		this.updateValidatorInEditMode(validators, employment);
		this.employmentForm = this.formGenerator.initEmployment(employment, true, validators);

		this.employmentForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			this.handleEmploymentDataChange({ status : this.employmentForm.status, data });
		});

		this.handleEmploymentDataChange({
			status : this.employmentForm.status,
			data   : this.employmentForm.getRawValue()
		});
	}

	private getValidators()
	{
		const validators = {
			company  : {
				company_info : {
					name                     : { validators : [Validators.required] },
					business_id              : { validators : [Validators.required] },
					foreign_business_id      : { validators : [Validators.required] },
					foreign_business_id_type : { validators : [Validators.required] },
					country                  : {
						country_code : { validators : [Validators.required] },
						country_name : { validators : [] },
						address      : {
							street   : { validators : [Validators.required] },
							postcode : { validators : [Validators.required] },
							city     : { validators : [Validators.required] }
						}
					},
					employer_role_id : { validators : [] }
				},
				contact      : {
					first_name   : { validators : [Validators.required] },
					last_name    : { validators : [Validators.required] },
					phone_number : { validators : [Validators.required, Validators.pattern(this.ctaStyle.phone_number.pattern)] },
					email        : { validators : [Validators.pattern(this.ctaStyle.email.pattern)] }
				}
			},
			contract : {
				work_type         : {
					code : { validators : [Validators.required] }
				},
				employee_group_id : { validators : [] },
				commissioner      : {
					name                     : { validators : [Validators.required] },
					business_id              : {
						validators : [Validators.required,
							Validators.pattern(this.ctaStyle.business_id.pattern)]
					},
					foreign_business_id      : { validators : [] },
					foreign_business_id_type : { validators : [] },
					country                  : {
						country_code : { validators : [Validators.required] },
						country_name : { validators : [] }
					}
				}
			}
		};

		return validators;
	}

	private updateCompanyValidators(companyValidators: any, companyInfo: TaCompanyInfo)
	{
		const country_code = companyInfo.country.country_code || this.ctaStyle.code;
		if (country_code == this.ctaStyle.code) {
			const business_id_pattern = TaCtaStyleService.getStyle(country_code).business_id.pattern;
			companyValidators.business_id.validators = [
				Validators.required,
				Validators.pattern(business_id_pattern)
			];
			companyValidators.foreign_business_id.validators = [];
			companyValidators.foreign_business_id_type.validators = [];
		} else if (companyInfo.business_id && companyInfo.business_id.length) {
			const business_id_pattern = TaCtaStyleService.getStyle(this.ctaStyle.code).business_id.pattern;
			companyValidators.business_id.validators = [Validators.pattern(business_id_pattern)];
			companyValidators.foreign_business_id.validators = [];
			companyValidators.foreign_business_id_type.validators = [];
		} else {
			companyValidators.business_id.validators = [];
			companyValidators.foreign_business_id.validators = [Validators.required];
			companyValidators.foreign_business_id_type.validators = [Validators.required];
		}

		if (companyValidators.country.address) {
			const postcode_pattern = TaCtaStyleService.getStyle(country_code).postcode.pattern;
			companyValidators.country.address.postcode.validators = [
				Validators.required,
				Validators.pattern(postcode_pattern)
			];
		}
	}

	private resetCommissionerValidators(commissioner_validators: any)
	{
		commissioner_validators.name.validators = [];
		commissioner_validators.business_id.validators = [];
		commissioner_validators.foreign_business_id.validators = [];
		commissioner_validators.foreign_business_id_type.validators = [];
		commissioner_validators.country.country_code.validators = [];
	}

	private updateVatNumberValidators(validators: any, country_code: string)
	{
		const vat_number_rule = TaCtaStyleService.getVatNumberRule(country_code);
		validators.company.company_info.foreign_business_id.validators = [Validators.required, Validators.pattern(vat_number_rule)];
	}

	private updateValidatorInEditMode(validators: any, employment: TaEmployment)
	{
		const business_id_pattern = TaCtaStyleService.getStyle(this.ctaStyle.code).business_id.pattern;
		validators.company.company_info.business_id.validators = employment.company.company_info.foreign_business_id
			? []
			: [Validators.required, Validators.pattern(business_id_pattern)];
		validators.company.company_info.foreign_business_id.validators = employment.company.company_info.business_id
			? []
			: [Validators.required];
		validators.company.company_info.foreign_business_id_type.validators = employment.company.company_info.foreign_business_id
			? [Validators.required]
			: [];
		validators.company.contact.phone_number.validators = employment.company.contact.phone_number ? [Validators.required] : [];
	}

	private checkEmployeeAlreadyExists(employment: TaEmployment)
	{
		this.is_employee_already_exists = some(this.dataHolder.employments.employers, employee =>
			employee.company.company_info.business_id == employment.company.company_info.business_id
			&& employee.contract.work_type == employment.contract.work_type
			&& employee.is_active);
	}
}
