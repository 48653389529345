import { Component, OnInit } from '@angular/core';

@Component({
	selector    : 'cta-icon-calendar',
	templateUrl : './icon-calendar.component.html',
	styleUrls   : ['./icon-calendar.component.scss']
})
export class IconCalendarComponent implements OnInit {

	constructor()
	{
	}

	ngOnInit(): void
	{
	}

}
