<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">

		<div class="form-group row">
			<label [for]="id + '_order_date'" class="col-md-3 col-form-label text-input-label" tt>Order date</label>
			<div class="col-md-9 d-flex align-items-center">
				<div>
					{{ inputForm.get('order_date').value | amDateFormat:'DD.MM.YYYY' }}
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_cost_center_id'" class="col-md-3 col-form-label text-input-label" tt>Cost center</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_cost_center_id'"
				       name="cost_center"
				       formControlName="cost_center_id">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_buyer_name'" class="col-md-3 col-form-label text-input-label" tt>Order requester</label>
			<div class="col-md-9 d-flex align-items-center">
				<div>
					{{ inputForm.get('buyer_name').value }}
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_email'" class="col-md-3 col-form-label text-input-label" tt>Email</label>
			<div class="col-md-9 d-flex align-items-center">
				<div>
					{{ inputForm.get('email').value }}
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_phone_number'" class="col-md-3 col-form-label text-input-label" tt>Phone number</label>
			<div class="col-md-9 d-flex align-items-center">
				<div>
					{{ inputForm.get('phone_number').value }}
				</div>
			</div>
		</div>

		<div class="checkbox checkbox-md">
			<input type="checkbox" class="form-check-input" [id]="id + '_delivery_confirmation'"
			       name="delivery_confirmation" formControlName="delivery_confirmation">
			<label class="form-check-label"
			       [for]="id + '_delivery_confirmation'" tt>Delivery confirmation to e-mail</label>
		</div>
		<br>

	</div>
</ng-container>
