<ng-container *ngIf="company && company.company_info">
	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Company name</label>
		<div class="col-md-9">
			<div class="pt-1">
				{{ company.company_info.name }}
			</div>
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Business Id</label>
		<div class="col-md-9">
			<div class="pt-1">
				{{ company.company_info.business_id }}
			</div>
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Site External ID</label>
		<div class="col-md-9">
			<div class="pt-1">
				{{ company.location.external_id }}
			</div>
			<span *ngIf="!company.location.external_id" tt>external site id is missing in delivery notes</span>
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Country</label>
		<div class="col-md-9">
			<div class="pt-1">
				{{ company.company_info.country.country_name }}
			</div>
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Address</label>
		<div class="col-md-9">
			<div class="pt-1">
				{{ company.company_info.country.address.street }}
				{{ company.company_info.country.address.city }} {{ company.company_info.country.address.postcode }}
			</div>
		</div>
	</div>
</ng-container>
