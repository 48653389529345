import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';

export interface DashboardStatistics {
	employees_on_site: number;
	total_employees: number;
	companies_on_site: number;
	total_companies: number;
	access_events_today: number;
	working_time_this_month: number;
	total_time: number;
}

@Injectable({
	providedIn : 'root'
})
export class TaSiteStatisticsService extends EnkoraFetcher<DashboardStatistics> {

	protected params: CallParameter[] = [{ name : 'cta2/getSiteStatistics' }];

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	static get empty(): DashboardStatistics
	{
		return {
			employees_on_site       : 0,
			total_employees         : 0,
			companies_on_site       : 0,
			total_companies         : 0,
			access_events_today     : 0,
			working_time_this_month : 0,
			total_time              : 0
		};
	}

	preProcess(options: { location_id?: string } = {}): DashboardStatistics
	{
		options = options || {};
		const location_id = options.location_id;
		if (!location_id) return TaSiteStatisticsService.empty;

		Logger.log('Site statistics fetch for: location_id= ' + location_id);

		this.params = [{
			name    : 'cta2/getSiteStatistics',
			content : [{ location_id }]
		}];
	}
}
