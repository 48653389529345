import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { each, isEmpty } from 'lodash';
import { combineLatest } from 'rxjs';

import { Constants, Helpers, Logger, ModalOpenerService, ParameterService } from '../../../../../shared';
import { TaWorkType } from '../../interfaces';
import { CtaStyleCode, TaActiveDataHolderService, TaDataHelper, TaOverviewDataHolderService } from '../../services';
import { PersonalInfoModal } from '../../../protected-modules/time-attendance/employee/modals';
import { TaEmployeeGroupsService } from '../../../protected-modules/time-attendance/services';
import * as moment from 'moment/moment';

declare let Enkora: { tt: (a: string) => string };

@Component({
	selector    : 'cta-ta-form-employee-contract',
	templateUrl : './employee-contract.form.html',
	styleUrls   : ['./employee-contract.form.scss']
})
export class EmployeeContractForm implements OnInit {

	public workTypeForm: FormGroup = null;
	public work_types: { code: number, name: string }[] = [];

	public titles: { id: number, name: string }[] = [];
	public employee_groups: { id: string, name: string } [] = [];
	public commissioner_is_required = false;
	public userUpdateError = false;
	private default_employee_group_id: string;

	public min_start = Helpers.momentToNgbDate(moment().add(-6, 'months'));
	public min_end = Helpers.momentToNgbDate(moment().add(-6, 'months'));
	public max_date = Helpers.momentToNgbDate(moment().add(10, 'year'));

	public min_start_calendar = Helpers.momentToNgbDate(moment().add(-2, 'year'));
	public min_end_calendar = Helpers.momentToNgbDate(moment().add(-2, 'year'));
	public max_date_calendar = Helpers.momentToNgbDate(moment().add(15, 'year'));

	public removed_start_date_error = false;
	public removed_end_date_error = false;

	constructor(private params: ParameterService,
	            private modalHelper: ModalOpenerService,
	            private dataHolder: TaActiveDataHolderService,
	            private employeeGroupsService: TaEmployeeGroupsService,
	            public dataOverview: TaOverviewDataHolderService)
	{
		this.work_types = [];
		each(Constants.CTA_TAX_CODES, (name, tax_code) => {
			this.work_types.push({ code : +tax_code, name : Enkora.tt(name) });
		});

		combineLatest([
			this.params.getValue('cta_employment_titles', Constants.NM_EN_CTA),
			this.params.getValue('default employee_group_id', Constants.NM_EN_CTA),
			this.employeeGroupsService.get(),
		]).subscribe(reply => {
			const [titles, default_employee_group_id, employee_groups] = reply;
			this.titles = titles as unknown as { id: number, name: string }[];
			this.employee_groups = employee_groups;
			this.default_employee_group_id = default_employee_group_id;
			if (this._inputForm) {
				this.changeEmployeeGroup(default_employee_group_id);
			}
		});
	}

	private changeEmployeeGroup(new_employee_group_id: string) {
		const employee_group_id = new_employee_group_id || this.default_employee_group_id;
		if (employee_group_id) {
			this._inputForm.get('employee_group_id').setValue(employee_group_id);
		}
	}

	private _inputForm: FormGroup;
	public commissionerForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(form: FormGroup)
	{
		this._inputForm = form;
		this.workTypeForm = (form.get('work_type') as FormGroup);
		this.commissionerForm = (form.get('commissioner') as FormGroup);

		this._inputForm.addControl('set_valid_start_date', new FormControl());
		this._inputForm.addControl('set_valid_end_date', new FormControl());
		this._inputForm.patchValue({ set_valid_start_date : false });
		this._inputForm.patchValue({ set_valid_end_date : false });

		this._inputForm.addControl('start_date_hidden', new FormControl());
		this._inputForm.addControl('end_date_hidden', new FormControl());
		this._inputForm.patchValue({ start_date_hidden : null });
		this._inputForm.patchValue({ end_date_hidden : null });

		if (!form.value.employee_group_id) {
			this.changeEmployeeGroup(this._inputForm.get('default_employee_group_id').value);
			this._inputForm.get('default_employee_group_id').valueChanges.subscribe(value => {
				this.changeEmployeeGroup(value);
			});
		}

		if (this.workTypeForm) {
			this.checkWorkType(this.workTypeForm.get('code').value);
			this.workTypeForm.get('code').valueChanges.subscribe((code: number) => {
				const new_work_type: TaWorkType = TaDataHelper.extractWorkType(code);
				this.workTypeForm.patchValue({ name : new_work_type.name, is_visitor : new_work_type.is_visitor });
				this.checkWorkType(new_work_type.code);

				const openModal = (new_work_type.code && this.dataHolder.employee.is_visitor
					&& (this.dataHolder.employee.personal_info.tax_number == null
						||	this.dataHolder.employee.personal_info.date_of_birth == null
						|| this.dataHolder.employee.personal_info.first_name == null
						|| this.dataHolder.employee.personal_info.last_name == null)
					&& Constants.TAX_CODE_VISITOR != new_work_type.code);

				if (openModal)
				{
					const modalRef = this.modalHelper.openLgModal(PersonalInfoModal);
					modalRef.componentInstance.is_visitor = false;
					this.dataHolder.is_add_new_employee = false;

					modalRef.result.then((result) => {
						this.dataOverview.personal_info = result;
						this.dataHolder.personal_info = result;
						this.userUpdateError = false;
					},
						() => {
							if (openModal) {
								this.workTypeForm.controls['code'].setValue(Constants.TAX_CODE_VISITOR);
								this.userUpdateError = true;
							}
						}
					);
				}
			});
		}

		this._inputForm.get('start')?.valueChanges.subscribe(value => {
			if (this._inputForm.get('start').valid && !this.removed_end_date_error) {
				this.min_end = Helpers.momentToNgbDate(value);
			}
			this.removed_start_date_error = false;
			this._inputForm.get('set_valid_start_date')?.enable();
			this._inputForm.get('set_valid_start_date')?.setValue(false);
			this._inputForm.get('start_date_hidden')?.setValue(value, {emitEvent : false});
		});

		this._inputForm.get('end')?.valueChanges.subscribe(value => {
			this.removed_end_date_error = false;
			this._inputForm.get('set_valid_end_date')?.enable();
			this._inputForm.get('set_valid_end_date')?.setValue(false);
			this._inputForm.get('end_date_hidden')?.setValue(value, {emitEvent : false});
		});

		this._inputForm.get('start_date_hidden')?.valueChanges.subscribe(value => {
			this._inputForm.get('start')?.setValue(value);
		});

		this._inputForm.get('end_date_hidden')?.valueChanges.subscribe(value => {
			this._inputForm.get('end')?.setValue(value);
		});
	}

	ngOnInit(): void
	{
	}

	private checkWorkType(work_type_code: number)
	{
		Logger.log('Changed work type to ', work_type_code);
		if (this.workTypeForm)
			this.commissioner_is_required = work_type_code == Constants.TAX_CODE_AGENCY_WORKER;
		if (this.commissioner_is_required) {
			this.commissionerForm.get('name').setValidators([Validators.required]);
			this.commissionerForm.get('country').get('country_code').setValidators([Validators.required]);

			if (this.commissionerForm.get('country').get('country_code').value != CtaStyleCode.FI
				&& !isEmpty(this.commissionerForm.get('country').get('country_code').value)) {
				this.commissionerForm.get('business_id').clearValidators();
				this.commissionerForm.get('business_id').setErrors(null);
			} else {
				this.commissionerForm.get('business_id').setValidators([Validators.required]);
			}

		} else {
			this.commissionerForm.get('name').clearValidators();
			this.commissionerForm.get('country').get('country_code').clearValidators();
			this.commissionerForm.get('business_id').clearValidators();
			this.commissionerForm.get('foreign_business_id').clearValidators();
			this.commissionerForm.get('foreign_business_id_type').clearValidators();
			this.commissionerForm.get('name').setErrors(null);
			this.commissionerForm.get('country').get('country_code').setErrors(null);
			this.commissionerForm.get('business_id').setErrors(null);
			this.commissionerForm.get('foreign_business_id').setErrors(null);
			this.commissionerForm.get('foreign_business_id_type').setErrors(null);
		}
		this.commissionerForm.updateValueAndValidity();
	}

	removeStartDateError()
	{
		this._inputForm.get('start').setErrors(null);
		this._inputForm.updateValueAndValidity();
		this._inputForm.get('set_valid_start_date')?.disable();
		this.removed_start_date_error = true;
	}

	removeEndDateError()
	{
		this._inputForm.get('end').setErrors(null);
		this._inputForm.updateValueAndValidity();
		this._inputForm.get('set_valid_end_date')?.disable();
		this.removed_end_date_error = true;
	}

}
