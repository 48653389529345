import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GuidService } from 'shared';

import { CtaStyle, TaCtaStyleService } from '../../services';

@Component({
	selector    : 'cta-ta-form-address',
	templateUrl : './address.form.html',
	styleUrls   : ['./address.form.scss']
})
export class AddressForm implements OnInit {

	@Input() public inputForm: FormGroup;
	@Input() public only_address = false;
	@Input() public readonly = false;
	public ctaStyle: CtaStyle = TaCtaStyleService.emptyStyle;
	public id: string;
	public post_code_placeholder: string;

	constructor(guid: GuidService,
	            private cta_style: TaCtaStyleService)
	{
		this.id = `address_${guid.Next}`;
		this.cta_style.get().subscribe(cta_style => {
			this.post_code_placeholder = cta_style.postcode.placeholder;
		});
	}

	@Input() set home_country_code(value: string)
	{
		if (!value) return;
		this.post_code_placeholder = TaCtaStyleService.getStyle(value).postcode.placeholder;
	}

	ngOnInit(): void
	{
	}
}
