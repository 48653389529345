import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { clone, each } from 'lodash';
import { map } from 'rxjs/operators';

import { CallParameter, CallService, Constants, EnkoraFetcher } from '../../../../shared';
import { TaContact } from '../interfaces';

@Injectable()
export class TaFindUsersAndContactsService extends EnkoraFetcher<TaContact[]> {

	protected params: CallParameter[] = [{ name : 'cta2/findUsersAndContacts' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public convertReplyToContact = (data: any): TaContact => {
		return {
			name            : data.name || (data.first_name + ' ' + data.last_name),
			user_id         : data.user_id,
			account_id      : data.account_id,
			contact_id      : data.contact_id,
			contact_type_id : data.contact_type_id,
			first_name      : data.first_name,
			last_name       : data.last_name,
			phone_number    : data.phone_number,
			email           : data.email
		};
	};

	preProcess(options?: any)
	{
		options = options || {};
		if (!options.name) return [];
		if (options.name && options.name.length <= 3) return [];

		const new_options = { user_type_id : Constants.UT_ACCESS_MANAGER, ...options };
		this.params = [{
			name    : 'cta2/findUsersAndContacts',
			content : [
				new_options
			]
		}];
	}

	postProcess(reply: any[]): TaContact[]
	{
		const result: TaContact[] = [];
		each(reply, row => {
			result.push(this.convertReplyToContact(row));
		});

		return result;
	}

	public findEmployee(first_name: string, last_name: string): Observable<TaContact[]>
	{
		const params = {
			'user_type_id' : Constants.UT_EMPLOYEE,
			'first_name'   : first_name,
			'last_name'    : last_name
		};

		// TODO: something strange is used instead of getOnce()????
		return this.call.make('cta2/findUsersAndContacts', [params]).pipe(
			map(data => {
				return this.postProcess(data);
			}));
	}

	public saveUser(contact: TaContact): Observable<TaContact>
	{
		const params = {
			'user_type_id'   : Constants.UT_ACCESS_MANAGER,
			'first_name'     : contact.first_name,
			'last_name'      : contact.last_name,
			'email'          : contact.email,
			'username'       : contact.username,
			'password'       : contact.password,
			'email_password' : contact.send_password
		};

		return this.call.make('common/createAccount', [false, false, params, true]).pipe(
			map(data => {
				const reply = { ...params, ...data };
				const new_contact = this.convertReplyToContact(reply);

				const new_value = clone(this.value) || [];
				new_value.push(new_contact);
				this.value = new_value;

				return new_contact;
			})
		);
	}
}
