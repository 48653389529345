import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface Location {
	location_id: string;
	name: string;
}

export interface Organization {
	organization_id: string;
	name: string;
	label: string;
	location_count: number;

	locations: Location[];
}

declare let Enkora: { tt: (a: string) => string };

@Injectable({
	providedIn : 'root'
})
export class TaOrganizationsLocationsService extends EnkoraFetcher<Organization[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getOrganizationsLocations' }];

	constructor(call: CallService)
	{
		super(call);
	}

	protected postProcess(reply: Organization[]): Organization[]
	{
		const sites = Enkora.tt('sites');
		each(reply, row => {
			row.label = `${row.name} (${row.location_count} ${sites})`;
		});

		return reply;
	}
}
