<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">

		<div class="row">

			<div class="col-lg-6">
				<div class="order-detail-form-box p-3">
					<h3 tt>Order details</h3>

					<div>
						<h5 tt>Order Info</h5>

						<cta-order-form-info [inputForm]="abstractControlToFormGroup(inputForm.get('order_info'))">
						</cta-order-form-info>
					</div>

					<div>
						<h5 tt>Customer info</h5>

						<cta-order-form-company-static [company]="inputForm.get('company').value">
						</cta-order-form-company-static>
					</div>
				</div>
			</div>

			<div class="col-lg-6">
				<div class="order-detail-form-box p-3">
					<h3 tt>Delivery details</h3>

					<div>
						<h5 tt>Delivery info</h5>

						<cta-order-form-delivery-details
							[inputForm]="abstractControlToFormGroup(inputForm.get('delivery_details'))">
						</cta-order-form-delivery-details>
					</div>
				</div>
			</div>

		</div>

		<!--		<div class="checkbox mt-3">
					<input id="send_email" type="checkbox">
					<label for="send_email" tt>
						Send email confirmation
					</label>
				</div>-->

	</div>
</ng-container>
