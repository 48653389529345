import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopResourceTagItem {
	tag_id: string;
	name: string;
	value: string;
	tag_group_id: string;
	tag_group_name: string;
	resource_ids: string[];
}

@Injectable()
export class ShopResourceTagListService extends EnkoraFetcher<ShopResourceTagItem[]> {

	public tag_map: { [tag_id: string]: ShopResourceTagItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationResourcesTags' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByResources(resource_ids: string[] = []): Observable<ShopResourceTagItem[]>
	{
		const resource_map = Helpers.arrayToMap(resource_ids);

		return this.getLazy().pipe(
			first(),
			map(resource_tags => {
				const filtered_list: ShopResourceTagItem[] = [];

				each(resource_tags, tag => {
					if (!tag.resource_ids) return;

					if (!find(tag.resource_ids, id => resource_map(id))) return;

					filtered_list.push(tag);
				});
				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopResourceTagItem[]): ShopResourceTagItem[]
	{
		this.tag_map = {};
		each(reply, item => {
			this.tag_map[item.tag_id] = item;
		});
		return reply;
	}
}
