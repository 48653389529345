<div class="container">
  <h1 class="inline-block" tt>Cache reset</h1>
  <p tt>Cache reset completed. You may navigate away from this page.</p>
  <p class="small" tt>
    However, if you arrived to this page without going to /index/cache_reset the cache is not cleaned.
    <br>You can reset the cache by pressing the "Reset cache button below".
  </p>
  <button (click)="$event.preventDefault(); goToBaseUrl()" tt>Go to startpage</button>
  <button class="lighter" (click)="$event.preventDefault(); goToCacheReset()" tt>Reset cache</button>
</div>
