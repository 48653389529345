import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { each } from 'lodash';

import { CallParameter, CallService, Constants, EnkoraFetcher, Helpers, ParameterService } from 'shared';

export interface ShopSalesGroupItem {
	sale_group_id: string;
	name: string;
	name_customer: string;
	description: string;
	is_hidden: boolean;
}

@Injectable()
export class ShopSaleGroupListService extends EnkoraFetcher<ShopSalesGroupItem[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2SaleGroups', content : [{ customer_names : true }] }];

	constructor(call: CallService,
	            private paramService: ParameterService)
	{
		super(call);

	}

	postProcess(reply: ShopSalesGroupItem[]): Observable<ShopSalesGroupItem[]>
	{
		return new Observable(observer => {
			this.paramService.getValue('hidden salegroups in webshop', Constants.NM_EN_WEBSHOP).subscribe(param => {
				const hidden_sale_groups = Helpers.split(param);

				const list: ShopSalesGroupItem[] = [];
				const sale_group_map = Helpers.arrayToMap(hidden_sale_groups, null, false, false);
				each(reply, item => {
					if (!item || !item.sale_group_id) {
						return;
					}

					item.is_hidden = sale_group_map(item.sale_group_id);
					list.push(item);
				});

				observer.next(list);
				observer.complete();
			});
		});

	}
}
