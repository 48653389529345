import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

export interface ConfirmParams {
	title: string;
	message: string;
	inner_message?: string;

	ok_button?: string;
	cancel_button?: string;

	input?: {
		label: string;
		value?: string;
	}

	before_return?: (ConfirmParams) => Observable<any>;
}

@Component({
	selector      : 'confirm-modal',
	templateUrl   : './confirm.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./confirm.modal.scss']
})
export class ConfirmModal implements OnInit {

	public in_process = false;

	constructor(public modal: NgbActiveModal)
	{
	}

	public static get default(): ConfirmParams
	{
		return {
			title         : 'Are you sure?',
			message       : '',
			inner_message : '',
			ok_button     : 'Confirm',
			cancel_button : 'Cancel',
			input         : null
		};
	}

	private _params: ConfirmParams = ConfirmModal.default;

	get params(): ConfirmParams
	{
		return this._params;
	}

	@Input()
	set params(value: ConfirmParams)
	{
		this._params = value;
	}

	ngOnInit(): void
	{
		this.in_process = false;
	}

	close(): void
	{
		if (!this._params.before_return) {
			this.modal.close(this._params);
			return;
		}

		this.in_process = true;
		this._params.before_return(this._params).subscribe(() => {
			this.modal.close(this._params);
		}, () => {
			this.in_process = false;
		});
	}
}
