import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CallService, EnkoraMessageService } from 'shared';

import { TaContactSupport } from '../../interfaces';

declare let Enkora: { tt: (a: string) => string };

@Component({
	selector    : 'cta-contact-support-modal',
	templateUrl : './contact-support-modal.component.html',
	styleUrls   : ['./contact-support-modal.component.scss']
})
export class ContactSupportModal implements OnInit {

	@Input() inputForm: FormGroup;
	@Input() user: TaContactSupport;
	submitted = true;

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService)
	{
	}

	ngOnInit(): void
	{
		this.inputForm.valueChanges.subscribe(() => {
			this.submitted = !this.inputForm.valid;
		});
	}

	sendContactSupport(): boolean
	{
		this.submitted = true;
		this.call.make('cta2/sendContactSupportEmail', [this.inputForm.value]).subscribe(
			() => {
				this.activeModal.dismiss();
				this.messageService.display('info', Enkora.tt('Your message sent successfully'));
			},
			() => {
				this.submitted = false;
				this.messageService.error();
			}
		);

		return false;
	}
}
