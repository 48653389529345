<ng-container *ngIf="inputForm">
	<div class="form-group row mt-3" [formGroup]="inputForm">
		<label for="organization" class="col-md-3 col-form-label text-input-label" tt>Organization</label>
		<div class="col-md-7">
			<ng-select [items]="companyList"
			           labelForId="organization"
			           (change)="changeCompany($event)"
			           bindLabel="name"
			           bindValue="organization_id"
			           [hideSelected]="true"
			           [searchable]="!readonly"
			           [clearable]="!readonly"
			           [loading]="findService.fetching"
			           [typeahead]="companyInput$"
			           [typeToSearchText]="'Type to search' | tt"
			           [notFoundText]="'No items found' | tt"
			           [loadingText]="'Loading' | tt"
			           formControlName="organization_id">

				<ng-template ng-label-tmp let-item="item">
					<div>{{item.name}} <span *ngIf="item.business_id">({{ item.business_id }})</span></div>
				</ng-template>

				<ng-template ng-option-tmp let-item="item">
					<div>{{item.name}} <span *ngIf="item.business_id">({{ item.business_id }})</span></div>
				</ng-template>
			</ng-select>
		</div>
		<div class="text-end col-md-2">
			<button class="btn btn-secondary btn-sm"
			        [disabled]="readonly || show_company"
			        (click)="showAddNewCompany()" tt>
				Add new
			</button>
		</div>
	</div>

	<div *ngIf="show_company" class="modal-form-adding pb-4">
		<div class="modal-form-adding__content pt-3">
			<div class="d-flex align-items-end mb-3">
				<h5 class="mb-0" tt> Add new company</h5>
				<button type="button" class="btn-modal-close close ms-auto" aria-label="Close"
				        (click)="hideNewCompanyForm()">
					<cta-icon-close></cta-icon-close>
				</button>
			</div>

			<cta-ta-form-company-info [readonly]="readonly"
			                          [inputForm]="newCompanyInfoForm">
			</cta-ta-form-company-info>

			<div class="text-end">
				<button class="btn btn-primary-custom btn-sm"
				        [disabled]="readonly || newCompanyInfoForm.invalid"
				        (click)="addNewCompany()" tt>
					Add new main contractor
				</button>
			</div>
		</div>
	</div>
</ng-container>
