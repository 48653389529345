import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { CallService } from "../../shared";

import * as _ from "lodash";

@Injectable()
export class FareProductsService {
	get fare_products() : Array<any> {
		return this._fare_products;
	}

	set fare_products(value : Array<any>) {
		this._is_loaded = true;
		this._fare_products = value;

		this.fare_products$.next(value);
		this.fare_products$.complete();
	}

	private _fare_products = [];
	private _is_loaded = false;
	private fare_products$ = new Subject<any[]>();

	constructor(private call : CallService) {
	}

	getFareProducts(params?) : Observable<any[]> {
		if (this._is_loaded) return of(this.fare_products);

		this.call.make('common/getFareProducts', [true, false, params]).subscribe(fare_products => {
			this.fare_products = _.values(fare_products);
		});

		return this.fare_products$;
	}
}
