import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CallService } from '../../shared/services';
import { LocationResourceDataService } from './location-resource-data.service';
import { UnitTimeSpan } from './reservation-time-span';

import * as _ from 'lodash';
import * as moment from 'moment/moment';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class CheckResourceConflictsService {

	constructor(private call : CallService,
	            private locationResourceDataService : LocationResourceDataService)
	{
	}

	checkConflicts(times : Array<UnitTimeSpan>) : Observable<any>
	{
		let calls : any = [];
		let index = 1;

		let merge_times : any = {};
		let unique_dates : any = {};

		_.forEach(times, (time : UnitTimeSpan) => {
			let key = [
				time.reservation_event_id,
				time.resource_id
			].join('-');

			if (!time.marked_deleted) {
				let unique_dates_key = [moment(time.time_start).unix(), moment(time.time_end).unix(), time.resource_id].join('-');
				if (!_.isObject(merge_times[key])) {
					unique_dates[unique_dates_key] = true;
					merge_times[key] = {
						reservation_event_id : time.reservation_event_id,
						dates                : [],
						resources            : [time.resource_id]
					};
					if (time.reservation_event_id) {
						merge_times[key].time_start = moment(time.time_start).format('YYYY-MM-DD HH:mm');
						merge_times[key].time_end = moment(time.time_end).format('YYYY-MM-DD HH:mm');
					} else {
						merge_times[key].dates.push({
							time_start : moment(time.time_start).format('YYYY-MM-DD HH:mm'),
							time_end   : moment(time.time_end).format('YYYY-MM-DD HH:mm')
						});
					}
				} else {
					merge_times[key].resources.push(time.resource_id);
					if (!unique_dates[unique_dates_key]) {
						merge_times[key].dates.push({
							time_start : moment(time.time_start).format('YYYY-MM-DD HH:mm'),
							time_end   : moment(time.time_end).format('YYYY-MM-DD HH:mm')
						});
						unique_dates[unique_dates_key] = true;
					}
				}
			}
		});
		_.forEach(Object.keys(merge_times), (key) => {
			let time = merge_times[key];
			calls.push({
				'call' : 'reservation.findConflictingEvents.' + index++,
				'args' : [{
					reservation_event_id : time.reservation_event_id,
					time_start           : time.time_start,
					time_end             : time.time_end,
					service_id           : this.locationResourceDataService.local_service_id
				}, _.uniq(time.resources), time.dates]
			});
		});

		return this.call.make('common/multicall', [calls]).pipe(flatMap(replies => {
			let result = {};
			let parents = [];
			_.forEach(replies, reply => {
				_.forEach(reply, (value, key) => {
					if (_.isArray(parents[key])) {
						parents[key] = _.uniq(parents[key].concat(value['parents']));
					} else {
						parents[key] = value['parents'];
					}
				});
			});
			_.forEach(replies, reply => {
				_.forEach(reply, (value : any, key) => {
					if (value.resources && parents[key].indexOf(value.resources) == -1) {
						if (!_.isEmpty(result[key]) && _.isArray(result[key].resources)) {
							if (_.isArray(value.resources)) {
								result[key].resources = result[key].resources.concat(value.resources);
							} else {
								result[key].resources.push(value.resources);
							}
							result[key].resources = _.uniq(result[key].resources);
						} else {
							result[key] = value;
						}
					}
				});
			});

			return of(result);
		}));
	}
}
