import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { find } from 'lodash';

import { Constants, ParameterService, Helpers, abstractControlToFormGroup } from 'shared';

@Component({
	selector    : 'cta-ta-form-country',
	templateUrl : './country.form.html',
	styleUrls   : ['./country.form.scss']
})
export class CountryForm implements OnInit {

	public selectedCountryCode: string;

	@Input() public readonly = false;
	@Input() public disableCountry = false;
	@Input() needAddress = true;

	public id: string;
	countries: { name: string, id: string }[]

	constructor(private params: ParameterService)
	{
		this.params.getTyped<{ name: string, id: string }[]>('iso_country_codes', Constants.NM_EN_CTA).subscribe(ret => {
			this.countries = Helpers.sortTranslatedCountries(ret);
		});
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this._inputForm.get('country_code').valueChanges.subscribe(value => {
			const found = find(this.countries, { id : value });
			this._inputForm.get('country_name').patchValue(found ? found.name : value);
			this.selectedCountryCode = value;
		});
	}

	ngOnInit(): void
	{
	}

	protected readonly abstractControlToFormGroup = abstractControlToFormGroup;
}
