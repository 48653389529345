import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';

export interface RawOrganizationData {
	organization_id: string;
	name: string;
	address: string;
	business_id: string;
	foreign_business_id: string;
	info: string;
	total_employees: string;
	employees_on_site: string;
	active_employees: number;
	last_event: string;
	tax_status: boolean;
	latest_tax_check_time: string;
	valid_until?: string;
	tax_status_ui?: number;
	organization_role_name?: string;
}

@Injectable({
	providedIn : 'root'
})
export class TaCompanyListService extends EnkoraFetcher<RawOrganizationData[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getOrganizationList' }];

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			}, () => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { location_id?: string } = {}): void
	{
		const location_id = options.location_id;

		Logger.log('Company list update: location_id= ' + location_id);

		this.params = [{
			name    : 'cta2/getOrganizationList',
			content : [
				{
					location_id
				}
			]
		}];
	}

	postProcess(reply: RawOrganizationData[]): RawOrganizationData[]
	{
		return reply;
	}

	signOutAllEmployees(location_id: string, company: RawOrganizationData): Observable<void>
	{
		return this.call.make<void>('cta2/signOutAllEmployees', [
			{
				location_id,
				organization_id : company.organization_id
			}
		]).pipe(tap(() => {
			this.reload();
		}));
	}

	deactivateAllAccessRights(location_id: string, company: RawOrganizationData): Observable<void>
	{
		return this.call.make('cta2/deactivateAllAccessRights', [
			{
				location_id,
				organization_id : company.organization_id
			}
		]);
	}

	public getEmployeeCountForLocationAndOrganization(location_id: string, organization_id: string): Observable<number>
	{
		return this.call.make('cta2/getEmployeeCountForLocationAndOrganization', [
			location_id,
			organization_id
		]);
	}
}
