import { Injectable } from '@angular/core';
import { each } from 'lodash';
import * as moment from 'moment';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ResourceLimits {
	max_events: number;
	max_capacity: number;
	max_events_starting: number;
}

export interface ResourceLimitsMap {
	[resource_id: string]: ResourceLimits;
}

export interface ResourceEventsCounters {
	events_count: number;
	starting_events_count: number;
}

export interface ResourceEventsCountersMap {
	[resource_id: string]: ResourceEventsCounters;
}

export interface ResourceReservationEvent {
	resource_ids: string[];
	is_for_sale: boolean;
	time_slots: any[][];
}

export interface ResourceReservationEventMap {
	[resource_id: string]: ResourceReservationEvent;
}

export interface ResourceSlot {
	id: string;
	time_start: moment.Moment;
	time_end: moment.Moment;
	free_count: number;
	free_capacity: { [resource_id: string]: number };
	free: number;
	total_count: number;
	prices: { [resource_id: string]: number };

	resource_ids: string[];
	resource_ids_free: string[];
	resource_ids_reserved: string[];
	resource_status: string[];

	resources_limits: ResourceLimitsMap;
	events_counters: ResourceEventsCountersMap;
	reservation_event_ids: ResourceReservationEventMap;

	is_modified_timeslot: boolean;
	is_past: boolean;
	is_owned: boolean;
	is_unconfirmed: boolean;
	is_reservable: boolean;
	is_available: boolean;
	is_on_sale: boolean;
}

@Injectable()
export class ShopResourceSlotsService extends EnkoraFetcher<ResourceSlot[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2ResourceSlots' }];

	constructor(call: CallService)
	{
		super(call);
	}

	isModifiedTimeslot = (item: ResourceSlot): boolean => {
		if (!item.reservation_event_ids) return false;
		let is_modified = false;

		const time_start = item.time_start.format('HH:mm');
		const time_end = item.time_end.format('HH:mm');

		each(item.reservation_event_ids, re => {
			if (!re.time_slots || re.time_slots.length > 1) {
				is_modified = true;
				return false;
			}

			const time_slot_time_start = moment(re.time_slots[0][0]).format('HH:mm');
			const time_slot_time_end = moment(re.time_slots[0][1]).format('HH:mm');

			if (time_start == time_slot_time_start || time_end == time_slot_time_end) {
				is_modified = true;
				return false;
			}
		});

		return is_modified;
	};

	preProcess(options?: { service_id: string, resource_ids: string[], location_ids: string[], tag_ids: string[], date: string }): void | any
	{
		if (!options || !options.resource_ids || !options.location_ids) return [];

		this.params = [{
			name    : 'reservation2/getR2ResourceSlots',
			content : [{
				service_id   : options.service_id,
				resource_ids : options.resource_ids.join(','),
				location_ids : options.location_ids.join(','),
				tag_ids      : options.tag_ids.join(','),
				date         : options.date
			}]
		}];
	}

	postProcess(reply: Record<string, any> []): ResourceSlot[]
	{
		const result: ResourceSlot[] = [];

		each(reply, row => {
			const time_start = moment(row.time_start);
			const time_end = moment(row.time_end);

			const new_row: ResourceSlot = {
				...(row as ResourceSlot),
				time_start,
				time_end,

				resource_status : [],
				is_past         : time_start.isBefore()
			};

			const free_map = Helpers.arrayToSimpleMap(new_row.resource_ids_free);
			const reserved_map = Helpers.arrayToSimpleMap(new_row.resource_ids_reserved);
			each(new_row.resource_ids, rid => new_row.resource_status.push(free_map(rid) && 'free' || reserved_map(rid) && 'reserved' || ''));

			new_row.is_modified_timeslot = this.isModifiedTimeslot(new_row);

			result.push(new_row);
		});

		return result;
	}
}
