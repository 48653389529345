import { Component, OnInit } from '@angular/core';

@Component({
  selector    : 'cta-cache-reset',
  templateUrl : './cache-cleaned.component.html',
  styleUrls   : ['./cache-cleaned.component.scss']
})
export class CacheCleanedComponent implements OnInit {

  window = (<any>window);

  constructor() { }

  ngOnInit() {
  }

  composeBaseUrl (): string {
    const w = this.window;
    return '//' + w.location.hostname + w.baseUrl;
  }

  goToBaseUrl () {
    this.window.location = this.composeBaseUrl()
  }

  goToCacheReset () {
    this.window.location = this.composeBaseUrl() + '/index/cache_reset'
  }

}
