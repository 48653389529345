import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalClickNotifierService {

	private clicks$ = new Subject<MouseEvent>();

	constructor()
	{
	}

	public get(): Subject<MouseEvent>
	{
		return this.clicks$;
	}

	public notifyClickEvent(event: MouseEvent): void
	{
		this.clicks$.next(event);
	}
}
