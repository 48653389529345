import { Injectable } from '@angular/core';

@Injectable()
export class DashboardProviderService {

	public quickLinks: {
		label: string;
		link: string;
		is_ng: boolean;
	}[] = [
		{
			label : 'Add employee',
			link  : 'app/cta/addemployee',
			is_ng : false
		},
		{
			label : 'Yard status',
			link  : 'app/cta/status/yard_status',
			is_ng : false
		},
		{
			label : 'Reports',
			link  : 'app/cta/status/access_events',
			is_ng : false
		},
		{
			label : 'Change password',
			link  : 'app2/password2',
			is_ng : false
		}
	];

	public sections = [
		{
			element : 'div',
			class   : 'contact-person-role',
			content : [],
			text    : `Your contact person`
		}, // ; section
		{
			element : 'div',
			class   : 'block-contact-person-content',
			content : [
				{
					element : 'div',
					class   : 'contact-person-name',
					text    : `Customer service`
				}, // ; item
				{
					element : 'div',
					class   : 'contact-person-info',
					text    : `Tel. 0800 171 414`,
					link    : {
						href : 'mailto:asiakaspalvelu@ramirent.fi',
						text : `asiakaspalvelu@ramirent.fi`
					}
				} // ; item
			], // ; content
			text    : null
		}, // ; section
		{
			element : 'div',
			class   : 'block-ramirent-contact',
			content : [
				{
					element : 'div',
					class   : 'ramirent-company-name',
					text    : `Ramirent Finland Oy`
				}, // ; item
				{
					element : 'p',
					class   : 'no-margin-bottom',
					text    : `PL 31, Tapulikaupungintie 37`
				}, // ; item
				{
					element : 'p',
					class   : 'no-margin-bottom',
					text    : `00751 Helsinki`
				}, // ; item
				{
					element : 'a',
					class   : 'btn-link blue',
					href    : 'http://www.ramirent.fi/portal/fi/yhteystiedot/',
					text    : `Details on Ramirent's website`
				}, // ; item
				{
					element : 'p',
					class   : 'no-margin-bottom',
					text    : `Tel. 020 750 200`
				}, // ; item
				{
					element : 'a',
					class   : 'btn-link blue',
					href    : 'mailto:etunimi.sukunimi@ramirent.fi',
					text    : `etunimi.sukunimi@ramirent.fi`
				} // ; item
			], // ; content
			text    : null
		} // ; section
	];

	constructor()
	{
	}
}
