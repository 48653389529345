import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ResourceEditorDataService {
	get data() : any {
		return this._data;
	}

	set data(value : any) {
		this._data = value;
		this.data$.next(value);
	}

	private _data : {
		id                ? : any,
		services_at_areas ? : any,
		resource_id       ? : any,
		lesson            ? : any,
		from              ? : any,
		until             ? : any,
		time_start        ? : any,
		time_end          ? : any
	} = {};

	public data$ = new BehaviorSubject<any>(this._data);

	constructor() {
	}

}
