import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

import { TaQualification } from '../../../shared';

export interface TagGroup {
	tag_group_id: string;
	name: string;
}

export interface Tag {
	tag_id: string;
	name: string;
	tag_group_id: string;
	external_id: string;
	type: string;
	ui_order: string;
	is_required: string;
	possible_values: string;
	description: string;
}

export interface TagRelations {
	qualifications: TaQualification[];
	is_permitted: boolean[];
}

@Injectable({
	providedIn : 'root'
})
export class TaQualificationsListService extends EnkoraFetcher<Tag[]> {

	public tag_map: Record<string, Tag> = {};
	public groups_map: { id: string, name: string }[] = [];
	protected params: CallParameter[] = [{ name : 'cta2/getAllowedTagGroups' }, { name : 'cta2/getAllowedTags' }];

	constructor(call: CallService)
	{
		super(call);
	}

	postProcess(reply: [TagGroup[], Tag[]]): Tag[]
	{
		const [groups, tags] = reply;

		this.tag_map = {};
		each(tags, tag => {
			this.tag_map[tag.tag_id] = tag;
		});

		each(groups, group => {
			this.groups_map.push({ id : group.tag_group_id, name : group.name });
		});

		return tags;
	}
}
