<div class="modal-header">
	<h3 class="modal-title" tt>{{title}}</h3>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="alert" [ngClass]="type_class" role="alert" tt>
		{{text}}
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn"
	        id="cancelButton"
	        (click)="activeModal.dismiss()">
		Ok
	</button>
</div>
