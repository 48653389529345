import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, Logger } from '../../../../../shared';
import { TaDataHelper, TaRole } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaOrganizationRoleListService extends EnkoraFetcher<TaRole[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getOrganizationRoles' }];

	constructor(call: CallService)
	{
		super(call);
	}

	preProcess(options?: any)
	{
		Logger.log('Organization Role list update');

		this.params = [{
			name    : 'cta2/getOrganizationRoles',
			content : [{}]
		}];
	}

	postProcess(reply: any, options)
	{
		return TaDataHelper.extractOrganizationRoles(reply);
	}

}
