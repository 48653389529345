import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, Logger } from 'shared';
import { TaEmployeeInfo } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaEmployeeListService extends EnkoraFetcher<TaEmployeeInfo[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getEmployees' }];

	constructor(call: CallService)
	{
		super(call);
	}

	preProcess(options: { location_id?: string } = {}): TaEmployeeInfo[]
	{
		options = options || {};
		const location_id = options.location_id;
		if (!location_id) return null;

		Logger.log('Employee list update: location_id= ' + location_id);

		this.params = [{
			name    : 'cta2/getEmployees',
			content : [{
				location_id
			}]
		}];
	}

	postProcess(reply: TaEmployeeInfo[]): TaEmployeeInfo[]
	{
		return reply;
	}

}
