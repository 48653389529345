import { Component, OnInit } from '@angular/core';

@Component({
  selector    : 'cta-icon-globe',
  templateUrl : './icon-globe.component.html',
  styleUrls   : ['./icon-globe.component.scss']
})
export class IconGlobeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
