import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment/moment';
import { Moment } from 'moment';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

interface ServerDateTime {
	serverDateTime: string;
}

@Injectable()
export class ShopServerTimeService extends EnkoraFetcher<ServerDateTime> {
	public latest_server_time: string;
	public server_time_difference_ms = 0;
	private time_start: Moment;
	private latency_ms = 0;
	protected params: CallParameter[] = [{ name : 'reservation/getServerTime' }];

	constructor(call: CallService)
	{
		super(call);
	}

	protected preProcess(content?: unknown): void | ServerDateTime
	{
		this.time_start = moment();
		return super.preProcess(content);
	}

	postProcess(reply: ServerDateTime): Observable<ServerDateTime>
	{
		return new Observable(observer => {
			if (!reply?.serverDateTime) {
				return;
			}

			const local_time = moment();
			this.latency_ms = local_time.diff(this.time_start, 'milliseconds') / 2;
			this.latest_server_time = reply.serverDateTime;
			const server_time_moment = moment(reply.serverDateTime, 'YYYY-MM-DD HH:mm:ss');
			this.server_time_difference_ms = (local_time.clone().subtract(this.latency_ms, 'milliseconds'))
															.diff(server_time_moment, 'milliseconds');

			observer.next(reply);
			observer.complete();
		});
	}

	/**
	 * Get server time based on the offset (current time minus server timestamp)
	 * @param delay Add delay to help keeping client time slightly behind of server time
	 */
	public getServerTime(delay = 1000): Moment
	{
		if (this.server_time_difference_ms <= 0) {
			return moment().add((this.server_time_difference_ms + delay) * -1, 'milliseconds');
		}

		return moment().subtract(this.server_time_difference_ms + delay, 'milliseconds');
	}

}
