import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

declare let Enkora: { tt: (a: string) => string };

export type EnkoraMessageType = 'error' | 'success' | 'warn' | 'info';

export interface ErrorMessageBody {
	severity: EnkoraMessageType;
	summary: string;
	detail: string;
	life: number;
}

@Injectable()
export class EnkoraMessageService {
	public value$: Subject<ErrorMessageBody> = new Subject<ErrorMessageBody>();

	constructor(private messageService: MessageService)
	{
	}

	public display(severity: EnkoraMessageType = 'info', summary = '', detail = ''): void
	{
		const msgBody = {
			severity : severity,
			summary  : summary,
			detail   : detail,
			life     : 5000
		};

		this.messageService.add(msgBody);
		this.value$.next(msgBody);
	}

	public error(reason = '', call = '', title = 'Something went wrong'): void
	{
		this.display(
			'error',
			Enkora.tt(title),
			`${call ? `${call}: ` : ''}${Enkora.tt(reason)}`
		);
	}

	public success(reason = '', call = '', title = 'Success'): void
	{
		this.display(
			'success',
			Enkora.tt(title),
			(call ? call + ': ' : '') + Enkora.tt(reason)
		);
	}

	public info(reason = '', call = '', title = 'Information'): void
	{
		this.display(
			'info',
			Enkora.tt(title),
			(call ? call + ': ' : '') + Enkora.tt(reason)
		);
	}

	clear(): void
	{
		this.messageService.clear();
	}

}
