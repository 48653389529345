import { Component, OnInit } from '@angular/core';

@Component({
  selector    : 'cta-icon-close',
  templateUrl : './icon-close.component.html',
  styleUrls   : ['./icon-close.component.scss']
})
export class IconCloseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
