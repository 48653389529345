import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

type Class = new (...args: unknown[]) => unknown;

declare class NgbModalTyped<T> extends NgbModalRef {
	get componentInstance(): T;
}

@Injectable({
	providedIn : 'root'
})
export class ModalOpenerService {
	constructor(private modalService: NgbModal)
	{
	}

	openSmModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			size        : 'sm',
			windowClass : 'modal-size-sm'
		});
	}

	openLgModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			size : 'lg'
		});
	}

	openLgModalCenter<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			size     : 'lg',
			centered : true
		});
	}

	openSmModalCenter<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			size     : 'sm',
			centered : true
		});
	}

	openModalCenter<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			centered : true
		});
	}

	openStaticSmModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			backdrop    : 'static',
			size        : 'sm',
			keyboard    : false,
			windowClass : 'modal-size-sm'
		});
	}

	openStaticSmModalCenter<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			backdrop    : 'static',
			size        : 'sm',
			centered    : true,
			keyboard    : false,
			windowClass : 'modal-size-sm'
		});
	}

	openStaticLgModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			backdrop : 'static',
			size     : 'lg',
			keyboard : false
		});
	}

	openShopModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			centered : true,
			size     : 'shop'
		});
	}

	openStaticShopModal<T extends Class>(content: T, options = {}): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			...options,
			...{
				backdrop : 'static',
				centered : true,
				size     : 'shop',
			}
		});
	}

	openModal<T extends Class>(content: T): NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {});
	}

	openModalSmPadding<T extends Class>(content: T) : NgbModalTyped<InstanceType<T>>
	{
		return this.modalService.open(content, {
			windowClass : 'modal-size-sm'
		});
	}

	dismissAll(): void
	{
		return this.modalService.dismissAll();
	}

}
