<ng-container *ngIf="!reservationEventGroupsService.is_loading && reservationEventGroupsService.value?.length">
	<button class="pull-right btn btn-small btn-primary"
	        [class.active]="show_past"
	        (click)="togglePast()"
	        *ngIf="past_count> 0 && past_allowed"
	        tt>
		Include past items
	</button>

	<h3 tt>Courses</h3>
	<!-- Check if there are only past events && past display mode is not set-->

	<ng-container *ngIf="!reservation_event_groups || !reservation_event_groups.length ||
			!show_past && past_count>0 && past_count==reservation_event_groups.length; else has_content">
		<p class="well" tt>No active or upcoming courses.</p>
	</ng-container>

	<ng-template #has_content>
		<table class="user-event-groups table table-striped table-condensed" style="width: 100%;">
			<thead>
			<tr>
				<th tt>Date</th>
				<th tt>Name</th>
				<th class="ue-location" tt>Location</th>
				<th class="ue-instructor" tt>Reserver</th>
				<th class="ue-instructor" tt>Owner</th>
				<th tt>Status</th>
				<th class="right"></th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let reg of reservation_event_groups; let i=index">
				<tr *ngIf="!reg.is_past || show_past">
					<td> {{ reg.start | amDateFormat:"DD.MM." }} - {{ reg.end | amDateFormat:"DD.MM." }}</td>
					<td>{{ reg.name }}</td>
					<td class="ue-location">{{ reg.location }}</td>
					<td class="ue-location">{{ reg.reserving_user_name }}</td>
					<td class="ue-location">{{ reg.instructors }}</td>
					<td>
						<ng-container *ngIf="reg.is_past; else not_past">
							<span class="badge badge-secondary" tt>Finished</span>
						</ng-container>
						<ng-template #not_past>
							<ng-container [ngSwitch]="reg.reservation_status_id">
								<span *ngSwitchCase="constants.RES_QUEUE || constants.RES_CONFIRMED_QUEUE" class="badge badge-warning" tt>
									Queue
								</span>

								<span *ngSwitchCase="constants.RES_RESERVED || constants.RES_CONFIRMED_RESERVED" class="badge badge-success" tt>
									Reserved
								</span>

								<span *ngSwitchCase="constants.RES_CANCELLED" class="badge badge-secondary" tt>
									Cancelled
								</span>

								<span *ngSwitchCase="constants.RES_ADMITTED" class="badge badge-success" tt>
									Admitted
								</span>

								<span *ngSwitchCase="constants.RES_UNCONFIRMED" class="badge badge-secondary" tt>
									Unconfirmed
								</span>

								<span *ngSwitchCase="constants.RES_UNCONFIRMED_QUEUE" class="badge badge-secondary" tt>
									Unconfirmed queue
								</span>

								<span *ngSwitchCase="constants.RES_LATE" class="badge badge-danger" tt>
									Late
								</span>
							</ng-container>
						</ng-template>
					</td>
					<td class="right">
						<button (click)="openCourse(i)" class="btn btn-info btn-mini showEventGroup" tt>
							Show
						</button>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	</ng-template>
</ng-container>
