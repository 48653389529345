<div class="modal-header" #modalContent>
	<span class="aria-focus" tabindex="0"></span>
	<h3 tabindex="0" tt>Retrieve forgotten password</h3>
	<button type="button" class="close" data-dismiss="modal" [attr.aria-label]="'Close' | tt"
	        (click)="activeModal.dismiss()">&times;
	</button>
</div>

<div class="modal-body">
	<p class="lead" tabindex="0" tt>Please submit your email to which we will send the login instruction.</p>

	<shop-alert-message [alert]="alert"></shop-alert-message>

	<div class="form-group row">
		<label class="offset-1 col-3 col-form-label text-end" for="inputEmail" tt>Email</label>

		<div class="col-8">
			<div class="input-group input-group-sm">
				<input type="text" id="inputEmail" class="form-control" name="email" [(ngModel)]="email">
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn" id="backButton" (click)="back()" tt>
		Back to login form
	</button>

	<button type="submit"
	        class="btn btn-primary"
	        id="retrieveButton"
	        (click)="retrieve()"
	        [disabled]="is_retrieving" tt>
		Retrieve
	</button>
</div>
