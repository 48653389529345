import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { SelectItem } from 'primeng/api';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from '../../shared';

declare let Enkora: { tt: (a: string) => string };

export interface CourseTag {
	tag_id : number;
	name : string;
}

@Injectable()
export class CourseTagsService extends EnkoraFetcher<CourseTag[]> {

	params : CallParameter[] = [{name : 'reservation/getCourseTargetGroupsTags'}];
	label_value : SelectItem[] = [];

	constructor(call : CallService)
	{
		super(call);
	}

	protected postProcess(reply : CourseTag[])
	{
		this.label_value = [{label : '(' + Enkora.tt('Select tag filter') + ')', value : null}];
		each(reply, tag => this.label_value.push({
			label : Helpers.translateKey(`tag ${tag.tag_id} name`, tag.name, true),
			value : tag.tag_id
		}));

		return reply;
	}
}
