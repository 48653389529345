import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopResourceItem {
	resource_id: string;
	name: string;
	description: string;
	link: string;
	is_pay_later: number;
	max_events: number;
	max_capacity: number;
	max_events_starting: number;
	location_ids: string[];
	building_name: string;
	floor_name: string;
	building_id: number;
	floor_id: number;
	service_ids: string[];
	tags:
		{
			tag_id: string;
			name: string;
			value: any
		}[];
	reservation_event_ids: string[];
	access_area_ids: string[];
	timetable: string[];
	fare_products: string[];
}

@Injectable()
export class ShopResourceListService extends EnkoraFetcher<ShopResourceItem[]> {

	public resource_map: { [resource_id: string]: ShopResourceItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationResources' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByLocations(service_id  = '', location_ids: string[] = []): Observable<ShopResourceItem[]>
	{
		const location_map = Helpers.arrayToMap(location_ids);

		return this.getLazy().pipe(
			first(),
			map(resource_list => {
				const filtered_list: ShopResourceItem[] = [];

				each(resource_list, resource => {
					if (!resource.service_ids || !resource.location_ids) return;

					if (!find(resource.service_ids, id => id == service_id)) return;
					if (!find(resource.location_ids, id => location_map(id))) return;

					filtered_list.push(resource);
				});
				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopResourceItem[]): ShopResourceItem[]
	{
		this.resource_map = {};
		each(reply, item => {
			this.resource_map[item.resource_id] = item;
		});

		return reply;
	}
}
