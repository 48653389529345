import { Component, Input, OnInit } from '@angular/core';
import { each, keyBy } from 'lodash';

import { AuthenticationService, Constants, Helpers, ModalOpenerService, ParameterService } from 'shared';

import { Reservation, ShopReservationListService } from '../../services';

import { UserReservationEventsDetailsModal } from './details/details.modal';

interface ReservationView extends Reservation {
	reservation_status_label: 'success' | 'warning' | 'nolabel';
	reservation_status_name: string;
}

const status_label: { [key: number]: 'success' | 'warning' | 'nolabel' } = {
	[Constants.RES_QUEUE]              : 'warning',
	[Constants.RES_RESERVED]           : 'success',
	[Constants.RES_CONFIRMED_QUEUE]    : 'warning',
	[Constants.RES_CONFIRMED_RESERVED] : 'success',
	[Constants.RES_ADMITTED]           : 'success',
	[Constants.RES_CANCELLED]          : 'nolabel',
	[Constants.RES_UNCONFIRMED]        : 'warning',
	[Constants.RES_UNCONFIRMED_QUEUE]  : 'nolabel',
	[Constants.RES_LATE]               : 'nolabel'
};

@Component({
	selector    : 'shop-user-reservation-events',
	templateUrl : './reservation-events.component.html',
	styleUrls   : ['./reservation-events.component.scss']
})
export class UserReservationEventsComponent implements OnInit {

	@Input() past_allowed = true;
	@Input() all_accounts = false;

	show_past = false;
	past_count = 0;
	future_count = 0;
	hide_instructors = false;
	hide_name = false;
	hide_owner = false;
	hide_status = false;
	hide_service_ids: Record<string, string> = {};
	max_reserve: number = null;
	public reservation_views: ReservationView[] = [];

	constructor(public auth: AuthenticationService,
	            private params: ParameterService,
	            public reservation_list: ShopReservationListService,
	            private modalService: ModalOpenerService)
	{
	}

	ngOnInit(): void
	{
		this.params.getNumberValue('maximum reservations', Constants.NM_EN_RESERVATIONS).subscribe(p => this.max_reserve = p);
		this.params.getValues([
				'webshop user events hide instructor',
				'webshop user events hide reservation name',
				'webshop user events hide reservation owner',
				'webshop user events hide reservation status',
				'webshop user events hide reservations for service ids'
			], Constants.NM_EN_WEBSHOP
		).subscribe(value => {
			let hide_service_ids: string | number;
			[this.hide_instructors, this.hide_name, this.hide_owner, this.hide_status, hide_service_ids] = value;
			this.hide_service_ids = keyBy(Helpers.split(hide_service_ids));
		});

		this.reservation_list.get().subscribe(() => {
			const reservation_events = this.reservation_list.filterByAccount(this.auth.value.account_id, false);
			this.loadReservationEvents(reservation_events);
		});
	}

	public togglePast(): void
	{
		this.show_past = !this.show_past;
	}

	public showEvent(reservation: ReservationView): void
	{
		this.reservation_list.getReservation(reservation.reservation_id, reservation.reservation_event_id).subscribe(
			details => {
				const modalRef = this.modalService.openModalCenter(UserReservationEventsDetailsModal);
				modalRef.componentInstance.details = details;

				modalRef.result.then(() => {
				}, () => {
				});
			});
	}

	private loadReservationEvents(reservations: Reservation[])
	{
		const reservation_views: ReservationView[] = [];

		let past_count = 0;
		each(reservations, reservation => {
			if (this.hide_service_ids[reservation.service_id]) return;

			if (reservation.is_past) past_count++;
			reservation_views.push({
				...reservation,
				reservation_status_name  : Constants.RESERVATION_STATUS[reservation.reservation_status_id],
				reservation_status_label : status_label[reservation.reservation_status_id]
			});
		});

		this.reservation_views = reservation_views;
		this.past_count = past_count;
	}
}
