import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector      : 'app-error-404',
	templateUrl   : './error-404.component.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./error-404.component.scss']
})
export class Error404Component implements OnInit {

	constructor()
	{
	}

	ngOnInit(): void
	{
	}

	back(): void
	{
		window.history.back();
	}
}
