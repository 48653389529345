import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { clone } from 'lodash';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { CallParameter, EnkoraFetcher, Logger } from '../../../misc';
import { CallService } from '../../call.service';
import { AuthenticationService } from '../../authentication';

export interface LocationItem {
	location_id: string;
	name: string;
	description: string;
	organization_id: string;
	organization_name: string;
	address: string;
	code: string;
	external_id: string;
}

export interface LocationsList {
	all: LocationItem[];
	current: LocationItem;
}

@Injectable()
export class LocationService extends EnkoraFetcher<LocationsList> {

	protected params: CallParameter[] = [{ name : 'cta/getAllowedLocations' }, { name : 'cta/getLastLocation' }];

	constructor(call: CallService,
	            private auth: AuthenticationService,
	            public router: Router)
	{
		super(call);
		this.auth.get().subscribe(() => this.get(true));
	}

	getLocationId(forceLoad = false): Observable<string>
	{
		return this.get(forceLoad).pipe(
			map((location) => location.current ? location.current.location_id : null)
		);
	}

	getLocation(forceLoad = false): Observable<LocationItem | null>
	{
		return this.get(forceLoad).pipe(
			map((location: LocationsList) => location.current ? location.current : null)
		);
	}

	set(location: LocationItem): void
	{
		if (location) {
			this.is_loaded = false;
			Logger.log('📌', 'setCurrentLocation: ', location);
			// If new location is selected then we will send it to server
			if (location && (!this.value.current || location.location_id !== this.value.current.location_id)) {
				this.call.make('cta/setLastLocation', [location.location_id]).subscribe(
					value => {
						const new_location: LocationsList = clone(this.value);
						new_location.current = location;
						this.value = new_location;
						Logger.log('📌', 'Server responded to call: cta/setLastLocation with:', value);
					},
					error => {
						console.error('📌', 'Error base-provider, setCurrentLocation', error);
					}, () => {
						this.is_loaded = true;
					}
				);
			}
		}
	}

	protected preProcess(): LocationsList
	{
		if (!this.auth.value) {
			return {
				all     : [],
				current : null
			};
		}
	}

	protected postProcess(reply: [all: LocationItem[], content_id: string]): LocationsList
	{
		const [all, current_id] = reply;
		const current = all.find(value => value.location_id == current_id);

		return {
			all     : all,
			current : current
		};
	}
}
