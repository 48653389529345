import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService, CallService, Helpers } from 'shared';

import { LoginState } from '../../services';
import { AlertCode, AlertType } from '../../alerts';

@Component({
	selector    : 'shop-reminder-modal',
	templateUrl : './reminder.modal.html',
	styleUrls   : ['./reminder.modal.scss']
})
export class ShopReminderModal implements OnInit, AfterViewInit {
	@ViewChild('modalContent') modalContent!: ElementRef;
	@Input() public email = '';
	public alert: AlertType = null;
	public is_retrieving = false;

	constructor(public activeModal: NgbActiveModal,
	            public auth: AuthenticationService,
	            public call: CallService,
	) {
	}

	ngOnInit(): void
	{
	}

	public back(): void
	{
		this.activeModal.close({ state : LoginState.Login });
	}

	public retrieve(): void
	{
		this.is_retrieving = true;
		this.call.make('user/senduserinfo', [{ email : this.email }]).subscribe(
			() => {
				this.is_retrieving = false;
				this.alert = {
					code    : AlertCode.Success,
					title   : 'Action successful',
					message : 'A password reminder has been sent to %s.'
				};
			},
			(error) => {
				this.is_retrieving = false;
				this.alert = {
					code    : AlertCode.Error,
					title   : 'Error retrieving password',
					message : error
				};
			}
		);
	}

	ngAfterViewInit(): void
	{
		setTimeout(() => { 
			Helpers.setElementFocus(this.modalContent?.nativeElement, '.aria-focus');
		}, 0);
	}
}
