import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { clone } from 'lodash';

import { CallParameter, Constants, EnkoraFetcher, Logger, User } from '../../misc';
import { CallService } from '../call.service';
import { EnkoraMessageService } from '../enkora-message';
import { ParameterService } from '../parameter.service';
import { TranslationsService } from '../translations.service';

declare let Enkora: { tt: (a: string) => string };

export interface CustomerLoginParams {
	get_linked_accounts: boolean;
	return_getcurrentuser: boolean;
	return_account_tags: boolean;
}

@Injectable()
export class AuthenticationService extends EnkoraFetcher<User> {
	public previous_user_id = null;
	protected params: CallParameter[] = [{ name : 'user/getCurrentUser', content : [true, true] }];

	constructor(call: CallService,
	            private parameterService: ParameterService,
	            private translationsService: TranslationsService,
	            private messageService: EnkoraMessageService)
	{
		super(call);

		this.get();
	}

	public isAuthenticated(): boolean
	{
		return !!this.value && !!this.value.user_id;
	}

	public getRedirectLogin(username: string): Observable<{ redirect: boolean, href: string }>
	{
		return this.call.make('common/getRedirectLogin', [username]);
	}

	public login(username: string, password: string): Observable<User>
	{
		return this.loginPostProcess(
			this.call.make('common/login', [username, password])
		);
	}

	public loginWithEmail(email: string, password: string): Observable<User>
	{
		return this.loginPostProcess(
			this.call.make('common/loginWithEmail', [{
				email,
				password
			}])
		);
	}

	public customerLogin(email: string, password: string, options: Partial<CustomerLoginParams>): Observable<User>
	{
		return this.loginPostProcess(
			this.call.make('common/customerlogin', [
				{
					email,
					password
				}, options
			])
		);
	}

	public logout(show_notification = false, show_redirection_message = true): Subject<boolean>
	{
		if (show_notification) {
			this.messageService.display(
				'info',
				Enkora.tt('Logging out'),
				show_redirection_message ? Enkora.tt('Redirecting to login page.') : Enkora.tt('Stand by...')
			);
		}

		const subject = new Subject<boolean>();
		this.loginPostProcess(this.call.make('common/logout')).subscribe(
			() => {
				subject.next(true);
				subject.complete();
			},
			error => {
				// Network error or other errors
				Logger.log('🔑', 'AuthenticationService : logout() - error', error);
				subject.error(error);
			}
		);

		return subject;
	}

	public isAccessManager(): boolean
	{
		return this.value &&
			(
				this.value.user_type_id == Constants.UT_ADMINISTRATOR
				|| this.value.user_type_id == Constants.UT_ACCESS_MANAGER
			);
	}

	public isSimplyAccessManager(): boolean
	{
		return this.value && (this.value.user_type_id == Constants.UT_ACCESS_MANAGER);
	}

	public isAdmin(): boolean
	{
		return this.value && (this.value.user_type_id == Constants.UT_ADMINISTRATOR);
	}

	protected preProcess(): void
	{
		Logger.log('user/getCurrentUser called');
	}

	protected postProcess(reply: User): User
	{
		if (!this._value && !reply) return null;

		this.previous_user_id = this.value ? this.value.user_id : null;
		// sometimes we really want to reload user with this.auth.get(true)...
		// if (this._value && reply && this._value.user_id == reply.user_id) return;

		Logger.log('🔑', 'Changing current user : from', clone(this._value), 'to', clone(reply));

		return !reply ? null : reply;
	}

	private loginPostProcess<T>(login$: Observable<T>): Observable<User>
	{
		return login$.pipe(
			switchMap(data => {
				if (!data) return throwError(null);

				return combineLatest([
					this.parameterService.reload(),
					this.translationsService.reload()
				]).pipe(first());
			}),
			switchMap(() => this.reload().pipe(first()))
		);
	}
}
