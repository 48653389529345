import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ReservationEventGroupDataService {

	get reservation_event_group_data() : any
	{
		return this._reservation_event_group_data;
	}

	set reservation_event_group_data(value : any)
	{
		this._reservation_event_group_data = value;
		this.reservation_event_group_data$.next(value);
	}

	private _reservation_event_group_data = null;
	public reservation_event_group_data$ = new BehaviorSubject<any>(null);

	on_reservation_group_data_saved = new BehaviorSubject<any>(null);

	reservationGroupDataSaved(value)
	{
		this.on_reservation_group_data_saved.next(value);
	}

	getReservationGroupDataSavedEmitter()
	{
		return this.on_reservation_group_data_saved;
	}

	constructor()
	{
	}
}
