import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { Helpers, Logger } from '../../../../../shared';
import { TaAgreement } from '../..';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector    : 'cta-ta-form-company-agreement',
	templateUrl : './company-agreement.form.html',
	styleUrls   : ['./company-agreement.form.scss']
})
export class CompanyAgreementForm implements OnInit {
	@Input() public readonly = false;

	private _initUserIdLength: number;
	private _has_employees: boolean;
	private _inputForm: FormGroup;
	public show_alert_change_length: boolean;
	get inputForm(): FormGroup
	{
		return this._inputForm;
	}
	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this._initUserIdLength = this._inputForm.get('personal_id_length').value;
		this._has_employees = this._inputForm.get('has_employees').value;
	}

	@Input()
	public set validity(value: { upper_bound: number, lower_bound: number })
	{
		if (!value) return;

		this.lower_bound = value.lower_bound || 0;
		this.upper_bound = value.upper_bound || 0;
	}

	lower_bound = 0;
	upper_bound = 0;

	start_min = null;
	end_max = null;
	end_min = null;

	saved_agreement: TaAgreement = { start : null, end : null };

	constructor()
	{
	}

	ngOnInit(): void
	{
		if (this.lower_bound)
			this.end_min = this.start_min = Helpers.momentToNgbDate(
				Helpers.now.clone().subtract(this.lower_bound, 'months')
			);

		if (this.upper_bound)
			this.end_max = Helpers.momentToNgbDate(moment().add(this.upper_bound, 'months'));
	}

	checkIdLength(value: number): void
	{
		this.show_alert_change_length = this._has_employees && this._initUserIdLength != value
	}
}
