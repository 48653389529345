import { Pipe, PipeTransform } from '@angular/core';

const { baseUrl } = window as { baseUrl?: string };

@Pipe({
	name : 'apiurl'
})
export class ApiurlPipe implements PipeTransform {
	baseUrl = baseUrl;

	transform(url: string): string
	{
		if (url && url.match(/^http[s]*:\/\//)) {
			return url;
		}

		return (this.baseUrl || '') + '/' + (url || '');
	}
}
