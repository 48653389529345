<div class="modal-header">
	<h2 class="no-margin-bottom">
		{{ (dataHolder.is_add_new_employee ? 'Add a new employee' : 'Edit tokens') | tt }}
	</h2>
	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.dismiss()">
		<cta-icon-close></cta-icon-close>
	</button>
</div>

<div *appLoading="is_searching"></div>

<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="5">
	</cta-ta-add-employee-progressbar>

	<p class="mb-5" tt>
		Time and attendance tokens modal description
	</p>

	<h4>
		{{ (dataHolder.is_add_new_employee ? 'Add employee tokens' : 'Edit employee tokens') | tt }}
	</h4>

	<ng-container *ngIf="!prohibit_am_from_adding_tokens; else as_note">
		<cta-ta-token-input [tax_number]="tax_number"
		                    [companies]="companies"
		                    [default_organization]="default_organization"
		                    (statusUpdate)="updateToken($event)"
		                    (searchingUpdate)="handleSearching($event)">
		</cta-ta-token-input>
	</ng-container>
	<ng-template #as_note>
		<p class="mb-5" tt>
			Please note that access managers are not allowed to add tokens
		</p>
	</ng-template>

	<cta-ta-token-list class="mt-3"
	                   [can_edit]="true"
	                   [(tokens)]="tokens"
	                   (tokensChange)="handleTokensChange($event)">
	</cta-ta-token-list>

	<div class="alert alert-danger mt-1 mb-0" *ngIf="tokenStatus.type === InputTokenStatus.Error">
		{{ tokenStatus.message | tt }}
	</div>

	<div class="alert alert-danger" *ngIf="token_messages.length">
		<strong tt>Save unsuccessful</strong>
		<p *ngFor="let token_message of token_messages">
			<span tt>Token</span> {{token_message.token_alias}}
			<span tt>(organization </span> {{token_message.new_organization_id}})
			<span tt>for user_id</span> {{user_id}}
			<span tt>is already used for another employee (account </span> {{token_message.current_account_alias}})
			<span tt>organization</span> {{token_message.current_organization_alias}}
		</p>
		<div class="checkbox">
			<input type="checkbox" [(ngModel)]="rewrite_token" class="form-control" id="change_token_organization">
			<label for="change_token_organization" tt>
				Click to change token organization
			</label>
		</div>
	</div>

</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary me-auto" *ngIf="!standalone" (click)="back()" tt>Previous</button>

	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" tt>Close</button>

	<button type="button"
	        class="btn btn-primary-custom"
	        *ngIf="save_message || !standalone"
	        [disabled]="!is_valid && !no_changes || tokenStatus.type === InputTokenStatus.InProgress"
	        (click)="confirm()">
		{{save_message + (standalone ? 'close' : 'continue') | titlecase | tt}}
	</button>
</div>
