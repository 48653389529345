import { BehaviorSubject } from 'rxjs';

import {
	PermissionGroupModel,
	TaCompany,
	TaEmployee,
	TaEmployment,
	TaEmployments,
	TaPersonalInfo,
	TaQualification,
	TaUserIdentifier,
	Token
} from '../../interfaces';

import { TaDataHelper } from './ta-data-helper.class';

export class TaDataHolder {

	public employee$ = new BehaviorSubject<TaEmployee>(null);
	public employment$ = new BehaviorSubject<TaEmployment>(null);

	public groups: PermissionGroupModel[] = [];
	public permission_group: PermissionGroupModel = TaDataHelper.emptyPermissionGroup;
	public is_add_new_employee = false;
	public is_machine: boolean;

	permission_groups$ = new BehaviorSubject<PermissionGroupModel[]>([]);
	qualifications$ = new BehaviorSubject<TaQualification[]>([]);
	public personal_info$ = new BehaviorSubject<TaPersonalInfo>(null);
	private company$ = new BehaviorSubject<TaCompany>(null);
	public user_identifier$ = new BehaviorSubject<TaUserIdentifier>(null);

	constructor()
	{
		this.reset();

		this.employee$.subscribe(employee => {
			this.permission_groups = employee.groups;
			this.personal_info = employee.personal_info;
		});
	}

	private _employee: TaEmployee;

	get employee(): TaEmployee
	{
		return this._employee;
	}

	set employee(value: TaEmployee)
	{
		this._employee = value;
		this.employee$.next(value);
	}

	private _employment: TaEmployment;

	get employment(): TaEmployment
	{
		return this._employment;
	}

	set employment(value: TaEmployment)
	{
		this._employment = value;
		this.employment$.next(value);
	}

	private _user_identifier: TaUserIdentifier;

	get user_identifier(): TaUserIdentifier
	{
		return this._user_identifier;
	}

	set user_identifier(value: TaUserIdentifier)
	{
		this._user_identifier = value;
		this.user_identifier$.next(value);
	}

	private _company: TaCompany;

	get company(): TaCompany
	{
		return this._company;
	}

	set company(input_company: TaCompany)
	{
		if (input_company) this._company = input_company;
		else this._company = TaDataHelper.emptyCompany;

		const employment = TaDataHelper.emptyEmployment;
		employment.company = this._company;

		this.employment = employment;
		this.company$.next(this.company);
	}

	get permission_groups(): PermissionGroupModel[]
	{
		return this._employee.groups;
	}

	set permission_groups(groups: PermissionGroupModel[])
	{
		this._employee.groups = groups;
		this.permission_groups$.next(groups);
	}

	get qualifications(): TaQualification[]
	{
		return this._employee.qualifications;
	}

	set qualifications(qualifications: TaQualification[])
	{
		this._employee.qualifications = qualifications;
		this.qualifications$.next(qualifications);
	}

	get user_id(): string
	{
		if (this._employee.personal_info) {
			return this._employee.personal_info.user_id;
		}

		return null;
	}

	get personal_info(): TaPersonalInfo
	{
		return this._employee.personal_info;
	}

	set personal_info(value: TaPersonalInfo)
	{
		this._employee.personal_info = value;
		this.personal_info$.next(value);
	}

	get employments(): TaEmployments
	{
		return this._employee.employments;
	}

	set employments(value: TaEmployments)
	{
		this._employee.employments = value;
	}

	get tokens(): Token[]
	{
		return this.employee.tokens;
	}

	reset(): void
	{
		this.employee = TaDataHelper.emptyEmployee;
		this._company = TaDataHelper.emptyCompany;
		this.employment = TaDataHelper.emptyEmployment;
	}

	resetEmployee(input_employee: TaEmployee = null): void
	{
		if (!input_employee) {
			this.employee = TaDataHelper.emptyEmployee;
		} else this.employee = input_employee;
	}

	resetEmployment(input_employee: TaEmployment = null): void
	{
		if (!input_employee) this.employment = TaDataHelper.emptyEmployment;
		else this.employment = input_employee;
	}

	resetPermissionGroup(group = null): void
	{
		if (group) this.permission_group = group;
		else this.permission_group = TaDataHelper.emptyPermissionGroup;
	}
}
