<ng-container *ngIf="inputForm">
	<form [formGroup]="inputForm" novalidate>
		<!-- Personal information -->

		<h5 class="mt-5" tt>Personal information</h5>

		<div class="form-group row mb-3">
			<div class="col-md-9 offset-md-3" *ngIf="picture_filename && picture_filename.length">
				<img class="profile-photo" [src]="'picture/' + picture_filename | apiurl">
			</div>
		</div>
		<div class="form-group row" *ngIf="!is_visitor || cta_style.value.code != CtaStyleCode.SE">
			<label for="tax_number" class="col-md-3 col-form-label text-input-label" tt>{{id_type}}</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="tax_number" name="tax_number" formControlName="tax_number" placeholder="{{(cta_style.value.code == CtaStyleCode.SE) ? 'yyyymmddnnnn' : ''}}">
			</div>
		</div>
		<div class="form-group row">
			<label for="first_name" class="col-md-3 col-form-label text-input-label" tt>First name</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="first_name" name="first_name" formControlName="first_name">
			</div>
		</div>
		<div class="form-group row">
			<label for="last_name" class="col-md-3 col-form-label text-input-label" tt>Last name</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="last_name" name="last_name" formControlName="last_name">
			</div>
		</div>
		<div class="form-group row">
			<label for="birthday" class="col-md-3 col-form-label text-input-label" tt>Date of birth</label>
			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       id="birthday"
					       [placeholder]="'dd.mm.yyyy' | tt"
					       name="date_of_birth"
					       formControlName="date_of_birth"
					       [maxDate]="today"
					       [minDate]="past"
					       ngbDatepicker
					       #dpBirthday="ngbDatepicker"/>
					<div
							class="input-group-addon btn-icon-calendar"
							(click)="dpBirthday.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="phone_number" class="col-md-3 col-form-label text-input-label" tt>Phone number</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="phone_number" name="phone_number"
				       formControlName="phone_number">
				<small class="form-text text-gray" tt>In the international format, example: +35401234567</small>
			</div>
		</div>
		<div class="form-group row">
			<label for="email" class="col-md-3 col-form-label text-input-label" tt>Email</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="email" name="email" formControlName="email">
			</div>
		</div>
		<div [formGroup]="countryForm">
			<div class="form-group row">
				<label for="nationality" class="col-md-3 col-form-label text-input-label" tt>Nationality</label>
				<div class="col-md-9">
					<select class="form-control"
					        type="text"
					        id="nationality"
					        name="country_code"
					        formControlName="country_code">
						<option value="" tt>Select nationality...</option>
						<option *ngFor="let country of countries" [ngValue]="country.id">
							{{country.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group row" *ngIf="countryForm.value.country_code == 'XX'">
				<label for="country_name" class="col-md-3 col-form-label text-input-label" tt>Country name</label>
				<div class="col-md-9">
					<input class="form-control" type="text" id="country_name" name="country_name"
					       formControlName="country_name">
				</div>
			</div>
		</div>
		<div [formGroup]="homeCountryForm">
			<div class="form-group row">
				<label for="home_country" class="col-md-3 col-form-label text-input-label" tt>Home country</label>
				<div class="col-md-9">
					<select class="form-control"
					        type="text"
					        id="home_country"
					        name="country_code"
					        formControlName="country_code">
						<option value="" tt>Select country...</option>
						<option *ngFor="let country of countries" [ngValue]="country.id">
							{{country.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group row" *ngIf="homeCountryForm.value.country_code == 'XX'">
				<label for="home_country_name" class="col-md-3 col-form-label text-input-label" tt>Country name</label>
				<div class="col-md-9">
					<input class="form-control" type="text" id="home_country_name" name="country_name"
					       formControlName="country_name">
				</div>
			</div>
		</div><!-- /Personal information -->

		<!-- Address in home country -->
		<ng-container *ngIf="address_is_required && name">
			<h5 class="mt-5" tt>Address in home country</h5>
			<cta-ta-form-address [inputForm]="homeCountryAddress"
			                     [home_country_code]="home_country_code">
			</cta-ta-form-address>
			<!-- /Address in home country -->
			<!-- Address in Finland -->
			<h5 class="mt-5">{{'Address in ' + name | tt }}</h5>
			<cta-ta-form-address [inputForm]="countryAddress">
			</cta-ta-form-address>
			<!-- /Address in Finland -->
		</ng-container>

		<ng-container *ngIf="show_emergency_contact">
			<!-- Emergency contact person -->
			<h5 class="mt-5" tt>Emergency contact person</h5>
			<div class="form-group row">
				<label for="emergency_name" class="col-md-3 col-form-label text-input-label" tt>Name, surname</label>
				<div class="col-md-9">
					<input class="form-control" type="text" id="emergency_name" name="emergency_name"
					       formControlName="emergency_name">
				</div>
			</div>
			<div class="form-group row">
				<label for="emergency_phone_number" class="col-md-3 col-form-label text-input-label" tt>Phone number</label>
				<div class="col-md-9">
					<input class="form-control"
					       type="text"
					       id="emergency_phone_number"
					       name="emergency_phone_number"
					       formControlName="emergency_phone_number">
					<small class="form-text text-gray" tt>In the international format, example: +35401234567</small>
				</div>
			</div><!-- /Emergency contact person -->
		</ng-container>
	</form>
</ng-container>
