import { Component, Input, OnInit } from '@angular/core';

import { GuidService } from 'shared';

import { TaCompany } from '../../interfaces';

@Component({
	selector    : 'cta-order-form-company-static',
	templateUrl : './company-info-static.form.html',
	styleUrls   : ['./company-info-static.form.scss']
})
export class CompanyInfoStaticForm implements OnInit {

	@Input() public company: TaCompany;

	public id: string;

	constructor(guid: GuidService)
	{
		this.id = `order_info_${guid.Next}`;
	}

	ngOnInit(): void
	{
	}
}
