import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep, each } from 'lodash';
import * as moment from 'moment';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface ReservationEventGroup {
	reservation_event_group_id: string;

	reservation_account_id: string;
	reserving_account_id: string;
	reservation_status_id: string;

	name: string;
	users: string;

	is_self: boolean;
	is_past: boolean;
	start: moment.Moment | string;
	end: moment.Moment | string;

	location: string;
	reserving_user_name: string;
	instructors: string;

	[key: string]: any;
}

@Injectable()
export class ShopReservationEventGroupsService extends EnkoraFetcher<ReservationEventGroup[]> {

	public filtered_list$: BehaviorSubject<ReservationEventGroup[]> = new BehaviorSubject<ReservationEventGroup[]>([]);

	protected params: CallParameter[] = [{ name : 'reservation2/getR2EventGroupReservations' }];

	constructor(call: CallService)
	{
		super(call);
	}

	private _filtered_list: ReservationEventGroup[] = [];

	get filtered_list(): ReservationEventGroup[]
	{
		return this._filtered_list;
	}

	set filtered_list(value: ReservationEventGroup[])
	{
		this._filtered_list = value;
		this.filtered_list$.next(value);
	}

	filterByAccount(account_id = ''): ReservationEventGroup[]
	{
		const reservation_event_groups: ReservationEventGroup[] = [];

		each(this.value, (reservation_event_group: ReservationEventGroup) => {
			if (reservation_event_group.is_course) return;

			if (reservation_event_group.reservation_event_group_account_id == account_id
				|| reservation_event_group.reserving_account_id == account_id) {

				const new_reservation_event_group = cloneDeep(reservation_event_group);

				new_reservation_event_group.is_self = new_reservation_event_group.reservation_event_group_account_id == account_id;
				reservation_event_groups.push(new_reservation_event_group);
			}
		});

		return reservation_event_groups;
	}

	postProcess(reply: ReservationEventGroup[]): ReservationEventGroup[]
	{
		const result: ReservationEventGroup[] = [];
		each(reply, item => {
			const row: ReservationEventGroup = item;

			row.start = moment(item.start);
			row.end = moment(item.end);

			row.is_past = row.end.isBefore();

			result.push(row);
		});

		return result;
	}

}
