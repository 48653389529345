import { FormGroup } from '@angular/forms';

/**
 * Validates whether account has been selected or not
 *
 * @param group
 * @returns {any}
 * @constructor
 */
export function SelectedAccountValidator(group: FormGroup): null | { hasAccount: false }
{
	const value: { account_id: string } = group.value;
	return value?.account_id ? null : {hasAccount : false};
}

export enum WebshopValidators {
	EMAIL = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
	PHONE = '^\\+?(\\d+[ -]?)*\\d+$',
	POSTAL_CODE = '^[0-9]*$',
}
