import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { each, join, keys, uniqBy } from 'lodash';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

import {
	CallParameter,
	CallService,
	Constants,
	EnkoraFetcher,
	Helpers,
	LocationService,
	Logger,
	ModalOpenerService
} from 'shared';
import {
	AccessRight,
	ConfirmationModal,
	TaDataHelper,
	TaEmployeeStatus,
	TaQualification,
	TaToken
} from '../../../shared';

import { TaSiteStatisticsService } from './ta-site-statistics.service';
import { TaAccessEventsService } from './ta-access-events.service';
import { TaCompanyListService } from './ta-company-list.service';

export interface RawTagEmployeeInput {
	tag_id: string;
	tag_name: string;
	tag_start_date: string;
	tag_end_date: string;
	tag_value: string;
}

export interface RawTokenEmployeeInput {
	token_id: string;
	token_name: string;
	token_type_id: string;
	token_hex: string;
}

export interface RawAccessRightEmployeeInput {
	access_right_template_group_id: string;
	access_right_template_group_name: string;
	access_right_template_id: string;
	access_right_template_name: string;
	access_right_id: string;
	timetable_id: string;
	service_at_area_id: string;
	access_area_id: string;
	location_id: string;
	expiration_date: string;
	access_right_status: string;
}

export interface RawGetEmployeeInput {
	account_id: string;
	user_id: string;
	employee_id: string;
	access_area_id: string;
	access_area_name: string;

	name: string;
	firstname: string;
	lastname: string;
	tax_number: string;
	date_of_birth: string;
	phone_number: string;
	address: string;
	country_code: string;
	home_address: string;
	home_country_code: string;

	is_in_tax_register: string;
	tax_number_check_timestamp: string;
	is_tax_number_matching_birthday: string;

	employee_start: string;
	employee_end: string;
	employee_is_expired: string;
	work_type_tax_code: number;
	employee_group_id: string;
	employee_group_name: string;

	organization_name: string;
	organization_id: string;
	organization_country_code: string;
	organization_address: string;

	business_id: string;
	foreign_business_id: string;
	foreign_business_id_type: string;

	location_name: string;
	location_code: string;

	access_timestamp: string;
	is_access_allowed: boolean;
	at_work: boolean;

	tags: RawTagEmployeeInput[];
	access_rights: RawAccessRightEmployeeInput[];
	tokens: RawTokenEmployeeInput[];
}

@Injectable()
export class TaSiteStatusDataService extends EnkoraFetcher<TaEmployeeStatus[], {
	location_id?: string,
	access_right_template_group_id?: string,
	limit?: string
}> {

	protected params: CallParameter[] = [{ name : 'cta2/getEmployeeStatuses' }];
	private companyList: TaCompanyListService = null;

	constructor(call: CallService,
	            private location: LocationService,
	            private stats: TaSiteStatisticsService,
	            private accesses: TaAccessEventsService,
	            private modal: ModalOpenerService,
	            private injector: Injector,
	            @Inject('site_status_data_limit') @Optional() private site_status_data_limit?: string)
	{
		super(call);

		const limit = site_status_data_limit
			? { limit : site_status_data_limit }
			: {};

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id, ...limit });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options?: { location_id?: string, access_right_template_group_id?: string, limit?: string }): void | []
	{
		options = options || {};
		const location_id = options.location_id;
		const access_right_template_group_id = options.access_right_template_group_id;
		const limit = options.limit;
		if (!location_id) return [];

		Logger.log(`Site status update: location_id= ${location_id}`);

		this.params = [{
			name    : 'cta2/getEmployeeStatuses',
			content : [{
				location_id,
				access_right_template_group_id,
				limit
			}]
		}];
	}

	postProcess(reply: RawGetEmployeeInput[]): TaEmployeeStatus[]
	{
		const result: TaEmployeeStatus[] = [];
		each(reply, row => {

			const tag_map: { [key: string]: boolean } = {};
			const qualifications: TaQualification[] = [];
			const tokens: TaToken[] = [];
			each(row.tags, tag => {
				if (tag.tag_id && tag.tag_name != 'emergency_info') {
					const qualification: TaQualification = {
						tag_id     : tag.tag_id,
						name       : tag.tag_name,
						value      : tag.tag_value,
						start_date : moment(tag.tag_start_date, Helpers.serverDateFormat),
						end_date   : moment(tag.tag_end_date, Helpers.serverDateFormat)
					};
					qualification.is_expired = qualification.end_date.isBefore(Helpers.now);

					qualifications.push(qualification);

					if (!qualification.is_expired) {
						tag_map[tag.tag_name] = true;
					}
				}
			});

			each(row.tokens, t => {
				const token: TaToken = {
					token_id      : t.token_id,
					token_name    : t.token_name,
					token_type_id : t.token_type_id,
					token_hex     : t.token_hex
				};
				tokens.push(token);
			});

			const group_map: { [key: string]: boolean } = {};
			const access_rights: AccessRight[] = [];
			const access_right_map: { [key: string]: true } = {};

			each(row.access_rights, ar => {
				if (ar.access_right_template_group_id) {
					if (!access_right_map[ar.access_right_id]) {
						const access_right: AccessRight = {
							...ar,
							expiration_date : moment(ar.expiration_date, Helpers.serverDateFormat)
						};
						access_right.is_expired = ar.access_right_status == Constants.AR_INVALID;
						access_rights.push(access_right);
						access_right_map[ar.access_right_id] = true;
					}

					if (ar.access_right_status != Constants.AR_INVALID) {
						group_map[ar.access_right_template_group_name] = true;
					}
				}
			});

			const access_timestamp = moment(row.access_timestamp, Helpers.serverFullDateFormat);

			const status: TaEmployeeStatus = {
				account_id  : row.account_id,
				employee_id : row.employee_id,
				user_id     : row.user_id,
				name        : row.name,
				tax_number  : row.tax_number,

				access_area_id   : row.access_area_id,
				access_area_name : row.access_area_name,

				employee_group_id   : row.employee_group_id,
				employee_group_name : row.employee_group_name,

				organization_id           : row.organization_id,
				organization_name         : row.organization_name,
				business_id               : row.business_id,
				foreign_business_id       : row.foreign_business_id,
				organization_country_code : row.organization_country_code,
				work_type                 : TaDataHelper.extractWorkType(row.work_type_tax_code),

				tag_names            : join(keys(tag_map), ', '),
				group_names          : join(keys(group_map), ', '),
				qualifications,
				access_rights,
				tokens,
				unique_access_rights : uniqBy(access_rights, 'access_right_id'),

				access_timestamp                : access_timestamp.isValid() ? access_timestamp : null,
				is_access_allowed               : row.is_access_allowed,
				at_work                         : row.at_work,
				is_in_tax_register              : !!row.is_in_tax_register,
				is_tax_number_matching_birthday : !!row.is_tax_number_matching_birthday
			};
			result.push(status);
		});

		return result;
	}

	togglePresence(row: TaEmployeeStatus): Observable<boolean>
	{
		const location_id = this.latest_content.location_id;

		return new Observable((observer: Observer<boolean>) => {
			const modalRef = this.modal.openModalSmPadding(ConfirmationModal);
			const params = ConfirmationModal.default;

			const ok_button_handler = this.call.make('cta/setaccountpresence', [
				location_id,
				row.account_id,
				row.employee_id,
				!row.at_work
			]).pipe(tap(() => {

				this.reload();
				this.accesses.invalidate();
				this.stats.reload();
				(this.companyList ?? this.injector.get(TaCompanyListService)).invalidate();
			}));

			params.inner_message = row.at_work ? 'Do you want to sign out employee?' : 'Do you want to sign in employee?';
			params.inner_message += `<br><i>${row.name} (${row.organization_name})</i>`;
			params.before_return = ok_button_handler;

			modalRef.componentInstance.params = params;

			modalRef.result.then(() => {
				observer.next(true);
				observer.complete();
			}, (error) => {
				if (!!error) {
					observer.error(error);
				}
			});
		});
	}

	fixTimestamp(row: TaEmployeeStatus, time: { start, end }): Observable<string | number>
	{
		const location_id = this.latest_content.location_id;

		return this.call.make<string | number>('cta/setaccountpresence', [
			location_id, row.account_id, row.employee_id, null, time.start, time.end]
		).pipe(tap(() => this.reload()));
	}

	update(row: TaEmployeeStatus): Observable<any>
	{
		return this.call.make('cta/updateUser', [row.user_id, { employee_id : row.employee_id }]).pipe(
			tap(() => this.reload())
		);
	}
}
