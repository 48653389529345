import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { find, includes } from 'lodash';
import { finalize, first } from 'rxjs/operators';

import { CallService, Constants, EnkoraMessageService, ParameterService } from 'shared';

import {
	CtaStyleCode,
	TaActiveDataHolderService,
	TaCompanyInfo,
	TaCtaStyleService,
	TaDataHelper,
	Token
} from '../../../../shared';
import { TaTokenTypesService } from '../../services/ta-token-types.service';

declare let Enkora: { tt: (a: string) => string };

export enum InputTokenStatus {
	None = 'None',
	InProgress = 'InProgress',
	Added = 'Added',
	Error = 'Error'
}

export interface TokenError {
	type: InputTokenStatus.Error;
	message: string;
}

export interface TokenInProgress {
	type: InputTokenStatus.InProgress;
	token: Token;
}

export interface TokenAdded {
	type: InputTokenStatus.Added;
	token: Token;
}

export type InputToken = TokenError | TokenAdded | TokenInProgress | { type: InputTokenStatus.None };

@Component({
	selector    : 'cta-ta-token-input',
	templateUrl : './input.component.html',
	styleUrls   : ['./input.component.scss']
})
export class TokenInputComponent implements OnInit {

	static default_type = 'mf';
	public can_add = false;
	private pin_length;

	@Input() tax_number = null;
	@Input() default_organization: TaCompanyInfo = null;

	@Output() statusUpdate = new EventEmitter<InputToken>();
	@Output() searchingUpdate = new EventEmitter<boolean>();

	public types: string[] = [];
	show_organisation_search = true;
	public token: Token = TaDataHelper.emptyToken;

	constructor(private call: CallService,
	            private messageService: EnkoraMessageService,
	            token_types: TaTokenTypesService,
	            public cta_style: TaCtaStyleService,
	            public dataHolder: TaActiveDataHolderService,
	            private param: ParameterService)
	{
		token_types.get().pipe(first()).subscribe(types => this.types = types);
	}

	private _companies: TaCompanyInfo[] = [];

	get companies(): TaCompanyInfo[]
	{
		return this._companies;
	}

	@Input()
	set companies(value: TaCompanyInfo[])
	{
		this._companies = value;
		this.token.organization_id = null;

		if (!this._companies.length) {
			if (!this.default_organization?.business_id && !this.default_organization?.foreign_business_id) return;
			this._companies.push(this.default_organization);
			this.token.organization_id = this.default_organization.organization_id;
		} else if (this._companies.length == 1 && this._companies[0].organization_id) {
			this.token.organization_id = this._companies[0].organization_id;
		}
	}

	ngOnInit(): void
	{
		this.cta_style.get().subscribe();
		if (this.cta_style.value.code == CtaStyleCode.SE) {
			this.show_organisation_search = false;
		}

		this.param.getNumberValue('access pin length', Constants.NM_EN_ACCESS_CONTROL, 4).subscribe(value => {
			this.pin_length = value;
		});
	}

	search(): void
	{
		const company = find(this.companies, c => c.organization_id == this.token.organization_id);
		if (!company) return;

		this.searchingUpdate.emit(true);
		this.call.make('cta/findValttikorttiUid',
			[company.business_id ?? company.foreign_business_id, this.tax_number]
		).pipe(first(), finalize(() => this.searchingUpdate.emit(false))
		).subscribe(
			result => {
				if (!result) {
					this.messageService.info(Enkora.tt('Card not found'));
					return;
				}
				this.token.name = result;
				this.token.is_verified = true;
				if (includes(this.types, TokenInputComponent.default_type)) {
					this.token.type = TokenInputComponent.default_type;
					this.update();
				}
			},
			error => this.messageService.error(error)
		);
	}

	update(): void
	{
		this.token.existing = this.token.type + '_' + this.token.name;

		if (this.token.organization_id) {
			const company = find(this.companies, c => c.organization_id == this.token.organization_id);
			this.token.organization_name = company.name;
		} else {
			this.token.organization_name = Enkora.tt('Default organization');
		}

		this.can_add = this.token.name && !!this.token.type && this.isValidPin();

		if (this.token.name || this.token.type) {
			this.statusUpdate.emit({ type : InputTokenStatus.InProgress, token : this.token });
		} else {
			this.statusUpdate.emit({ type : InputTokenStatus.None });
		}
	}

	addToken(): void
	{
		this.call.make<{
			token_id: string;
			name: string;
			account_id: string;
			organization_id: string;
		}>('cta2/findExistingToken', [{
			token_type : this.token.type,
			token_name : this.token.name
		}]).subscribe(token => {
			if (token.token_id) {
				if (token.account_id === this.dataHolder.employee.personal_info.account_id
					&& token.organization_id === this.token.organization_id
				) {
					this.statusUpdate.emit({
						type    : InputTokenStatus.Error,
						message : 'The token is already linked to this employee for the selected organization'
					});
					return;
				}
			}
			this.statusUpdate.emit({ type : InputTokenStatus.Added, token : this.token });
			this.token = TaDataHelper.emptyToken;
		});
	}

	isValidPin(): boolean
	{
		if (`${this.token.type}_` != Constants.TOKEN_PRE_PIN) {
			return true;
		}

		return !!this.token.name?.length && this.token.name?.length == this.pin_length && /^\d+$/.test(this.token.name);
	}

	generatePin(): void
	{
		this.call.make('acs/generatePin').subscribe(pin => {
			this.token.name = pin;
			this.update();
		}, (error) => {
			this.messageService.error(error);
		});
	}
}
