import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CalendarStateService {
	public application_mode = false;
	get month() : number {
		return this._month;
	}

	public month$ = new BehaviorSubject(1);

	set month(value : number) {
		this._month = value;
		this.month$.next(value);
	}

	get year() : number {
		return this._year;
	}

	public year$ = new BehaviorSubject(1900);

	set year(value : number) {
		this._year = value;
		this.year$.next(value);
	}

	private _year : number = 0;
	private _month : number = 0;

	constructor() {
	}

}
