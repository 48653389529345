import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface PaymentType {
	name: string;
	payment_type_id: number;
	is_disabled: boolean;
	is_balance: boolean;
}

@Injectable({ providedIn : 'root' })
export class PaymentTypesService extends EnkoraFetcher<PaymentType[]> {

	protected params: CallParameter[] = [{ name : 'cart/getonlinepaymenttypes' }];

	constructor(call: CallService)
	{
		super(call);
	}
}

