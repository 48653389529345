<div class="modal-header">
	<h2 class="no-margin-bottom"> {{ params.title | tt }}</h2>

	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="modal.dismiss()">
		<cta-icon-close></cta-icon-close>
	</button>
</div>

<div class="modal-body">
	<ng-container *ngIf="params.inner_message; else string_message">
		<div [innerHTML]="params.inner_message | safeHtml"></div>
	</ng-container>
	<ng-template #string_message>
		{{ params.message | tt }}
	</ng-template>
</div>

<div class="modal-footer">
	<button type="button"
	        *ngIf="params.cancel_button"
	        class="btn btn-secondary"
	        (click)="modal.dismiss()">
		{{ params.cancel_button | tt }}
	</button>
	<button type="button" class="btn btn-primary-custom" [disabled]="in_process" (click)="close()">
		{{ params.ok_button | tt }}
	</button>
</div>
