// https://ng-bootstrap.github.io/#/components/datepicker/examples
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';

import { Helpers } from '../../misc';

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<Moment> {

	fromModel(date: Moment): NgbDateStruct
	{
		return Helpers.momentToNgbDate(date);
	}

	toModel(date: NgbDateStruct): Moment
	{
		return Helpers.ngbDateToMoment(date);
	}
}
