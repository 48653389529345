import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import * as _ from "lodash";

@Injectable()
export class ServicesManagerService {
	get services() : Array<any> {
		return this._services;
	}

	set services(value : Array<any>) {
		this._services = value;
		this.services$.next(value);

		this._service_map = {};
		_.forEach(this._services, (service) => {
			this._service_map[service.service_id.toString()] = service;
		});
	}

	private _services = [];
	private _service_map : any = {};

	public services$ : Subject<any> = new Subject<any>();

	getNamesForIds(ids : any) : string[] {
		let res : string[] = [];

		if (!_.isArray(ids)) ids = [ids];
		_.forEach(ids, (id) => {
			let service = this._service_map[id.toString()];
			if (service) res.push(service.name || service.id);
		});

		return res;
	}

	getServicesForIds(ids : any) : any[] {
		let res : string[] = [];

		if (!_.isArray(ids)) ids = [ids];
		_.forEach(ids, (id) => {
			let service = this._service_map[id.toString()];
			if (service) res.push(service);
		});

		return res;
	}

	flattenServiceIds(service_ids : any) : Array<any> {
		let res : any = {};

		let ids = service_ids;
		if (!_.isArray(service_ids)) ids = [service_ids];
		_.forEach(ids, temp_id => {
			try {
				let true_ids = ("" + temp_id).split(',');
				_.forEach(true_ids, id => {
					res[id] = id;
				});
			} catch (e) {
				console.log(e);
			}
		});

		return _.values(res);
	}

	getListForIds(ids : any[]) : string {
		return this.getNamesForIds(ids).join(', ');
	}

	constructor() {
	}

}
