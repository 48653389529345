import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { each, filter, keys, last } from 'lodash';
import { combineLatest, Observable, of, zip } from 'rxjs';

import { Helpers } from 'shared';

import {
	FareProductField,
	FieldFormsGeneratorService,
	HotelSlot,
	MakeReservation,
	ResourceField,
	SelectorsState,
	ShopFareProductFieldsService,
	ShopField,
	ShopFieldInput,
	ShopFields,
	ShopReservationListService,
	ShopResourceFieldsService
} from '../../services';
import { AlertCode, AlertType } from '../../alerts';
import { DayEvent } from '../../main';

declare let Enkora: { tt: (a: string) => string };

@Component({
	templateUrl   : './hotel-reservation.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./hotel-reservation.modal.scss']
})

export class HotelReservationModal implements OnInit {

	public form: FormGroup = null;
	public resource_fields_form: FormGroup = null;
	public loading_extra_fields = false;
	public fare_product_fields_form: FormGroup = null;

	public alert: AlertType = null;
	public min_date: NgbDateStruct = null;
	public max_date: NgbDateStruct = null;
	public is_pay_later = false;

	public modalRef: NgbModalRef;
	public info: DayEvent;
	public selected_resource: HotelSlot;
	public resource_fields: ResourceField[] = [];
	public resource_shop_fields: ShopFields;
	public fare_product_shop_fields: ShopFields;
	public fare_product_fields: FareProductField[] = [];
	public fare_product_field_groups: string[] = [];
	public lang;
	public hotel_slots_map: Map<string, HotelSlot[]> = new Map();
	private reservation: MakeReservation = null;
	private state: SelectorsState = null;

	constructor(public activeModal: NgbActiveModal,
	            private fb: FormBuilder,
	            private shopReservationListService: ShopReservationListService,
	            private shopResourceFieldsService: ShopResourceFieldsService,
	            private shopFareProductFieldsService: ShopFareProductFieldsService,
	            private formGenerator: FieldFormsGeneratorService,
	            private cdRef: ChangeDetectorRef
	)
	{
		this.lang = Helpers.getCalendarLanguage();
	}

	@Input() set input({ reservation, info, is_pay_later, hotel_slots, selected_resource, state }: {
		reservation: MakeReservation,
		info: DayEvent,
		is_pay_later: boolean,
		hotel_slots: Map<string, HotelSlot[]>,
		selected_resource: HotelSlot,
		state: SelectorsState
	})
	{
		this.reservation = reservation;
		this.info = info;
		this.is_pay_later = is_pay_later;

		this.hotel_slots_map = hotel_slots;
		this.loading_extra_fields = true;
		this.selected_resource = selected_resource;
		this.state = state;

		const field_groups = {};
		each(this.selected_resource?.fare_product, fp => {
			field_groups[fp.field_group] = true;
		});
		this.fare_product_field_groups = keys(field_groups);

		const start = moment(info.startStr, 'YYYY-MM-DD');
		const end = moment(info.startStr, 'YYYY-MM-DD').add(1, 'days');

		const event_time = [start, end];
		this.min_date = Helpers.momentToNgbDate(start.clone().add(1, 'days'));

		this.form = this.fb.group({
			resource            : [info.title],
			resource_id         : [info.id],
			service_id          : [info.service_id],
			event_time          : [event_time],
			checkin_time        : [start],
			checkout_time       : [end],
			resource_date_start : [start.format('dddd DD.MM.YYYY')],
			resource_date_end   : [end.format('dddd DD.MM.YYYY')],
			resource_time_start : [info.extendedProps.resource_time_start.substring(0, 5)],
			resource_time_end   : [info.extendedProps.resource_time_end.substring(0, 5)],
			total               : [0],
			total_str           : ['']
		});

		this.updatePrice();
		this.fetchExtraFields();
	}

	ngOnInit(): void
	{
	}

	public fetchExtraFields(): void
	{
		const fields$: Observable<[FareProductField[], string]>[] = [];
		each(this.fare_product_field_groups, group => {
			fields$.push(
				zip(this.shopFareProductFieldsService.getOnce({ field_group : group }), of(group))
			);
		});

		combineLatest([
			combineLatest(fields$),
			this.shopResourceFieldsService.get(true, { resource_id : this.info.id })
		]).subscribe(([fare_product_fields, resource_fields]) => {
			each(fare_product_fields, fields => {
				each(fields[0], field => {
					field.field_group = fields[1];
				});
				const filtered_fields = fields[0].filter(field => field.is_customer_editable == 1);
				this.fare_product_fields.push(...filtered_fields);
			});

			const fare_product_shop_fields = this.fareProductToShopInputFields(this.fare_product_fields);
			this.fare_product_shop_fields = this.formGenerator.generateForm(fare_product_shop_fields);

			each(fare_product_shop_fields, field => {
				const validators: ValidatorFn[] = [];
				if (field.is_required) {
					validators.push(Validators.required);
				}

				if (field.validation_regex) {
					field.validation_regex = Helpers.parseRegExFromStr(field.validation_regex);
					const regex = new RegExp(field.validation_regex);
					validators.push(Validators.pattern(regex));
				}

				if (validators.length > 0) {
					this.fare_product_shop_fields.rootForm.get(`field${field.ui_order}`)
					.setValidators(Validators.compose(validators));
				}
			});

			this.resource_fields = resource_fields;
			const resource_shop_fields = this.resourceToShopInputFields(resource_fields);
			this.resource_shop_fields = this.formGenerator.generateForm(resource_shop_fields);

			each(resource_shop_fields, field => {
				if (field.is_required) {
					const validator = field.type === 'checkbox' ? Validators.requiredTrue : Validators.required;
					this.resource_shop_fields.rootForm.get(`field${field.ui_order}`)
					.setValidators(Validators.compose([validator]));
				}
			});

			this.fare_product_fields_form = this.fare_product_shop_fields.rootForm;
			this.resource_fields_form = this.resource_shop_fields.rootForm;
			this.loading_extra_fields = false;
			this.cdRef.detectChanges();
		}, (err) => {
			this.loading_extra_fields = false;
			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Reservation Extra Fields Error'),
				message : Enkora.tt(err)
			};
		});
	}

	public resourceToShopInputFields(fields: ResourceField[]): ShopFieldInput[]
	{
		const fields_new: ShopFieldInput[] = [];

		each(fields, field => {
			let data_type: string;
			switch (field.data_type) {
				case 'boolean':
					data_type = 'checkbox';
					break;

				case 'long_text':
					data_type = 'textarea';
					break;

				case 'datetime':
					data_type = 'date';
					break;

				default:
					data_type = field.data_type;
					break;
			}

			fields_new.push({
				id              : `field${field.ui_order}`,
				name            : field.data_type === 'link' ? '' : field.field_name,
				is_required     : field.is_required,
				ui_order        : field.ui_order,
				type            : data_type,
				value           : field.data_type === 'link' ? field.field_name : null,
				possible_values : field.data_type === 'boolean' ? [false] : []
			});
		});

		return fields_new;
	}

	public fareProductToShopInputFields(fields: FareProductField[]): ShopFieldInput[]
	{
		const fields_new: ShopFieldInput[] = [];

		each(fields, field => {
			fields_new.push({
				id               : `field${field.ui_order}`,
				name             : field.field_name,
				is_required      : !!field.is_required,
				ui_order         : field.ui_order,
				type             : '',
				field_group      : field.field_group,
				validation_regex : field.validation_regex,
				description      : field.description
			});
		});

		return fields_new;
	}

	public updatePrice(): number
	{
		if (!this.info || !this.hotel_slots_map) {
			return 0;
		}

		const last_slot = last(this.getHotelSlotsList(this.hotel_slots_map));
		const day_range = moment(last_slot[0]).diff(this.form.get('checkin_time').value, 'days') + 1;
		const max_days = this.selected_resource.max_consecutive_nights || day_range;

		this.max_date = this.calculateMaxCheckoutDate(
			this.hotel_slots_map,
			parseInt(this.info.id, 10),
			this.form.get('checkin_time').value,
			max_days
		);

		const end = new Date(this.info.startStr);
		end.setDate(end.getDate() + 1);
		const total_price = this.calculateTotalPrice(this.hotel_slots_map, this.info.startStr,
			moment(end).format('YYYY-MM-DD'), this.info.id);
		this.form.get('total').setValue(total_price);
		this.form.get('total_str').setValue(`${total_price / 100}€`);
	}

	public eventTimeChanged(): void
	{
		const checkout_time = moment(this.form.get('checkout_time').value);
		const checkout_time_key = checkout_time.format('YYYY-MM-DD');

		this.form.get('resource_date_end').setValue(checkout_time.format('dddd DD.MM.YYYY'));

		if (this.selected_resource) {
			const end = this.selected_resource.resource_time_end.substring(0, 5);
			this.form.get('resource_time_end').setValue(end);
		}

		const checkin_time_key = moment(this.form.get('checkin_time').value).format('YYYY-MM-DD');
		const total_price = this.calculateTotalPrice(this.hotel_slots_map, checkin_time_key,
			checkout_time_key, this.info.id);
		this.form.get('total').setValue(total_price);
		this.form.get('total_str').setValue(`${total_price / 100}€`);
	}

	public calculateMaxCheckoutDate(hotel_slots_map: Map<string, HotelSlot[]>, resource_id: number, checkin_time: Date, max_days: number): NgbDateStruct
	{
		const checkin = moment(checkin_time);
		const date_slots_list = this.getHotelSlotsList(hotel_slots_map);

		let max_date = moment(checkin_time);
		let count = 0;

		for (const [date_key, slots] of date_slots_list) {
			max_date = moment(date_key);
			const date = moment(date_key);

			if (date.isAfter(checkin)) {
				let found = false;
				each(slots, slot => {
					if (slot.resource_id == resource_id && !slot.reservation_count) {
						found = true;
						count++;
						return false;
					}
				});

				if (!found || count >= max_days) {
					break;
				}
			}
		}
		return Helpers.momentToNgbDate(max_date);
	}

	public getHotelSlotsList(hotel_slots_map: Map<string, HotelSlot[]>): [string, HotelSlot[]][]
	{
		const hotels_array: [string, HotelSlot[]][] = [];
		hotel_slots_map.forEach((slots, date) => {
			hotels_array.push([date, slots]);
		});

		return hotels_array.sort((a, b) => {
			const date_a = new Date(a[0]);
			const date_b = new Date(b[0]);
			return date_a.getTime() - date_b.getTime();
		});
	}

	public calculateTotalPrice(hotel_slots_map: Map<string, HotelSlot[]>,
	                           start_date: string, end_date: string, resource_id: string): number
	{
		const start = moment(start_date);
		const end = moment(end_date);
		let total_price = 0;

		for (const m = moment(start); m.isBefore(end); m.add(1, 'days')) {
			const key = m.format('YYYY-MM-DD');
			each(hotel_slots_map.get(key), slot => {
				if (slot.resource_id == parseInt(resource_id, 10)) {
					total_price += slot.fare_product[0].price;
				}
			});
		}

		return total_price;
	}

	public reserve(): void
	{
		if (this.is_pay_later) {
			if (!confirm(Enkora.tt('By clicking OK you agree to being billed for the service later.'))) return;
		}

		const filtered_fare_product_shop_fields = filter(this.fare_product_shop_fields.fields, field => !!field.form.value);
		const mapped_fields = this.mapFareProductExtraFields(filtered_fare_product_shop_fields);
		this.reservation.extra_data_for_event = mapped_fields.extra_data_for_event;

		const checkin_date = moment(this.form.get('checkin_time').value);
		const checkout_date = moment(this.form.get('checkout_time').value);
		this.reservation.start_date = `${checkin_date.format('YYYY-MM-DD')} ${this.form.get('resource_time_start').value}:00`;
		this.reservation.end_date = `${checkout_date.format('YYYY-MM-DD')} ${this.form.get('resource_time_end').value}:00`;

		const notes: string[] = [];
		each(this.resource_fields, field => {
			const val = this.resource_fields_form.get(`field${field.ui_order}`)?.value;
			if (val) {
				notes.push(`${field.field_name}: ${val}`);
			}
		});

		this.reservation.notes = notes.join('\n\n');

		this.shopReservationListService.makeHotelReservation(this.reservation)
		.subscribe(res => {
			this.activeModal.close({ response : res, is_pay_later : this.is_pay_later });
		}, (err) => {
			this.alert = {
				code    : AlertCode.Error,
				title   : Enkora.tt('Reservation did not succeed'),
				message : Enkora.tt(err)
			};
		});
	}

	private mapFareProductExtraFields(shop_fields: ShopField[])
	{
		const result: { extra_data_for_event: Record<string, Record<string, unknown>> } = { extra_data_for_event : {} };
		each(shop_fields, field => {
			if (!result.extra_data_for_event[field.field_group]) result.extra_data_for_event[field.field_group] = {};
			result.extra_data_for_event[field.field_group][field.name] = field.form.value;
		});

		return result;
	}
}
