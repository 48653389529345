import { Component } from '@angular/core';

import { LoaderModalService } from '../../services';

@Component({
	selector    : 'app-loader-modal',
	templateUrl : './loader.modal.html',
	styleUrls   : ['./loader.modal.scss']
})
export class LoaderModal {

	constructor(public loader: LoaderModalService)
	{
	}
}
