import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export type ModelFormGroup<T> = FormGroup<{
	[K in keyof T]: FormControl<T[K]>;
}>;

export function stringAsNumber(value: string): number
{
	return value as unknown as number;
}

export function abstractControlToFormGroup(value: AbstractControl): FormGroup
{
	return value as FormGroup;
}

export function convertTwoTypes<B, A>(value: A): B
{
	return value as unknown as B;
}

export function extractEventValue(prop: any)
{
	return prop.value;
}
