import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { CallParameter, CallService, EnkoraFetcher } from '../../shared';

import { each } from 'lodash';

declare let Enkora: { tt: (a: string) => string };

export interface ApplicationTagRaw {
	tag_id : number;
	name : string;
	type : string;
	is_required : number;
}

export interface ApplicationTag {
	tag_id : number;
	name : string;
	type : string;
	is_required : boolean;
}

export interface ApplicationTags {
	values : ApplicationTag[];
	map : { [tag_id : number] : ApplicationTag };
	labels : SelectItem[];
}

@Injectable()
export class ApplicationTagsService extends EnkoraFetcher<ApplicationTags> {

	params : CallParameter[] = [{name : 'reservation/getReservationApplicationTags'}];

	constructor(call : CallService)
	{
		super(call);
	}

	protected postProcess(reply : ApplicationTagRaw[])
	{
		const result : ApplicationTags = {
			values : [],
			map    : {},
			labels : [{label : Enkora.tt('Untagged'), value : 0}]
		};

		each(reply, r => {
			const tag = {
				tag_id      : r.tag_id,
				name        : r.name,
				type        : r.type,
				is_required : !!r.is_required
			};
			result.values.push(tag);
			result.labels.push({label : tag.name, value : tag.tag_id});
			result.map[tag.tag_id] = tag;
		});

		return result;
	}
}
