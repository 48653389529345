<!--Contact Support Form-->
	<div class="modal-header">
		<h2 class="no-margin-bottom" tt>New Support Request</h2>
		<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.dismiss()">
			<cta-icon-close></cta-icon-close>
		</button>
	</div>

	<div class="modal-body">
		<form [formGroup]="inputForm">
			<div class="form-group row mt-10">
				<label for="name" class="col-md-3 col-form-label text-input-label" tt>Name</label>
				<div class="col-md-9">
					<div class="input-group">
						<input class="form-control" id="name" type="text" formControlName="name" placeholder="{{ 'Enter your name' | tt }}" required>
					</div>
				</div>
			</div>

			<div class="form-group row mt-10">
				<label for="email" class="col-md-3 col-form-label text-input-label" tt>Email</label>
				<div class="col-md-9">
					<div class="input-group">
						<input type="email" id="email" class="form-control" formControlName="email" placeholder="{{ 'Enter your email' | tt }}" required>
					</div>
				</div>
			</div>

			<div class="form-group row mt-10">
				<label for="phone_number" class="col-md-3 col-form-label text-input-label" tt>Phone</label>
				<div class="col-md-9">
					<div class="input-group">
						<input type="text" id="phone_number" class="form-control"  formControlName="phone_number" placeholder="{{ '3584040444999' | tt }}" required>
					</div>
				</div>
			</div>

			<div class="form-row mt-10">
				<label for="text" class="col-md-3 col-form-label text-input-label"> <span tt>Message</span></label>
				<div class="col-md-9">
					<div class="input-group">
						<textarea id="text" class="form-control"  cols="70" rows="6" formControlName="text" placeholder="{{ 'Type your message here' | tt}}"> </textarea>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" tt>Close</button>
		<button type="button" class="btn btn-primary-custom" [disabled]="submitted" (click)="sendContactSupport()" tt>Send</button>
	</div>
