import { Injectable } from '@angular/core';

declare let window : { baseUrl? : string };

@Injectable()
export class ConfigService {

	constructor()
	{
		this._baseUrl = window.baseUrl || '/';

		// append slash to end of baseurl
		if (!this._baseUrl.endsWith('/')) {
			this._baseUrl = this._baseUrl + '/';
		}
	}

	private readonly _baseUrl : string;

	public get baseUrl() : string
	{
		return this._baseUrl;
	}

	private _normalizeApiCalls = true;

	get normalizeApiCalls() : boolean
	{
		return this._normalizeApiCalls;
	}

	isMobile() : boolean
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
	}
}
