import { Injectable } from '@angular/core';
import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';
import { each } from 'lodash';
import { CourseTag } from './course-tags.service';
import { SelectItem } from 'primeng/api';

declare let Enkora: { tt: (a: string) => string };

export interface TagGroupItem {
	name: string;
	name_translated?: string;
	tag_id: number;
	tag_group_id?: number;
	tag_group_name?: string;
}

@Injectable()
export class TagGroupListService extends EnkoraFetcher<TagGroupItem[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getR2CourseFilterTagGroupTags' }];
	label_value : SelectItem[] = [];

	constructor(call: CallService)
	{
		super(call);
	}

	protected postProcess(reply : TagGroupItem[])
	{
		const title = Helpers.translateKey(`tag_group ${reply[0]?.tag_group_id} name`, reply[0]?.tag_group_name, false).toLowerCase();
		this.label_value = [{label : `(${Enkora.tt('Select')} ${title})`, value : null}];
		each(reply, tag => this.label_value.push({
			label : Helpers.translateKey(`tag ${tag.tag_id} name`, tag.name, true),
			value : tag.tag_id
		}));

		return reply;
	}

}
