import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from 'lodash';

@Pipe({
	name : 'fastcents'
})
export class FastCentsPipe implements PipeTransform {

	transform(cents: string | number, show_currency = true): string
	{
		if (cents == '') return '';

		cents = toNumber(cents);
		if (!cents) cents = 0;

		let value = ((cents / 100).toFixed(2));
		value = (cents / 100).toLocaleString('fi', { maximumFractionDigits : 2, minimumFractionDigits : 2 });

		return show_currency ? `${value} €` : value;
	}
}
