import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { abstractControlToFormGroup, Logger } from 'shared';

@Component({
	selector    : 'cta-ta-form-location-tags',
	templateUrl : './location-tags.form.html',
	styleUrls   : ['./location-tags.form.scss']
})
export class LocationTagsForm implements OnInit {
	get inputForm(): FormArray
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormArray)
	{
		this._inputForm = value;

		if (!value) return;

		this._inputForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			Logger.log('Site reactive form update', data);
			this.onSiteDataChange.emit({ status : this._inputForm.status, data });
		});

		this.onSiteDataChange.emit({ status : this._inputForm.status, data : this._inputForm.value });
	}

	private _inputForm: FormArray;

	@Output() onSiteDataChange = new EventEmitter();
	public id: string;

	constructor()
	{
		this.id = `address${Math.floor(Math.random() * 1000000)}`;
	}

	ngOnInit(): void
	{
	}

	protected readonly abstractControlToFormGroup = abstractControlToFormGroup;
}
