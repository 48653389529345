import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, isEqual } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { AutoSubs, AutoUnsubscribe, CallService, EnkoraMessageService, Helpers, LocationService, Logger } from 'shared';

import {
	CtaStyleCode,
	TaActiveDataHolderService,
	TaCtaStyleService,
	TaFormGroupGeneratorService,
	TaPersonalInfo,
	TaUserIdentifier
} from '../../../../../shared';
import { combineLatest } from 'rxjs';

@Component({
	templateUrl : './personal-info.modal.html',
	styleUrls   : ['./personal-info.modal.scss']
})
@AutoUnsubscribe()
export class PersonalInfoModal implements OnInit, OnDestroy {
	@Input() public standalone = true;
	public latest_value : TaPersonalInfo = null;
	public initial_value : TaPersonalInfo = null;
	public is_valid = false;
	public is_visitor = false;
	public no_changes = true;
	public save_message = '';
	public card_error = '';
	public personalForm: FormGroup;
	public employee_groups: string[];
	public user_identifier: TaUserIdentifier;
	@AutoSubs() private subs;
	private location_id = '';

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            private cta_style: TaCtaStyleService,
	            private formGenerator: TaFormGroupGeneratorService,
	            public dataHolder: TaActiveDataHolderService,
	            private location: LocationService)
	{
		this.user_identifier = this.dataHolder.user_identifier;
		this.subs = location.getLocationId().subscribe(location_id => this.location_id = location_id);
	}

	ngOnInit(): void
	{
		this.subs = combineLatest([
			this.dataHolder.personal_info$,
			this.dataHolder.employee$
		]).subscribe(reply => {
			const [personal_info, employee] = reply;
			this.initPersonalForm(personal_info);
			if (employee.card_error) {
				this.card_error = employee.card_error;
			}
		});

		this.cta_style.getFirst().subscribe();
	}

	ngOnDestroy(): void
	{
	}

	confirm(): void
	{
		if (!this.is_valid) return;

		if (this.no_changes) {

			const params = {
				user_id       : this.initial_value.user_id,
				tax_number    : this.initial_value.tax_number,
				date_of_birth : this.initial_value.date_of_birth.format(Helpers.serverDateFormat)
			};
			this.call.make('cta2/validateTaxNumberForUser', [params]).subscribe();

			this.activeModal.close(null);
			return;
		}

		const saved_data: TaPersonalInfo = cloneDeep(this.latest_value);

		const params = {
			user_id          : saved_data.user_id,
			tax_number       : saved_data.tax_number,
			first_name       : saved_data.first_name,
			last_name        : saved_data.last_name,
			phone_number     : saved_data.phone_number,
			email            : saved_data.email,
			date_of_birth    : saved_data.date_of_birth && saved_data.date_of_birth.format(Helpers.serverDateFormat),
			picture_filename : saved_data.picture_filename,

			need_create_employee : this.is_visitor,
			is_visitor           : this.is_visitor,
			location_id          : this.location_id,

			country_code : saved_data.country.country_code,
			country_name : saved_data.country.country_code == 'XX' ? saved_data.country.country_name : null,
			address      : null,

			home_country_code    : saved_data.home_country.country_code,
			home_country_name    : saved_data.home_country.country_code == 'XX'
				? saved_data.home_country.country_name
				: null,
			home_country_address : null,

			emergency_name         : saved_data.emergency_name,
			emergency_phone_number : saved_data.emergency_phone_number
		};

		if (params.home_country_code != this.cta_style.value.code) {
			params.address = saved_data.country.address;
			params.home_country_address = saved_data.home_country.address;
		}

		this.call.make<{ user_id: string; account_id: string; }>('cta2/saveUserInformation', [params]).subscribe(result => {
			if (result?.user_id) {
				saved_data.user_id = result.user_id;
				saved_data.account_id = result.account_id;
			}
			this.activeModal.close(saved_data);
		}, error => {
			this.messageService.error(error);
		});
	}

	handleUserDataChange(params: { status: string, data: TaPersonalInfo }): void
	{
		this.is_valid = params.status != 'INVALID';

		const new_code = params.data.home_country.country_code;

		if (this.latest_value && new_code != this.latest_value.home_country.country_code
			|| !this.latest_value && new_code) {
			const validators = this.getValidators(new_code);
			this.formGenerator.updateFormGroupValidators(this.personalForm, validators);
		}

		this.latest_value = params.data;
		if (!this.initial_value) this.initial_value = cloneDeep(params.data);
		/*
		!!this.initial_value.account_id added for the case if data came from Person Api, this.initial_value and
		this.latest_value are equal but there is no account id so then we need to save the user
		*/
		this.no_changes = isEqual(this.initial_value, this.latest_value) && !!this.initial_value.account_id;
		this.save_message = this.no_changes ? '' : 'Save and ';
		Logger.log('Form: Modified user data: ', params);
	}

	getValidators(code: string = null): Record<string, unknown>
	{
		const tax_number_pattern = '[\\w\\-.# ]{' + this.user_identifier.length + '}$';
		if (this.is_visitor) {
			return {
				tax_number : { validators : [Validators.pattern(tax_number_pattern)] },
				first_name : { validators : Validators.required },
				last_name  : { validators : Validators.required }
			};
		}

		const validators = {
			tax_number    : { validators : [Validators.required, Validators.pattern(tax_number_pattern)] },
			first_name    : { validators : Validators.required },
			last_name     : { validators : Validators.required },
			date_of_birth : { validators : [Validators.required] },
			country       : {
				country_code : { validators : [] }
			},
			home_country  : {
				country_code : { validators : [] },
				address      : {
					city     : { validators : [] },
					postcode : { validators : [] },
					street   : { validators : [] }
				}
			}
		};

		if (this.cta_style.value.code == CtaStyleCode.SE) {
			validators.tax_number.validators = [Validators.required, Validators.pattern('[\\d]{12}$')];
			validators.date_of_birth.validators = [];
		}

		if (code == this.cta_style.value.code) {
			validators.home_country.address.postcode.validators = [];
		} else {
			const postcode_pattern = TaCtaStyleService.getStyle(code).postcode.pattern;
			validators.home_country.address.postcode.validators = [Validators.pattern(postcode_pattern)];
		}

		return validators;
	}

	private initPersonalForm(personal_info: TaPersonalInfo)
	{
		this.personalForm = this.formGenerator.initPersonalInfo(personal_info, this.getValidators());

		this.personalForm.valueChanges.pipe(debounceTime(300)).subscribe((data: TaPersonalInfo) => {
			this.handleUserDataChange({ status : this.personalForm.status, data });
		});

		this.handleUserDataChange({ status : this.personalForm.status, data : this.personalForm.getRawValue() });
	}
}
