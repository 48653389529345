import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { each } from 'lodash';

import { CallParameter, EnkoraFetcher, Helpers, Logger } from '../../misc';
import { CallService } from '../call.service';

export interface EnkoraLanguage {
	is_active: boolean;
	language_id: string;
	name: string;
	short_name: string;
}

declare let window: { language: string };

@Injectable()
export class SystemLanguageService extends EnkoraFetcher<EnkoraLanguage[]> {

	public map: Record<string, EnkoraLanguage> = {};
	public selected: EnkoraLanguage = null;

	protected params: CallParameter[] = [{ name : 'common/getLanguages' }];

	constructor(call: CallService)
	{
		super(call);

		this.selected = {
			is_active   : true,
			language_id : null,
			name        : window.language,
			short_name  : window.language.trim()
		};

		// not a lazy loaded approach
		this.get();
	}

	public get selected_name(): string
	{
		return this.selected?.short_name?.toLowerCase() || 'en';
	}

	set(id: string): void
	{
		this.changeLanguage(id).subscribe(() => {
			Helpers.reload();
		});
	}

	changeLanguage(id: string): Observable<boolean>
	{
		return this.call.make<boolean>('common/changeLanguage', [id]).pipe(
			map(data => {
				Logger.log('setLanguage', data);
				this.selected = this.map[id];

				return data;
			}), catchError(error => {
				Logger.error('setLanguage', error);
				return throwError(error);
			})
		);
	}

	protected postProcess(reply: EnkoraLanguage[]): EnkoraLanguage[]
	{
		const languages: EnkoraLanguage[] = [];

		this.map = {};
		each(reply, (language: EnkoraLanguage) => {
			if (language.is_active) {
				languages.push(language);
				this.map[language.language_id] = language;
				if (this.selected && this.selected.short_name.toLowerCase() == language.short_name.toLowerCase()) {
					this.selected = language;
				}
			}
		});

		return languages;
	}
}
