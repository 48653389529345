import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ShopCartListService } from '../../services';

@Component({
	selector    : 'shop-giftcart',
	templateUrl : './giftcart.component.html',
	styleUrls   : ['./giftcart.component.scss']
})
export class GiftcartComponent implements OnInit {

	public card_number = '';
	public balance = 0;
	public balance_to_load = 0;
	public message = '';

	@Output() balanceAdded = new EventEmitter();

	constructor(public cartService: ShopCartListService)
	{
	}

	ngOnInit(): void
	{
	}

	addGiftCard(): void
	{
		this.cartService.checkGiftcardBalance(this.card_number).subscribe(data => {
			if (!data.result) {
				this.message = (data as { message: string }).message;
			} else {
				this.message = null;
				this.balance = data.balance;
				this.balance_to_load = this.balance;
			}
		}, error => {
			this.message = error;
		});

	}

	loadGiftCardAmount(): void
	{
		if (!this.balance_to_load) {
			return;
		}
		this.cartService.loadBalanceFromGiftcard(this.card_number, this.balance_to_load).subscribe(() => {
			this.balance = null;
			this.message = null;
			this.card_number = null;
			this.balanceAdded.emit({});
		}, error => {
			this.balance = null;
			this.message = error;
		});
	}
}
