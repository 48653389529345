/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each } from 'lodash';

import { CallParameter, Constants, EnkoraFetcher, Helpers } from '../misc';
import { CallService } from './call.service';

export interface WebModule {
	_is_active: boolean;

	[parameter_name: string]: any;
}

export interface WebParameters {
	[module_id: number]: WebModule;
}

@Injectable()
export class ParameterService extends EnkoraFetcher<WebParameters> {

	protected params: CallParameter[] = [{ name : 'web/getWebParameters' }];

	constructor(public call: CallService)
	{
		super(call);
	}

	public getValue(parameter_name: string, module_id: number = Constants.NM_MAIN): Observable<string>
	{
		return this.get().pipe(
			map(values => values[module_id][parameter_name]),
			first()
		);
	}

	public getTyped<Type>(parameter_name: string, module_id: number = Constants.NM_MAIN): Observable<Type>
	{
		return this.get().pipe(
			map(values => values[module_id][parameter_name]),
			first()
		);
	}

	public getNumberValue(parameter_name: string, module_id: number = Constants.NM_MAIN, default_value?: number): Observable<number | undefined>
	{
		return this.getValue(parameter_name, module_id).pipe(
			map(value => value ? +value : default_value)
		);
	}

	public getBooleanValue(parameter_name: string, module_id: number = Constants.NM_MAIN, default_value?: boolean): Observable<boolean>
	{
		return this.getValue(parameter_name, module_id).pipe(
			map(value => value ?? default_value),
			map(value => !!value),
		);
	}

	public getValues(parameter_names: string[], module_id: number = Constants.NM_MAIN): Observable<any[]>
	{
		return this.get().pipe(
			map(values => {
				const module = values[module_id];

				const ret = [];

				each(parameter_names, parameter_name => {
					ret.push(module[parameter_name]);
				});

				return ret;
			}),
			first()
		);
	}

	public getValuePairs(pairs: [string, number?][]): Observable<string[]>
	{
		return this.get().pipe(
			map(values => {
				const ret: string[] = [];

				each(pairs, pair => {
					const module = values[pair[1] ?? Constants.NM_MAIN];
					ret.push(module[pair[0]]);
				});

				return ret;
			}),
			first()
		);
	}

	public getArray(parameter_name: string, module_id: number = Constants.NM_MAIN): Observable<string[]>
	{
		return this.getValue(parameter_name, module_id).pipe(
			map(value => {
				return Helpers.split(value);
			})
		);
	}
}

